import { config, login, api } from 'base-client';

import { configMap } from 'configurations';
import { actions as tenantActions } from 'tenant';
import { actions as analyticsActions } from 'analytics';
import { actions as accountActions } from 'userAccount';

const start = (serverUrl) => async (dispatch) => {
    await dispatch(config.actions.start(configMap));
    const host =
        process.env.REACT_APP_API_HOST || dispatch(config.actions.getData(configMap.api.name, configMap.api.host.name));
    const gateway =
        process.env.REACT_APP_API_GATEWAY ||
        dispatch(config.actions.getData(configMap.api.name, configMap.api.gateway.name)); // concora api

    dispatch(api.actions.start(host, gateway));

    await dispatch(tenantActions.start(serverUrl));

    if (serverUrl) return;

    const {
        [configMap.auth.domain.name]: domain,
        [configMap.auth.clientId.name]: clientID,
        [configMap.auth.audience.name]: audience,
    } = dispatch(config.actions.getData(configMap.auth.name)) || {};
    dispatch(login.actions.start({ domain, clientID, audience }));

    await dispatch(login.actions.checkLogin());

    const {
        [configMap.analytics.projectId.name]: projectId,
        [configMap.analytics.writeKey.name]: writeKey,
        [configMap.analytics.segment.name]: segmentKey,
    } = dispatch(config.actions.getData(configMap.analytics.name)) || {};

    dispatch(analyticsActions.start({ projectId, writeKey, segmentKey }));
    dispatch(analyticsActions.userVisitorId());

    await dispatch(accountActions.fillAccount());
};

export default { start };
