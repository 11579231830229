import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { history } from 'base-client';
import { Modal } from 'shared';
import { actions as projectActions } from 'projects';
import { actions as detailsActions } from 'projectDetails';
import SelectProject from './SelectProject';
import CreateNewProject from './CreateNewProject';

const ModalContent = styled.div`
    min-width: 50rem;
    font-size: 1.4rem;
`;

const FormGroup = styled.div`
    width: 100%;
    padding: 1.5rem 0 2rem;
    display: flex;
    flex-direction: column;
    &:first-child {
        padding-top: 0;
    }
    &.options {
        label {
            &:first-child {
                margin-right: 2rem;
            }
        }
    }
    &.projectInfor {
        padding-bottom: 0;
        display: flex;
    }
    .projectList {
        display: inline-block;
        width: 80%;
    }
`;

const Label = styled.label`
    cursor: pointer;
    font-weight: 500;
    margin-bottom: 8px;
    input {
        margin-right: 0.5rem;
    }
    &.required {
        position: relative;
        &:after {
            position: absolute;
            content: '*';
            top: 0;
            right: -1rem;
            color: ${(props) => props.theme.red};
        }
    }
`;

const ChooseAnotherProjectModal = ({ productDetails, currentProject, onClose, dispatch }) => {
    const [type, setType] = useState(2);
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        getProjects();
    }, []);

    const getProjects = async () => {
        const ommitId = currentProject ? currentProject.id : null;
        const result = await dispatch(projectActions.getAllProjects());
        if (result && result.length > 0) {
            const list = result
                .map(({ id, name }) => ({ id, name }))
                .filter(({ id }) => {
                    return id !== ommitId;
                })
                .sort(({ name: a }, { name: b }) => a.toLowerCase().localeCompare(b.toLowerCase()));

            setProjects(list);
            list.length < 1 ? setType(1) : setType(0);
        } else {
            setType(1);
        }
    };

    const toggleProjectType = (evt) => {
        const { value } = evt.target;
        setType(parseInt(value));
    };

    const modalProps = {
        title: projects.length < 1 ? 'No other projects, create a new one' : 'Choose another project',
        open: true,
        onClose: () => onClose(),
    };

    const handleCreateProject = async ({ name, zipCode }) => {
        const result = await dispatch(projectActions.createProject({ name, zipCode }));
        if (result) {
            if (productDetails) {
                await dispatch(detailsActions.getProject(result.id));
                await dispatch(
                    detailsActions.loadSavedProducts({
                        projectId: result.id,
                        restart: true,
                        noQuery: true,
                    })
                );
            } else {
                history.location.pathname.includes('submittal')
                    ? history.push(`/projects/${result.id}/submittal`)
                    : history.push(`/projects/${result.id}/brochure`);
            }
            onClose(true);
        }
        return result;
    };

    const handleSelectProject = async (obj) => {
        const { id } = obj;
        if (productDetails) {
            await dispatch(detailsActions.getProject(id));
            await dispatch(detailsActions.loadSavedProducts({ projectId: id, restart: true, noQuery: true }));
        } else if (currentProject.id !== id) {
            history.location.pathname.includes('submittal')
                ? history.push(`/projects/${id}/submittal`)
                : history.push(`/projects/${id}/brochure`);
        }
        onClose(currentProject.id !== id);
        return;
    };

    const CardContainer = styled.div`
        display: flex;
        flex-direction: column;
    `;

    return type === 2 ? null : (
        <Modal {...modalProps}>
            <ModalContent>
                <CardContainer>
                    {projects && projects.length > 0 && (
                        <FormGroup className="options">
                            <Label htmlFor="useExistProject">
                                <input
                                    type="radio"
                                    id="useExistProject"
                                    name="Use Existing Project"
                                    value={0}
                                    checked={type === 0}
                                    onClick={(e) => toggleProjectType(e)}
                                    readOnly
                                />
                                Use Existing Project
                            </Label>
                            <Label htmlFor="startNewProject">
                                <input
                                    type="radio"
                                    id="startNewProject"
                                    name="Start New Project"
                                    value={1}
                                    checked={type === 1}
                                    readOnly
                                    onClick={(e) => toggleProjectType(e)}
                                />
                                Start New Project
                            </Label>
                        </FormGroup>
                    )}
                    {type === 0 && <SelectProject {...{ projects, handleSelectProject }} />}
                    {type === 1 && <CreateNewProject {...{ handleCreateProject }} />}
                </CardContainer>
            </ModalContent>
        </Modal>
    );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(ChooseAnotherProjectModal);
