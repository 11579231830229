import React from 'react';
import styled from 'styled-components';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { Card, StyledTabs } from 'shared';
import TabProjectDetails from './TabProjectDetails';
import TabContactInformation from './TabContactInformation';
import TabAdditionalInformation from './TabAdditionalInformation';

const TabContent = styled.div`
    padding: 2rem 3rem;
`;

const ProjectDetailsTabCard = ({ ...generalData }) => (
    <Card className="project-detail-card">
        <StyledTabs>
            <Tabs>
                <TabList>
                    <Tab key={1}>Project Details</Tab>
                    <Tab key={2}>Additional Information</Tab>
                    <Tab key={3}>Contact Information</Tab>
                </TabList>
                <TabContent className="tab-content">
                    <TabPanel key={1}>
                        <TabProjectDetails {...generalData} {...generalData.address} />
                    </TabPanel>
                    <TabPanel key={2}>
                        <TabAdditionalInformation {...generalData} />
                    </TabPanel>
                    <TabPanel key={3}>
                        <TabContactInformation {...generalData} />
                    </TabPanel>
                </TabContent>
            </Tabs>
        </StyledTabs>
    </Card>
);

export default ProjectDetailsTabCard;
