import React from 'react';
import { connect } from 'react-redux';
import { reducerUtil } from 'base-client';
import styled from 'styled-components';

import { FormInput, LinkButton, PoweredConcora } from 'shared';
import { reducerData as tenantData } from 'tenant';

const HelpText = styled.p`
    font-size: 1.4rem;
    line-height: 1.45;
    margin: 1.5rem 0 3rem;
`;

const LoginForm = styled.form`
    text-align: center;
`;

const EmailPassword = ({ email, emailError, handleInputChange, handleResetPasswordLink, tenantId }) => (
    <>
        <HelpText>Enter your Sign In email address so we can send you steps on how to reset your password.</HelpText>
        <LoginForm noValidate>
            <FormInput
                onChange={handleInputChange}
                value={email}
                field="email"
                labelTitle="Email Address"
                placeholder="Enter your email address"
                type="email"
                hasError={!!emailError}
                errorMessage={emailError}
                required
            />
            <LinkButton
                small
                marginTop="2rem"
                marginBottom="2rem"
                onClick={() => handleResetPasswordLink({ tenantId })}
            >
                Send Email
            </LinkButton>
            <PoweredConcora margin="1rem 0 0" />
        </LoginForm>
    </>
);

const mapStateToProps = (state) => ({
    tenantId: reducerUtil.getSlice(tenantData, tenantData.tenant, state),
});

export default connect(mapStateToProps, null)(EmailPassword);
