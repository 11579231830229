import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { login, reducerUtil } from 'base-client';

import { actions as analyticsActions } from 'analytics';
import { reducerData as tenantData } from 'tenant';
import ExternalLink from './ExternalLink';
import protocolFix from 'utils/protocolFix';

const FileContainer = ({ canDownload, meta, title, url, link, children, dispatch, ...props }) => {
    const onClick = canDownload
        ? () => dispatch(analyticsActions.trackDownload(meta))
        : () => dispatch(login.actions.toLogin());

    const href = canDownload ? protocolFix(url) || protocolFix(link) : undefined;

    if (url) {
        return (
            <a download={title} {...{ onClick, href }} target="_blank" rel="noopener noreferrer" {...props}>
                {React.Children.map(children, (child) => child && React.cloneElement(child, { canDownload }))}
            </a>
        );
    } else if (link) {
        return (
            <ExternalLink {...{ href, onClick }} {...props}>
                {React.Children.map(children, (child) => child && React.cloneElement(child, { canDownload }))}
            </ExternalLink>
        );
    } else return <div>{React.Children.map(children, (child) => child && React.cloneElement(child))}</div>;
};

FileContainer.propTypes = {
    canDownload: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    url: PropTypes.string,
    title: PropTypes.string,
    meta: PropTypes.object,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
};
FileContainer.defaultProps = {
    name: undefined,
    children: undefined,
};

const mapStateToProps = (state) => {
    const { download } = reducerUtil.getSlice(tenantData, tenantData.requireLogin, state) || {};
    const token = reducerUtil.getSlice(login.reducerData, login.reducerData.token, state);
    return { canDownload: !download || (download && !!token) };
};

export default connect(mapStateToProps)(FileContainer);
