import { config, login, reducerUtil, history } from 'base-client';
import { configMap } from 'configurations';
import KeenTracking from 'keen-tracking';
import ReactGA from 'react-ga';
import segmentLoader from 'analytics.js-loader';
import { v4 as uuid } from 'uuid';

import { actions as analyticsActions } from 'analytics';
import { reducerData as tenantData } from 'tenant';
import { reducerData as userData } from 'userAccount';

const cookieName = 'design-studio-cookie';
const guestPrefix = '77777777-7777-';
const visitorIdName = 'guest_id';
let keen;
let segment;

const start =
    ({ segmentKey, ...keenData }) =>
    (dispatch) => {
        if (keenData.projectId) {
            keen = new KeenTracking({ ...keenData });

            if (segmentKey) {
                segment = segmentLoader({ writeKey: segmentKey, skipPageCall: true });
            }

            dispatch(setVisitorId());
            dispatch(setData());
            dispatch(trackPageViews());
        }
    };

const setVisitorId = () => (dispatch) => {
    if (!dispatch(getVisitorId())) dispatch(newVisitorId());
    else dispatch(updateSegmentId());
};

const newVisitorId = (newId) => (dispatch) => {
    const cookie = KeenTracking.utils.cookie(cookieName);
    cookie.set(visitorIdName, newId || KeenTracking.helpers.getUniqueId());
    dispatch(updateSegmentId());
};

const getVisitorId = () => () => {
    const cookie = KeenTracking.utils.cookie(cookieName);
    return cookie.get(visitorIdName);
};

const getUserId = () => (dispatch, getState) => {
    const guestState = getState().guest;
    if (guestState && guestState.generalData && guestState.generalData.user_id) {
        return guestState.generalData.user_id;
    }

    const profile = reducerUtil.getSlice(login.reducerData, login.reducerData.profile, getState());
    const { 'https://permissions': permissions } = profile || {};
    const { user_id } = permissions || {};
    return user_id;
};

const userVisitorId = () => (dispatch, getState) => {
    const profile = reducerUtil.getSlice(login.reducerData, login.reducerData.profile, getState());
    const { 'https://permissions': permissions } = profile || {};
    const { visitor_id: visitorId } = permissions || {};
    if (visitorId) dispatch(newVisitorId(visitorId));
};

const setData = () => (dispatch, getState) => {
    keen.extendEvents(() => {
        const tenant_id = reducerUtil.getSlice(tenantData, tenantData.tenant, getState()) || {};
        const user_id = dispatch(getUserId());
        const visitor_id = dispatch(getVisitorId());

        return {
            tenant_id,
            visitor_id,
            user_id,
            site: window.location.hostname,
            source: 'designStudio',
            geo: {
                /* Enriched data from the API will be saved here */
                /* https://keen.io/docs/api/?javascript#ip-to-geo-parser */
                // eslint-disable-next-line no-template-curly-in-string
                ip_address: '${keen.ip}',
            },
            page: {
                /* Enriched */
                title: document.title,
                url: document.location.href,
            },
            referrer: {
                /* Enriched */
                url: document.referrer,
            },
            tech: {
                /* Enriched */
                browser: KeenTracking.helpers.getBrowserProfile(),
                // eslint-disable-next-line no-template-curly-in-string
                user_agent: '${keen.user_agent}',
            },
            keen: {
                addons: [
                    {
                        name: 'keen:ip_to_geo',
                        input: {
                            ip: 'geo.ip_address',
                        },
                        output: 'geo',
                    },
                    {
                        name: 'keen:ua_parser',
                        input: {
                            ua_string: 'tech.user_agent',
                        },
                        output: 'tech',
                    },
                    {
                        name: 'keen:url_parser',
                        input: {
                            url: 'page.url',
                        },
                        output: 'page',
                    },
                    {
                        name: 'keen:referrer_parser',
                        input: {
                            referrer_url: 'referrer.url',
                            page_url: 'page.url',
                        },
                        output: 'referrer',
                    },
                ],
            },
        };
    });
};

const updateSegmentId = () => (dispatch, getState) => {
    if (!segment) return;

    const user_id = dispatch(getUserId());

    if (user_id) {
        const profile =
            user_id.substr(0, 14) === guestPrefix
                ? {
                      sub: null,
                      given_name: 'Guest',
                      family_name: 'User',
                      nickname: 'Guest',
                      name: 'Guest User',
                      locale: null,
                      updated_at: null,
                      picture: null,
                  }
                : reducerUtil.getSlice(userData, userData.data, getState());
        segment.identify(user_id, profile || {});
    } else {
        segment.reset();
    }
};

const extendSegmentEvent = () => (dispatch, getState) => {
    if (!segment) return;

    const tenant_id = reducerUtil.getSlice(tenantData, tenantData.tenant, getState()) || {};
    const user_id = dispatch(getUserId());
    const visitor_id = dispatch(getVisitorId());

    let profile;
    if (user_id) {
        const profile =
            user_id.substring(0, 14) === guestPrefix
                ? {
                      sub: null,
                      given_name: 'Guest',
                      family_name: 'User',
                      nickname: 'Guest',
                      name: 'Guest User',
                      locale: null,
                      updated_at: null,
                      picture: null,
                  }
                : reducerUtil.getSlice(userData, userData.data, getState());
        segment.identify(user_id, profile || {});
    }

    return {
        profile,
        tenant_id,
        visitor_id,
        user_id,
        site: window.location.hostname,
        source: 'designStudio',
        page: {
            title: document.title,
            url: document.location.href,
        },
        referrer: {
            url: document.referrer,
        },
        tech: {
            browser: KeenTracking.helpers.getBrowserProfile(),
        },
    };
};

const track =
    (event, meta = {}, segmentOnly) =>
    async (dispatch) => {
        const event_id = uuid();
        const data = { type: event, event_id, eventMetadata: meta };
        if (meta.tenant_id) data.tenant_id = meta.tenant_id;

        try {
            if (keen && !segmentOnly) {
                await keen.recordEvent(event, data);
            }

            if (segment) {
                const segmentEvent = dispatch(extendSegmentEvent());
                if (event === 'pageview') {
                    segment.page(null, { ...data, ...segmentEvent });
                } else {
                    segment.track(event, { ...data, ...segmentEvent });
                }
            }
        } catch (error) {
            dispatch(config.actions.error(error));
        }
        return event_id;
    };

const trackDownload = (meta) => (dispatch) => dispatch(track('download', meta));

const trackClick =
    (url, meta = {}) =>
    (dispatch) =>
        dispatch(track('click', { url, ...meta }));

const trackPageViews = () => (dispatch) => {
    dispatch(track('referral'));
    history.listen((location, action) => {
        if (location) {
            //ReactGA.pageview(location.pathname + location.search);
            dispatch(track('pageview', { action }));
        }
    });
};

const reinitialize = () => (dispatch) => {
    const {
        [configMap.analytics.projectId.name]: projectId,
        [configMap.analytics.writeKey.name]: writeKey,
        [configMap.analytics.segment.name]: segmentKey,
    } = dispatch(config.actions.getData(configMap.analytics.name)) || {};

    dispatch(analyticsActions.start({ projectId, writeKey, segmentKey }));
};

export default {
    getUserId,
    getVisitorId,
    newVisitorId,
    reinitialize,
    start,
    track,
    trackClick,
    trackDownload,
    updateSegmentId,
    userVisitorId,
};
