import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import styled from 'styled-components';

import CloseIcon from './CloseIcon';

const defaultStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        maxWidth: '90%',
        maxHeight: '95%',
        transform: 'translate(-50%, -50%)',
        padding: '2rem 2rem 0',
    },
    overlay: {
        background: 'rgba(0, 0, 0, 0.5)',
        zIndex: '9999',
    },
};

const Header = styled.div`
    display: inline-flex;
    width: 100%;
    margin-top: -1rem;
`;

const Title = styled.h3`
    margin: 0 0 2rem;
    padding-right: 3rem;
`;

global.document && ReactModal.setAppElement(document.getElementById('root'));

const Modal = ({
    children,
    open,
    onOpen,
    onClose,
    title,
    disableCloseOutside,
    customStyles,
    closeTimeoutMS,
    ...props
}) => {
    let styles = { ...defaultStyles };

    if (customStyles) {
        const { content: defautl } = defaultStyles;
        styles = {
            ...styles,
            content: {
                ...defautl,
                ...customStyles,
            },
        };
    }
    return (
        <ReactModal
            isOpen={open}
            onAfterOpen={onOpen}
            onRequestClose={disableCloseOutside ? undefined : onClose}
            style={styles}
            contentLabel={title}
            closeTimeoutMS={closeTimeoutMS || 500}
            shouldCloseOnOverlayClick={false}
            {...props}
        >
            <Header>
                {title && <Title>{title}</Title>} <CloseIcon onClick={onClose} height={16} />
            </Header>
            {children}
        </ReactModal>
    );
};

Modal.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]).isRequired,
    onClose: PropTypes.func.isRequired,
    onOpen: PropTypes.func,
    disableCloseOutside: PropTypes.bool,
    open: PropTypes.bool,
    title: PropTypes.string,
    customStyles: PropTypes.object,
};

Modal.defaultProps = {
    open: false,
    title: '',
};

export default Modal;
