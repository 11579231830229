import React from 'react';
import styled from 'styled-components';

const CarouselPagination = ({ pageCount, currentPage, setCurrentPage, className, color = 'light', mobile = false }) => {
    const paginationClasses = `${color} ${mobile ? 'mobile' : 'desktop'}`;

    return (
        <PageSelectorContainer pageCount={pageCount} className={`${className} ${paginationClasses}`}>
            <PageArrowLeft
                enabled={currentPage > 0}
                onClick={() => currentPage > 0 && setCurrentPage({ type: 'prev' })}
                className={paginationClasses}
            >
                <LeftArrow className={paginationClasses} />
            </PageArrowLeft>
            {!mobile &&
                [...Array(pageCount)].map((x, idx) => (
                    <PageSelector
                        onClick={() => setCurrentPage(idx)}
                        selected={currentPage === idx}
                        className={paginationClasses}
                        key={idx}
                    />
                ))}
            <PageArrowRight
                enabled={currentPage < pageCount - 1}
                onClick={() => currentPage < pageCount - 1 && setCurrentPage({ type: 'next' })}
                className={paginationClasses}
            >
                <RightArrow className={paginationClasses} />
            </PageArrowRight>
        </PageSelectorContainer>
    );
};

const mobilePageSelectorStyles = `
  width: 100%; 
  height: 100%; 
  padding: 0 5%; 
  align-items: center;
`;

const desktopPageSelectorStyles = `
  margin: 16px auto 30px auto;
  width: fit-content;
`;

const PageSelectorContainer = styled.div`
    z-index: 2;
    display: flex;
    justify-content: space-between;

    ${(props) => (props.className.includes('mobile') ? mobilePageSelectorStyles : desktopPageSelectorStyles)}
`;

const Arrow = styled.div`
    border: solid ${(props) => (props.className.includes('dark') ? '#fff' : '#77899e')};
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    margin: auto;
    position: relative;
`;

const PageArrow = styled.div`
    width: 24px;
    height: 24px;
    border-radius: 100%;
    display: flex;
    ${(props) => (props.enabled ? 'cursor: pointer;' : '')}
    background-color: ${(props) => (props.className.includes('dark') ? props.theme.grey6 : '#dee5ed')}
  &:hover {
        background-color: ${(props) => (props.className.includes('dark') ? '#000' : '#c3cdd9')};
    }
`;

const LeftArrow = styled(Arrow)`
    left: 1px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
`;

const RightArrow = styled(Arrow)`
    right: 1px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
`;

const PageArrowLeft = styled(PageArrow)`
    margin-right: 8px;
`;

const PageArrowRight = styled(PageArrow)`
    margin-left: 8px;
`;

const PageSelector = styled.div`
    cursor: pointer;
    border-radius: 100%;
    width: 11px;
    height: 11px;
    border: 1px solid ${(props) => (props.className.includes('dark') ? props.theme.grey6 : '#5a6678')};
    &:hover {
        background-color: ${(props) => (props.className.includes('dark') ? props.theme.grey6 : '#c3cdd9')};
    }
    margin: auto 5px;
    z-index: 2;
    background-color: ${(props) =>
        props.className.includes('dark')
            ? props.selected
                ? props.theme.grey6
                : 'transparent'
            : props.selected
            ? '#5A6678'
            : 'white'};
`;

export default CarouselPagination;
