import React from 'react';
import PropTypes from 'prop-types';

const FormDropdownOption = ({ optionLabel, ...props }) => <option {...props}>{optionLabel}</option>;

FormDropdownOption.propTypes = {
    optionLabel: PropTypes.string.isRequired,
};

export default FormDropdownOption;
