import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// import { withRouter } from 'react-router-dom';
// import { reducerUtil } from 'base-client';
// import { reducerData as tenantData } from 'tenant';
import { actions as productSearchActions } from './ProductSearch';
import CarouselPagination from '../../shared/CarouselPagination';
import useBoundingClientRect from 'utils/useBoundingClientRect';
import truncateString from '../../utils/truncateString';
import useCarouselReducer from '../../shared/hooks/useCarouselReducer';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 424px;
    background-color: white;
    margin-bottom: 48px;
`;

const Title = styled.h1`
    font-size: 24px;
    font-weight: 700;
    margin: 30px auto 24px auto;
`;
const Description = styled.div`
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    margin: 0px auto 24px auto;
    width: 800px;
`;

const FeaturedProductsContainer = styled.div`
    display: flex;
    width: 100%;
    height: 300px;
    justify-content: center;
    padding: 0px 12px;
`;

const ProductCardContainer = styled(Link)`
    width: 300px;
    min-height: 280px;
    display: flex;
    flex-direction: column;
    padding: 0px 16px 16px 16px;
`;

const ProductCardImage = styled.img`
    height: 150px;
    max-width: 100%;
    object-fit: contain;
    margin: 0px auto 15px auto;
`;

const ProductCardName = styled.div`
    color: #5a6678;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    margin: 0px 3px 7px 3px;
`;

const ProductCardDescription = styled.div`
    color: #5a6678;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
`;

const ProductCard = ({ name, thumbnail, description, id }) => {
    return (
        <ProductCardContainer to={id ? { pathname: `/products/${id}` } : '#'}>
            <ProductCardImage src={thumbnail} />
            <ProductCardName>{name}</ProductCardName>
            <ProductCardDescription>{truncateString(description, 15)}</ProductCardDescription>
        </ProductCardContainer>
    );
};

const transitionSpeed = 1; // seconds
const swipeSensitivity = 20;

const FeaturedProducts = ({ dispatch, title, description, products }) => {
    const [carouselState, dispatchCarousel] = useCarouselReducer({
        transitionSpeed,
        swipeSensitivity,
    });
    const [pageCount, setPageCount] = useState(0);
    const [productsPerPage, setProductsPerPage] = useState(4);
    const [loadedProducts, setLoadedProducts] = useState([]);
    const [bounds, setBounds] = useState();
    const containerRef = useRef();
    const containerRect = useBoundingClientRect(containerRef);

    useEffect(() => {
        if (containerRef && containerRef.current) {
            if (containerRect) {
                setBounds(containerRect);
            }
        }
    }, [containerRect]);

    useEffect(() => {
        const loadProducts = async () => {
            const productsFromAPI = await dispatch(productSearchActions.getFeaturedProducts(products));
            if (productsFromAPI) setLoadedProducts(productsFromAPI);
        };
        loadProducts();
    }, [products]);

    useEffect(() => {
        let columns = (bounds && bounds.width && Math.floor(bounds.width / 300)) || 4;
        setProductsPerPage(columns);
        dispatchCarousel({ type: 'count', value: Math.ceil(loadedProducts.length / columns) });
    }, [bounds, loadedProducts]);
    return (
        <Container ref={containerRef}>
            <Title>{title || 'Featured Products'}</Title>
            {description && <Description>{description}</Description>}
            <FeaturedProductsContainer>
                {loadedProducts
                    .slice(productsPerPage * carouselState.index)
                    .slice(0, productsPerPage)
                    .map((product, idx) => (
                        <ProductCard {...product} key={idx} />
                    ))}
            </FeaturedProductsContainer>
            <CarouselPagination
                pageCount={carouselState.slideCount}
                currentPage={carouselState.index}
                setCurrentPage={(action) => {
                    dispatchCarousel(action);
                }}
                color="light"
            />
        </Container>
    );
};

export default connect()(FeaturedProducts);
