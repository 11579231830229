import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reducerUtil } from 'base-client';
import { actions as detailsActions, reducerData } from 'projectDetails';
import { Button } from 'shared';

const StyledButton = styled(Button)`
    width: initial;
`;

const TransitionButton = ({ dispatch, addProduct, project, textOnly, generalData, inverse, step, text, style }) => {
    if (!project && !generalData) return null;
    return (
        <StyledButton
            onClick={async () => {
                if (addProduct) {
                    addProduct();
                    return;
                }

                if (text.includes('Brochure')) {
                    await dispatch(detailsActions.goToBrochure(project || generalData.id, step));
                } else {
                    await dispatch(detailsActions.goToSubmittal(project || generalData.id, step));
                }
            }}
            fullWidth
            btnLink={textOnly}
            inverse={inverse}
            style={style}
            noUpperCase
        >
            {text}
        </StyledButton>
    );
};

const mapStateToProps = (state) => ({
    generalData: reducerUtil.getSlice(reducerData, reducerData.generalData, state),
});

export default withRouter(connect(mapStateToProps)(TransitionButton));
