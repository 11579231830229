import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Tooltip } from 'shared';

const StyledWrapper = styled.div`
    width: 100%;
    margin: 0 auto;
`;

const StyledProgressBar = styled.ul`
    list-style-type: none;
    margin: 0;
    padding: 0;
`;

const StepWrapper = styled.li`
    float: left;
    height: 4px;
    width: ${(props) => `${100 / (props.count - 1)}%`};
    background-color: ${(props) => props.theme.grey1};
    &.completed {
        background-color: ${(props) => props.theme.purple1};
        .circle {
            background: ${(props) => props.theme.purple1};
            box-shadow: 0 0 0 6px ${(props) => props.theme.purple1};
            &:after {
                position: absolute;
                display: block;
                content: '';
                left: 1px;
                top: -2px;
                width: 4px;
                height: 7px;
                border: solid ${(props) => props.theme.white};
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);
            }
        }
    }
    &.active {
        .circle {
            background-color: ${(props) => props.theme.purple1};
            box-shadow: 0 0 0 4px ${(props) => props.theme.purple3}, 0 0 0 7px ${(props) => props.theme.white},
                0 0 3px 9px rgba(0, 0, 0, 0.15);
        }
        .tooltip {
            opacity: 1;
        }
    }
    &:last-child {
        width: 0;
    }
    .tooltip {
        top: -9.2rem;
        left: -6rem;
        right: -6rem;
        text-align: center;
        white-space: nowrap;
        font-size: 1.2rem;
        font-weight: normal;
        background-color: ${(props) => props.theme.purple3};
        border: 2px solid ${(props) => props.theme.purple1};
        color: ${(props) => props.theme.grey5};
        &:before {
            bottom: -0.6rem;
            background: ${(props) => props.theme.purple3};
            border-right: 2px solid ${(props) => props.theme.purple1};
            border-bottom: 2px solid ${(props) => props.theme.purple1};
        }
    }
`;

const StyledContent = styled.div`
    position: relative;
    display: inline-block;
    &:hover {
        .tooltip {
            opacity: 1;
        }
    }
`;

const StyledCircle = styled.div`
    width: 8px;
    height: 8px;
    border-radius: 100%;
    box-shadow: 0 0 0 4px ${(props) => props.theme.grey1};
    position: absolute;
    top: -19px;
    left: -3px;
    background: ${(props) => props.theme.white};
    transition: all 0.2s ease-in;
    cursor: ${(props) => (props.noProductsSelected ? 'not-allowed' : 'pointer')};
    &:hover {
        .progress-tooltip > .tooltip-content {
            opacity: 1;
            z-index: 9999;
        }
    }
`;

const TooltipTitle = styled.div`
    text-transform: uppercase;
    font-weight: 500;
    color: ${(props) => props.theme.grey5};
`;

const ProgressiveWizard = ({ steps, activeStep, moveStep, noProductsSelected }) => {
    if (!steps || (steps && steps.length < 1)) {
        return null;
    }
    const activeStepIndex = steps.findIndex(({ step }) => step === activeStep);

    const stepsCount = (steps && steps.length) || 0;

    const handleStepClick = (stepIndex, step) => {
        if (stepIndex > activeStepIndex + 1 || stepIndex === activeStepIndex) return;
        moveStep(step);
    };
    return (
        <StyledWrapper className="progressive-wizard">
            <StyledProgressBar>
                {steps.map(({ step, text }, index) => {
                    let stepStatus = '';
                    if (!activeStep) {
                        stepStatus = 'inactive';
                    } else if (step === activeStep) {
                        stepStatus = 'active';
                    } else if (index < activeStepIndex || activeStepIndex > stepsCount) {
                        stepStatus = 'completed';
                    }

                    return (
                        <StepWrapper key={step} count={stepsCount} className={stepStatus}>
                            <StyledContent>
                                <StyledCircle
                                    className="circle"
                                    onClick={() => {
                                        noProductsSelected
                                            ? console.error('step is currently disabled')
                                            : handleStepClick(index, step);
                                    }}
                                    noProductsSelected={noProductsSelected}
                                />
                                <Tooltip>
                                    <TooltipTitle>{`Step ${index + 1}`}</TooltipTitle>
                                    <div>{text}</div>
                                </Tooltip>
                            </StyledContent>
                        </StepWrapper>
                    );
                })}
            </StyledProgressBar>
        </StyledWrapper>
    );
};

ProgressiveWizard.propTypes = {
    steps: PropTypes.arrayOf(
        PropTypes.shape({
            step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            text: PropTypes.string,
        })
    ),
    activeStep: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    moveStep: PropTypes.func,
};

export default ProgressiveWizard;
