import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { reducerData as tenantData } from 'tenant';
import { history, reducerUtil, login } from 'base-client';

import { PageHeader, Row, Column, Loading } from 'shared';

import { actions as guestActions, reducerData as guestData } from 'guest';
import { actions as detailsActions, reducerData as detailsData } from 'projectDetails';
import { actions as editActions } from 'projectEdit';
import { actions as proposalActions, reducerData as proposalData } from 'proposal';

import { Button } from 'shared';
import { ErrorProjectLogin } from 'errors';
import OrderProducts from './OrderProducts';
import ProgressiveWizard from './ProgressiveWizard';
import ProjectEdit from 'projectEdit';
import ProposalReview from './ProposalReview';
import SelectDocuments from './SelectDocuments';
import WorkflowProductSearch from 'workflowProductSearch';

const StyledProposal = styled.div`
    position: relavtive;
`;

const StyledHeader = styled.div`
    margin-top: 2rem;
    .rowHeader {
        align-items: center;
    }
    .progressive-wizard {
        width: 100%;
    }
    .btnCol {
        display: flex;
        justify-content: flex-end;
    }
`;

const ProgressionsButton = styled(Button)`
    font-size: 1.6rem;
    font-weight: 700;
    height: 45px;
    padding: 13px 20px;
    ${(props) =>
        props.rightArrow
            ? `&:after {
    content: '';
    display: inline-block;
    width: 6px;
    height: 6px;
    position: relative;
    top: -2px;
    right: -0.8rem;
    border: none;
    border-right: 2px solid
      ${(props) => (props.color ? props.color : props.secondary ? props.theme.grey3 : props.theme.white)};
    border-top: 2px solid
      ${(props) => (props.color ? props.color : props.secondary ? props.theme.grey3 : props.theme.white)};
    transform: rotate(45deg);
  }`
            : ''}
`;

const ProgressContainer = styled(Column)`
    overflow: visible !important;
`;

const Proposal = ({
    dispatch,
    form,
    generalData,
    guestGeneralData,
    guestOccupation,
    guestProducts,
    location,
    match,
    products,
    projectSubmittalSettings,
    theme,
    token,
}) => {
    const { defaultStep } = (location && location.state) || {};
    const [isLoading, setIsLoading] = useState(true);
    const [step, setStep] = useState(defaultStep || 'product-search');
    const [noProductsSelected, setNoProductsSelected] = useState(true);

    const { brochureEnabled } = (theme && theme) || false;
    const isBrochure = brochureEnabled && location.pathname.endsWith('/brochure');
    const id = match && match.params && match.params.id;
    useEffect(() => {
        const loadData = async () => {
            setIsLoading(true);
            await dispatch(detailsActions.getProject(id));
            await dispatch(detailsActions.loadSavedProducts({ projectId: id, restart: true, forSubmittal: true }));
            setIsLoading(false);
        };
        if (!guestOccupation && id !== 'guest') {
            loadData();
        } else {
            setIsLoading(false);
        }
    }, [id]);

    useEffect(() => {
        if (!guestOccupation) {
            products && dispatch(proposalActions.setProductOrder(products.map((product) => product.id)));
            _.isEmpty(products) ? setNoProductsSelected(true) : setNoProductsSelected(false);
        } else {
            _.isEmpty(guestProducts) ? setNoProductsSelected(true) : setNoProductsSelected(false);
            setIsLoading(false);
        }
    }, [products, guestProducts]);

    useEffect(() => {
        location.state && location.state.defaultStep && setStep(location.state.defaultStep);
        location.state && !location.state.defaultStep && setStep('product-search');
    }, [location.state]);

    useEffect(() => {
        return () => {
            if (!guestOccupation) {
                dispatch(proposalActions.clearProposalData());
            }
        };
    }, [id]);

    if (projectSubmittalSettings.disabled) {
        history.push('/products');
        return null;
    }
    if (!id && !guestOccupation) {
        history.push('/');
        return null;
    }

    if (projectSubmittalSettings.requiredLogin && !token && !guestOccupation) {
        return (
            <ErrorProjectLogin>
                Please <Link to="/login">log in</Link> to view this project
            </ErrorProjectLogin>
        );
    }

    if ((!generalData || generalData.id !== id) && !guestOccupation) return null;

    const { name } = generalData || {};

    const moveStep = (nextStep) => {
        switch (step) {
            case 'proposal-review':
                break;
            case 'project-edit':
                if (nextStep === 'proposal-review') {
                    guestOccupation
                        ? dispatch(guestActions.updateProject())
                        : dispatch(editActions.updateProject(true));
                }
                break;
            case 'set-proposal-products':
                break;
            case 'select-proposal-documents':
                dispatch(proposalActions.setProposalProducts());
                break;
            case 'product-search':
            default:
                break;
        }
        setStep(nextStep);
    };

    const handleDownload = () => {
        if (isBrochure) {
            dispatch(proposalActions.createBrochure());
        } else {
            guestOccupation ? dispatch(guestActions.createSubmittal()) : dispatch(proposalActions.createSubmittal());
        }
    };

    const getCurrentStepComponent = () => {
        switch (step) {
            case 'proposal-review':
                return (
                    <ProposalReview
                        {...{
                            generalData: guestOccupation ? guestGeneralData : generalData,
                            guestOccupation,
                            setStep,
                            step,
                        }}
                    />
                );
            case 'project-edit':
                return (
                    <ProjectEdit
                        {...{
                            generalData: guestOccupation ? guestGeneralData : generalData,
                            hideHeader: true,
                            projectSubmittalSettings,
                            token,
                        }}
                    />
                );
            case 'select-proposal-documents':
                return (
                    <SelectDocuments
                        setStep={setStep}
                        projectId={guestOccupation ? guestGeneralData.id : generalData.id}
                        guestOccupation={guestOccupation}
                    />
                );
            case 'set-proposal-products':
                return (
                    <OrderProducts
                        setStep={setStep}
                        projectId={guestOccupation ? guestGeneralData.id : generalData.id}
                    />
                );
            case 'product-search':
            default:
                if (!isLoading) {
                    if (isBrochure) {
                        return <WorkflowProductSearch goToNextStep={() => moveStep('set-proposal-products')} />;
                    }
                    return <WorkflowProductSearch goToNextStep={() => moveStep('select-proposal-documents')} />;
                }
                return <Loading />;
        }
    };

    const getNextStepButton = () => {
        switch (step) {
            case 'proposal-review':
                return (
                    <ProgressionsButton id="btn-download-submittal" onClick={() => handleDownload()}>
                        {`Generate ${isBrochure ? 'Brochure' : 'Submittal'}`}
                    </ProgressionsButton>
                );
            case 'project-edit':
                return (
                    <ProgressionsButton
                        id="btn-review-submittal"
                        rightArrow
                        onClick={() => moveStep('proposal-review')}
                        disabled={
                            !form.projectDetails ||
                            !form.projectDetails.values ||
                            !form.projectDetails.values.address.zipcode
                        }
                    >
                        {`Next - ${isBrochure ? 'Brochure' : 'Submittal'} Review`}
                    </ProgressionsButton>
                );
            case 'select-proposal-documents':
                return (
                    <ProgressionsButton
                        id="btn-edit-project-details"
                        rightArrow
                        fullWidth
                        onClick={() => moveStep('project-edit')}
                    >
                        Next - Project Details
                    </ProgressionsButton>
                );
            case 'set-proposal-products':
                return (
                    <ProgressionsButton
                        id="btn-edit-project-details"
                        rightArrow
                        fullWidth
                        onClick={() => moveStep('select-proposal-documents')}
                    >
                        Next - Select Documents
                    </ProgressionsButton>
                );
            case 'product-search':
            default:
                return null;
        }
    };

    const submittalStepList = [
        { step: 'product-search', text: 'Add Products' },
        { step: 'select-proposal-documents', text: 'Select Documents' },
        { step: 'project-edit', text: 'Edit Project' },
        { step: 'proposal-review', text: 'Submittal Review' },
    ];

    const brochureStepList = [
        { step: 'product-search', text: 'Add Products' },
        { step: 'set-proposal-products', text: 'Set Product Order' },
        { step: 'select-proposal-documents', text: 'Select Documents' },
        { step: 'project-edit', text: 'Edit Project' },
        { step: 'proposal-review', text: 'Brochure Review' },
    ];

    return (
        <StyledProposal>
            <StyledHeader>
                <Row className="rowHeader">
                    <Column mdUp={4}>
                        <PageHeader title={isBrochure ? 'Create a Brochure' : 'Create a Submittal'} subtitle={name} />
                    </Column>
                    <ProgressContainer mdUp={5}>
                        <ProgressiveWizard
                            steps={isBrochure ? brochureStepList : submittalStepList}
                            activeStep={step}
                            moveStep={moveStep}
                            noProductsSelected={noProductsSelected}
                        />
                    </ProgressContainer>
                    <Column mdUp={3} className="btnCol">
                        {getNextStepButton()}
                    </Column>
                </Row>
            </StyledHeader>
            {getCurrentStepComponent()}
        </StyledProposal>
    );
};

Proposal.propTypes = {
    projectSubmittalSettings: PropTypes.object,
    generalData: PropTypes.object,
    token: PropTypes.string,
    downloadLink: PropTypes.string,
    fileLocation: PropTypes.string,
};

const mapStateToProps = (state) => ({
    projectSubmittalSettings: reducerUtil.getSlice(tenantData, tenantData.projectSubmittalSettings, state),
    generalData: reducerUtil.getSlice(detailsData, detailsData.generalData, state),
    products: reducerUtil.getSlice(detailsData, detailsData.products, state),
    token: reducerUtil.getSlice(login.reducerData, login.reducerData.token, state),
    downloadLink: reducerUtil.getSlice(proposalData, proposalData.downloadLink, state),
    fileLocation: reducerUtil.getSlice(proposalData, proposalData.fileLocation, state),
    theme: reducerUtil.getSlice(tenantData, tenantData.theme, state),
    guestOccupation: reducerUtil.getSlice(guestData, guestData.guestOccupation, state),
    guestGeneralData: reducerUtil.getSlice(guestData, guestData.generalData, state),
    guestProducts: reducerUtil.getSlice(guestData, guestData.products, state),
    form: state.form,
});

export default withRouter(connect(mapStateToProps)(Proposal));
