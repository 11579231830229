import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { Card, Button, ConfirmModal, Dropdown } from 'shared';
import { addComma } from 'shared-features-client';

const StyledProjectCard = styled(Card)`
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
`;

const Header = styled.div`
    padding: 1.5rem;
    min-height: 9.1rem;
    border-bottom: 1px solid ${(props) => props.theme.grey1};
`;

const Name = styled.h3`
    font-size: 1.8rem;
    line-height: 2.4rem;
    font-weight: bold;
    margin: 0;
    color: ${(props) => props.theme.grey5};
`;

const Address = styled.p`
    font-size: 1.4rem;
    line-height: 2.4rem;
    font-weight: normal;
    margin: 0;
    padding: 0.5rem 0;
    color: ${(props) => props.theme.grey4};
`;

const UpdateInfor = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    div {
        font-size: 1.2rem;
        line-height: 1;
        font-weight: normal;
        color: ${(props) => props.theme.grey3};
    }
`;

const MainContent = styled.div`
    font-size: 1.4rem;
    p {
        margin: 0;
        text-transform: uppercase;
        font-size: 1.1rem;
        color: ${(props) => props.theme.grey4};
    }
`;

const GeneralInfor = styled.div`
    display: flex;
    width: 100%;
    padding: 1.5rem;
    div {
        width: calc(100% / 3);
    }
    p {
        color: ${(props) => props.theme.grey4};
    }
`;

const Details = styled.ul`
    list-style: none;
    padding: 0;
    li {
        display: inline-flex;
        line-height: 3rem;
        align-items: center;
        width: 100%;
        padding: 0 1.5rem;
        &:nth-child(odd) {
            background-color: #f0f3f6;
        }
    }
    strong {
        padding-left: 0.5rem;
        font-weight: normal;
    }
`;

const ProjectDropdown = styled(Dropdown)`
    border: 2px solid ${(props) => props.theme.redDark};
    border-radius: 3px;
    color: ${(props) => props.theme.redDark};
    padding: 2px 10px;

    &.active .dropdown-label::after {
        top: 8px !important;
        transform: rotate(-45deg) !important;
    }

    .dropdown-label {
        padding-right: 45px !important;

        &::after {
            border-color: ${(props) => props.theme.redDark};
            position: absolute;
            top: 4px;
        }
    }

    .visible {
        bottom: 80px;
        display: flex;
        flex-direction: column;
        left: 10px;
        top: inherit;
        width: 205px;

        &::before {
            bottom: -5px !important;
            right: 24px !important;
            top: inherit !important;
            transform: rotate(225deg) !important;
        }

        button {
            margin: 10px 0px;
        }

        button:first-of-type {
            margin: 0px 0px 0px 0px;
        }

        button:last-of-type {
            margin: 10px 0px 0px 0px;
        }
    }
`;

const Footer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 1.5rem;
    border-top: 1px solid ${(props) => props.theme.grey1};
    font-weight: 500;
    color: ${(props) => props.theme.grey4};
    margin-top: auto;
`;

const ProjectCard = ({ project, onCreateSubmittal, onDeleteProject, onCloneProject, ...props }) => {
    if (!project) return null;
    const {
        id,
        name,
        address,
        created_at,
        updated_at,
        project_budget,
        project_size,
        project_type,
        project_phase,
        owner_type,
        'products.count': productCount,
    } = project;

    const { zipcode } = address || {};
    const zip = `${zipcode}`;

    return (
        <StyledProjectCard className="project-card" {...props}>
            <Link to={id ? `/projects/${id}` : '#'}>
                <Header>
                    <Name>{name}</Name>
                    <Address>Zip: {zip}</Address>
                    <UpdateInfor>
                        <div>Created: {moment(created_at).format('MMM DD, YYYY')}</div>
                        <div>Updated: {moment(updated_at).format('MMM DD, YYYY')}</div>
                    </UpdateInfor>
                </Header>
                <MainContent>
                    <GeneralInfor>
                        <div>
                            <p>Budget</p>
                            <strong>{project_budget ? `$${addComma(project_budget)}` : 'N/A'}</strong>
                        </div>
                        <div>
                            <p>Size</p>
                            <strong>{project_size ? `${addComma(project_size)} sq. ft` : 'N/A'}</strong>
                        </div>
                        <div>
                            <p>Products</p>
                            <strong>{productCount || 0}</strong>
                        </div>
                    </GeneralInfor>
                    <Details>
                        <li>
                            <p>Type</p>
                            <strong>{project_type || 'N/A'}</strong>
                        </li>
                        <li>
                            <p>Phase</p>
                            <strong>{project_phase || 'N/A'}</strong>
                        </li>
                        <li>
                            <p>Owner Type</p>
                            <strong>{owner_type || 'N/A'}</strong>
                        </li>
                    </Details>
                </MainContent>
            </Link>
            <Footer>
                <ProjectDropdown text={'Project Actions'}>
                    {project ? (
                        <Button inverse noUpperCase fontWeight="500" onClick={onCloneProject}>
                            Clone Project
                        </Button>
                    ) : null}
                    <Button inverse noUpperCase fontWeight="500" onClick={onCreateSubmittal}>
                        Create a Submittal
                    </Button>
                </ProjectDropdown>
                <ConfirmModal
                    title={`Delete ${name}`}
                    messages="Deleting a project cannot be undone"
                    onConfirm={onDeleteProject}
                    confirmBtnText="Delete Project"
                />
            </Footer>
        </StyledProjectCard>
    );
};

ProjectCard.propTypes = {
    project: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        address: PropTypes.object,
        createdDate: PropTypes.string,
        updatedDate: PropTypes.string,
        detailsInfor: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                value: PropTypes.string,
            })
        ),
    }),
    onCreateSubmittal: PropTypes.func,
    onDeleteProject: PropTypes.func,
};

export default ProjectCard;
