import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FormLabel, Input, ReduxFormError } from 'shared';

const Container = styled.div`
    margin-bottom: 1.5rem;
    text-align: left;
    margin-top: ${(props) => (props.labelTitle ? null : '-1rem')};
`;

const Group = styled.span`
    display: flex;
`;

const Prepend = styled.span`
    color: ${(props) => props.theme.grey3};
    font-size: 1.4rem;
    display: flex;
    width: 3rem;
    align-items: center;
    justify-content: center;
    background: ${(props) => props.theme.grey1};
    border: 1px solid ${(props) => props.theme.grey2};
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    margin-right: -1px;
`;

const GroupInput = styled(Input)`
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
`;

const ReduxFormInputGroup = ({ type, required, labelTitle, input, placeholder, meta, className, preText }) => (
    <Container {...{ labelTitle, className }}>
        {labelTitle && <FormLabel {...{ required, labelTitle }} />}
        <Group>
            <Prepend>{preText}</Prepend>
            <GroupInput {...input} placeholder={placeholder} type={type} />
        </Group>
        <ReduxFormError {...{ meta }} />
    </Container>
);

ReduxFormInputGroup.propTypes = {
    /** This is the type attribute */
    type: PropTypes.string.isRequired,
    /** This is the placeholder attribute */
    placeholder: PropTypes.string,
    /** This is label content */
    labelTitle: PropTypes.string,
    /** This is the prepend text content */
    preText: PropTypes.string,
    /** This is the required attribute */
    required: PropTypes.bool,
    /** If validation fails, this should be true */
    /** This is the disabled attribute */
    disabled: PropTypes.bool,
    meta: PropTypes.any,
    input: PropTypes.any,
};

ReduxFormInputGroup.defaultProps = {
    type: 'text',
};

export default ReduxFormInputGroup;
