import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FormLabel, ReduxFormError } from 'shared';

const Container = styled.div`
    margin-bottom: 1.5rem;
    // text-align: left;
    margin-top: ${(props) => (props.labelTitle ? null : '-1rem')};
`;

const TextArea = styled.textarea`
    width: 100%;
    padding: 1rem;
    resize: ${(props) => (props.resize ? props.resize : null)};
    color: ${(props) => props.theme.grey6};
    border: 1px solid ${(props) => props.theme.grey2};
    font-family: inherit;
    font-size: 1.4rem;
    border-radius: 3px;
`;

const ReduxFormTextArea = ({ className, required, labelTitle, placeholder, meta, input, resize, rows }) => (
    <Container {...{ labelTitle, className }}>
        {labelTitle && <FormLabel {...{ required, labelTitle }} />}
        <TextArea {...input} {...{ placeholder, resize, rows }} />
        <ReduxFormError {...{ meta }} />
    </Container>
);

ReduxFormTextArea.propTypes = {
    /** This is the type attribute */
    type: PropTypes.string.isRequired,
    /** This is the placeholder attribute */
    placeholder: PropTypes.string,
    /** This is label content */
    labelTitle: PropTypes.string,
    /** This is the required attribute */
    required: PropTypes.bool,
    /** If validation fails, this should be true */
    /** This is the disabled attribute */
    disabled: PropTypes.bool,
    resize: PropTypes.string,
    rows: PropTypes.number,
    meta: PropTypes.any,
    input: PropTypes.any,
};

ReduxFormTextArea.defaultProps = {
    type: 'text',
    rows: 8,
};

export default ReduxFormTextArea;
