import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const SelectElm = styled.div`
    display: flex;
    font-size: 1.4rem;
    cursor: pointer;
`;

const Label = styled.label`
    margin-left: 0.35rem;
    cursor: pointer;
`;

const Checkbox = ({ id, label, value, checked, onChange, ...props }) => (
    <SelectElm {...props} className="select">
        <input
            type="checkbox"
            id={id}
            readOnly
            checked={checked}
            value={value || id}
            onClick={() => onChange({ id, checked })}
        />
        {label ? <Label htmlFor={id}>{label}</Label> : null}
    </SelectElm>
);

Checkbox.propTypes = {
    id: PropTypes.any,
    label: PropTypes.string,
    value: PropTypes.any,
    checked: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
};

Checkbox.defaultProps = {
    checked: false,
};

export default Checkbox;
