import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { connect } from 'react-redux';
import { reducerUtil } from 'base-client';

import { max50, max500 } from 'utils/reduxFormValidator';
import { ReduxFormInput, ReduxFormTextArea, Modal, ContactForm, FormGroup, LinkButton } from 'shared';
import { formNames } from 'message/utils';
import MessageForm from './MessageForm';
import { reducerData as tenantData } from 'tenant';

const formName = formNames.request;

class RequestQuote extends React.Component {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        requestTitle: PropTypes.string,
        requestQuote: PropTypes.func,
    };

    state = {
        modalIsOpen: false,
    };

    openModal = () => {
        this.setState({ modalIsOpen: true });
    };

    closeModal = () => {
        this.setState({ modalIsOpen: false });
    };

    render() {
        const { disabled, requestTitle, children } = this.props;

        if (disabled) return null;

        const modalStyles = {
            maxWidth: '800px',
        };

        const defaultText = (
            <div>
                <p>Request A Quote</p>
                <span>Give us some information for a quote</span>
            </div>
        );

        return (
            <React.Fragment>
                <LinkButton small onClick={() => this.openModal()}>
                    {children || requestTitle || defaultText}
                </LinkButton>
                <Modal
                    open={this.state.modalIsOpen}
                    title={requestTitle || 'Request A Quote'}
                    onClose={() => this.closeModal()}
                    disableCloseOutside
                    customStyles={modalStyles}
                >
                    <ContactForm>
                        <MessageForm form={formName} onComplete={this.closeModal}>
                            <FormGroup>
                                <Field
                                    className="full-width"
                                    name="message"
                                    type="text"
                                    component={ReduxFormTextArea}
                                    label="message"
                                    labelTitle="Message Details"
                                    validate={[max500]}
                                    placeholder="Please provide some additional details of what you are looking for"
                                    resize="vertical"
                                    rows={3}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Field
                                    name="projectName"
                                    type="text"
                                    component={ReduxFormInput}
                                    label="ProjectName"
                                    labelTitle="Project Name"
                                    validate={[max50]}
                                />
                                <Field
                                    name="projectLocation"
                                    type="text"
                                    component={ReduxFormInput}
                                    label="projectLocation"
                                    labelTitle="Project Location"
                                    validate={[max50]}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Field
                                    name="value"
                                    type="text"
                                    component={ReduxFormInput}
                                    label="projectValue"
                                    labelTitle="Project Value"
                                    validate={[max50]}
                                />
                                <Field
                                    name="stage"
                                    type="text"
                                    component={ReduxFormInput}
                                    label="projectStage"
                                    labelTitle="Project Stage"
                                    validate={[max50]}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Field
                                    name="segment"
                                    type="text"
                                    component={ReduxFormInput}
                                    label="projectSegment"
                                    labelTitle="Project Segment"
                                    validate={[max50]}
                                />
                                <Field
                                    name="productCount"
                                    type="number"
                                    component={ReduxFormInput}
                                    label="productCount"
                                    labelTitle="Product Count Needed"
                                    validate={[max50]}
                                />
                            </FormGroup>
                        </MessageForm>
                    </ContactForm>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    disabled: !reducerUtil.getSlice(tenantData, tenantData.showRequest, state),
    requestTitle: reducerUtil.getSlice(tenantData, tenantData.requestTitle, state),
});

export default connect(mapStateToProps)(RequestQuote);
