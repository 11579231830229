import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Card, Button } from 'shared';
import ComparisonButton from './ComparisonButton';
import { AddToProjectButton } from 'projects';

const Container = styled(Card)`
    width: 100%;
    padding: 1.5rem 2rem;
    font-size: 1.4rem;
    font-weight: 500;
    margin-bottom: 1rem;
    button {
        text-transform: none;
        margin-right: 1rem;
        font-weight: 500;
        line-height: 2rem;
        height: 2.5rem;
        padding: 0 1rem;
        border: 1px solid ${(props) => props.theme.blueDarkest};
        color: ${(props) => props.theme.white};
        background-color: ${(props) => props.theme.blueDarkest};
        &:hover {
            color: ${(props) => props.theme.blueDarkest};
            background-color: ${(props) => props.theme.white};
            border-color: ${(props) => props.theme.blueDarkest};
        }
    }
`;

const Title = styled.h4`
    margin: 0 0 0.5rem;
`;

const Description = styled.p`
    font-weight: normal;
    margin: 0 0 1.5rem;
`;

const Actions = styled.div`
    display: flex;
    width: 100%;
`;

const SelectedText = styled.span`
    font-size: 1.2rem;
    letter-spacing: 0.2px;
    line-height: 3rem;
    font-weight: 500;
    margin-right: 3rem;
`;

const SubmittalWorkflowBanner = ({ selectedProducts, clearSelectedProducts }) => (
    <Container className="action-banner">
        <Title>Create a Submittal</Title>
        <Description>Select the products you need and add them to a Submittal</Description>
        <Actions>
            <SelectedText>{selectedProducts.length} Selected</SelectedText>
            <AddToProjectButton transparent small products={selectedProducts} isSubmittalWorkflow>
                Add to Submittal
            </AddToProjectButton>
            <ComparisonButton selectedProducts={selectedProducts}>
                Compare Products {selectedProducts.length}/4
            </ComparisonButton>
            <Button
                transparent
                small
                onClick={clearSelectedProducts}
                disabled={!selectedProducts || (selectedProducts && selectedProducts.length < 1)}
            >
                Cancel
            </Button>
        </Actions>
    </Container>
);

SubmittalWorkflowBanner.propTypes = {
    selectedProducts: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        })
    ),
    clearSelectedProducts: PropTypes.func,
};

export default SubmittalWorkflowBanner;
