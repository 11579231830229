import React from 'react';
import { connect } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import { chain, sortBy, groupBy } from 'lodash';
import { reducerUtil, config, api } from 'base-client';

import { configMap } from 'configurations';
import { Card, StyledTabs } from 'shared';
import ProductBimData from './ProductBimData';
import AttributeDisplay from './AttributeDisplay';
import SideNav from 'shared/SideNav';
import Resources from './Resources';
import Resource from './Resource';
import Sustainability from './Sustainability';
import { createElementId } from '../utils';
import { reducerData as tenantData } from 'tenant';
import { reducerData as detailsData, PRODUCT_HIDDEN_SECTIONS } from 'productDetails';
import Loading from '../../shared/Loading.js';

const StyledProductTabs = styled(StyledTabs)`
    margin-top: 2rem;

    li {
        color: ${(props) => props.theme.grey4};
    }
`;

const GroupName = styled.h2`
    font-weight: 500;
    display: block;
`;

const GridViewContainer = styled.div`
    display: block;
    width: 100%;
    margin-top: 1em;
`;

const GridList = styled.ul`
    display: inline-flex;
    flex-wrap: wrap;
    list-style-type: none;
`;

const List = styled.ul`
    list-style-type: none;
`;

const ListViewContainer = styled.div`
  display: block;
  width: 100%
  margin-top: 1em;
`;

const InputContainer = styled.div`
    margin-top: 1em;
    margin-left: 1em;
    margin-bottom: 1em;
`;
class ProductTabsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.state = { displayBimData: false };
    }

    static propTypes = {
        productSections: PropTypes.any,
        information: PropTypes.arrayOf(PropTypes.shape({})),
        bimData: PropTypes.arrayOf(PropTypes.shape({})),
        resources: PropTypes.arrayOf(PropTypes.shape({})),
        assetGroups: PropTypes.arrayOf(PropTypes.shape({})),
        sustainabilityData: PropTypes.arrayOf(PropTypes.shape({})),
    };
    static defaultProps = {
        information: [],
        bimData: [],
        resources: [],
        assetGroups: [],
        sustainabilityData: [],
    };

    state = {
        showArchivedItems: false,
    };

    componentDidMount() {
        const { bimData } = this.props;
        this.getBimData();
        this.setState({ familyType: bimData && bimData[0] && bimData[0].id });
    }

    getFileType = (l) => l.substring(l.length - 3, l.length);

    handleChange = (event) => {
        this.setState({ familyType: event.target.value });
    };

    handleClick() {
        const currentState = this.state.showArchivedItems;
        this.setState({ showArchivedItems: !currentState });
    }

    bimDataOptions = (bimData) => {
        const { getValue } = this;
        const bimFamily = bimData.find(({ id }) => id === this.state.familyType) || {};
        let options = chain(bimFamily.bimData)
            .sortBy('attributeSection')
            .groupBy('attributeSection')
            .map((v, i) => ({
                label: i,
                element: AttributeDisplay,
                data: sortBy(
                    v
                        .filter((a) => !!getValue(a))
                        .map((a) => ({
                            id: a.attributeId,
                            label: a.attributeName,
                            value: getValue(a),
                            type: a.attributeType,
                        })),
                    'label'
                ),
            }))
            .value();

        this.setState({ bimDataOptions: options });
    };

    getValue = ({ attributeType, attributeValue }) => {
        const { resources } = this.props;
        if (attributeType !== 'assetselection') return attributeValue;
        const resource = resources.find(({ id }) => id === attributeValue) || {};
        return resource.url;
    };

    getBimData = async () => {
        const { dispatch } = this.props;
        const { id } = this.props.general;
        const resp = await dispatch(api.actions.get(`products/${id}?scopes=['revit]`));
        const bimData = resp.configurations;
        await dispatch(reducerUtil.setSlice(detailsData, detailsData.bimData, bimData));
        this.setState({ familyType: bimData && bimData[0] && bimData[0].id });

        this.bimDataOptions(bimData);
        this.setState({ displayBimData: true });
    };

    render() {
        const {
            information,
            bimData,
            resources,
            assetGroups,
            theme,
            productSections,
            sustainabilityData,
            attachments,
            dispatch,
        } = this.props;

        const disabledSustainability = dispatch(
            config.actions.getData(configMap.disabled.name, configMap.disabled.sustainability.name)
        );

        //if productSections is an explicitly empty array, hide the tabs area
        if (Array.isArray(productSections) && productSections.length === 0) return null;

        const { familyType } = this.state;

        const getValue = ({ attributeType, attributeValue }) => {
            if (attributeType !== 'assetselection') return attributeValue;
            const resource = resources.find(({ id }) => id === attributeValue) || {};
            return resource.url;
        };

        const infoOptions = chain(information)
            .filter((i) => !PRODUCT_HIDDEN_SECTIONS.includes(i.attributeSection))
            .filter((i) => !!i.attributeValue)
            .sortBy('attributeSection')
            .groupBy('attributeSection')
            .map((v, i) => ({
                label: i,
                element: AttributeDisplay,
                data: sortBy(
                    v
                        .filter((a) => !!getValue(a))
                        .map((a) => ({
                            id: a.attributeId,
                            label: a.attributeName,
                            value: getValue(a),
                            type: a.attributeType,
                        })),
                    'label'
                ),
            }))
            .value();

        const sustainabilityHasData = (data) => {
            if (
                data.length === 1 &&
                data[0].documents.length < 1 &&
                data[0].environmentCharacteristics.length < 1 &&
                data[0].ratingSystems.length < 1
            ) {
                return false;
            }
            return true;
        };

        // pull in theme linkColor for Resources
        const linkColor = theme ? theme.linkColor.toString() : '#0000FF';

        let assetGroupsSanitized = assetGroups.map((group) => {
            if (!assetGroups.length) return null;
            let groupObject = {};

            groupObject.groupName = group.groupName;
            groupObject.groupType = group.groupType;

            return groupObject;
        });

        // create the initial sorted groups object
        let initialGroups = groupBy(resources, 'group');

        // create bucket for falsy groupNames e.g. undefined, null, empty string
        let uncategorizedGroups = [];

        // check to see if falsy keys exist. If they do, grab all groups
        // with falsy groupNames and add them to separate bucket
        if (initialGroups[undefined]) {
            uncategorizedGroups.push(...initialGroups[undefined]);
        }

        if (initialGroups[null]) {
            uncategorizedGroups.push(...initialGroups[null]);
        }

        if (initialGroups['']) {
            uncategorizedGroups.push(...initialGroups['']);
        }

        // map over each group and change the groupName to uncategorized
        const renameGroups = uncategorizedGroups.map((object) => {
            object.group = 'general';
            return object;
        });

        // take each group and add it to a single object
        const renamedGroups = groupBy(renameGroups, 'group');

        const emptyStringKeyToRemove = '';
        const nullKeyToRemove = null;
        const undefinedKeyToRemove = undefined;

        // destructure and remove out original falsy groupNames from initialGroup
        let {
            [emptyStringKeyToRemove]: emptyString,
            [nullKeyToRemove]: nullKey,
            [undefinedKeyToRemove]: undefinedKey,
            ...groups
        } = initialGroups;

        // merge the uncategorized groups in with the rest of the truthy groups
        groups = { ...groups, ...renamedGroups };

        const groupsArray = Object.entries(groups).map((e) => ({
            group: e[0],
            items: e[1],
        }));

        const groupItems = groupsArray.map((groups) => {
            return groups.items.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }));
        });

        const GridView = ({ group }) => {
            const { group: groupName, items } = group;

            const groupHasArchivedItems = group.items.find((item) => item.archived === true);

            return (
                <GridViewContainer>
                    {groupHasArchivedItems && !this.state.showArchivedItems && group.length <= 1 ? (
                        <></>
                    ) : (
                        <>
                            <GroupName>{groupName}</GroupName>
                            <GridList>
                                {items.map((item, idx) => {
                                    return (
                                        <Resource
                                            {...item}
                                            key={item.id}
                                            type={this.getFileType(item.url)}
                                            displayType={'grid'}
                                            showArchivedItems={this.state.showArchivedItems}
                                        />
                                    );
                                })}
                            </GridList>
                        </>
                    )}
                </GridViewContainer>
            );
        };

        const ListView = ({ group }) => {
            const { group: groupName, items } = group;

            const groupHasArchivedItems = group.items.find((item) => item.archived === true);

            return (
                <ListViewContainer>
                    {groupHasArchivedItems && !this.state.showArchivedItems && group.items.length <= 1 ? (
                        <></>
                    ) : (
                        <>
                            {groupName ? <GroupName>{groupName}</GroupName> : <></>}
                            <List>
                                {items.map((item, idx) => {
                                    return (
                                        <Resource
                                            {...item}
                                            key={item.id}
                                            type={this.getFileType(item.url)}
                                            displayType={'list'}
                                            showArchivedItems={this.state.showArchivedItems}
                                        />
                                    );
                                })}
                            </List>
                        </>
                    )}
                </ListViewContainer>
            );
        };

        function renderResourceGroups(groupsArray) {
            const groupLists = [];

            if (!groupsArray) return null;

            groupsArray.map((group) => {
                // Check to see if there's an assetGroup display configuration Object coming back from LL
                // if there is none add it and set the display style to list view
                if (!assetGroupsSanitized.length) {
                    return null;

                    // TO DO: Keep or delete below line based on feedback for if we want to display all items without a matching config
                    // in a 'General' category or hide them from view completely. Logic is currently setup to only show
                    // items with a corresponding config or if the config is empty, display all items as a list.

                    // assetGroupsSanitized.push({groupName: "general", groupType: "list"})
                }

                // Determine which group the items from the resource array belong to
                const configGrouping = assetGroupsSanitized.find(
                    (item) => item.groupName.toLowerCase() === group.group.toLowerCase()
                );

                if (!configGrouping) return null;

                // Then render depending on what it matches to in the config object
                if (configGrouping.groupType === 'grid') {
                    groupLists.push(<GridView group={group} key={createElementId()} />);
                }

                if (configGrouping.groupType === 'list') {
                    groupLists.push(<ListView group={group} key={createElementId()} />);
                }
            });

            function isEqual(object1, object2) {
                return object1.group === object2.groupName;
            }

            function isEmptyObject(obj) {
                return JSON.stringify(obj) === '{}';
            }

            // Separates groups into two arrays: those that match the assetGroup config at position [0]: findGroupsWithoutMatchingConfig[0]
            // and those that don't match anything in assetGroup config at position [1]: findGroupsWithoutMatchingConfig[1]
            const findGroupsWithoutMatchingConfig = groupsArray.reduce(
                (acc, group) => {
                    if (!assetGroupsSanitized.length) return {};

                    const objExist = assetGroupsSanitized.find((config) => isEqual(group, config));
                    if (objExist) acc[0].push(group);
                    else acc[1].push(group);
                    return acc;
                },
                [[], []]
            );

            // Merge all the items of every group into a single object with no groupName
            const items = [].concat(...groupsArray.map(({ items }) => items));
            const noDisplayConfig = [{ groupName: null, items }];

            // Check to see if findGroupsWithoutMatchingConfig is empty or if there are no groups that match the assetGroup config.
            // If either are true, set the value to the single object with all items, otherwise return null
            const noMatchingConfigGroups =
                isEmptyObject(findGroupsWithoutMatchingConfig) || !findGroupsWithoutMatchingConfig[0].length
                    ? noDisplayConfig
                    : null;

            if (noMatchingConfigGroups) {
                noMatchingConfigGroups.map((group) => {
                    groupLists.push(<ListView group={group} key={createElementId()} />);
                });
            }

            return groupLists;
        }

        function hasArchivedItems(groupItems) {
            return groupItems.some((item) => item.some(({ archived }) => archived && archived !== false));
        }

        const showProductInfor =
            infoOptions &&
            infoOptions.length > 0 &&
            (!productSections || (productSections && productSections.includes('product')));

        const showBimData =
            bimData &&
            bimData.length > 0 &&
            (!productSections || (productSections && productSections.includes('revit')));

        const showResources =
            groupsArray &&
            groupsArray.length > 0 &&
            (!productSections || (productSections && productSections.includes('attachments')));

        const showSustainability =
            sustainabilityHasData(sustainabilityData) &&
            !disabledSustainability &&
            sustainabilityData &&
            sustainabilityData.length > 0;

        return (
            <Card>
                <StyledProductTabs>
                    <Tabs className="product-tabs">
                        <TabList className="tab-list">
                            {showProductInfor ? <Tab>Product Information</Tab> : null}
                            {showResources ? <Tab>Resources</Tab> : null}
                            {showBimData ? <Tab>BIM Data</Tab> : null}
                            {showSustainability ? <Tab>Sustainability</Tab> : null}
                            <Tab style={{ display: 'none' }} />
                        </TabList>
                        {showProductInfor && infoOptions ? (
                            <TabPanel>
                                <SideNav options={infoOptions || []} />
                            </TabPanel>
                        ) : null}

                        {showResources ? (
                            <TabPanel>
                                {hasArchivedItems(groupItems) && (
                                    <InputContainer>
                                        <input type="checkbox" onClick={this.handleClick} /> Show Archived Files{' '}
                                    </InputContainer>
                                )}
                                <Resources>
                                    {renderResourceGroups(
                                        groupsArray.sort((a, b) =>
                                            a.group.localeCompare(b.group, 0, { caseFirst: 'lower' })
                                        )
                                    )}
                                </Resources>
                            </TabPanel>
                        ) : null}
                        {showBimData ? (
                            <TabPanel>
                                {(bimData && (
                                    <ProductBimData
                                        value={familyType}
                                        onChange={this.handleChange}
                                        options={
                                            bimData &&
                                            bimData.map((c) => ({
                                                label: c.name,
                                                value: c.id,
                                            }))
                                        }
                                    />
                                )) || <Loading />}
                                {this.state.bimDataOptions && this.state.bimDataOptions.length ? (
                                    <SideNav options={this.state.bimDataOptions} />
                                ) : (
                                    <Loading />
                                )}
                            </TabPanel>
                        ) : null}
                        {showSustainability ? (
                            <TabPanel>
                                <Sustainability {...{ attachments }} data={sustainabilityData} />
                            </TabPanel>
                        ) : null}
                    </Tabs>
                </StyledProductTabs>
            </Card>
        );
    }
}

const mapStateToProps = (state) => ({
    productSections: reducerUtil.getSlice(tenantData, tenantData.productSections, state),
    assetGroups: reducerUtil.getSlice(tenantData, tenantData.assetGroups, state),
    theme: reducerUtil.getSlice(tenantData, tenantData.theme, state),
    information: reducerUtil.getSlice(detailsData, detailsData.information, state),
    bimData: reducerUtil.getSlice(detailsData, detailsData.bimData, state),
    resources: reducerUtil.getSlice(detailsData, detailsData.resources, state),
    sustainabilityData: reducerUtil.getSlice(detailsData, detailsData.sustainabilityData, state),
    attachments: reducerUtil.getSlice(detailsData, detailsData.attachments, state),
    general: reducerUtil.getSlice(detailsData, detailsData.general, state),
    details: reducerUtil.getSlice(detailsData, detailsData, state),
});

export default connect(mapStateToProps)(ProductTabsContainer);
