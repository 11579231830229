import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { configMap } from 'configurations';
import { sbBranding } from 'global-styles';
import { reducerUtil, config } from 'base-client';
import { reducerData as tenantData } from 'tenant';

const StyledCookie = styled.div`
    background-color: ${sbBranding.grey6};
    color: ${sbBranding.white};
    width: 100%;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 9;
`;

const CookieContent = styled.div`
  font-size: 1.2rem;
  padding 1rem 10rem;
  position: relative;
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px){
    padding: 1rem 1.5rem;
  }

  @media (max-width: 640px){
    flex-direction: column;
    align-items: center;
  }
`;

const CookieText = styled.p`
    a {
        text-decoration: underline;
    }
    @media (max-width: 768px) {
        padding-right: 15px;
    }
`;

const CookieButtons = styled.div`
    width: 15rem;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const CookieButton = styled.div`
    line-height: 1.2rem;
    border-radius: 3px;
    border-style: none;
    color: ${sbBranding.white};
    cursor: pointer;
`;

const Agree = styled(CookieButton)`
    background-color: ${sbBranding.grey4};
    padding: 1rem;

    :hover {
        background-color: ${sbBranding.grey5};
    }
`;

const ReadMore = styled(CookieButton)`
    background-color: transparent;
    :hover {
        color: ${sbBranding.grey3};
    }
`;

const CookieBanner = ({ dispatch, showCookieBanner }) => {
    const link = dispatch(config.actions.getData(configMap.agreement.name, configMap.agreement.cookieConsent.name));

    const [isBanner, setBanner] = useState(false);

    useEffect(() => {
        getCookieStatus();
    });

    const getCookieStatus = () => {
        const cookieStatus = localStorage.getItem('cookieAgreement');
        if (!cookieStatus) setBanner(true);
    };

    const disableCookieBanner = () => {
        localStorage.setItem('cookieAgreement', 'trusted');
        setBanner(false);
    };

    return (
        <div>
            {showCookieBanner && isBanner && (
                <StyledCookie>
                    <CookieContent>
                        <CookieText>
                            This site uses cookies to provide you with a great user experience. By using this site, you
                            accept our{' '}
                            <a target="__blank" href={link}>
                                use of cookies
                            </a>
                            .
                        </CookieText>
                        <CookieButtons>
                            <Agree onClick={() => disableCookieBanner()}>I agree</Agree>
                            <ReadMore small onClick={() => window.open(link, '__blank')}>
                                Read more
                            </ReadMore>
                        </CookieButtons>
                    </CookieContent>
                </StyledCookie>
            )}
        </div>
    );
};

CookieBanner.propTypes = {
    dispatch: PropTypes.func.isRequired,
    showCookieBanner: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
    const showCookieBanner = reducerUtil.getSlice(tenantData, tenantData.showCookieBanner, state);
    return { showCookieBanner };
};

export default connect(mapStateToProps)(CookieBanner);
