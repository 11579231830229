import { reducerUtil } from 'base-client';

import { reducerData as detailsData } from 'productDetails';
import { actions as analyticsActions } from 'analytics';

const trackClick =
    ({ id: attributeId, name: attributeName, url }) =>
    (dispatch, getState) => {
        const state = getState();
        const { id, name } = reducerUtil.getSlice(detailsData, detailsData.general, state) || {};

        const meta = { productName: name, product_id: id, attribute_id: attributeId, attributeName };
        dispatch(analyticsActions.trackClick(url, meta));
    };

export default { trackClick };
