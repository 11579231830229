import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Card, Button, ButtonText } from 'shared';
import ChooseAnotherProjectModal from '../../projects/components/Modal/ChooseAnotherProjectModal';

const Container = styled(Card)`
    display: flex;
    justify-content: space-between;
    margin-left: 1.2rem;
    padding: 1.5rem 2rem;
    font-size: 1.4rem;
    font-weight: 500;
    margin-bottom: 1rem;
`;

const Title = styled.h4`
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin: 0 0 12px 0;
    color: #343e47;
`;

const Description = styled.p`
    font-weight: normal;
    margin: 0 0 1.5rem;
`;

const LeftSection = styled.div`
    display: flex;
    flex-direction: column;
`;

const ProjectTitle = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    height: 24px;
`;

const ProjectAddress = styled.div`
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    height: 24px;
`;

const ProjectProducts = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    height: 17px;
    color: ${(props) => props.theme.primaryColor || props.theme.grey3};
`;

const RightSectionDivider = styled.div`
    border-left: 1px solid ${(props) => props.theme.grey3};
    margin: 0px 15px;
`;
const RightSection = styled.div`
    display: flex;
`;

const RightSectionActions = styled.div`
    display: flex;
    flex-direction: column;
`;

const ChooseAnotherProjectButton = styled.div`
    text-align: left;
    margin-top: 16px;
    font-weight: 500;
    color: ${(props) => props.theme.primaryColor || props.theme.blue};
`;

const ActionButton = styled(Button)`
    width: 300px;
    min-height: 45px;
    font-size: 1.8rem;
`;

const Actions = styled.div`
    display: flex;
    width: 100%;
    margin-top: 16px;
`;

const SelectedText = styled.span`
    font-size: 1.2rem;
    letter-spacing: 0.2px;
    line-height: 3rem;
    font-weight: 500;
    margin-right: 3rem;
`;

const formatAddress = (address) => {
    return `${address.address_line_1 ? `${address.address_line_1}` : ''} ${
        address.address_line_2 ? ` ${address.address_line_2}` : ''
    }${address.city ? `, ${address.city}` : ''}${address.state ? `, ${address.state}` : ''}${
        address.zipcode ? ` ${address.zipcode}` : ''
    }${address.country ? ` ${address.country}` : ''}`;
};

const formatProjectProduct = (projectProducts) => {
    return projectProducts && projectProducts.length > 0
        ? `${projectProducts.length} Products in Project`
        : 'No Products in Project';
};

const AddProductsToProjectButton = styled(Button)`
    margin-right: 10px;
`;

const ProjectWorkflowBanner = ({
    projectData,
    projectProducts,
    selectedProducts,
    clearSelectedProducts,
    handleAddProducts,
    goToNextStep,
    isBrochure,
    theme,
    guestOccupation,
    guestGeneralData,
    guestProducts,
}) => {
    const [chooseAnotherProjectModalOpen, setChooseAnotherProjectModalOpen] = useState(false);
    if (!guestOccupation && !projectData) return null;

    return (
        <Container className="action-banner">
            {chooseAnotherProjectModalOpen && (
                <ChooseAnotherProjectModal
                    title="Choose another project"
                    currentProject={projectData}
                    open={true}
                    onClose={() => setChooseAnotherProjectModalOpen(false)}
                />
            )}
            <LeftSection>
                <Title>Add products to your current project</Title>
                <ProjectTitle>{guestOccupation ? guestGeneralData.name : projectData.name}</ProjectTitle>
                <ProjectAddress>
                    {guestOccupation ? formatAddress(guestGeneralData.address) : formatAddress(projectData.address)}
                </ProjectAddress>
                <ProjectProducts>
                    {guestOccupation ? formatProjectProduct(guestProducts) : formatProjectProduct(projectProducts)}
                </ProjectProducts>
                {selectedProducts.length > 0 && (
                    <Actions>
                        <SelectedText>{selectedProducts.length} Selected</SelectedText>
                        <AddProductsToProjectButton small onClick={() => handleAddProducts()}>
                            Add Products to Project
                        </AddProductsToProjectButton>
                        <Button
                            transparent
                            small
                            onClick={() => clearSelectedProducts()}
                            disabled={!selectedProducts || (selectedProducts && selectedProducts.length < 1)}
                        >
                            Cancel
                        </Button>
                    </Actions>
                )}
            </LeftSection>
            <RightSection>
                <RightSectionDivider />
                <RightSectionActions>
                    {!guestOccupation ? (
                        <>
                            <ActionButton
                                big
                                type="submit"
                                onClick={() => goToNextStep()}
                                disabled={!projectProducts || (projectProducts && projectProducts.length < 1)}
                            >
                                {`Configure ${isBrochure ? 'Brochure' : 'Submittal'}`}
                            </ActionButton>
                            <ChooseAnotherProjectButton>
                                <ButtonText onClick={() => setChooseAnotherProjectModalOpen(true)}>
                                    Choose Another Project
                                </ButtonText>
                            </ChooseAnotherProjectButton>
                        </>
                    ) : (
                        <ActionButton
                            big
                            type="submit"
                            onClick={() => goToNextStep()}
                            disabled={!guestProducts || (guestProducts && guestProducts.length < 1)}
                        >{`Configure ${isBrochure ? 'Brochure' : 'Submittal'}`}</ActionButton>
                    )}
                </RightSectionActions>
            </RightSection>
        </Container>
    );
};

ProjectWorkflowBanner.propTypes = {
    selectedProducts: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        })
    ),
    clearSelectedProducts: PropTypes.func,
};

export default ProjectWorkflowBanner;
