import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { reducerUtil } from 'base-client';

import ProductMetaItem from './ProductMetaItem';
import { reducerData as detailsData } from 'productDetails';
import { determinePricingRegion } from '../../utils/pricingInformation';

const Callouts = ({ callouts }) => {
    if (callouts.length < 1) return null;

    const region = JSON.parse(localStorage.getItem('region'));

    const pricingRegionValue = determinePricingRegion(region)

    const calloutMap = callouts.filter((item) => item.name !== "Price US" && item.name !== 'Price CAD');
    const pricingCallout = callouts.find((item) => item.name === pricingRegionValue)
    pricingCallout ? calloutMap.push(pricingCallout) : null

    console.log('CALLOUTS in CONTAINER ====>', callouts)
    console.log('CALLOUT MAP in CONTAINER ===>', calloutMap)
    console.log('PRICING CALLOUT in SEARCH ===>', pricingCallout)

    return (
        <React.Fragment>
            {calloutMap.map(({ id, name, value }) => (
                <ProductMetaItem key={`${id}-${name}`} header={name} detail={value} />
            ))}
        </React.Fragment>
    );
};

Callouts.propTypes = {
    callouts: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
            value: PropTypes.string,
        })
    ),
};

Callouts.defaultProps = {
    callouts: [],
};

const mapStateToProps = (state) => ({
    callouts: reducerUtil.getSlice(detailsData, detailsData.callouts, state),
});

export default connect(mapStateToProps)(Callouts);
