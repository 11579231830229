import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    background: ${(props) => (props.image ? `url(${props.image})` : 'none')};
    background-repeat: no-repeat;
    background-size: cover;
`;

const Title = styled.h1`
    font-size: 48px;
    font-weight: 400;
    margin: 48px auto 17px auto;
    color: white;
    text-align: left;
    padding-left: 15%;
    padding-right: 15%;
    @media (max-width: 992px) {
        padding-left: 10%;
        padding-right: 5%;
    }
    @media (max-width: 768px) {
        font-size: 36px;
    }
`;
const Description = styled.div`
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    margin: 0px auto auto;
    margin-bottom: 7em;
    color: white;
    width: 65%;
    @media (max-width: 992px) {
        width: 85%;
    }
    @media (max-width: 768px) {
        width: 85%;
        padding-left: 10%;
        padding-right: 10%;
    }
`;

const Header = ({ image, title, description }) => {
    return (
        <Container image={image}>
            <Title>{title}</Title>
            <Description>{description}</Description>
        </Container>
    );
};

export default Header;
