export default {
    name: 'projects',
    modalOpen: 'modalOpen',
    isSubmittalWorkflow: 'isSubmittalWorkflow',
    products: 'products',
    projects: 'projects',
    meta: 'meta',
    fetchId: 'fetchId',
    selectedProducts: 'selectedProducts',
};
