import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';

import media from 'utils/media';
import { FormDropdown, FormDropdownOption } from 'shared';

const Container = styled.div`
    margin-top: 2rem;
`;

const FamilyType = styled.div`
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    ${media.medium`
    display: inline;
    float: left;
    width: 15%;
    margin-right: 2rem;
    text-align: right;
    margin-top: 0.6rem;
    margin-bottom: 2rem;
  `};
`;

const Dropdown = styled.div`
    min-width: 150px;
    width: fit-content;
    float: left;
    select {
        color: ${(props) => props.theme.grey5};
    }
`;

const ProductBimData = ({ value, options, onChange }) => {
    if (!options.length) {
        return '';
    }
    return (
        <Container>
            <FamilyType>Family Type</FamilyType>
            <Dropdown>
                <FormDropdown {...{ value, onChange }}>
                    {options.map((o) => (
                        <FormDropdownOption value={o.value} optionLabel={o.label} key={o.value} />
                    ))}
                </FormDropdown>
            </Dropdown>
        </Container>
    );
};

ProductBimData.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
        })
    ).isRequired,
};

export default ProductBimData;
