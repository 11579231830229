import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FormInput, FormPasswordInput, LinkButton, ButtonText, PoweredConcora } from 'shared';

import Body from './Body';
import Header from './Header';
import FlashMessage from './FlashMessage';
import SocialContainer from './SocialContainer';
import Footer from './Footer';
import Agreement from './Agreement';
import Logo from './Logo';

const SignUpForm = styled.form`
    text-align: center;
`;

const StyledSignUp = styled.div`
    .login-link {
        text-align: center;
        button {
            color: ${(props) => props.theme.blue};
        }
    }
    .privacyPolicy {
        font-size: 1.1rem;
        text-align: left;
        flex: 0 0 65%;
        a {
            color: ${(props) => props.theme.blue};
        }
    }
`;

const ButtonAgreement = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const SignUpButton = styled(LinkButton)`
    flex: 0 0 30%;
`;

const LinkToLogin = styled.p`
    text-align: center;
    font-size: 1.2rem;
    button {
        color: ${(props) => props.theme.blue};
    }
`;

const SignUp = ({
    tenantId,
    errors,
    headerText,
    isSignUpReady,
    logoImageUrl,
    logoText,
    message,
    onSignup,
    showError,
    showSuccess,
    toLogin,
    updateForm,
    validate,
}) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
        <StyledSignUp>
            <Logo {...{ logoText, logoImageUrl }} />
            <Header {...{ headerText }} />
            <LinkToLogin>
                Already have an account? <ButtonText onClick={toLogin}>Login</ButtonText>
            </LinkToLogin>

            {showError ? <FlashMessage hasError messageText={message} /> : null}
            {showSuccess && (
                <React.Fragment>
                    <FlashMessage messageText={message} />
                    <LinkToLogin>
                        Go back to <ButtonText onClick={toLogin}>login</ButtonText> page
                    </LinkToLogin>
                </React.Fragment>
            )}
            {!showSuccess && (
                <Body>
                    <SignUpForm>
                        <SocialContainer />

                        <FormInput
                            labelTitle="Email Address"
                            placeholder="Enter your email address"
                            type="email"
                            field="email"
                            required
                            hasError={errors.email && errors.email.hasError}
                            onBlur={(value, field) => validate(value, field)}
                            onChange={(value, field) => updateForm(value, field)}
                            errorMessage={errors.email && errors.email.hasError ? errors.email.message : ''}
                        />

                        <FormPasswordInput
                            labelTitle="Password"
                            placeholder="Enter a password"
                            type="password"
                            field="password"
                            required
                            hasError={errors.password && errors.password.hasError}
                            onBlur={(value, field) => validate(value, field)}
                            onChange={(value, field) => updateForm(value, field)}
                            errorMessage={errors.password && errors.password.hasError ? errors.password.message : ''}
                            showPassword={showPassword}
                            setShowPassword={setShowPassword}
                        />

                        <FormPasswordInput
                            labelTitle="Re-enter Password"
                            placeholder="Confirm the password"
                            type="password"
                            field="reEnterPassword"
                            required
                            hasError={errors['reEnterPassword'] && errors['reEnterPassword'].hasError}
                            onBlur={(value, field) => validate(value, field)}
                            onChange={(value, field) => updateForm(value, field)}
                            errorMessage={
                                errors['reEnterPassword'] && errors['reEnterPassword'].hasError
                                    ? errors['reEnterPassword'].message
                                    : ''
                            }
                            showPassword={showPassword}
                            setShowPassword={setShowPassword}
                        />
                        <ButtonAgreement>
                            <SignUpButton small marginTop="1rem" marginBottom="1rem" onClick={() => onSignup(tenantId)}>
                                Sign Up
                            </SignUpButton>
                            <p className="privacyPolicy">
                                <Agreement />
                            </p>
                        </ButtonAgreement>
                    </SignUpForm>

                    <Footer>
                        <PoweredConcora margin="1rem 0 0" />
                    </Footer>
                </Body>
            )}
        </StyledSignUp>
    );
};

SignUp.propTypes = {
    errors: PropTypes.object.isRequired,
    headerText: PropTypes.string.isRequired,
    isSignUpReady: PropTypes.bool.isRequired,
    message: PropTypes.string,
    onContinue: PropTypes.func,
    onSignup: PropTypes.func,
    onUserAgreementCheck: PropTypes.func,
    showError: PropTypes.bool,
    showSuccess: PropTypes.bool,
    toLogin: PropTypes.func.isRequired,
    updateForm: PropTypes.func,
    validate: PropTypes.func,
    tenantId: PropTypes.string
};

export default SignUp;
