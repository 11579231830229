import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import media from 'utils/media';

import NavigationCard from './NavigationCard';

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    top: -3rem;
    margin: 0 -1% -3rem;
    ${media.large`
    top: -5rem;
    margin-bottom: -5rem;
  `};
`;

const Navigations = ({ navigationTiles }) => {
    const displayList = navigationTiles.length > 4 ? navigationTiles.slice(0, 4) : [...navigationTiles];
    return (
        <Container>
            {displayList.map((navigation, index) => (
                <NavigationCard key={index} {...navigation} />
            ))}
        </Container>
    );
};

Navigations.propTypes = {
    navigationTiles: PropTypes.arrayOf(
        PropTypes.shape({
            header: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
            urlDisplayValue: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
        })
    ),
};

Navigations.defaultProps = {
    navigationTiles: [],
};

export default Navigations;
