import styled from 'styled-components';

const Input = styled.input`
    display: block;
    width: 100%;
    height: 3.4rem;
    border: 1px solid
        ${(props) => {
            if (props.hasError) return props.theme.red;
            if (props.disabled) return props.theme.grey1;
            return props.theme.grey2;
        }};
    background: ${(props) => (props.disabled ? props.theme.grey0 : null)};
    cursor: ${(props) => (props.disabled ? 'not-allowed' : null)};
    border-radius: 2px;
    appearance: none;
    font-family: ${(props) => props.theme.fontBody};
    font-size: 1.4rem;
    color: ${(props) => (props.disabled ? props.theme.grey3 : props.theme.grey5)};
    padding: 0.5rem 0.8rem;
    &:focus {
        outline: none;
        border-color: ${(props) => props.theme.accentColor};
        box-shadow: 0 0 3px rgba(10, 123, 228, 0.5);
    }
`;

export default Input;
