import React from 'react';
import styled from 'styled-components';
import { history } from 'base-client';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 48px;
`;

const CardContainer = styled.li`
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
    width: 400px;
    min-height: 381px;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 3px;
    cursor: pointer;
    margin: 0px 0px 24px 0px;
    box-shadow: 0 3px 5px rgba(52, 62, 71, 0.12);
`;

const CardTitle = styled.div`
    font-size: 18px;
    font-weight: 600px;
    margin: 15px auto 7px 19px;
`;

const CardSubtitle = styled.div`
    font-size: 14px;
    line-height: 20px;
    margin: 0px 19px 20px 19px;
`;

const CardImage = styled.div`
    width: 400px;
    height: 300px;
    background: ${(props) => (props.image ? `url(${props.image})` : 'none')};
    background-repeat: no-repeat;
    background-size: cover;
`;

const ChildCard = ({ title, description, image, url }) => {
    return (
        <CardContainer onClick={() => history.push(url)}>
            <CardImage image={image} />
            <CardTitle>{title}</CardTitle>
            <CardSubtitle>{description}</CardSubtitle>
        </CardContainer>
    );
};

const ChildGrid = ({ children }) => {
    return (
        <Container>
            {children.map((child) => {
                return <ChildCard {...{ ...child }} />;
            })}
        </Container>
    );
};

export default ChildGrid;
