import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { sbBranding } from 'global-styles';
import { FileType } from './CustomAsset';
import { CustomAsset } from 'shared';

const Container = styled.div`
    margin-top: 2rem;
    a {
        color: ${(props) => props.theme.grey6};
        opacity: 0.7;
        transition: all 0.3s ease-in;
        padding: 0 0 1rem;
        position: relative;
        margin-right: 1.6rem;
        cursor: pointer;
        &:hover {
            opacity: 1;
            div {
                opacity: 1;
            }
        }
        > div {
            text-align: center;
            transition: opacity 0.3s ease-in;
            top: -3rem;
        }
    }
`;

const FileList = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const HeaderAssets = ({ children, assets, productId, showViewAllAssetsLink }) => {
    if (assets.length < 1) return null;

    return (
        <Container className="header-assets">
            {children}
            <FileList>
                {assets.map((asset, idx) => (
                    <CustomAsset key={idx} {...asset} />
                ))}
                {showViewAllAssetsLink && (
                    <Link to={`/products/${productId}`}>
                        <FileType color={sbBranding.grey3}>View All</FileType>
                    </Link>
                )}
            </FileList>
        </Container>
    );
};

HeaderAssets.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
    assets: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
    showViewAllAssetsLink: PropTypes.bool,
    productId: PropTypes.string,
};

HeaderAssets.defaultProps = {
    assets: [],
    showViewAllAssetsLink: false,
    productId: undefined,
};

export default HeaderAssets;
