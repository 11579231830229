export default {
    name: 'search',
    list: 'list',
    meta: 'meta',
    query: 'query',
    fetchId: 'fetchId',
    searchId: 'searchId',
    searching: 'searching',
    selectedProducts: 'selectedProducts',
};
