import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { login, history, reducerUtil } from 'base-client';
import { Modal, Button } from 'shared';
import { actions as projectActions, reducerData as projectData } from 'projects';
import { reducerData as tenantData } from 'tenant';
import { actions as notificationActions, projectNotification } from 'notification';

const ModalContent = styled.div`
    min-width: 50rem;
    font-size: 1.4rem;
`;

const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const FormGroup = styled.div`
    width: 100%;
    padding: 1.5rem 0 2rem;
    display: flex;
    flex-direction: column;
    &:first-child {
        padding-top: 0;
    }
    &.options {
        label {
            &:first-child {
                margin-right: 2rem;
            }
        }
        padding-bottom: 0px;
    }
    &.projectInfor {
        padding-bottom: 0;
        display: flex;
    }
    .projectList {
        display: inline-block;
        width: 80%;
    }
`;

const Label = styled.label`
    cursor: pointer;
    font-weight: 500;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    input {
        margin-right: 0.5rem;
    }
    &.required {
        position: relative;
        &:after {
            position: absolute;
            content: '*';
            top: 0;
            right: -1rem;
            color: ${(props) => props.theme.red};
        }
    }
`;

const Checkbox = styled.input`
    cursor: pointer;
`;

const ProjectCreationModal = ({ token, title, open, onClose, dispatch, theme, projectsWithProduct, removeProduct }) => {
    const [checkedState, setCheckedState] = useState([]);
    let modalProps = {
        title: title,
        open: open,
        onClose: () => defaultClose(),
    };

    if (onClose) {
        modalProps.onClose = onClose;
    }

    const defaultClose = async () => {
        dispatch(projectActions.modalClose());
    };

    useEffect(() => {
        if (projectsWithProduct && projectsWithProduct.length > 0) {
            const initialCheckedState = projectsWithProduct.map((project) => {
                return { ...project, checked: false };
            });
            setCheckedState(initialCheckedState);
        }
    }, [projectsWithProduct]);

    return (
        <Modal {...modalProps}>
            <ModalContent>
                <CardContainer>
                    <FormGroup className="options">
                        {checkedState !== undefined &&
                            checkedState.map((project, idx) => (
                                <Label key={idx} for={`remove_${idx}`}>
                                    <Checkbox
                                        readOnly
                                        id={`remove_${idx}`}
                                        type="checkbox"
                                        value={checkedState[idx].checked}
                                        onClick={() => {
                                            const newCheckedState = JSON.parse(JSON.stringify(checkedState));
                                            newCheckedState[idx].checked = !newCheckedState[idx].checked;
                                            setCheckedState(newCheckedState);
                                        }}
                                    />
                                    {checkedState[idx].name}
                                </Label>
                            ))}
                    </FormGroup>
                    <FormGroup>
                        <Button
                            small
                            noUpperCase
                            type="submit"
                            disabled={
                                JSON.parse(JSON.stringify(checkedState)).filter((project) => {
                                    if (project.checked) {
                                        return true;
                                    }
                                }).length === 0
                            }
                            onClick={() => {
                                const removeFrom = checkedState.filter((project) => {
                                    if (project.checked) {
                                        return true;
                                    }
                                });
                                removeProduct(removeFrom);
                            }}
                        >
                            Remove Product From Project(s)
                        </Button>
                    </FormGroup>
                </CardContainer>
            </ModalContent>
        </Modal>
    );
};

const mapStateToProps = (state) => ({
    token: reducerUtil.getSlice(login.reducerData, login.reducerData.token, state),
    modalState: reducerUtil.getSlice(projectData, projectData.modalOpen, state),
    theme: reducerUtil.getSlice(tenantData, tenantData.theme, state),
    state: state,
});

export default connect(mapStateToProps)(ProjectCreationModal);
