import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { reducerUtil } from 'base-client';

import { max500 } from 'utils/reduxFormValidator';
import { ReduxFormTextArea, Modal, ContactForm, FormGroup } from 'shared';
import { formNames } from 'message/utils';
import MessageForm from './MessageForm';
import { reducerData as tenantData } from 'tenant';

const formName = formNames.contact;

const StyledContactSale = styled.div`
    font-weight: 500;
    cursor: pointer;
    color: ${(props) => props.theme.textColor || props.theme.grey3};
    &:hover {
        color: ${(props) => props.theme.textHoverColor || 'inherit'};
    }
`;

const modalStyles = {
    maxWidth: '800px',
};

class ContactSales extends React.Component {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        contactTitle: PropTypes.string,
    };

    state = {
        modalIsOpen: false,
    };

    openModal = () => {
        this.setState({ modalIsOpen: true });
    };

    closeModal = () => {
        this.setState({ modalIsOpen: false });
    };

    render() {
        const { disabled, contactTitle } = this.props;

        if (disabled) return null;

        return (
            <React.Fragment>
                <StyledContactSale onClick={() => this.openModal()}>{contactTitle || 'CONTACT US'}</StyledContactSale>
                <Modal
                    open={this.state.modalIsOpen}
                    title={contactTitle || 'Contact Us'}
                    onClose={() => this.closeModal()}
                    customStyles={modalStyles}
                    disableCloseOutside
                >
                    <ContactForm>
                        <MessageForm form={formName} onComplete={this.closeModal}>
                            <FormGroup>
                                <Field
                                    className="full-width"
                                    name="message"
                                    type="text"
                                    component={ReduxFormTextArea}
                                    label="message"
                                    labelTitle="Message Details"
                                    validate={[max500]}
                                    placeholder="Please provide some additional details of what you are looking for"
                                    rows={4}
                                    resize="vertical"
                                />
                            </FormGroup>
                        </MessageForm>
                    </ContactForm>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    disabled: !reducerUtil.getSlice(tenantData, tenantData.showContact, state),
    contactTitle: reducerUtil.getSlice(tenantData, tenantData.contactTitle, state),
});

export default connect(mapStateToProps)(ContactSales);
