import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { reducerUtil } from 'base-client';

import { Row, Column } from 'shared';

import SideDetails from './SideDetails';
import SubmittalDocuments from './SubmittalDocuments';

import { actions as proposalActions, reducerData as proposalData } from 'proposal';
import { actions as detailsActions, reducerData as detailsData } from 'projectDetails';
import { actions as guestActions, reducerData as guestData } from 'guest';

const SelectDocuments = ({
    projectId,
    fetchId,
    setStep,
    products = [],
    pagination = {},
    orderedProducts,
    guestOccupation,
    guestProducts,
    dispatch,
}) => {
    const [isLoading, setIsLoading] = useState();

    useEffect(() => {
        const loadData = async () => {
            setIsLoading(true);
            await dispatch(detailsActions.loadSavedProducts({ projectId, forSubmittal: true, restart: true }));
            setIsLoading(false);
        };
        if (!guestOccupation) {
            loadData();
        }
    }, [projectId]);

    /* Determine if there is more to load based on the metadata */
    let hasNextPage;
    if (pagination) {
        const { page, pageCount } = pagination;
        hasNextPage = page < pageCount;
    }

    const infiniteListProps = {
        list: orderedProducts ? products.filter(({ id }) => orderedProducts.includes(id)) : [...products],
        hasNextPage,
        fetching: !!fetchId,
        getNextPage: () => {
            dispatch(detailsActions.loadNextPage({ projectId, forSubmittal: true }));
        },
        loadMoreRows: fetchId
            ? () => null
            : () => dispatch(detailsActions.loadNextPage({ projectId, forSubmittal: true })),
        isRowLoaded: ({ index }) => !hasNextPage || index < products.length,
    };

    const documentCardProps = {
        projectId,
        products: orderedProducts ? products.filter(({ id }) => orderedProducts.includes(id)) : products,
        handleCollapse: (id) => dispatch(proposalActions.handleCollapse(id)),
        handleUpdateSelection: (id, selectedItems) =>
            dispatch(proposalActions.handleUpdateSelection(id, selectedItems)),
        handleRemoveProduct: async (id) => {
            dispatch(proposalActions.removeProduct(id));
        },
    };

    const infiniteListGuestProps = {
        list: guestProducts,
        hasNextPage,
        fetching: !!fetchId,
        getNextPage: () => {
            //
        },
        loadMoreRows: () => {
            //
        },
        isRowLoaded: ({ index }) => !hasNextPage || index < guestProducts.length,
    };

    const documentCardGuestProps = {
        projectId: 'guest',
        products: guestProducts,
        handleCollapse: (id) => dispatch(guestActions.handleCollapse(id)),
        handleUpdateSelection: (id, selectedItems) => dispatch(guestActions.handleUpdateSelection(id, selectedItems)),
        handleRemoveProduct: (id) => dispatch(guestActions.removeProductFromProject(id)),
    };

    return (
        <Row>
            <Column mdUp={4} className="left-content">
                <SideDetails
                    title="Select Documents"
                    description="Select the documents you would like to include in your Submittal.  For ease of use, you can select all documents across all products by clicking Select All button below."
                    buttons={[
                        {
                            buttonText: 'Select All Documents',
                            onClick: () =>
                                guestOccupation
                                    ? dispatch(guestActions.selectAllDocuments())
                                    : dispatch(proposalActions.selectAllDocuments()),
                        },
                        {
                            buttonText: 'Deselect All Documents',
                            onClick: () =>
                                guestOccupation
                                    ? dispatch(guestActions.deselectAllDocuments())
                                    : dispatch(proposalActions.deselectAllDocuments()),
                        },
                    ]}
                />
                <SideDetails
                    style={{ margin: '40px 3rem auto 0px' }}
                    description="Are you still missing a few products?"
                    buttons={[
                        {
                            buttonText: 'Add More Products',
                            onClick: () => {
                                window.scrollTo({
                                    top: 0,
                                });
                                setStep('product-search');
                            },
                        },
                    ]}
                />
            </Column>
            <Column mdUp={8} className="right-content">
                <SubmittalDocuments
                    {...(guestOccupation ? { infiniteListGuestProps } : { infiniteListProps })}
                    {...(guestOccupation ? documentCardGuestProps : documentCardProps)}
                />
            </Column>
        </Row>
    );
};

const mapStateToProps = (state) => ({
    fetchId: reducerUtil.getSlice(detailsData, detailsData.fetchId, state),
    guestProducts: reducerUtil.getSlice(guestData, guestData.products, state),
    orderedProducts: reducerUtil.getSlice(proposalData, proposalData.orderedProducts, state),
    pagination: reducerUtil.getSlice(detailsData, detailsData.pagination, state),
    products: reducerUtil.getSlice(detailsData, detailsData.products, state),
});

export default connect(mapStateToProps)(SelectDocuments);
