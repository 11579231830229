import React, { Component } from 'react';
import PropTypes from 'prop-types';

import HybridView from './HybridView';
import HybridCard from './HybridCard';

import { HYBRID_ROW_HEIGHT } from '../constants';

/** Class representing a container for the hybrid view. */
class HybridViewContainer extends Component {
    static propTypes = {
        products: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                name: PropTypes.string,
                manufacturer: PropTypes.string,
                category: PropTypes.string,
                subcategory: PropTypes.string,
                thumbnail: PropTypes.string,
                description: PropTypes.string,
            })
        ),
        guestProducts: PropTypes.array,
        guestOccupation: PropTypes.string,
        enableCheckbox: PropTypes.bool,
        toggleSelectProduct: PropTypes.func,
        hasPrice: PropTypes.bool
    };

    static defaultProps = {
        products: [],
        guestProducts: [],
    };

    rowRenderer = ({ index, key, style }) => {
        const {
            products,
            toggleSelectProduct,
            enableCheckbox,
            showViewAllAssetsLink,
            showDownloadAllFilesLink,
            guestOccupation,
            guestProducts,
            hasPrice,
            handleRemoveProduct,
            handleAddProduct,
        } = this.props;
        if (!products) return;

        const product = products[index];
        const inGuestProject =
            guestOccupation && guestProducts.find((guestProduct) => guestProduct.id === product.id) ? true : false;
        if (!product) return;

        return (
            <HybridCard
                {...{
                    handleRemoveProduct,
                    handleAddProduct,
                    key,
                    style,
                    product,
                    inGuestProject,
                    enableCheckbox,
                    toggleSelectProduct,
                    showViewAllAssetsLink,
                    hasPrice
                }}
            />
        );
    };

    render() {
        const { products, enableCheckbox, toggleSelectProduct, registerChild } = this.props;

        const additionalProps = {
            rowRenderer: this.rowRenderer,
            rowCount: products ? products.length : 0,
            rowHeight: HYBRID_ROW_HEIGHT,
            enableCheckbox,
            toggleSelectProduct,
        };

        return <HybridView {...this.props} {...additionalProps} />;
    }
}

export default HybridViewContainer;
