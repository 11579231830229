import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from 'shared';
import TransitionButton from 'proposal/components/TransitionButton';

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const InnerButtonContainer = styled(ButtonContainer)`
    flex-direction: column;
`;

const StyledButton = styled(Button)`
    height: 45px;
    font-size: 1.6rem;
    font-weight: 700;
    justify-content: center;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    flex-grow: 1;
`;

const DropdownButton = styled.div`
    border-left: 1px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.theme.primaryColor};
    width: 50px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    cursor: pointer;
`;

const Arrow = styled.div`
    height: 0.8rem;
    width: 0.8rem;
    border-right: 2px solid white;
    border-top: 2px solid white;
`;

const UpArrow = styled(Arrow)`
    transform: rotateZ(-45deg);
`;

const DownArrow = styled(Arrow)`
    transform: rotateZ(135deg);
    margin-top: -0.4rem;
`;

const MultiAddButton = ({ addProduct, addProductGoBrochure, addProductGoSubmittal, brochureEnabled }) => {
    const [show, setShow] = useState(false);
    const styles = {
        height: '45px',
        fontSize: '1.6rem',
        fontWeight: '700',
        width: '100%',
        textTransform: 'uppercase',
    };

    return (
        <>
            <ButtonContainer>
                <StyledButton
                    onClick={() => {
                        addProduct();
                    }}
                >
                    Add To Project
                </StyledButton>
                <DropdownButton
                    onClick={() => {
                        setShow(!show);
                    }}
                >
                    {show ? <UpArrow /> : <DownArrow />}
                </DropdownButton>
            </ButtonContainer>
            {show && (
                <InnerButtonContainer>
                    {brochureEnabled && (
                        <TransitionButton
                            text="Add &amp; Configure Brochure"
                            step={'set-proposal-products'}
                            style={{ ...styles, margin: '20px 0px' }}
                            addProduct={addProductGoBrochure}
                        />
                    )}
                    <TransitionButton
                        text="Add &amp; Configure Submittal"
                        step={'select-proposal-documents'}
                        style={styles}
                        addProduct={addProductGoSubmittal}
                    />
                </InnerButtonContainer>
            )}
        </>
    );
};

export default MultiAddButton;
