import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Button = styled.div`
    cursor: pointer;
    font-size: 1.3rem;
    color: ${(props) => (props.theme.linkColor ? props.theme.linkColor : props.theme.blue)};
    &:hover,
    &:focus {
        color: ${(props) => props.theme.blueDarkest};
    }
`;

const ExpandCollapse = ({ showMore, children, onClick }) => (
    <React.Fragment>
        {showChildren(showMore, children)}
        <Button onClick={onClick} href="toggle">
            Show {moreOrLess(showMore)}
        </Button>
    </React.Fragment>
);

ExpandCollapse.propTypes = {
    showMore: PropTypes.bool,
    children: PropTypes.any.isRequired,
    onClick: PropTypes.func.isRequired,
};

ExpandCollapse.defaultProps = {
    showMore: false,
};

const moreOrLess = (showMore) => (showMore ? ' Less...' : ' More...');
const showChildren = (showMore, children) => showMore && children;

export default ExpandCollapse;
