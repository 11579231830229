import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import media from 'utils/media';

export const Container = styled.div`
    display: flex;
    justify-content: ${(props) => (props.justifyContent ? props.justifyContent : 'space-between')};
    align-items: center;
    margin: 1.5rem 0 3rem;
`;

const Header = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Title = styled.div`
    font-size: 2rem;
    font-weight: 800;
    margin-right: 2rem;
    ${media.medium`
    font-size: 2.4rem;
  `};
`;

const Subtitle = styled.div`
    font-size: 1.6rem;
    font-weight: 800;
    line-height: 3.2rem;
    color: ${(props) => props.theme.grey5};
`;

const PageHeader = ({ title, subtitle, children, justifyContent }) => (
    <Container {...{ justifyContent }}>
        <Header>
            {title && <Title>{title}</Title>}
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
        </Header>
        {children}
    </Container>
);

PageHeader.propTypes = {
    /** The page title */
    title: PropTypes.string,
    /** Optional subheader */
    subtitle: PropTypes.string,
    /** If an additional right aligned component, e.g. ViewIcons is needed */
    children: PropTypes.any,
};

export default PageHeader;
