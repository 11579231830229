import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { actions as detailsActions } from 'productDetails';
import { DisplayLink } from 'shared';

const DetailsLink = ({ data, dispatch }) => {
    let { id, value, label, type } = data;
    if (type === 'assetselection') label = 'Click Here To Download';
    const onClick = () => dispatch(detailsActions.trackClick({ id, name: label, url: value }));
    return (
        <div {...{ onClick }}>
            <DisplayLink url={value || ''}>{label || value}</DisplayLink>
        </div>
    );
};

DetailsLink.propTypes = {
    dispatch: PropTypes.func.isRequired,
    data: PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
    }).isRequired,
};

export default connect()(DetailsLink);
