export default [
    'Architect',
    'Project Manager',
    'Marketing/Sales',
    'Designer',
    'Estimator',
    'Specifier',
    'Mechanical Engineer',
    'Electrical Engineer',
    'Plumbing Engineer',
    'Structural Enginner',
    'Other Engineer',
    'Operations',
    'Executive/Owner',
    'Manager',
    'Developer',
    'Contractor',
    'Facility Manager',
    'Property Manager',
    'Other',
];
