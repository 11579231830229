import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FormDropdown, ReduxFormError } from 'shared';

export const DropdownContainer = styled.div`
    margin-bottom: 1.5rem;
    text-align: left;
    margin-top: ${(props) => (props.labelTitle ? null : '-1rem')};
    vertical-align: top;
`;

const ReduxFormDropdown = ({ labelTitle, children, input, meta, className, ...props }) => (
    <DropdownContainer {...{ labelTitle, className }}>
        <FormDropdown {...input} {...{ labelTitle, ...props }}>
            {children}
        </FormDropdown>
        <ReduxFormError {...{ meta }} />
    </DropdownContainer>
);

ReduxFormDropdown.propTypes = {
    labelTitle: PropTypes.string,
    children: PropTypes.array,
    input: PropTypes.object.isRequired,
    type: PropTypes.string,
    meta: PropTypes.any,
};
export default ReduxFormDropdown;
