import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { reducerUtil } from 'base-client';
import styled from 'styled-components';

import { reducerData as detailsData } from 'productDetails';

const Container = styled.div``;

const Title = styled.h3`
    font-size: 1.3rem;
    text-transform: uppercase;
    letter-spacing: 0.3px;
    color: ${(props) => props.theme.grey4};
    font-weight: 400;
    position: relative;
    &:before {
        content: '';
        width: calc(100% - 7.5rem);
        margin-left: 7.5rem;
        position: absolute;
        height: 1px;
        top: 0.8rem;
        background: ${(props) => props.theme.grey1};
    }
`;

const FeatureList = styled.ul`
    list-style-position: inside;
`;

const FeatureItem = styled.li`
    padding: 0.2rem 0;
    list-style-position: outside;
    margin-left: 16px;
`;

const Features = ({ features }) => {
    if (!features || (features && features.length < 1)) return null;

    return (
        <Container className="product-features">
            <Title>Features</Title>
            <FeatureList>
                {features.map((item) => {
                    return item.value ? <FeatureItem key={item.id}>{item.value}</FeatureItem> : null;
                })}
            </FeatureList>
        </Container>
    );
};

Features.propTypes = {
    features: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            value: PropTypes.string,
        })
    ),
};

Features.defaultProps = {
    features: [],
};

const mapStateToProps = (state) => ({
    features: reducerUtil.getSlice(detailsData, detailsData.features, state),
});

export default connect(mapStateToProps)(Features);
