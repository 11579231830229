/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { login, reducerUtil, history } from 'base-client';
import Column from '../../shared/Column';

import { Button, ButtonText, Card, HeaderAssets } from 'shared';
import AddToProjectButton from 'projects/components/AddToProjectButton';
import RequestQuote from 'message/components/RequestQuote';
import ProjectCreationModal from '../../projects/components/Modal/ProjectCreationModal';
import RemoveProductModal from '../../projects/components/Modal/RemoveProductModal';
import ChooseAnotherProjectModal from '../../projects/components/Modal/ChooseAnotherProjectModal';
import { actions as projectActions, reducerData as projectData } from 'projects';
import { actions as projectDetailsActions, reducerData as projectDetails } from 'projectDetails';
import { reducerData as productDetails } from 'productDetails';
import { reducerData as detailsData } from 'productDetails';
import { reducerData as proposalData } from 'proposal';
import { reducerData as tenantData } from 'tenant';
import MultiAddButton from './MultiAddButton';
import { getPricingInfo, UNITED_STATES, CANADA } from '../../utils/pricingInformation';

const StyledColumn = styled(Column)`
    .actionCard {
        margin-bottom: 2rem;
    }
    .addToProjectbtn {
        button {
            width: 100%;
            height: 4rem;
            font-size: 1.6rem;
        }
        .tooltip {
            max-width: 100%;
            text-align: center;
            left: 0;
            right: 0;
        }
    }
`;

const RequestAQuoteWrapper = styled.div`
    border-top: 1px solid #c3cdd9;
    padding-top: 20px;
    button {
        background-color: ${(props) => props.theme.grey0};
        color: ${(props) => props.theme.grey5};
        text-transform: none;
        font-size: 1.6rem;
        font-weight: normal;
        height: auto;
        line-height: 1.5;
        text-align: left;
        padding: 2rem;
        width: 100%;
        position: relative;
        &:after {
            content: '';
            display: inline-block;
            width: 0.8rem;
            height: 0.8rem;
            position: absolute;
            top: 42%;
            right: 1.2rem;
            border: none;
            border-right: 2px solid ${(props) => props.theme.grey4};
            border-top: 2px solid ${(props) => props.theme.grey4};
            transform: rotate(45deg);
        }
        p {
            margin: 0;
            line-height: 1.9rem;
        }
        span {
            font-size: 1.2rem;
            line-height: 1.4rem;
            color: ${(props) => props.theme.grey4};
        }
    }
`;

const Assets = styled(Card)`
    .header-assets {
        margin-top: 0;
    }
`;

const ProjectTitle = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
`;

const ProjectAddress = styled.div`
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    margin: auto auto 15px 0px;
`;

const AssetsTitle = styled.h3`
    font-size: 1.3rem;
    letter-spacing: 0.3px;
    color: ${(props) => props.theme.grey3};
    font-weight: 400;
    position: relative;
    margin-top: 0;
    text-transform: uppercase;
    &:before {
        content: '';
        width: calc(100% - 5rem);
        margin-left: 5rem;
        position: absolute;
        height: 1px;
        top: 0.8rem;
        background: ${(props) => props.theme.grey1};
    }
`;

const StyledButton = styled(Button)`
    height: 45px;
    font-size: 1.6rem;
    font-weight: 700;
`;

const ButtonContainer = styled.div`
    height: 45px;
    width: 100%;
    margin: 0px auto 20px auto;
`;

const ProjectContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const ChooseAnotherProjectButton = styled.div`
    text-align: left;
    margin-top: 16px;
    font-weight: 600;
    font-size: 1.2rem;
    color: ${(props) => props.theme.primaryColor || props.theme.blue};
    margin-bottom: 23px;
`;

const Select = styled.select`
    margin-bottom: 16px;
`;

const Currency = styled.h2`
  margin: 4px 0 4px 0;
`;

const BottomSectionDivider = styled.div`
  border-bottom: 1px solid ${props => props.theme.grey1};
  margin: 15px 0 15px 0;
`;

const formatAddress = (address) => {
    return `${address.address_line_1 ? `${address.address_line_1}` : ''} ${address.address_line_2 ? ` ${address.address_line_2}` : ''
        }${address.city ? `, ${address.city}` : ''}${address.state ? `, ${address.state}` : ''}${address.zipcode ? ` ${address.zipcode}` : ''
        }${address.country ? ` ${address.country}` : ''}`;
};

const RightContent = ({
    location,
    dispatch,
    assets,
    projectData,
    projectProducts,
    productInfo,
    hasPrice,
    projectsWithProduct,
    product,
    hideActions,
    hideAssets,
    modalOpen,
    theme,
    token,
}) => {
    const [chooseAnotherProjectModalOpen, setChooseAnotherProjectModalOpen] = useState(false);
    const [addToProjectModalOpen, setAddToProjectModalOpen] = useState(false);
    const [showRemoveProductModal, setShowRemoveProductModal] = useState(false);
    const [region, setRegion] = useState('');
    const { brochureEnabled } = (theme && theme) || false;

    useEffect(() => {
        fetchProjectsWithProduct();
    }, []);

    useEffect(() => {
        const region = JSON.parse(localStorage.getItem('region'));
        region ? setRegion(region) : setRegion('United States')
    }, []);

    const fetchProjectsWithProduct = async () => {
        dispatch(projectDetailsActions.getProjectsWithProduct(product.id));
    };

    const handleRemoveFromProjects = async (removeFrom) => {
        if (!removeFrom && projectData) {
            await dispatch(projectActions.removeProductFromProject(product.id, projectData));
            await dispatch(
                projectDetailsActions.loadSavedProducts({
                    projectId: projectData.id,
                    restart: true,
                    noQuery: true,
                })
            );
        } else {
            const dispatchPromises = removeFrom.map((project) => {
                return () => dispatch(projectActions.removeProductFromProject(product.id, project));
            });
            await Promise.all(dispatchPromises.map((promise) => promise()));
        }

        fetchProjectsWithProduct();
    };

    const handleAddToProject = async (cb) => {
        await dispatch(projectActions.addSelectedProductsToProject([product], projectData));
        await dispatch(
            projectDetailsActions.loadSavedProducts({
                projectId: projectData.id,
                restart: true,
                noQuery: true,
            })
        );
        fetchProjectsWithProduct();
        if (cb) {
            cb();
        }
    };

    if (!product || (hideActions && hideAssets)) return null;

    const prices = getPricingInfo(productInfo);
    const usd = prices ? prices['usd'] : null;
    const cad = prices ? prices['cad'] : null;

    return (
        <StyledColumn mdUp={3} className="rightContent">
            {hideActions ? null : (
                <Card className="actionCard" padding="2rem">
                    <ProjectContainer>
                        {hasPrice && prices && (region === UNITED_STATES || region === CANADA) && (
                            <>
                                {usd && region === UNITED_STATES && <Currency>{usd} USD</Currency>}
                                {cad && region === CANADA && <Currency>{cad} CAD</Currency>}
                                <BottomSectionDivider />
                            </>
                        )}
                        {projectData && (
                            <>
                                <ProjectTitle>{projectData.name}</ProjectTitle>
                                <ProjectAddress>{formatAddress(projectData.address)}</ProjectAddress>

                                {projectsWithProduct &&
                                    projectsWithProduct.find((project) => project.id === projectData.id) ? (
                                    <ButtonContainer>
                                        <StyledButton
                                            fullWidth
                                            inverse
                                            color={'#5a6678'}
                                            onClick={() => {
                                                handleRemoveFromProjects();
                                            }}
                                        >
                                            Remove From Project
                                        </StyledButton>
                                    </ButtonContainer>
                                ) : (
                                    <MultiAddButton
                                        brochureEnabled={brochureEnabled}
                                        addProduct={() => {
                                            handleAddToProject();
                                        }}
                                        addProductGoBrochure={() => {
                                            handleAddToProject(
                                                async () =>
                                                    await dispatch(
                                                        projectDetailsActions.goToBrochure(
                                                            projectData.id,
                                                            'set-proposal-products'
                                                        )
                                                    )
                                            );
                                        }}
                                        addProductGoSubmittal={() => {
                                            handleAddToProject(
                                                async () =>
                                                    await dispatch(
                                                        projectDetailsActions.goToSubmittal(
                                                            projectData.id,
                                                            'select-proposal-documents'
                                                        )
                                                    )
                                            );
                                        }}
                                    />
                                )}
                                <ChooseAnotherProjectButton>
                                    {chooseAnotherProjectModalOpen && (
                                        <ChooseAnotherProjectModal
                                            title="Choose another project"
                                            currentProject={projectData}
                                            productDetails={product}
                                            open={chooseAnotherProjectModalOpen}
                                            onClose={() => {
                                                dispatch(projectActions.modalClose());
                                                setChooseAnotherProjectModalOpen(false);
                                            }}
                                        />
                                    )}
                                    <ButtonText
                                        onClick={() => {
                                            setChooseAnotherProjectModalOpen(true);
                                        }}
                                    >
                                        Choose Another Project
                                    </ButtonText>
                                    {projectsWithProduct &&
                                        projectsWithProduct.filter((project) => project.id !== projectData.id).length >
                                        0 && (
                                            <ButtonText
                                                style={{ marginTop: '15px' }}
                                                onClick={() => {
                                                    setShowRemoveProductModal(true);
                                                }}
                                            >
                                                Remove From Other Project
                                            </ButtonText>
                                        )}
                                </ChooseAnotherProjectButton>
                            </>
                        )}
                        {!projectData && (
                            <>
                                <AddToProjectButton
                                    {...{ products: [product], modalOpen }}
                                    onClick={() => {
                                        setAddToProjectModalOpen(true);
                                    }}
                                >
                                    Add to project
                                </AddToProjectButton>
                                {addToProjectModalOpen && (
                                    <ProjectCreationModal
                                        title="Add to a project"
                                        currentProject={projectData}
                                        productDetails={product}
                                        open={addToProjectModalOpen}
                                        from={'RightContent'}
                                        fetchProjectsWithProduct={() => {
                                            fetchProjectsWithProduct();
                                        }}
                                        onClose={(route) => {
                                            setChooseAnotherProjectModalOpen(false);
                                            dispatch(projectActions.modalClose());
                                            if (route) history.push(location.pathname);
                                            setAddToProjectModalOpen(false);
                                        }}
                                    />
                                )}
                                {projectsWithProduct && projectsWithProduct.length > 0 && (
                                    <ChooseAnotherProjectButton>
                                        <ButtonText
                                            onClick={() => {
                                                setShowRemoveProductModal(true);
                                            }}
                                        >
                                            Remove From Other Project
                                        </ButtonText>
                                    </ChooseAnotherProjectButton>
                                )}
                            </>
                        )}
                    </ProjectContainer>
                    <RequestAQuoteWrapper>
                        <RequestQuote />
                    </RequestAQuoteWrapper>
                </Card>
            )}
            {hideAssets ? null : (
                <Assets padding="2rem">
                    <HeaderAssets {...{ assets }}>
                        <AssetsTitle>Links</AssetsTitle>
                    </HeaderAssets>
                </Assets>
            )}
            {showRemoveProductModal && (
                <ProjectContainer>
                    <RemoveProductModal
                        title="Remove From A Project"
                        projectsWithProduct={
                            projectData
                                ? projectsWithProduct.filter((project) => project.id !== projectData.id)
                                : projectsWithProduct
                        }
                        productDetails={product}
                        open={showRemoveProductModal}
                        from={'RightContent'}
                        removeProduct={(removeFrom) => {
                            handleRemoveFromProjects(removeFrom);
                            dispatch(projectActions.modalClose());
                            setShowRemoveProductModal(false);
                        }}
                        onClose={(route) => {
                            dispatch(projectActions.modalClose());
                            if (route) history.push(location.pathname);
                            setShowRemoveProductModal(false);
                        }}
                    />
                </ProjectContainer>
            )}
        </StyledColumn>
    );
};

RightContent.propTypes = {
    assets: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
            color: PropTypes.string,
            url: PropTypes.string,
        })
    ),
    products: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
        })
    ),
    hideActions: PropTypes.bool,
    hideAssets: PropTypes.bool,
};

const mapStateToProps = (state) => ({
    projectsWithProduct: reducerUtil.getSlice(productDetails, productDetails.projectsWithProduct, state),
    projectData: reducerUtil.getSlice(projectDetails, projectDetails.generalData, state),
    projectProducts: reducerUtil.getSlice(projectDetails, projectDetails.products, state),
    modalOpen: reducerUtil.getSlice(projectData, projectData.modalOpen, state),
    theme: reducerUtil.getSlice(tenantData, tenantData.theme, state),
    token: reducerUtil.getSlice(login.reducerData, login.reducerData.token, state),
    productInfo: reducerUtil.getSlice(detailsData, detailsData.information, state),
    hasPrice: reducerUtil.getSlice(tenantData, tenantData.hasPrice, state)
});

export default withRouter(connect(mapStateToProps)(RightContent));
