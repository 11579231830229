import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { reducerUtil } from 'base-client';

import { actions as searchActions, reducerData as searchData } from '../../../index';
import ActionsBar from './ActionsBar';
class ActionsBarContainer extends Component {
    static propTypes = {
        filterData: PropTypes.arrayOf(
            PropTypes.shape({
                attribute: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
            })
        ),
        dispatch: PropTypes.func.isRequired,
        totalHits: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    };

    static defaultProps = {
        selectedFilters: [],
        filterData: [],
    };

    render() {
        const { selectedFilters, filterData, totalHits, dispatch } = this.props;
        const filters = [];
        const removeAllFilters = () => dispatch(searchActions.clearAllFilters());

        if (selectedFilters) {
            selectedFilters.forEach(({ attribute, facets }) => {
                if (facets) {
                    const { name, single_select_facet } = filterData.find((item) => item.attribute === attribute) || {};
                    if (!single_select_facet) {
                        facets.forEach((facet) => {
                            filters.push({
                                key: `${attribute}${facet}`,
                                name,
                                facet,
                                onClick: () => dispatch(searchActions.removeFilter({ attribute, facets: [facet] })),
                            });
                        });
                    }
                }
            });
        }

        return (
            <ActionsBar
                {...{
                    filters,
                    totalHits,
                    removeAllFilters,
                }}
            />
        );
    }
}

const mapStateToProps = (state) => {
    const { filters: selectedFilters } = reducerUtil.getSlice(searchData, searchData.query, state) || {};
    const { pagination, filters } = reducerUtil.getSlice(searchData, searchData.meta, state) || {};
    const { totalHits } = pagination || {};
    return {
        selectedFilters,
        filterData: filters,
        totalHits,
    };
};

export default connect(mapStateToProps)(ActionsBarContainer);
