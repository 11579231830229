import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
    margin-bottom: 3rem;
`;

const DescriptionTitle = styled.h3`
    font-size: 1.3rem;
    text-transform: uppercase;
    letter-spacing: 0.3px;
    color: ${(props) => props.theme.grey4};
    font-weight: 400;
    position: relative;
    &:before {
        content: '';
        width: calc(100% - 10rem);
        margin-left: 10rem;
        position: absolute;
        height: 1px;
        top: 0.8rem;
        background: ${(props) => props.theme.grey1};
    }
`;

const ProductDescription = ({ description }) => (
    <Container>
        <DescriptionTitle>Description</DescriptionTitle>
        <p>{description}</p>
    </Container>
);

ProductDescription.propTypes = {
    description: PropTypes.string.isRequired,
};

export default ProductDescription;
