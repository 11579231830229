import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components';
import { config, history, reducerUtil } from 'base-client';
import { configMap } from 'configurations';

import { Button, Modal, PageHeader, ReduxFormInput } from 'shared';
import buttonStyles from '../../shared/styles/buttonStyles';

import userAccount from '../actions/userAccount';
import { required, max50 } from '../../utils/reduxFormValidator';
import media from '../../utils/media';

const GDPRForm = styled.div`
    width: 100%;
    margin: 0 auto;
    ${media.medium`
    width: 50%;
  `} ${media.large`
    width: 50%;
  `};
`;

const ButtonText = styled.span`
    margin-left: 20px;
    color: ${(props) => props.theme.blue};
    &:hover {
        cursor: pointer;
        color: ${(props) => props.theme.blueDarkest};
    }
`;

const Link = styled.a`
  color: ${(props) => props.theme.blue}
  &:hover {
    color: ${(props) => props.theme.blueDarkest};
  }
`;
const StyledSubmit = styled.button`
    ${buttonStyles};
`;

const Subtitle = styled.div`
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.8rem;
    color: ${(props) => props.theme.grey5};
    margin-bottom: 2rem;
    margin-top: -2rem;
`;

const GDPRContainer = ({
    handleSubmit,
    pristine,
    reset,
    submitting,
    valid,
    dispatch,
    deleteAccount,
    deleteToken,
    gdpr,
    token,
    loginPage,
    user_id,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const privacy = dispatch(config.actions.getData(configMap.agreement.name, configMap.agreement.privacy.name));

    const submitForm = async () => {
        const { values } = gdpr;
        const deleted = await deleteAccount({ ...values, user_id });

        setIsOpen(false);
        if (deleted) {
            deleteToken();
            history.push('/');
        }
    };

    useEffect(() => {
        if (!token) {
            history.push(loginPage);
        }
    }, [token]);

    return (
        <GDPRForm>
            <PageHeader title="Delete My Personal Data"></PageHeader>
            <Subtitle>
                Fill out the form below, or call 877-436-9031 to submit your data deletion request. Concora will process
                the request within 45 days, as required by{' '}
                <Link href={privacy} target="_blank">
                    California Consumer Privacy Act
                </Link>
            </Subtitle>
            <form onSubmit={handleSubmit(() => setIsOpen(true))}>
                <div>
                    <Field
                        name="firstName"
                        type="text"
                        component={ReduxFormInput}
                        label="firstName"
                        labelTitle="First Name - So We Can Verify Your Request"
                        validate={[required, max50]}
                        required
                    />
                    <Field
                        name="lastName"
                        type="text"
                        component={ReduxFormInput}
                        label="lastName"
                        labelTitle="Last Name - So We Can Verify Your Request"
                        validate={[required, max50]}
                        required
                    />
                    <Field
                        name="email"
                        type="email"
                        component={ReduxFormInput}
                        label="email"
                        labelTitle="Email - So We Can Notify You On Data Deletion"
                        validate={[required, max50]}
                        required
                    />
                </div>
                <StyledSubmit type="submit" disabled={!valid || pristine || submitting}>
                    Submit
                </StyledSubmit>
                <ButtonText disabled={!valid || pristine || submitting} onClick={reset}>
                    Cancel
                </ButtonText>
                <Modal
                    {...{
                        isOpen,
                        title: 'Please Confirm Your Data Deletion Request',
                        onClose: () => setIsOpen(false),
                    }}
                >
                    <p>
                        By clicking the <b>Yes, Delete</b> button below,
                        <br />I ackowledge that my account and all account related data will be permanently deleted from
                        Concora servers and that this action is irreversible.
                        <br />
                        <br />
                        Deletion may take several moments... please be patient.
                    </p>
                    <div>
                        <Button
                            onClick={() => {
                                submitForm();
                            }}
                        >
                            Yes, Delete
                        </Button>
                        <ButtonText
                            onClick={() => {
                                setIsOpen(false);
                            }}
                        >
                            Cancel
                        </ButtonText>
                    </div>
                    <br></br>
                </Modal>
            </form>
        </GDPRForm>
    );
};

const mapStateToProps = (state) => ({
    loginPage: reducerUtil.getSlice({ name: 'login' }, 'loginPage', state),
    token: reducerUtil.getSlice({ name: 'login' }, 'token', state),
    gdpr: state.form.gdpr,
    user_id: state.login.profile['https://permissions'].user_id,
});

const mapDispatchToProps = (dispatch) => ({
    deleteAccount: (values) => dispatch(userAccount.deleteAccount(values)),
    deleteToken: () => {
        reducerUtil.setSlice({ name: 'login' }, 'token', null);
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('profile');
        window.localStorage.removeItem('cookieAgreement');
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    reduxForm({
        form: 'gdpr',
        enableReinitialize: true,
        keepDirtyOnReinitialize: true,
    })(GDPRContainer)
);
