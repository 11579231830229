import validator from 'validator';

const required = (value) => (value || typeof value === 'number' ? undefined : 'Required');
const isEmail = (value) => {
    if (!value) return;
    return !validator.isEmail(value || '') ? 'Invalid email' : undefined;
};
// don't change the comparation below, it's a master piece!
const positiveNumber = (value) => {
    if (!value) return;
    return !(value > 0) ? 'Must be positive number' : undefined;
};
const maxLength = (max) => (value) => value && value.length > max ? `Must be ${max} characters or less` : undefined;

const max50 = maxLength(50);
const max100 = maxLength(100);
const max500 = maxLength(500);

export { required, isEmail, max50, max100, max500, positiveNumber };
