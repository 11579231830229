import { reducerUtil, config } from 'base-client';

import { reducerData as detailsData } from 'projectDetails';
import { reducerData as proposalData, DocumentTypes } from 'proposal';
import { configMap } from 'configurations';

const getDocuments = (attachments) => {
    if (attachments.length < 1) return [];

    const result = JSON.parse(JSON.stringify(DocumentTypes));

    attachments.forEach(({ id, name, location }, index) => {
        const fileExt = location.split('.').pop().toLowerCase();

        result.forEach(({ exts, files = [] }, index) => {
            if (exts.includes(fileExt)) {
                result[index].files = [...files, { id, name, location }];
            }
        });
    });

    return result.filter(({ files }) => files && files.length > 0);
};

const formatSubmittalProducts = (data) => (dispatch, getState) => {
    const disabledLinks = dispatch(config.actions.getData(configMap.disabled.name, configMap.disabled.assetLinks.name));

    const state = getState();

    const formattedData = data.map((product) => {
        const { id, name, thumbnail, productInfo, attachments = [] } = product;

        // add the other urls as attachments here - make them look the same.
        if (!disabledLinks) {
            const { information } = productInfo || {};
            const urlAssets =
                (information &&
                    information
                        .filter(({ attributeType, attributeValue }) => attributeType === 'assetlink' && attributeValue)
                        .map(({ attributeName, attributeValue }) => ({
                            id: attributeValue,
                            name: attributeName,
                            location: attributeValue,
                            downloadLocation: attributeValue,
                        }))) ||
                [];

            let documents = getDocuments(attachments.concat(urlAssets));

            documents = documents.map((asset) => {
                return { ...asset, files: asset.files.filter((file) => !file.archived) };
            });

            return { id, name, thumbnail, documents };
        } else {
            const documents = getDocuments(attachments);
            return { id, name, thumbnail, documents };
        }
    });

    //check if assets are selected.
    const selectedProducts = reducerUtil.getSlice(proposalData, proposalData.selectedProducts, state) || [];

    if (selectedProducts && selectedProducts.length > 0) {
        const result = [...formattedData];

        for (let i = 0; i < selectedProducts.length; i++) {
            const { id: selectedProductId, selected: currentSelected } = selectedProducts[i];

            const findIndex = result.findIndex(({ id: productId }) => productId === selectedProductId);

            if (findIndex >= 0) {
                result[findIndex].selected = [...currentSelected];
            }
        }
        return result;
    }

    return formattedData;
};

const handleCollapse = (productId) => (dispatch, getState) => {
    const state = getState();
    const products = reducerUtil.getSlice(detailsData, detailsData.products, state) || [];

    const newData = products.map((product) => {
        if (product.id === productId) {
            const { collapse: preVal } = product;
            return { ...product, collapse: !preVal };
        } else {
            return { ...product };
        }
    });

    dispatch(reducerUtil.setSlice(detailsData, detailsData.products, newData));
};

const selectAllDocuments = () => (dispatch, getState) => {
    const state = getState();
    const products = reducerUtil.getSlice(detailsData, detailsData.products, state) || [];

    if (products && products.length > 0) {
        const newData = products.map((product) => {
            const { documents = [] } = product;
            let selected = [];

            documents.forEach((document) => {
                const { files = [] } = document;
                const selectedIds = files.map(({ id }) => id);
                selected = [...selected, ...selectedIds];
            });

            return {
                ...product,
                selected,
            };
        });

        dispatch(reducerUtil.setSlice(detailsData, detailsData.products, newData));
    }
};

const deselectAllDocuments = () => (dispatch, getState) => {
    const state = getState();

    const products = reducerUtil.getSlice(detailsData, detailsData.products, state) || [];
    if (products && products.length > 0) {
        const newData = products.map((product) => {
            return {
                ...product,
                selected: undefined,
            };
        });

        dispatch(reducerUtil.setSlice(detailsData, detailsData.products, newData));
    }
};

const handleUpdateSelection = (productId, selectedItems) => (dispatch, getState) => {
    if (!Array.isArray(selectedItems)) selectedItems = [selectedItems];

    const state = getState();
    const products = reducerUtil.getSlice(detailsData, detailsData.products, state) || [];

    const newData = products.map((product) => {
        if (product.id === productId) {
            return { ...product, selected: [...selectedItems] };
        } else {
            return { ...product };
        }
    });

    dispatch(reducerUtil.setSlice(detailsData, detailsData.products, newData));
};

const handleRemoveProduct =
    (productId, updateOrder = true) =>
    (dispatch, getState) => {
        const state = getState();
        const products = reducerUtil.getSlice(detailsData, detailsData.products, state) || [];
        const newData = products.filter((product) => product.id !== productId);
        dispatch(reducerUtil.setSlice(detailsData, detailsData.products, newData));

        if (updateOrder) {
            const order = reducerUtil.getSlice(proposalData, proposalData.orderedProducts, state) || [];
            const newOrder = order.filter((id) => id !== productId);
            dispatch(reducerUtil.setSlice(proposalData, proposalData.orderedProducts, newOrder));
        } else {
            dispatch(reducerUtil.setSlice(proposalData, proposalData.orderedProducts, null));
        }
    };

const setProductOrder = (order) => (dispatch, getState) => {
    dispatch(reducerUtil.setSlice(proposalData, proposalData.orderedProducts, order));
};

const setProposalProducts = () => async (dispatch, getState) => {
    const state = getState();
    const products = reducerUtil.getSlice(detailsData, detailsData.products, state) || [];
    const selectedProducts = products
        .filter(({ selected }) => selected && selected.length > 0)
        .map((product) => {
            const { id, name, thumbnail, documents: orgDocuments = [], selected } = product;

            let documents = [];
            orgDocuments.forEach((document) => {
                const { type, files = [] } = document;
                const selectedFiles = files.filter(({ id: documentId }) => selected.includes(documentId));
                if (selectedFiles.length > 0) {
                    documents.push({ type, files: selectedFiles });
                }
            });

            return {
                id,
                name,
                thumbnail,
                selected,
                documents,
            };
        });

    await dispatch(reducerUtil.setSlice(proposalData, proposalData.selectedProducts, selectedProducts));
};

const collapseSelectedProducts = (productId) => (dispatch, getState) => {
    const state = getState();
    const products = reducerUtil.getSlice(proposalData, proposalData.selectedProducts, state) || [];

    const newData = products.map((product) => {
        if (product.id === productId) {
            const { collapse: preVal } = product;
            return { ...product, collapse: !preVal };
        } else {
            return { ...product };
        }
    });

    dispatch(reducerUtil.setSlice(proposalData, proposalData.selectedProducts, newData));
};

export default {
    handleCollapse,
    handleUpdateSelection,
    handleRemoveProduct,
    setProposalProducts,
    setProductOrder,
    collapseSelectedProducts,
    formatSubmittalProducts,
    selectAllDocuments,
    deselectAllDocuments,
};
