import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import debounce from 'lodash/debounce';

import { reducerUtil, login, history } from 'base-client';
import { reducerData as tenantData } from 'tenant';
import { Row, Column } from 'shared';

import { ErrorProjectLogin } from 'errors';
import { actions, reducerData } from 'projects';
import { actions as detailsActions } from 'projectDetails';
import ProjectCard from './ProjectCard';
import { SortDropdown, PageHeader } from 'shared';
import sortOptions from '../sortOptions';
import AddToProjectButton from './AddToProjectButton';
import ProjectCreationModal from '../../projects/components/Modal/ProjectCreationModal';
import { PoweredConcora, Card } from 'shared';

const NoProjectCard = styled(Card)`
    padding: 10rem;
    text-align: center;
    color: ${(props) => props.theme.grey3};
`;

const RightActions = styled.div`
    margin-left: auto;
`;

const CardWrapper = styled(Column)`
    flex-flow: column wrap;
`;

const SortColumn = styled(Column)`
    align-self: center;
`;

class Projects extends React.Component {
    constructor(props) {
        super(props);
        this.state = { createProjectModalOpen: false };
    }

    componentDidMount() {
        this.setState({ createProjectModalOpen: false });
        this.props.dispatch(actions.getProjects(true));
    }

    componentWillUnmount() {
        let element = document.querySelector('body');
        if (!!element) {
            element.onscroll = undefined;
            element.onresize = undefined;
        }
    }

    componentDidUpdate() {
        let self = this;
        self.isLoading = false;

        if (!this.props.meta) return;

        const {
            projects,
            meta: {
                pagination: { resultCount, limit },
            },
        } = this.props;

        let element = document.querySelector('body');
        if (!!element) {
            element.onscroll = debounce(() => {
                onScrolled();
            }, 50);
        }

        function onScrolled() {
            if (
                document.documentElement.scrollTop > 0.7 * element.offsetHeight &&
                resultCount === limit &&
                self.isLoading === false
            ) {
                self.props.dispatch(actions.getProjects());
                self.isLoading = true;
            }
        }
    }

    render() {
        const { projects, dispatch, projectSubmittalSettings, token, meta } = this.props;

        const deleteProject = (id) => {
            dispatch(actions.deleteProject(id));
        };

        const handleSortChange = (val) => {
            let sort = sortOptions.find((o) => o.name === val);
            dispatch(reducerUtil.setSlice(reducerData, reducerData.meta, { ...meta, sort }));
            dispatch(actions.getProjects(true));
        };

        const openModal = () => {
            this.setState({ createProjectModalOpen: true });
        };

        const closeModal = () => {
            this.setState({ createProjectModalOpen: false });
            this.props.dispatch(actions.getProjects(true));
        };

        const createSubmittal = (id) => dispatch(detailsActions.goToSubmittal(id));

        const cloneProject = async (project) => {
            const result = await dispatch(detailsActions.cloneProject(project));
            this.props.dispatch(actions.getProjects(true));
            return result;
        };

        const StyledHeader = ({ noCta }) => (
            <Column mdUp={12}>
                <PageHeader title="My Projects" justifyContent="flex-start">
                    <PoweredConcora margin="0.3rem 0 0" />
                    {!noCta && (
                        <RightActions>
                            <div>
                                <AddToProjectButton onClick={() => openModal()}>Create a Project</AddToProjectButton>
                            </div>
                        </RightActions>
                    )}
                </PageHeader>
            </Column>
        );
        if (projectSubmittalSettings.disabled) {
            history.push('/products');
            return null;
        }

        if (projectSubmittalSettings.requiredLogin && !token) {
            return (
                <ErrorProjectLogin>
                    Please <Link to="/login">log in</Link> to view projects
                </ErrorProjectLogin>
            );
        }

        if (!projects) return null;

        if (!projects.length)
            return (
                <Row>
                    <StyledHeader noCta />
                    <Column mdUp={12}>
                        <NoProjectCard>
                            <p>Create your first project!</p>
                            <AddToProjectButton onClick={this.openModal}>Create a Project</AddToProjectButton>
                        </NoProjectCard>
                    </Column>
                </Row>
            );

        return (
            <React.Fragment>
                {this.state.createProjectModalOpen && (
                    <ProjectCreationModal
                        title="Create project"
                        productDetails
                        open={this.state.createProjectModalOpen}
                        from={'Projects'}
                        hideOption={[0]}
                        defaultSelected={1}
                        onClose={() => closeModal()}
                    />
                )}
                <Row>
                    <StyledHeader />
                </Row>
                <Row>
                    <Column mdUp={4}>
                        <h4>Showing {projects.length} Projects</h4>
                    </Column>
                    <SortColumn mdUp={8}>
                        <SortDropdown
                            {...{ sortOptions, handleChange: handleSortChange }}
                            style={{ justifyContent: 'flex-end' }}
                        />
                    </SortColumn>
                </Row>
                <Row>
                    <Column mdUp={12}>
                        <Row>
                            {projects.map((p) => (
                                <CardWrapper mdUp={4} key={p.id}>
                                    <ProjectCard
                                        project={p}
                                        key={p.id}
                                        onDeleteProject={() => deleteProject(p.id)}
                                        onCreateSubmittal={() => createSubmittal(p.id)}
                                        onCloneProject={() => cloneProject(p)}
                                    />
                                </CardWrapper>
                            ))}
                        </Row>
                    </Column>
                </Row>
            </React.Fragment>
        );
    }
}

Projects.propTypes = {};

Projects.defaultProps = {};

const mapStateToProps = (state) => ({
    projectSubmittalSettings: reducerUtil.getSlice(tenantData, tenantData.projectSubmittalSettings, state),
    token: reducerUtil.getSlice(login.reducerData, login.reducerData.token, state),
    projects: reducerUtil.getSlice(reducerData, reducerData.projects, state),
    meta: reducerUtil.getSlice(reducerData, reducerData.meta, state),
});

export default connect(mapStateToProps)(Projects);
