import { css } from 'styled-components';

const buttonStyles = css`
    display: inline-block;
    font-weight: 600;
    font-size: 1.2rem;
    font-family: ${(props) => props.theme.fontBody};
    text-transform: uppercase;
    letter-spacing: 0.2px;
    text-align: center;
    min-width: 7rem;
    padding: 0 1.5rem 0.1rem;
    margin: ${(props) => props.marginTop || '0'} 0 ${(props) => props.marginBottom || '0'};
    cursor: pointer;
    color: ${(props) => (props.secondary ? props.theme.grey3 : 'white')};
    background: ${(props) => (props.secondary ? props.theme.grey1 : props.theme.accentColor)};
    border: 0;
    border-radius: 3px;
    appearance: none;
    position: relative;
    height: 3rem;
    transition: color 0.2s ease-in-out, background 0.2s ease-in-out;
    &:hover:not[disabled],
    &:focus {
        color: ${(props) => (props.secondary ? props.theme.grey4 : 'white')};
        background: ${(props) => (props.secondary ? props.theme.grey2 : props.theme.blueDarkest)};
        outline: none;
    }
    &[disabled] {
        opacity: 0.5;
        cursor: not-allowed;
    }
`;

const inverseButtonStyles = css`
    background-color: ${(props) => (props.secondary ? props.theme.grey3 : props.theme.white)};
    color: ${(props) => props.color || (props.theme.primaryColor ? props.theme.primaryColor : props.theme.blue)};
    border: 1px solid
        ${(props) => props.color || (props.theme.primaryColor ? props.theme.primaryColor : props.theme.blue)};
    &:hover {
        background-color: ${(props) =>
            props.color || (props.theme.primaryColor ? props.theme.primaryColor : props.theme.blue)};
        color: ${(props) => (props.secondary ? props.theme.grey3 : props.theme.white)};
        border: 1px solid ${(props) => props.color || (props.secondary ? props.theme.grey3 : props.theme.white)};
    }
`;

const transparentButtonStyles = css`
    color: ${(props) => props.theme.textColor};
    border: 1px solid ${(props) => (props.borderColor ? props.borderColor : props.theme.textColor)};
    background-color: transparent;
    &:hover {
        color: ${(props) => props.theme.textHoverColor};
        border: 1px solid ${(props) => props.theme.textHoverColor};
    }
`;

const dangerButtonStyles = css`
    color: ${(props) => props.theme.white};
    background-color: ${(props) => props.theme.redDark};
`;

const rightArrowStyles = css`
    padding-right: 2rem;
    &:after {
        content: '';
        display: inline-block;
        width: 5px;
        height: 5px;
        position: relative;
        right: -0.8rem;
        border: none;
        border-right: 2px solid
            ${(props) => (props.color ? props.color : props.secondary ? props.theme.grey3 : props.theme.white)};
        border-top: 2px solid
            ${(props) => (props.color ? props.color : props.secondary ? props.theme.grey3 : props.theme.white)};
        transform: rotate(45deg);
    }
`;

export default buttonStyles;
export { inverseButtonStyles, transparentButtonStyles, dangerButtonStyles, rightArrowStyles };
