import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { history } from 'base-client';
import { Button, Tooltip } from 'shared';

const Wrapper = styled.div`
    position: relative;
    &.hasTooltip {
        .tooltip {
            left: -2rem;
            right: -2rem;
        }
        &:hover {
            .tooltip {
                opacity: 1;
            }
        }
    }
`;

const ComparisonButton = ({ selectedProducts, children }) => {
    const isDisabled = !selectedProducts || selectedProducts.length < 2 || selectedProducts.length > 4;

    const tooltipText =
        !selectedProducts || selectedProducts.length < 2
            ? 'Please select at least 2 products to view a comparison'
            : 'Please select between 2-4 products for comparison';

    const selectedIds = selectedProducts.map(({ id }) => id);

    const handleClick = () => history.push(`/compare?ids=${selectedIds.join('&')}`);

    return (
        <Wrapper className={isDisabled ? 'hasTooltip comparison' : ''}>
            <Button transparent small onClick={handleClick} disabled={isDisabled}>
                {children ? children : 'Add Selected Products to Compare'}
            </Button>
            {isDisabled ? <Tooltip>{tooltipText}</Tooltip> : null}
        </Wrapper>
    );
};

ComparisonButton.propTypes = {
    selectedProducts: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string,
        })
    ),
};

export default ComparisonButton;
