import React, { Component } from 'react';
import PropTypes from 'prop-types';
import truncateString from '../utils/truncateString';

class ClampLines extends Component {
    static propTypes = {
        text: PropTypes.string.isRequired,
        lines: PropTypes.number,
        className: PropTypes.string,
        delay: PropTypes.number,
    };

    static defaultProps = {
        text: '',
        className: '',
        delay: 300,
    };

    constructor(props) {
        super(props);

        this.element = null;
        this.original = props.text;
        this.watch = true;

        this.state = {
            noClamp: false,
            text: '',
        };

        // If window is undefined it means the code is executed server-side
        this.ssr = typeof window === 'undefined';

        if (!this.ssr) {
            this.debounced = this.debounce(this.action, props.delay);
        }
    }

    componentDidMount() {
        if (this.props.text && !this.ssr) {
            this.clampLines();

            if (this.watch) {
                window.addEventListener('resize', this.debounced);
            }
        }
    }

    componentWillUnmount() {
        if (!this.ssr) {
            window.removeEventListener('resize', this.debounced);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.text !== this.props.text) {
            this.original = this.props.text;

            this.clampLines();
        }
    }

    debounce(func, wait, immediate) {
        let timeout;

        return () => {
            const context = this,
                args = arguments;
            const later = () => {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            const callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    }

    action = () => {
        if (this.watch) {
            this.setState({
                noClamp: false,
            });
            this.clampLines();
        }
    };

    clampLines = () => {
        if (!this.element) return;

        if (this.state.text) {
            this.setState({
                text: '',
            });
        }

        const truncatedText = truncateString(this.original, 25);

        this.setState({
            text: truncatedText,
        });
    };

    getClassName = () => {
        return `clamp-lines ${this.props.className || ''}`;
    };

    render() {
        if (!this.props.text) {
            return null;
        }

        const { text } = this.state;
        const className = this.getClassName();

        return (
            <div
                className={className}
                ref={(e) => {
                    this.element = e;
                }}
            >
                {text}
            </div>
        );
    }
}

export default ClampLines;
