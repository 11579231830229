import { login, api, reducerUtil, history, config } from 'base-client';

import { format } from 'date-fns';

import { actions as errorsActions } from 'errors';
import { reducerData as tenantData } from 'tenant';
import { reducerData as productDetailsData } from 'productDetails';
import { reducerData as detailsData } from 'projectDetails';
import { actions as analyticsActions } from 'analytics';

import projectsData from '../reducerData';
import _ from 'lodash';

const getProject =
    (id, safemode = false) =>
    async (dispatch, getState) => {
        let state = getState();

        const tenantId = reducerUtil.getSlice(tenantData, tenantData.tenant, state);
        try {
            let pastSubmittals, pastBrochures, participants;
            let project = {};
            Promise.all([
                (project = await dispatch(api.actions.get(`projects/${id}?tenant_id=${tenantId}`, true))),
                (participants = await dispatch(
                    api.actions.get(`projects/${id}/participants?tenant_id=${tenantId}`, true)
                )),
                (pastSubmittals = await dispatch(
                    api.actions.get(
                        `projects/${id}/submittals?tenant_id=${tenantId}&limit=10&order[created_at]=desc`,
                        true
                    )
                )),
                (pastBrochures = await dispatch(
                    api.actions.get(
                        `projects/${id}/brochures?tenant_id=${tenantId}&limit=10&order[created_at]=desc`,
                        true
                    )
                ).catch((e) => {
                    return null;
                })),
            ]).then(() => {
                ['contractor', 'general_contractor', 'architect', 'distributor'].forEach((o) => {
                    project[o] = participants.projectParticipants.find((p) => p.participant_type === o);
                });
                dispatch(reducerUtil.setSlice(detailsData, detailsData.generalData, project));
                dispatch(
                    reducerUtil.setSlice(
                        detailsData,
                        detailsData.pastSubmittals,
                        pastSubmittals
                            ? pastSubmittals.projectSubmittals.map((submittal) => ({
                                  ...submittal,
                                  type: 'submittal',
                              }))
                            : []
                    )
                );
                dispatch(
                    reducerUtil.setSlice(
                        detailsData,
                        detailsData.pastBrochures,
                        pastBrochures
                            ? pastBrochures.projectBrochures.map((brochure) => ({
                                  ...brochure,
                                  type: 'brochure',
                              }))
                            : []
                    )
                );
            });
        } catch (error) {
            if (!safemode) dispatch(errorsActions.error(error));
        }
    };

const getProjectsWithProduct =
    (product_id, safemode = false) =>
    async (dispatch, getState) => {
        const state = getState();
        const token = reducerUtil.getSlice(login.reducerData, login.reducerData.token, state);
        if (!token) {
            return;
        }
        try {
            const projectsWithProduct = await dispatch(
                api.actions.post('projects/project-check/', { product_id }, true)
            );
            dispatch(
                reducerUtil.setSlice(productDetailsData, productDetailsData.projectsWithProduct, projectsWithProduct)
            );
        } catch (error) {
            if (!safemode) dispatch(errorsActions.error(error));
        }
    };

const clearProjectsDetailsData = () => (dispatch) => {
    // dispatch(reducerUtil.setSlice(detailsData, detailsData.generalData, undefined));
    // dispatch(reducerUtil.setSlice(detailsData, detailsData.pastSubmittals, undefined));
    dispatch(reducerUtil.setSlice(detailsData, detailsData.products, undefined));
    dispatch(reducerUtil.setSlice(detailsData, detailsData.selectedProducts, undefined));
};

const wipeProjectsDetailsData = () => (dispatch) => {
    dispatch(reducerUtil.setSlice(detailsData, detailsData.generalData, undefined));
    dispatch(reducerUtil.setSlice(detailsData, detailsData.pastSubmittals, undefined));
    dispatch(reducerUtil.setSlice(detailsData, detailsData.pastBrochures, undefined));
};

const goToSubmittal = (id, defaultStep) => async () => {
    history.push(`/projects/${id}/submittal`, { defaultStep });
};

const goToBrochure = (id, defaultStep) => async () => {
    history.push(`/projects/${id}/brochure`, { defaultStep });
};

const goToProjectEdit = (id) => async () => {
    history.push(`/projects/${id}/edit`);
};

const setSubmittalOptions = (options) => (dispatch) => {
    dispatch(reducerUtil.setSlice(detailsData, detailsData.submittalOptions, options));
};

const cloneProject = (projectInfo) => async (dispatch, getState) => {
    projectInfo.name = `Copy_${projectInfo.name}_${format(new Date(), 'Pp')}`;
    const existingProjectId = projectInfo.id;
    const existingProjectProductsCount = projectInfo['products.count'];

    const apiParams = _.omit(projectInfo, ['address.id', 'address_id', 'id']);
    try {
        // clone the project
        const result = await dispatch(
            api.actions.post(
                'projects',
                { existingProjectId, existingProjectProductsCount, clone: true, ...apiParams },
                true
            )
        );

        if (result) {
            const { id, name } = result;

            dispatch(
                analyticsActions.track('projectclone', {
                    project_id: existingProjectId,
                })
            );
            dispatch(analyticsActions.track('projectcreate', { project_id: id, name }));

            return result;
        }
    } catch (error) {
        dispatch(config.actions.error(error));
    }
};

export default {
    clearProjectsDetailsData,
    cloneProject,
    getProject,
    getProjectsWithProduct,
    goToBrochure,
    goToProjectEdit,
    goToSubmittal,
    setSubmittalOptions,
    wipeProjectsDetailsData,
};
