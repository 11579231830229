import React, { useEffect } from 'react';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { login, reducerUtil } from 'base-client';

import { actions as messageActions } from 'message';
import { actions as accountActions, reducerData as accountData } from 'userAccount';
import { reducerData as tenantData } from 'tenant';
import { SubmitButton, FormGroup, ReduxFormInput, ReduxFormDropdown, FormDropdownOption } from 'shared';
import { required, max50, max100, isEmail } from 'utils/reduxFormValidator';
import industries from 'utils/staticData/industries';
import occupations from 'utils/staticData/occupations';

const MessageForm = ({
    children,
    dispatch,
    handleSubmit,
    form,
    pristine,
    valid,
    submitting,
    tenantName,
    onComplete,
    token,
}) => {
    useEffect(() => {
        dispatch(accountActions.getUserAccount(true));
    }, []);

    const onClick = () => {
        const handleClick = async () => {
            form === 'reqForQuote'
                ? await dispatch(messageActions.quote(form))
                : await dispatch(messageActions.contact(form));
            onComplete();
        };
        handleClick();
    };
    return (
        <form onSubmit={handleSubmit}>
            <React.Fragment>
                <FormGroup>
                    <Field
                        name="firstName"
                        type="text"
                        component={ReduxFormInput}
                        label="firstName"
                        labelTitle="First Name"
                        validate={[max50, required]}
                        required
                    />
                    <Field
                        name="lastName"
                        type="text"
                        component={ReduxFormInput}
                        label="lastName"
                        labelTitle="Last Name"
                        validate={[max50, required]}
                        required
                    />
                </FormGroup>
                <FormGroup>
                    <Field
                        name="email"
                        type="text"
                        component={ReduxFormInput}
                        label="email"
                        labelTitle="Email Address"
                        validate={[max50, required, isEmail]}
                        required
                    />
                    <Field
                        name="zipcode"
                        type="text"
                        component={ReduxFormInput}
                        label="zipcode"
                        labelTitle="Zipcode"
                        validate={[required]}
                        required
                    />
                    <Field
                        name="companyName"
                        type="text"
                        component={ReduxFormInput}
                        label="company"
                        labelTitle="Company"
                        validate={[max100, required]}
                        required
                    />
                </FormGroup>
                <FormGroup>
                    <Field
                        name="industry"
                        type="text"
                        component={ReduxFormDropdown}
                        label="industry"
                        labelTitle="Industry"
                        validate={[required]}
                        required
                    >
                        <FormDropdownOption value={''} optionLabel={'Select an Industry'} disabled />
                        {industries.map((item) => (
                            <FormDropdownOption key={item} value={item} optionLabel={item} />
                        ))}
                    </Field>
                    <Field
                        name="occupation"
                        type="text"
                        component={ReduxFormDropdown}
                        label="occupation"
                        labelTitle="Occupation"
                        validate={[required]}
                        required
                    >
                        <FormDropdownOption value={''} optionLabel={'Select an Occupation'} disabled />
                        {occupations.map((item) => (
                            <FormDropdownOption key={item} value={item} optionLabel={item} />
                        ))}
                    </Field>
                </FormGroup>
            </React.Fragment>

            {children}
            <SubmitButton disabled={!valid || pristine || submitting} {...{ onClick }}>
                Send message to {tenantName}
            </SubmitButton>
        </form>
    );
};

const mapStateToProps = (state) => {
    const token = reducerUtil.getSlice(login.reducerData, login.reducerData.token, state);
    const userData = reducerUtil.getSlice(accountData, accountData.data, state) || {};
    const { name: tenantName } = reducerUtil.getSlice(tenantData, tenantData.theme, state) || {};
    return { tenantName, initialValues: userData, token };
};

export default connect(mapStateToProps)(
    reduxForm({
        enableReinitialize: true,
    })(MessageForm)
);
