import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledContent = styled.div``;

const Title = styled.div`
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 2.5rem;
`;

const Container = styled.div`
    height: 1rem;
    width: 100%;
    margin-bottom: 1rem;
    border-radius: 0.5rem;
    position: relative;
    background-color: ${(props) => props.theme.grey1};
`;

const Filter = styled.div`
    width: ${(props) => (props.width ? props.width : '60%')};
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: ${(props) => props.theme.purple1};
    border-radius: 0.5rem;
`;

const ProgressBar = ({ title, data, ...props }) => {
    let numberOfitems, completedEntries, percentage;
    if (!data) {
        numberOfitems = 0;
        completedEntries = 0;
        percentage = 0;
    } else {
        numberOfitems = Object.values(data).length;
        completedEntries = Object.values(data).filter((item) => item).length;
        percentage = (completedEntries / numberOfitems) * 100;
    }

    return (
        <StyledContent {...props}>
            <div>
                <Title>{title}</Title>
                <Container className="progress-bar">
                    <Filter className="filler" width={`${percentage}%`} />
                </Container>
            </div>
        </StyledContent>
    );
};

ProgressBar.propTypes = {
    title: PropTypes.string,
    data: PropTypes.object,
};

export default ProgressBar;
