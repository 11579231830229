import React from 'react';
import PropTypes from 'prop-types';

const Body = ({ children }) => <div>{children}</div>;

Body.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
};

export default Body;
