import React from 'react';
import styled from 'styled-components';

import { CloseIcon } from 'shared';

const Bold = styled.span`
    font-weight: bold;
    font-size: 16px;
    color: ${(props) => props.theme.grey5};
`;

const SubmittalProductsRemovedCard = ({ card, onRemove }) => (
    <div className="pad-15">
        <div className="notification-card-title">
            <Bold>Product Removed Successfully</Bold>
            <CloseIcon onClick={() => onRemove(card.id)} />
        </div>
        <div className="notification-card-content">
            {(card.payloads.products && card.payloads.products.length) || 0} Products have been removed from your
            project <Bold>{card.payloads.project.name}</Bold>
        </div>
    </div>
);

export default SubmittalProductsRemovedCard;
