import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';

import { actions as notificationActions, projectNotification } from 'notification';
import { FormPasswordInput, LinkButton, PoweredConcora } from 'shared';

const HelpText = styled.p`
    font-size: 1.4rem;
    line-height: 1.45;
    margin: 1.5rem 0 3rem;
`;

const Form = styled.form`
    text-align: center;
    position: relative;
`;

const getParams = () => {
    const params = new URL(decodeURIComponent(window.location.href)).searchParams;

    const auth0Id = params.get('auth0Id');
    const username = params.get('username');

    return { auth0Id, username };
};

// one number, one lowercase, one uppercase, one special characters, min 8 chars
const regex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])[A-Za-z\d~!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]{8,}$/;

const PasswordReset = ({ displayError, handleChangePassword }) => {
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const checkPasswordReqs = () => {
        if (!regex.test(password)) {
            setError('Password does not meet requirement of an uppercase, lowercase, number, and special character');
        } else if (password !== confirmPassword) {
            setError('Passwords do not match');
        } else {
            handleChangePassword({ ...getParams(), password });
        }
    };

    useEffect(() => {
        if (error && error.length) {
            displayError(error);
            setTimeout(function () {
                setError('');
            }, 3000);
        }
    }, [error]);

    return (
        <>
            <HelpText>
                Please enter a new password with at least 8 characters. Must contain both upper and lowercase letters, a
                number, and special character.
            </HelpText>
            <Form noValidate>
                <FormPasswordInput
                    onChange={(e) => setPassword(e.target.value)}
                    showPassword={showPassword}
                    setShowPassword={setShowPassword}
                    value={password}
                    field="password"
                    labelTitle="New Password"
                    placeholder="Enter your new password"
                    type="password"
                    hasError={!!error}
                    errorMessage={error}
                    required
                />
                <br />
                <FormPasswordInput
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    showPassword={showPassword}
                    setShowPassword={setShowPassword}
                    value={confirmPassword}
                    field="confirmPassword"
                    labelTitle="Confirm Password"
                    placeholder="Re-enter your new password"
                    type="password"
                    hasError={!!error}
                    errorMessage={error}
                    required
                />
                <LinkButton small marginTop="2rem" marginBottom="2rem" onClick={() => checkPasswordReqs()}>
                    Reset Password
                </LinkButton>
                <PoweredConcora margin="1rem 0 0" />
            </Form>
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    displayError: (error) => {
        dispatch(
            notificationActions.sendNotifications([
                {
                    id: `password-error-${uuid()}`,
                    type: projectNotification.GENERAL,
                    title: 'Password Reset Error',
                    body: error,
                },
            ])
        );
    },
});

export default connect(null, mapDispatchToProps)(PasswordReset);
