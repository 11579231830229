import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { login, config, history, reducerUtil } from 'base-client';
import { reducerData as tenantData } from 'tenant';
import { configMap } from 'configurations';
import { reducerData as projectData, actions as projectActions } from 'projects';
import { Tooltip, Dropdown } from 'shared';
import ProjectCreationModal from '../../projects/components/Modal/ProjectCreationModal';

const StyledProjectDropdown = styled.div`
    position: relative;
    transition: all 0.1s ease;
    display: flex;
    font-weight: 500;
    .dropdown {
        .visible {
            min-width: 15rem;
        }
        .dropdown-item {
            cursor: pointer;
            padding: 0 1.5rem 0.5rem;
            color: ${(props) => props.theme.textColor || props.theme.grey3};
            &:hover {
                color: ${(props) => props.theme.textHoverColor || 'inherit'};
            }
            &.active {
                color: ${(props) => props.theme.textHoverColor || 'inherit'};
                font-weight: 700;
            }
        }
    }
`;

const DisplayText = styled.div`
    cursor: pointer;
    color: ${(props) => props.theme.textColor || props.theme.grey3};
    ~ .tooltip {
        bottom: -5.5rem;
        left: -3.5rem;
        z-index: 9999;
        text-align: center;
        max-width: 12.5rem;
    }
    &:hover {
        color: ${(props) => props.theme.textHoverColor || 'inherit'};
        ~ .tooltip {
            opacity: 1;
        }
    }
`;

const ProjectButton = ({ products, token, loginPage, projectSubmittalSettings, activeIndex, dispatch, modalOpen }) => {
    const { disabled } = projectSubmittalSettings || {};
    const disabledProject = dispatch(config.actions.getData(configMap.disabled.name, configMap.disabled.project.name));

    const onClose = async () => {
        await dispatch(projectActions.modalClose());
    };

    const openModal = async () => {
        await dispatch(projectActions.openModal(true));
    };

    if (disabledProject || disabled) return null;

    if (!token)
        return (
            <StyledProjectDropdown>
                <DisplayText onClick={() => history.push(loginPage)}>Projects</DisplayText>
                <Tooltip position="bottom">Login to access projects</Tooltip>
            </StyledProjectDropdown>
        );

    return (
        <StyledProjectDropdown>
            <Dropdown text="Projects">
                <div className="dropdown-item" onClick={openModal}>
                    Create Project
                </div>
                <Link
                    to={'/projects'}
                    className={`dropdown-item ${activeIndex && activeIndex.startsWith('/projects') ? 'active' : ''}`}
                >
                    My Projects
                </Link>
            </Dropdown>
            {modalOpen && (
                <ProjectCreationModal
                    onClose={onClose}
                    title="Create a Project"
                    from={'ProjectsDropdown'}
                    hideOption={[0]}
                    defaultSelected={1}
                    open={modalOpen}
                />
            )}
        </StyledProjectDropdown>
    );
};

const mapStateToProps = (state) => ({
    projectSubmittalSettings: reducerUtil.getSlice(tenantData, tenantData.projectSubmittalSettings, state),
    token: reducerUtil.getSlice(login.reducerData, login.reducerData.token, state),
    loginPage: reducerUtil.getSlice(login.reducerData, login.reducerData.loginPage, state),
    modalOpen: reducerUtil.getSlice(projectData, projectData.modalOpen, state),
});

export default connect(mapStateToProps)(ProjectButton);
