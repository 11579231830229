import { v4 as uuid } from 'uuid';
import { api, reducerUtil, history } from 'base-client';

import { actions as analyticsActions } from 'analytics';
import { actions as errorsActions } from 'errors';
import { reducerData as tenantData } from 'tenant';
import { actions as productsSearchActions } from 'productSearch';
import { actions as notificationActions, projectNotification } from 'notification';

import { reducerData as projectDetailData, sortList } from 'projectDetails';
import { actions as proposalActions } from 'proposal';

const loadSavedProducts =
    ({ projectId, restart, forSubmittal, noQuery = false }) =>
    async (dispatch, getState) => {
        if (!projectId) return;

        let state = getState();

        const tenantId = reducerUtil.getSlice(tenantData, tenantData.tenant, state);

        // get the page information
        const { page: currentPage = 0 } =
            reducerUtil.getSlice(projectDetailData, projectDetailData.pagination, state) || {};
        const page = restart ? 1 : currentPage + 1;
        const limit = 100;

        // get the querystring
        const {
            location: { search: queryJson },
        } = history;

        const query = !noQuery && queryJson ? JSON.parse(decodeURIComponent(trimQuery(queryJson))) : {};
        const defaultSortQuery = { sortBy: sortList[0].sortBy, sortDir: sortList[0].sortDir };

        const apiParams = {
            ...defaultSortQuery,
            ...query,
        };

        const fetchId = uuid();
        dispatch(reducerUtil.setSlice(projectDetailData, projectDetailData.fetchId, fetchId));

        try {
            const apiEndpoint = `projects/${projectId}/products?tenant_id=${tenantId}&order[${apiParams.sortBy}]=${apiParams.sortDir}&page=${page}&limit=${limit}`;

            const {
                projectProducts: products = [],
                pagination,
                order,
            } = await dispatch(api.actions.get(apiEndpoint, true));

            // check that this is the correct fetch
            state = getState();
            if (fetchId !== reducerUtil.getSlice(projectDetailData, projectDetailData.fetchId, state)) return;
            // get current product info if not restarting
            const currentList =
                (!restart && reducerUtil.getSlice(projectDetailData, projectDetailData.products, state)) || [];

            //get tenant name from theme.
            const theme = reducerUtil.getSlice(tenantData, tenantData.theme, state) || {};
            const deletedText = `${theme.name} has removed this product listing.`;

            //check if the product has been deleted then add text to describe it.
            const filteredList = products.map(({ product = {}, product_id: id, name }) => {
                if (product && product.manufacturer_id) {
                    return { ...product };
                } else {
                    return {
                        id,
                        name,
                        description: deletedText,
                        isDeleted: true,
                    };
                }
            });

            if (forSubmittal) {
                //if products is using for submittal review page, call formatSubmittalProducts function to get documents
                const data = dispatch(proposalActions.formatSubmittalProducts(filteredList));
                dispatch(
                    reducerUtil.setSlice(projectDetailData, projectDetailData.products, [...currentList, ...data])
                );
            } else {
                // if products is using in product details page, call formatProducts function to get callouts/assets
                const formattedProducts = dispatch(productsSearchActions.formatProducts(filteredList));
                dispatch(
                    reducerUtil.setSlice(projectDetailData, projectDetailData.products, [
                        ...currentList,
                        ...formattedProducts,
                    ])
                );
            }

            dispatch(reducerUtil.setSlice(projectDetailData, projectDetailData.pagination, pagination));
            // update the search parameters
            dispatch(
                reducerUtil.setSlice(projectDetailData, projectDetailData.order, {
                    sortBy: order[0][0],
                    sortDir: order[0][1],
                })
            );
            // allow another search
            dispatch(reducerUtil.setSlice(projectDetailData, projectDetailData.fetchId, undefined));
            return;
        } catch (error) {
            dispatch(errorsActions.error(error));
        }
    };

const loadNextPage =
    ({ projectId, forSubmittal }) =>
    (dispatch) =>
        dispatch(loadSavedProducts({ projectId, forSubmittal }));

const setQuery = (projectId, order) => (dispatch) => {
    dispatch(reducerUtil.setSlice(projectDetailData, projectDetailData.order, order));
    history.push(`/projects/${projectId}?${encodeURIComponent(JSON.stringify(order))}`);
    return dispatch(loadSavedProducts({ projectId, restart: true }));
};

const setSort = (projectId, name) => (dispatch, getState) => {
    const state = getState();
    const order = reducerUtil.getSlice(projectDetailData, projectDetailData.order, state);
    const sortInfo = sortList.find((item) => item.name === name) || sortList[0];
    const { sortBy, sortDir } = sortInfo;
    return dispatch(setQuery(projectId, { ...order, sortBy, sortDir }));
};

const trimQuery = (query) => {
    if (typeof query !== 'string') return query;
    while (query.charAt(0) === '?') query = query.substr(1);
    return query;
};

const selectProduct = (product) => (dispatch, getState) => {
    const state = getState();
    const selectedProducts = reducerUtil.getSlice(projectDetailData, projectDetailData.selectedProducts, state) || [];
    return dispatch(
        reducerUtil.setSlice(projectDetailData, projectDetailData.selectedProducts, [...selectedProducts, product])
    );
};

const deselectProduct = (product) => (dispatch, getState) => {
    const state = getState();
    const selectedProducts = reducerUtil.getSlice(projectDetailData, projectDetailData.selectedProducts, state) || [];
    const newList = selectedProducts.filter(({ id }) => id !== product.id);
    return dispatch(
        reducerUtil.setSlice(
            projectDetailData,
            projectDetailData.selectedProducts,
            newList.length > 0 ? newList : undefined
        )
    );
};

const clearSelectedProducts = () => (dispatch) =>
    dispatch(reducerUtil.setSlice(projectDetailData, projectDetailData.selectedProducts, undefined));

const removeProductsFromProject = (id, name) => async (dispatch, getState) => {
    const state = getState();

    const userId = dispatch(analyticsActions.getUserId());
    const tenantId = reducerUtil.getSlice(tenantData, tenantData.tenant, state);
    const selectedProducts = reducerUtil.getSlice(projectDetailData, projectDetailData.selectedProducts, state) || [];
    const productIds = selectedProducts.map(({ id }) => id);

    try {
        await dispatch(
            api.actions.delete(
                `projects/${id}/products`,
                { user_id: userId, tenant_id: tenantId, product_ids: productIds },
                true
            )
        );

        const notification = [
            {
                id: `update-project-${uuid()}`,
                type: projectNotification.PRODUCT_REMOVED,
                payloads: {
                    project: {
                        id,
                        name,
                    },
                    products: selectedProducts,
                },
            },
        ];
        dispatch(notificationActions.sendNotifications(notification));
        // send analytics
        dispatch(
            analyticsActions.track('projectremoveproduct', {
                project_id: id,
                name,
                products: selectedProducts,
            })
        );

        //refresh the data for project details page.
        dispatch(reducerUtil.setSlice(projectDetailData, projectDetailData.selectedProducts, undefined));
        dispatch(loadSavedProducts({ projectId: id, restart: true }));
    } catch (error) {
        dispatch(errorsActions.error(error));
    }
};

export default {
    loadSavedProducts,
    loadNextPage,
    setSort,
    selectProduct,
    deselectProduct,
    clearSelectedProducts,
    removeProductsFromProject,
};
