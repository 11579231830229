import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from './Button';

const StyledButton = styled(Button)`
    display: flex;
    cursor: pointer;
    padding: 8px 13px;
    border-radius: 3px;
    height: 3.4rem;
    width: max-content;
    &:hover {
        path {
            fill: white;
        }
    }
`;
const FileInput = styled.input`
    display: none;
`;

const UploadSVG = styled.svg`
    margin-right: 10px;
    path {
        fill: ${(props) => (props.theme.primaryColor ? props.theme.primaryColor : props.theme.blue)};
    }
`;

class UploadButton extends Component {
    static propTypes = {
        onUpload: PropTypes.func.isRequired,
    };

    fileInputRef = React.createRef();

    openFileDialog = () => {
        const { current } = this.fileInputRef;
        current && current.click();
    };

    onFilesAdded = (evt) => {
        const fileObject = evt.target.files || {};
        const fileList = [];
        for (let i = 0; i < fileObject.length; ++i) {
            fileList.push(fileObject[i]);
        }
        this.props.onUpload(fileList);
    };

    render() {
        const { buttonText } = this.props;
        return (
            <StyledButton noUpperCase inverse preventDefault={false} onClick={this.openFileDialog}>
                <UploadSVG width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0)">
                        <path
                            d="M8.24998 4.81119V12.0007H9.74998V4.81119L12.2197 7.28094L13.2802 6.22044L8.99998 1.94019L4.71973 6.22044L5.78023 7.28094L8.24998 4.81119Z"
                            fill="#EA7C54"
                        />
                        <path
                            d="M13.5 13.5007V15.0007H4.5V13.5007H3V15.0007C3 15.8272 3.672 16.5007 4.5 16.5007H13.5C14.328 16.5007 15 15.8272 15 15.0007V13.5007H13.5Z"
                            fill="#EA7C54"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0">
                            <rect width="18" height="18" fill="white" />
                        </clipPath>
                    </defs>
                </UploadSVG>
                <FileInput type="file" onChange={(evt) => this.onFilesAdded(evt)} ref={this.fileInputRef} />
                <span>{buttonText}</span>
            </StyledButton>
        );
    }
}

export default UploadButton;
