import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { reducerUtil } from 'base-client';

import { actions as guestActions } from '../../guest';
import { actions as detailActions } from 'projectDetails';
import { reducerData as userData } from 'userAccount';

const LabelContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0px 0px 10px 0px;

    svg {
        cursor: pointer;
    }
`;

const OptionHeader = styled.h3`
    font-size: 1.8rem;
    color: ${(props) => props.theme.grey5}
    margin: 0px;
`;

const OptionGroupContainer = styled.div`
    margin-bottom: 15px;

    input[type='email'] {
        position: relative;
        display: block;
        border-radius: 5px;
        border: 1px solid black;
        width: 100%;
        padding: 5px;
    }
`;

const RadioLabel = styled.label`
    font-size: 1.35rem;
`;

const RadioContainer = styled.div`
    display: flex;
    position: relative;
    align-items: center;

    .inner {
        display: flex;
        position: relative;
        align-items: center;

        input[type='radio'] {
            margin-top: 4px;
        }

        input[type='radio'],
        input[type='radio']:after {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
        }

        input[type='radio']:before {
            content: '';
            display: inline-block;
            position: relative;
            width: 12px;
            height: 12px;
            border: 2px solid ${(props) => props.theme.grey4};
            border-radius: 50%;
            background-color: transparent;
            cursor: pointer;
        }

        input[type='radio']:checked:before {
            visibility: visible;
            border-color: ${(props) => props.theme.primaryColor};
        }

        input[type='radio']:checked:after {
            content: '';
            display: block;
            position: absolute;
            top: 9px;
            left: 20px;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: ${(props) => props.theme.primaryColor};
        }

        input[type='radio'].first:checked:after {
            left: 5px;
        }
    }
`;

const OptionLabel = ({ children, labelText, infoText }) => (
    <div>
        <LabelContainer>
            <>
                <OptionHeader>{labelText}</OptionHeader>&nbsp;&nbsp;
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    style={{ opacity: '0.8' }}
                >
                    <title>{infoText}</title>
                    <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2.033 16.01c.564-1.789 1.632-3.932 1.821-4.474.273-.787-.211-1.136-1.74.209l-.34-.64c1.744-1.897 5.335-2.326 4.113.613-.763 1.835-1.309 3.074-1.621 4.03-.455 1.393.694.828 1.819-.211.153.25.203.331.356.619-2.498 2.378-5.271 2.588-4.408-.146zm4.742-8.169c-.532.453-1.32.443-1.761-.022-.441-.465-.367-1.208.164-1.661.532-.453 1.32-.442 1.761.022.439.466.367 1.209-.164 1.661z" />
                </svg>
            </>
        </LabelContainer>
        {children}
    </div>
);

const OptionGroup = ({ children, onChange, options, optGroup, selected, style }) => (
    <OptionGroupContainer {...{ style }}>
        <RadioContainer>
            {options &&
                options.map((option, idx) => (
                    <div className="inner">
                        <input
                            type="radio"
                            required
                            className={idx === 0 && 'first'}
                            name={optGroup}
                            value={option}
                            onChange={onChange}
                            checked={selected === option}
                            style={{ marginLeft: idx ? '15px' : '0px' }}
                        />
                        <RadioLabel for={option}>{option}</RadioLabel>
                    </div>
                ))}
        </RadioContainer>
        {children && (
            <>
                <br />
                {children}
                <br />
            </>
        )}
    </OptionGroupContainer>
);

const SubmittalOptions = ({ guestOccupation, setGuestSubmittalOptions, setUserSubmittalOptions, userData }) => {
    const pdfOptions = ['Unified PDF', 'Separate PDFs'];
    const submittalOptions = ['Email Submittal'];
    // const submittalOptions = ['Download Submittal', 'Email Submittal'];

    const generationInfo = `
        Email Submittal
        Submittal download link will be emailed to address provided. 

        Guest users are required to input their email to receive the submittal. 
    `;
    const pdfInfo = `
        Unified PDF
        Submittal contains all selected products in a single PDF

        Multiple PDFs
        Submittal contains multiple files for each product
    `;

    const [email, setEmail] = useState(guestOccupation || !userData ? '' : userData.email);
    const [generationMethod, setGenerationMethod] = useState(submittalOptions[0]);
    const [pdfMethod, setPdfMethod] = useState(pdfOptions[0]);

    useEffect(() => {
        const postOptions = {
            isDirectDownload: generationMethod === 'Download Submittal' ? true : false,
            isUnifiedPdf: pdfMethod === 'Unified PDF' ? true : false,
            targetEmail: email,
        };
        guestOccupation ? setGuestSubmittalOptions(postOptions) : setUserSubmittalOptions(postOptions);
    }, [email, generationMethod, pdfMethod]);

    return (
        <div style={{ position: 'relative', overflow: 'hidden' }}>
            <OptionLabel infoText={generationInfo} labelText={'Generation Method'}>
                <OptionGroup
                    onChange={setGenerationMethod}
                    options={submittalOptions}
                    optGroup="submittalFields"
                    onChange={(e) => {
                        setGenerationMethod(e.target.value);
                    }}
                    selected={generationMethod}
                    style={{ marginBottom: '0px' }}
                >
                    {/* {!isDirectDownload && <input type="email" placeholder="Enter Email Before Continuing..." onChange={(e) => { setEmail(e.target.value); }} value={email} />} */}
                    <input
                        type="email"
                        placeholder="Enter Email Before Continuing..."
                        onChange={(e) => {
                            setEmail(e.target.value);
                        }}
                        value={email}
                    />
                </OptionGroup>
            </OptionLabel>
            <OptionLabel infoText={pdfInfo} labelText={'PDF Method'}>
                <OptionGroup
                    onChange={(e) => {
                        setPdfMethod(e.target.value);
                    }}
                    options={pdfOptions}
                    optGroup="pdfFields"
                    selected={pdfMethod}
                ></OptionGroup>
            </OptionLabel>
        </div>
    );
};

const mapStateToProps = (state) => ({
    userData: reducerUtil.getSlice(userData, userData.data, state),
});

const mapDispatchToProps = (dispatch) => ({
    setGuestEmail: (email) => dispatch(guestActions.setEmail(email)),
    setGuestSubmittalOptions: (options) => dispatch(guestActions.setSubmittalOptions(options)),
    setUserSubmittalOptions: (options) => dispatch(detailActions.setSubmittalOptions(options)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubmittalOptions);
