import React from 'react';
import styled from 'styled-components';
import Carousel from '../../shared/Carousel';
import Video from './Video.js';

function returnElements(section, idx1, featured = false) {
    switch (section.type) {
        case 'title':
            return section.text.map((string, idx2) => <Title key={`${idx1}-${idx2}`}>{string}</Title>);
        case 'subtitle':
            return section.text.map((string, idx2) => <Subtitle key={`${idx1}-${idx2}`}>{string}</Subtitle>);
        case 'body':
            return section.text.map((string, idx2) => <Body key={`${idx1}-${idx2}`}>{string}</Body>);
        case 'carousel':
            return (
                <Carousel
                    data={section.image}
                    style={featured ? { maxWidth: '100%', maxHeight: '100%' } : {}}
                    key={idx1}
                />
            );
        case 'video':
            // prettier-ignore
            return section.videos ? section.videos.map((string, idx2) => (
                <Video
                    data={string}
                    index={idx1}
                    key={`${idx1}-${idx2}`}
                    style={featured ? { maxWidth: '100%' } : {}}
                />
            )) : null;
        case 'cta':
            return (
                <div key={idx1}>
                    <FeaturedTitle>{section.title}</FeaturedTitle>
                    <FeaturedBody>{section.body}</FeaturedBody>
                    <a href={`${section.url}`}>
                        <Button>{section.button}</Button>
                    </a>
                </div>
            );
        default:
            return null;
    }
}

function returnFeature(features, idx1) {
    return (
        <Feature key={idx1}>
            {features.map((section, idx2) => {
                return (
                    <FeatureItems key={`${idx1}_${idx2}`} index={idx2}>
                        {returnElements(section, Math.random(), true)}
                    </FeatureItems>
                );
            })}
        </Feature>
    );
}

function renderElements(sections) {
    const elements = [];

    if (!sections) return null;

    sections.map((section, idx1) => {
        if (section.type === 'feature') {
            elements.push(returnFeature(section.features, idx1));
        } else {
            elements.push(returnElements(section, idx1));
        }
    });

    return elements;
}

const MarketingCopy = ({ sections }) => {
    return <>{renderElements(sections)}</>;
};

export default MarketingCopy;

const Title = styled.h1`
    font-size: 28px;
`;

const Subtitle = styled.h4`
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    color: #5a6678;
    padding-left: 26%;
    padding-right: 28%;
`;

const Body = styled.p`
    font-size: 18px;
    color: #5a6678;
    margin-bottom: 36px;
    padding-left: 18%;
    padding-right: 18%;
`;

const Button = styled.button`
    background-color: #33659c;
    color: #fff;
    padding: 6px 13px;
    text-align: center;
    border: 0px;
    border-radius: 3px;
    margin: 10px 0px;
    cursor: pointer;
    font-weight: 500;
    line-height: 19px;
    font-size: 14px;
`;

const Feature = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 18px 0px 36px 0px;

    @media (max-width: 767px) {
        flex-direction: column-reverse;
    }
`;

const FeaturedTitle = styled(Title)`
    margin-top: 0px;
    color: ${(props) => props.theme.grey4};
`;

const FeaturedBody = styled(Body)`
    line-height: 28px;
    margin: 10px 0px;
    padding: 0px;
`;

const FeatureItems = styled.div`
    width: 50%;
    margin-right: ${(props) => (props.index === 0 ? '20px' : '0px')};

    @media (max-width: 767px) {
        width: 100%;
        padding: 0px 20px;
        margin-right: 0px;
        margin-top: ${(props) => (props.index === 0 ? '10px' : '0px')};
    }
`;
