/* eslint-disable indent */
import React from 'react';
import styled from 'styled-components';
import Plyr from 'plyr';

const players = Plyr.setup('.video-player', {
    youtube: {
        autoplay: 1,
        controls: 0,
        noCookie: false,
        rel: 0,
        showinfo: 0,
        iv_load_policy: 3,
        modestbranding: 1,
    },
    vimeo: { byline: false, portrait: false, title: false, speed: true, transparent: false },
});

const Video = ({ data, index, style }) => {
    const { href, origin } = window.location;
    const videourl =
        data.source === 'vimeo'
            ? data.url
            : data.source === 'youtube'
            ? `https://youtube.com/embed/${data.url.substring(
                  data.url.indexOf('=') + 1,
                  data.url.length
              )}?origin=${origin}&widget_refferrer=${href}`
            : null;

    const EmbedVideo = styled.iframe`
        width: 100vw;
        height: 56.25vw;
        max-width: 820px;
        max-height: 462px;
        cursor: pointer;
        margin: auto;
        margin-top: ${index === 0 ? '-50px' : '0px'};
        border: 0px;
    `;

    return (
        <EmbedVideo
            className="video-player"
            style={style}
            src={videourl}
            allowfullscreen
            allowtransparency
            allow="autoplay"
        ></EmbedVideo>
    );
};

export default Video;
