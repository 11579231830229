import React from 'react';
import styled from 'styled-components';

const Bold = styled.span`
    font-weight: bold;
    font-size: 16px;
    color: ${(props) => props.theme.grey5};
`;

const StyledNotification = styled.div`
    padding: 15px 34px 15px 20px;
`;

const DownloadProposalCard = () => (
    <StyledNotification>
        <div className="notification-card-title">
            <Bold>We are preparing your files...</Bold>
        </div>
        <div className="notification-card-content">Your download will begin shortly.</div>
    </StyledNotification>
);

export default DownloadProposalCard;
