import React, { useEffect } from 'react';
import styled from 'styled-components';

const StyledList = styled.ul`
    padding: 1em;
    list-style-type: none;
`;

const List = (props) => {
    const { list } = props;

    return <StyledList>{list}</StyledList>;
};

export default List;
