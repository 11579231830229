import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

import Routes from './routes';
import Notification from './notification';
import Config from './configurations';
import Tenant from './tenant';

const appPropTypes = {
    store: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

const App = ({ store, history }) => (
    <Provider {...{ store }}>
        <Router {...{ history }}>
            <Config>
                <Tenant>
                    <>
                        <Routes />
                        <Notification />
                    </>
                </Tenant>
            </Config>
        </Router>
    </Provider>
);

App.propTypes = appPropTypes;

export default App;
