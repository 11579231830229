import styled from 'styled-components';

const StyledTabs = styled.div`
    [role='tablist'] {
        color: ${(props) => props.theme.grey4};
        border-bottom: 1px solid ${(props) => props.theme.grey1};
        padding: 0 0.5rem;
        margin-bottom: 0;
        @media (min-width: 500px) {
            padding: 0 1rem;
        }
        .react-tabs__tab--selected {
            color: ${(props) => props.theme.grey5};
        }
    }

    .react-tabs__tab {
        padding: 1rem 0.7rem 1.4rem;
        font-size: 1.4rem;
        margin-right: 0.5rem;
        @media (min-width: 500px) {
            padding: 1rem 2rem 1.4rem;
            margin-right: 2rem;
        }
        &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 3px;
            background: ${(props) => props.theme.accentColor};
            left: 0;
            bottom: 0;
            transform: scaleX(0);
            opacity: 0;
            transition: transform 0.2s ease;
        }
        &--selected {
            font-weight: 600;
            border: 0;
            position: relative;
            &:before {
                opacity: 1;
                transform: scaleX(1);
            }
        }
        &:focus {
            box-shadow: none;
            outline: none;
        }
    }
`;

export default StyledTabs;
