import React, { useState } from 'react';
import styled from 'styled-components';
import CreateProjectForm from '../CreateProjectForm';
import ProjectTemplateList from '../ProjectTemplateList';
import { reducerData as tenantData } from 'tenant';
import { reducerUtil } from 'base-client';
import { connect } from 'react-redux';

const Container = styled.div`
    margin-bottom: 0.5em;
`;

const LabelContainer = styled.div`
    max-width: 50rem;
    padding-bottom: 1em;
`;

const defaultState = {
    name: '',
    zipCode: '',
    template: '',
    duplicateMessage: '',
};

const ResponseMessage = styled.span`
    color: ${(props) => props.theme.red};
    margin-top: 0.5rem;
    display: block;
    width: 100%;
    font-size: 1.3rem;
`;

const CreateProjectFromTemplate = (props) => {
    const { handleCreateProject, projectTemplates } = props;
    const [state, setState] = useState(defaultState);
    const Label = () => {
        return (
            <LabelContainer>
                <b>CHOOSE A TEMPLATE</b> that matches your project needs to create a project with our most popular
                products from that segment. You are free to customize the project once its created.
            </LabelContainer>
        );
    };

    const handleChange = (evt) => {
        const { name, value } = evt.target;
        setState({
            ...state,
            [name]: value,
        });
    };

    return (
        <Container>
            <Label />
            <CreateProjectForm hideButton {...{ handleChange, state, setState }}></CreateProjectForm>
            <ProjectTemplateList
                {...{ projectTemplates, handleCreateProject, handleChange, setState, state }}
            ></ProjectTemplateList>
        </Container>
    );
};

const mapStateToProps = (state) => {
    const projectTemplates = reducerUtil.getSlice(tenantData, tenantData.projectTemplates, state) || {};
    return { projectTemplates: projectTemplates };
};

export default connect(mapStateToProps)(CreateProjectFromTemplate);
