import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { chain, sortBy } from 'lodash';

import { Filter, FilterGroup } from 'shared/Filters';

const Container = styled.div`
    flex: 0 0 20%;
    padding-right: 2rem;
    font-size: 1.4rem;
    &.filter-sticky {
        position: sticky;
        left: 0;
        top: 85px;
        right: 0;
        bottom: 10px;
        overflow-y: auto;
        max-height: calc(100vh - 85px);
    }
    .toggleBtn {
        cursor: pointer;
        font-size: 1.3rem;
        color: ${(props) => (props.theme.linkColor ? props.theme.linkColor : props.theme.blue)};
        &:hover,
        &:focus {
            color: ${(props) => props.theme.blueDarkest};
        }
    }
`;

const Header = styled.h3`
    font-size: 1.4rem;
    margin: 0;
`;

const ProductFilters = ({ filters, selected = [], defaultFilter, updateFilter, ...props }) => {
    const renderFilter = filters && filters.length > 0;

    const getCheckStatus = ({ attribute, selectedFacets, name, isSingleSelect, defaultFilter }) => {
        if (isSingleSelect && !selectedFacets) {
            return !!defaultFilter.find(
                ({ attribute: defaultAttr, facets }) => defaultAttr === attribute && facets.includes(name)
            );
        }
        return !!(selectedFacets && selectedFacets.find((facet) => facet === name));
    };

    const groupFilters = chain(filters)
        .groupBy('group')
        .map((value, index) => ({
            groupName: index,
            filters: sortBy(
                value.map((filter) => {
                    const { name, attribute, facets, single_select_facet: isSingleSelect } = filter;
                    const { facets: selectedFacets } =
                        selected.find(({ attribute: selectedAttribute }) => selectedAttribute === attribute) || {};
                    return {
                        name,
                        attribute,
                        type: isSingleSelect ? 'radio' : 'checkbox',
                        facets: facets.map(({ attribute: name, count }) => ({
                            name,
                            count,
                            checked: getCheckStatus({
                                attribute,
                                selectedFacets,
                                name,
                                isSingleSelect,
                                defaultFilter,
                            }),
                        })),
                    };
                }),
                'priority'
            ),
        }))
        .value();

    return (
        <Container className="filter">
            <Header>Refine By</Header>
            {renderFilter &&
                groupFilters.map(({ groupName, filters }, index) => {
                    if (!groupName) {
                        return filters.map((data, indx) => <Filter key={indx} {...data} {...{ updateFilter }} />);
                    } else {
                        return <FilterGroup {...{ groupName, filters, updateFilter }} />;
                    }
                })}
        </Container>
    );
};

export default ProductFilters;
