import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { history, reducerUtil, config } from 'base-client';

import DesktopHeader from './DesktopHeader';
import { reducerData as tenantData } from 'tenant';
import { configMap } from 'configurations';

const AppHeader = styled.header`
    background: ${(props) => props.theme.backgroundColor || '#ffffff'};
    color: ${(props) => props.theme.textColor || props.theme.grey3};
    box-shadow: 0 1px 4px ${(props) => props.theme.grey2};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 101;
    .dropdown {
        font-size: 1.4rem;
        button {
            &:after {
                border-color: ${(props) => props.theme.textColor || props.theme.grey3};
            }
            &:hover {
                color: ${(props) => props.theme.textHoverColor || 'inherit'};
            }
        }
        .visible {
            color: ${(props) => props.theme.textColor || props.theme.grey3};
            background: ${(props) => props.theme.backgroundColor || '#ffffff'};
            &:before {
                background: ${(props) => props.theme.backgroundColor || '#ffffff'};
            }
            a:hover {
                color: ${(props) => props.theme.textHoverColor || 'inherit'};
            }
        }
    }
    &.hiddenMenu {
        .toggleMenu {
            display: none;
        }
    }
`;

const HeaderContainer = ({ className, dispatch, activeIndex, ...props }) => {
    const disabledActiveLink = dispatch(
        config.actions.getData(configMap.disabled.name, configMap.disabled.activeLink.name)
    );

    return disabledActiveLink ? (
        <AppHeader className={className || ''}>
            <DesktopHeader {...props} />
        </AppHeader>
    ) : (
        <AppHeader className={className || ''}>
            <DesktopHeader {...props} {...{ activeIndex }} />
        </AppHeader>
    );
};

const mapStateToProps = (state) => {
    const { logo, name, headerLogoLink, headerLogoSize } =
        reducerUtil.getSlice(tenantData, tenantData.theme, state) || {};
    const activeIndex = history.location.pathname;
    return { logo, headerLogoLink, headerLogoSize, name, activeIndex };
};

export default connect(mapStateToProps)(HeaderContainer);
export { AppHeader, HeaderContainer };
