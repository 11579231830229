import React from 'react';
import styled from 'styled-components';

const duration = '2s';

const Wrapper = styled.div`
    width: auto;
    margin: 0 0.2rem;
    display: block;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
`;

const Spinner = styled.svg`
    margin: 40px auto;
    display: block;
    animation: spinAnimation ${duration} ease-in-out infinite;

    @keyframes spinAnimation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

const Path = styled.circle`
    position: relative;
    stroke-dasharray: 283;
    stroke-dashoffset: 280;
    transform-origin: 50% 50%;
    animation: dashAnimation ${duration} linear infinite, colorAnimation ${duration} ease-in-out infinite;

    @keyframes colorAnimation {
        0% {
            stroke: ${(props) => props.theme.grey3};
        }
        50% {
            stroke: ${(props) => props.theme.accentColor};
        }
        100% {
            stroke: ${(props) => props.theme.grey3};
        }
    }

    @keyframes dashAnimation {
        0%,
        25% {
            stroke-dashoffset: 280;
            transform: rotate(0);
        }

        50%,
        75% {
            stroke-dashoffset: 75;
            transform: rotate(45deg);
        }

        100% {
            stroke-dashoffset: 280;
            transform: rotate(360deg);
        }
    }
`;

const Loading = ({ className }) => (
    <Wrapper className={className}>
        <Spinner width="65px" height="65px" viewBox="0 0 68 68">
            <Path className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30" />
        </Spinner>
    </Wrapper>
);

export default Loading;
