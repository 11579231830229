import { api, login, history } from 'base-client';
import { v4 as uuid } from 'uuid';

import { actions as accountActions } from 'userAccount';
import { actions as analyticsActions } from 'analytics';
import { actions as guestActions } from 'guest';
import { actions as notificationActions, projectNotification } from 'notification';

const userLogin = (username, password) => async (dispatch) => {
    const visitorId = dispatch(analyticsActions.getVisitorId());
    const error = await dispatch(login.actions.userLogin(username, password, { visitor_id: visitorId }));

    if (!error) {
        dispatch(guestActions.deleteGuestSession());
        dispatch(accountCheck());
    }

    return error;
};

const accountCheck = () => async (dispatch) => {
    await dispatch(accountActions.fillAccount());
    dispatch(analyticsActions.updateSegmentId());
};

const googleLogin = () => (dispatch) => {
    dispatch(guestActions.deleteGuestSession());
    const visitorId = dispatch(analyticsActions.getVisitorId());
    return dispatch(login.actions.googleLogin({ visitor_id: visitorId }));
};

const linkedInLogin = () => (dispatch) => {
    dispatch(guestActions.deleteGuestSession());
    const visitorId = dispatch(analyticsActions.getVisitorId());
    return dispatch(login.actions.linkedInLogin({ visitor_id: visitorId }));
};

const logOut = () => (dispatch) => {
    // clear data
    dispatch(accountActions.clearAccount());

    // log out
    dispatch(login.actions.logOut());
};

const changePassword =
    ({ auth0Id, password, username }) =>
    async (dispatch) => {
        try {
            await dispatch(api.actions.post('users/password-change', { auth0Id, password, username }, true));

            await dispatch(login.actions.logOutWithoutRedirect()); // make sure not logged in still
            history.push('/redirect?msg=Password reset successful&path=/login');
        } catch (err) {
            dispatch(
                notificationActions.sendNotifications([
                    {
                        id: `password-reset-${uuid()}`,
                        type: projectNotification.GENERAL,
                        title: 'Password Reset Failed',
                        body: err && err.message ? err.message : 'Your new password failed to set',
                    },
                ])
            );
        }
    };

export default {
    changePassword,
    googleLogin,
    linkedInLogin,
    logOut,
    userLogin,
};
