import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import ListView from './ListView';
import { LIST_HEADER_HEIGHT, LIST_ROW_HEIGHT } from '../constants';
import { Checkbox } from 'shared';

const SelectWrapper = styled.div`
    .select {
        position: absolute;
        z-index: 1000;
        top: 2rem;
        left: 0.5rem;
    }
`;
const TableRow = styled.div`
    display: flex;
    height: 6rem;
    align-items: center;
    border-bottom: 1px solid ${(props) => props.theme.grey1};
    &:hover {
        background: ${(props) => props.theme.grey0};
    }
`;

/** Class representing a container for the list view. */
export class ListViewContainer extends React.Component {
    static propTypes = {
        products: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                name: PropTypes.string,
                category: PropTypes.string,
                subcategory: PropTypes.string,
                thumbnail: PropTypes.string,
            })
        ),
        enableCheckbox: PropTypes.bool,
        toggleSelectProduct: PropTypes.func,
    };
    static defaultProps = {
        products: [],
    };

    rowGetter = ({ index }) => {
        const { products } = this.props;
        return products[index] || {};
    };

    rowRenderer = ({ style, columns, rowData }) => {
        const { id, checked } = rowData;
        const { enableCheckbox, toggleSelectProduct } = this.props;
        if (enableCheckbox) {
            return (
                <SelectWrapper {...{ style }} key={id}>
                    <Checkbox id={id} value={id} checked={checked} onChange={() => toggleSelectProduct(rowData)} />
                    <Link key={id} to={id ? `/products/${id}` : '#'}>
                        <TableRow>{columns}</TableRow>
                    </Link>
                </SelectWrapper>
            );
        } else {
            return (
                <Link {...{ style }} key={id} to={id ? `/products/${id}` : '#'}>
                    <TableRow>{columns}</TableRow>
                </Link>
            );
        }
    };

    render() {
        const listProps = {
            rowGetter: this.rowGetter,
            rowRenderer: this.rowRenderer,
            headerHeight: LIST_HEADER_HEIGHT,
            rowHeight: LIST_ROW_HEIGHT,
        };
        return <ListView {...this.props} {...listProps} />;
    }
}

export default ListViewContainer;
