const generalDataObj = {
    id: '',
    user_id: '',
    tenant_id: '',
    name: 'Guest Project',
    address_id: '',
    purchase_order: null,
    estimated_ship_date: null,
    company_logo: null,
    notes: null,
    company_logo: null,
    project_budget: null,
    project_type: null,
    project_phase: null,
    project_size: null,
    owner_type: null,
    owner_occupied: false,
    is_active: true,
    country: null,
    created_at: '',
    updated_at: '',
    user: {
        firstName: 'Guest',
        lastName: 'User',
        industry: null,
        occupation: null,
        sector: '',
        profile: {
            companyName: null,
            email: '',
            country: null,
            city: null,
            state: null,
            phoneNumber: null,
        },
    },
    address: {
        id: '',
        address_line_1: null,
        address_line_2: null,
        city: null,
        state: null,
        zipcode: '',
        country_code: null,
        country: null,
        latitude: null,
        longitude: null,
        geocode_generated_at: '',
        created_at: '',
        updated_at: '',
    },
};

export default {
    generalDataObj,
};
