import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reducerUtil, config } from 'base-client';
import { InfiniteList } from 'shared-features-client';

import { configMap } from 'configurations';
import { reducerData as tenantData } from 'tenant';
import ProductSearch from './ProductSearch';
import HybridViewContainer from './HybridViewContainer';
import { actions as searchActions, reducerData as searchData } from 'workflowProductSearch';
import { actions as detailsActions, reducerData as projectDetailsData } from 'projectDetails';
import { actions as projectActions, reducerData as projectData } from 'projects';
import { actions as proposalActions, reducerData as proposalData } from 'proposal';
import { actions as guestActions, reducerData as guestData } from 'guest';

import stickyWrapper from './StickyWrapper';

class ProductSearchContainer extends React.Component {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        list: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                name: PropTypes.string,
                category: PropTypes.string,
                subcategory: PropTypes.string,
                thumbnail: PropTypes.string,
            })
        ),
        meta: PropTypes.shape({
            pagination: PropTypes.shape({
                page: PropTypes.number,
                pageCount: PropTypes.number,
            }),
        }),
        fetchId: PropTypes.string,
        tenantCompareProduct: PropTypes.bool,
        selectedProducts: PropTypes.array,
        projectSubmittalSettings: PropTypes.object,
        guestOccupation: PropTypes.string,
        guestProducts: PropTypes.array,
        hasPrice: PropTypes.bool
    };

    static defaultProps = {
        list: [],
        selectedProducts: [],
    };

    state = {};

    componentDidMount() {
        const {
            history: {
                location: { search },
            },
            selectedProducts,
            dispatch,
        } = this.props;

        if (!search && selectedProducts && selectedProducts.length > 0) {
            dispatch(searchActions.clearSelectedProducts());
        }

        this.handleSearch();
    }

    componentDidUpdate() {
        this.handleSearch();
    }

    handleSearch = () => {
        const { lastSearch } = this.state;
        const {
            history: {
                location: { search },
            },
        } = this.props;

        if (search !== lastSearch) {
            const { dispatch } = this.props;
            this.setState({ lastSearch: search });
            dispatch(searchActions.search(true));
        }
    };

    updateFilter = ({ checked, attribute, facets, type }) => {
        const { dispatch } = this.props;
        if (type === 'radio') return dispatch(searchActions.updateFilter({ attribute, facets }));

        if (checked) dispatch(searchActions.addFilter({ attribute, facets }));
        else dispatch(searchActions.removeFilter({ attribute, facets }));
    };

    toggleSelectProduct = (product) => {
        const { dispatch } = this.props;
        const { checked, id, name } = product;
        if (!checked) {
            return dispatch(searchActions.selectProduct({ id, name }));
        } else {
            return dispatch(searchActions.deselectProduct({ id, name }));
        }
    };

    render() {
        const {
            list,
            meta,
            query,
            fetchId,
            dispatch,
            defaultFilter,
            tenantCompareProduct,
            selectedProducts,
            projectProducts,
            projectSubmittalSettings,
            location,
            showViewAllAssetsLink,
            goToNextStep,
            projectData,
            guestOccupation,
            guestProducts,
            proposalProducts,
            hasPrice
        } = this.props;

        const currentWorkflow =
            location && location.pathname && location.pathname === '/submittal' ? 'Submittal' : 'Owner Brochure';

        const length = list && list.length;
        const enableCheckbox = tenantCompareProduct || !projectSubmittalSettings.disabled;

        //get filters props
        const { filters: baseFilters = [] } = meta || {};
        const { filters: selected = [] } = query || {};
        const disabledFilterGroup = dispatch(
            config.actions.getData(configMap.disabled.name, configMap.disabled.filterGroup.name)
        );
        const filterProps = {
            filters: baseFilters
                .sort((a, b) => (parseInt(a.priority) > parseInt(b.priority) ? 1 : -1))
                .map((filter, i) => ({
                    ...filter,
                    group: !disabledFilterGroup && filter.group ? filter.group : '',
                })),
            selected,
            defaultFilter,
            updateFilter: this.updateFilter,
        };

        /* Determine if there is more to load based on the metadata */
        let hasNextPage;
        if (meta) {
            const {
                pagination: { page, pageCount },
            } = meta;
            hasNextPage = page < pageCount;
        }

        /* Create react-virtualized view props */
        const rowCount = hasNextPage ? length + 1 : length + 0 || 0;

        /* Organize props */
        const headerProps = {};

        const infiniteListProps = {
            list,
            hasNextPage,
            fetching: !!fetchId,
            getNextPage: () => dispatch(searchActions.nextProductPage()),
        };

        const getProducts = () => {
            if (enableCheckbox) {
                return list.map((product) => {
                    return {
                        ...product,
                        projectId: projectData && projectData.id ? projectData.id : undefined,
                        inProject: !projectProducts ? false : !!projectProducts.find(({ id }) => id === product.id),
                        checked: !selectedProducts ? false : !!selectedProducts.find(({ id }) => id === product.id),
                    };
                });
            }
            return list;
        };

        const handleRemoveProduct = async (id) => {
            if (guestOccupation) {
                await dispatch(guestActions.removeProductFromProject(id));
            } else {
                if (proposalProducts && proposalProducts.constructor === Array) {
                    dispatch(proposalActions.removeProduct(id));
                }
                await dispatch(projectActions.removeProductFromProject(id, projectData));
                await dispatch(detailsActions.loadSavedProducts({ projectId: projectData.id, restart: true }));
            }
        };

        const handleAddProduct = async (id, name, thumbnail, checked, assets) => {
            if (guestOccupation) {
                await dispatch(guestActions.addSelectedProductsToProject([{ id, name, thumbnail, checked, assets }]));
            } else {
                if (proposalProducts && proposalProducts.constructor === Array) {
                    dispatch(proposalActions.addProduct(id));
                }
                await dispatch(projectActions.addSelectedProductsToProject([{ id, name }], projectData));
                await dispatch(detailsActions.loadSavedProducts({ projectId: projectData.id, restart: true }));
            }
        };

        const viewProps = {
            enableCheckbox,
            rowCount,
            showViewAllAssetsLink,
            products: getProducts(),
            guestProducts,
            guestOccupation,
            hasPrice,
            toggleSelectProduct: this.toggleSelectProduct,
            handleRemoveProduct,
            handleAddProduct,
        };

        const productSearchProps = {
            ...headerProps,
            filterProps,
            goToNextStep,
            selectedProducts,
            guestOccupation,
            currentWorkflow,
            clearSelectedProducts: () => dispatch(searchActions.clearSelectedProducts()),
        };

        return (
            <ProductSearch {...productSearchProps}>
                <InfiniteList {...infiniteListProps}>
                    <HybridViewContainer {...viewProps} />
                </InfiniteList>
            </ProductSearch>
        );
    }
}

const mapStateToProps = (state) => ({
    list: reducerUtil.getSlice(searchData, searchData.list, state),
    meta: reducerUtil.getSlice(searchData, searchData.meta, state),
    fetchId: reducerUtil.getSlice(searchData, searchData.fetchId, state),
    query: reducerUtil.getSlice(searchData, searchData.query, state),
    defaultFilter: reducerUtil.getSlice(tenantData, tenantData.defaultFilter, state),
    selectedProducts: reducerUtil.getSlice(searchData, searchData.selectedProducts, state) || [],
    projectProducts: reducerUtil.getSlice(projectDetailsData, projectDetailsData.products, state),
    projectData: reducerUtil.getSlice(projectDetailsData, projectDetailsData.generalData, state),
    tenantCompareProduct: reducerUtil.getSlice(tenantData, tenantData.compareProduct, state),
    projectSubmittalSettings: reducerUtil.getSlice(tenantData, tenantData.projectSubmittalSettings, state) || {},
    showViewAllAssetsLink: reducerUtil.getSlice(tenantData, tenantData.showViewAllAssetsLink, state),
    guestOccupation: reducerUtil.getSlice(guestData, guestData.guestOccupation, state),
    guestProducts: reducerUtil.getSlice(guestData, guestData.products, state),
    proposalProducts: reducerUtil.getSlice(proposalData, proposalData.orderedProducts, state),
    hasPrice: reducerUtil.getSlice(tenantData, tenantData.hasPrice, state)
});

export default withRouter(stickyWrapper(connect(mapStateToProps)(ProductSearchContainer)));
