import React from 'react';
import styled from 'styled-components';

import PropTypes from 'prop-types';

const MetaItemHeader = styled.h3`
    margin-top: 0;
    font-family: ${(props) => props.theme.fontAlt};
    font-size: 1.6rem;
    margin-bottom: 0.3rem;
`;

const MetaItemValue = styled.p`
    margin: 0;
`;

const ProductMetaItem = ({ header, detail }) => (
    <div className="meta-item">
        <MetaItemHeader>{header}</MetaItemHeader>
        <MetaItemValue>{detail}</MetaItemValue>
    </div>
);

ProductMetaItem.propTypes = {
    header: PropTypes.string.isRequired,
    detail: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default ProductMetaItem;
