import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    margin-bottom: 1rem;
    &:last-of-type {
        margin-bottom: 0;
    }
`;

const ProjectTabGroup = ({ children }) => <Container>{children}</Container>;

export default ProjectTabGroup;
