import React from 'react';
import PropTypes from 'prop-types';

import ErrorDisplay from './ErrorDisplay';

const ErrorProjectLogin = ({ children }) => (
    <ErrorDisplay header="Oops! Looks like that project is private" imageUrl="/contents/project-logged-out.png">
        {children}
    </ErrorDisplay>
);

ErrorProjectLogin.propTypes = {
    children: PropTypes.any,
};

export default ErrorProjectLogin;
