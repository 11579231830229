import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import ProductImage from './ProductImage';
import ProductDescription from './ProductDescription';
import CalloutsContainer from './CalloutsContainer';
import FeaturesContainer from './FeaturesContainer';

import media from 'utils/media';
import { Card } from 'shared';

const CardWrapper = styled(Card)`
    padding: 2rem;
`;

const DetailsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    .header-assets {
        a > span {
            text-transform: none;
            font-size: 1.6rem;
        }
    }
`;

const ProductSummary = styled.div`
    ${media.medium`
    flex: 0 0 57%;
  `} ${media.large`
    flex: 0 0 57%;
    padding: 0 2rem 0 4rem;
  `};
`;

const ProductMetadata = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 3rem;
    .meta-item {
        width: 32%;
        margin-right: 2%;
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6) {
            margin-top: 2rem;
        }
        &:nth-child(3n) {
            margin-right: 0;
        }
    }
`;

const DetailsHeader = ({ description, thumbnail }) => (
    <CardWrapper className="detailsHeader">
        <DetailsContainer>
            <ProductImage src={thumbnail} />
            <ProductSummary className="productSummary">
                <ProductMetadata>
                    <CalloutsContainer />
                </ProductMetadata>
                {description && <ProductDescription {...{ description }} />}
                <FeaturesContainer />
            </ProductSummary>
        </DetailsContainer>
    </CardWrapper>
);

DetailsHeader.propTypes = {
    description: PropTypes.string,
    thumbnail: PropTypes.string,
};

DetailsHeader.defaultProps = {
    description: '',
    thumbnail: '',
};

export default DetailsHeader;
