import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
    width: calc(100% + 4.2rem);
    margin-left: -2.1rem;
    text-align: center;
    background-color: ${(props) => props.theme.backgroundColor};
    padding: 2rem 0rem;
    border: 1px solid ${(props) => props.theme.grey2};
    border-top: 0px;
    border-bottom: 0px;
`;
const LogoImage = styled.img`
    max-height: 100px;
    max-width: 100%;
`;

const Logo = ({ logoText, logoImageUrl }) => {
    const content = (
        <Container>
            <LogoImage src={logoImageUrl} alt={logoText} />
        </Container>
    );
    return content;
};

Logo.propTypes = {
    logoText: PropTypes.string.isRequired,
    logoImageUrl: PropTypes.string,
};

Logo.defaultProps = {
    logoText: '',
    logoImageUrl: '',
};

export default Logo;
