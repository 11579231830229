import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import { api, history, reducerUtil } from 'base-client';

import { ButtonText, OutsideClick } from 'shared';

import { ExternalLink as BaseLink } from 'shared';

const arrowAnimation = keyframes`
0% {
  margin-left: 0px;
}
50% {
  margin-left: 10px;
}
100% {
  margin-left: 0px;
}
`;

const Container = styled.div``;

const HeaderLink = styled(ButtonText)`
    padding: 0 1.5rem 0.75rem;
    margin-right: 1rem;
    transition: color 0.5s ease;
    cursor: pointer;
    position: relative;
    font-size: 1.4rem;
    outline: none;
    &:after {
        content: '';
        display: inline-block;
        width: 6px;
        height: 6px;
        position: relative;
        top: -2px;
        right: -10px;
        border: none;
        border-right: 2px solid ${(props) => props.theme.grey3};
        border-top: 2px solid ${(props) => props.theme.grey3};
        transform: rotate(135deg);
    }
`;

const HeaderLinkContainer = styled(BaseLink)``;

const Menu = styled.div`
    ${(props) => (props.open ? 'display: flex' : 'display: none;')}
    position: fixed;
    left: ${(props) => props.style.left};
    top: ${(props) => props.style.top};
    background: ${(props) => props.backgroundColor || '#FFFFFF'};
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08), 0px 5px 30px rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    padding: 20px 0px;
`;

// made a function because linting is disgusting
const getMenuColumnBorder = (border) => {
    const width = border.width || '1px';
    const style = border.style || 'solid';
    const color = border.color || '#C3CDD9';
    return `${width} ${style} ${color}`;
};

const MenuHeader = styled.h3`
  padding: 0px 20px;
  margin-top: 0px;
  line-height: 20.8px;
  color: ${(props) => props.theme.grey6};
  font-weight: 700;
  cursor: pointer;

  &:hover {
    span {
      animation-duration: 1.25s;
      animation-iteration-count: infinite;
      animation-name ${arrowAnimation}
    }
  }
`;

const MenuInnerWrap = styled.div`
    display: flex;
    flex-direction: column;
`;

const MenuColumnWrap = styled.div`
    display: flex;
    flex-direction: row;
`;

const MenuColumn = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px 20px;
    ${(props) =>
        props.showBorder
            ? props.border
                ? `border-right: ${getMenuColumnBorder(props.border)};`
                : 'border-right: 1px solid #C3CDD9;'
            : ''}
`;
const MenuColumnHeader = styled.div`
    color: ${(props) => props.color || props.theme.grey4};
    ${(props) => (props.pointer ? 'cursor:pointer;' : '')}
    margin: 0px;
    font-weight: 700;
    font-size: 18px;
    line-height: 23.4px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
`;
const MenuColumnSubHeader = styled.div`
  color: ${(props) => props.color || props.theme.grey6};
  ${(props) => (props.pointer ? 'cursor:pointer;' : '')}
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 13px;
  line-height: 16.9px;
  letter-spacing: 0.02em;
  white-space: nowrap;

  &:hover {
    span {
      animation-duration: 1.25s;
      animation-iteration-count: infinite;
      animation-name ${arrowAnimation}
    }
  }
`;
const MenuColumnLinks = styled.div`
  columns: ${(props) => props.columns || 1}
  column-gap: 20px;
`;

const MenuColumnLink = styled.li`
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
`;

const InternalLink = styled(Link)`
    color: ${(props) => props.color || '#343E47'};
    width: max-content;
    margin-right: 0px !important;
    padding-bottom: 10px !important;
    padding-right: 0px !important;
    display: block;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.02em;
`;

const ExternalLink = styled(BaseLink)`
    color: ${(props) => props.color || '#343E47'};
    width: max-content;
    margin-right: 0px !important;
    padding-bottom: 10px !important;
    padding-right: 0px !important;
    display: block;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.02em;
`;

const HeaderLinkMenu = ({ displayValue: headerLinkDisplayValue, menu }) => {
    const { header, backgroundColor, borders, columns } = menu;
    const [open, setOpen] = useState(false);
    const [offsetLeft, setOffsetLeft] = useState(null);
    const [offsetTop, setOffsetTop] = useState(null);
    return (
        <OutsideClick onOutside={() => setOpen(false)}>
            <Container>
                <HeaderLinkContainer
                    onClick={(e) => {
                        const rect = e.target.getBoundingClientRect();
                        setOffsetLeft(rect.left);
                        setOffsetTop(rect.top + rect.height);
                        setOpen(!open);
                    }}
                >
                    <HeaderLink>{headerLinkDisplayValue}</HeaderLink>
                </HeaderLinkContainer>
                <Menu
                    onClick={() => {
                        setOpen(!open);
                    }}
                    open={open}
                    backgroundColor={backgroundColor}
                    style={{ left: `${offsetLeft}px !important`, top: `${offsetTop}px !important` }}
                >
                    <MenuInnerWrap>
                        {menu && headerLinkDisplayValue === 'Product Information' && (
                            <MenuHeader
                                onClick={() => {
                                    history.push('/products');
                                }}
                            >
                                See All Products <span>&gt;</span>
                            </MenuHeader>
                        )}
                        <MenuColumnWrap>
                            {columns.map(({ header, footer, links, linkColumnLength = 7 }, index) => {
                                return (
                                    <MenuColumn showBorder={index !== columns.length - 1} border={borders} key={index}>
                                        {header && (
                                            <>
                                                <MenuColumnHeader
                                                    color={header.color}
                                                    pointer={header.url}
                                                    onClick={() => header.url && history.push(header.url)}
                                                >
                                                    {header.displayValue}
                                                </MenuColumnHeader>
                                                {footer ? (
                                                    <MenuColumnSubHeader
                                                        color={footer.color}
                                                        pointer={footer.url}
                                                        onClick={() => footer.url && history.push(footer.url)}
                                                    >
                                                        See {footer.displayValue} <span>&gt;</span>
                                                    </MenuColumnSubHeader>
                                                ) : (
                                                    <MenuColumnSubHeader>
                                                        <br></br>
                                                    </MenuColumnSubHeader>
                                                )}
                                            </>
                                        )}
                                        <MenuColumnLinks columns={Math.ceil(links.length / linkColumnLength)}>
                                            {links.map(({ displayValue, url, color }, index) => (
                                                <MenuColumnLink key={index}>
                                                    {url.startsWith('/') ? (
                                                        <InternalLink color={color} key={index} to={url}>
                                                            {displayValue}
                                                        </InternalLink>
                                                    ) : (
                                                        <ExternalLink color={color} key={index} href={url}>
                                                            {displayValue}
                                                        </ExternalLink>
                                                    )}
                                                </MenuColumnLink>
                                            ))}
                                        </MenuColumnLinks>
                                    </MenuColumn>
                                );
                            })}
                        </MenuColumnWrap>
                    </MenuInnerWrap>
                </Menu>
            </Container>
        </OutsideClick>
    );
};

// menu: {
//   header: null,
//   backgroundColor: '#FFFFFF',
//   borders: { color: '#C3CDD9', width: '1px', style: 'solid' },
//   columns: [
//     {
//       header: {
//         displayValue: 'BATHROOM'
//       },
//       linkColumnLength: 7,
//       links: [
//         {
//           displayValue: 'Toilets',
//           url: '/bathrooms/toilets'
//         },
//         {
//           displayValue: 'Toilets Seats',
//           url: '/bathrooms/toilet-seats'
//         },
//         {
//           displayValue: 'Showers',
//           url: '/bathrooms/showers'
//         },
//         {
//           displayValue: 'Bathtubs',
//           url: '/bathrooms/bathtubs'
//         },
//         {
//           displayValue: 'Accessories',
//           url: '/bathrooms/accessories'
//         },
//         {
//           displayValue: 'Bath & Body',
//           url: '/bathrooms/bath-and-body'
//         },
//         {
//           displayValue: 'Bath Linens',
//           url: '/bathrooms/bath-linens'
//         },
//         {
//           displayValue: 'Faucets',
//           url: '/bathrooms/faucets'
//         },
//         {
//           displayValue: 'Sinks',
//           url: '/bathrooms/sinks'
//         },
//         {
//           displayValue: 'Vanities',
//           url: '/bathrooms/vanities'
//         },
//         {
//           displayValue: 'Medicine Cabinets',
//           url: '/bathrooms/medicine-cabinets'
//         },
//         {
//           displayValue: 'Mirrors',
//           url: '/bathrooms/mirrors'
//         },
//         {
//           displayValue: 'Commercial',
//           url: '/bathrooms/commercial'
//         },
//         {
//           displayValue: 'Lighting',
//           url: '/bathrooms/lighting'
//         }
//       ],
//       footer: { displayValue: 'All Bathroom', url: '/bathrooms' }
//     },
//     {
//       header: {
//         displayValue: 'KITCHEN'
//       },
//       linkColumnLength: 7,
//       links: [
//         {
//           displayValue: 'Faucets',
//           url: '/kitchen/faucets'
//         },
//         {
//           displayValue: 'Sinks',
//           url: '/kitchen/sinks'
//         },
//         {
//           displayValue: 'Lighting',
//           url: '/kitchen/lighting'
//         },
//         {
//           displayValue: 'Accessories',
//           url: '/kitchen/accessories'
//         }
//       ],
//       footer: { displayValue: 'All Kitchen', url: '/kitchen' }
//     },
//     {
//       header: {
//         displayValue: 'FEATURED'
//       },
//       linkColumnLength: 7,
//       links: [
//         {
//           displayValue: 'Smart Home',
//           url: '/smart-home'
//         },
//         {
//           displayValue: 'Water Filtration',
//           url: '/water-filtration'
//         },
//         {
//           displayValue: 'Water Saving',
//           url: '/water-saving'
//         }
//       ]
//     },
//     {
//       header: {
//         displayValue: 'COLLECTIONS'
//       },
//       linkColumnLength: 7,
//       links: [
//         {
//           displayValue: 'Memoirs®',
//           url: '/collections/memoirs'
//         },
//         {
//           displayValue: 'Tresham®',
//           url: '/collections/tresham'
//         },
//         {
//           displayValue: 'Archer®',
//           url: '/collections/archer'
//         },
//         {
//           displayValue: 'Cimarron®',
//           url: '/collections/cimarron'
//         },
//         {
//           displayValue: 'Persuade®',
//           url: '/collections/persuade'
//         },
//         {
//           displayValue: 'Wellworth®',
//           url: '/collections/wellworth'
//         },
//         {
//           displayValue: 'Kelston®',
//           url: '/collections/kelston'
//         },
//         {
//           displayValue: 'Devonshire®',
//           url: '/collections/devonshire'
//         },
//         {
//           displayValue: 'Bancroft®',
//           url: '/collections/bancroft'
//         },
//         {
//           displayValue: 'Purist®',
//           url: '/collections/purist'
//         },
//         {
//           displayValue: 'Rêve®',
//           url: '/collections/reve'
//         },
//         {
//           displayValue: 'Kathryn®',
//           url: '/collections/kathryn'
//         },
//         {
//           displayValue: 'Portrait®',
//           url: '/collections/portrait'
//         }
//       ],
//       footer: { displayValue: 'All Collections', url: '/collections' }
//     }
//   ]
// }

export default HeaderLinkMenu;
