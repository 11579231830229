import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { history, reducerUtil } from 'base-client';
import { withRouter } from 'react-router-dom';

import { Loading } from '../shared';
import { reducerData as tenantData } from '../tenant';

const Line = styled.hr`
    max-width: 100px;
    width: 100%;
`;

const LineContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    overflow: auto;
    white-space: nowrap;
    margin-top: 10px;

    h3 {
        background-color: #f6f7f7;
        padding: 15px;
    }
`;

const Logo = styled.img`
    height: auto;
    width: 100%;
    display: block;
    object-fit: contain;
`;

const MessageContainer = styled.div`
    display: flex;
    flex-direction: column;

    h1 {
        position: absolute;
        white-space: nowrap;
        margin: 0;
        left: 50%;
        transform: translateX(-50%);
    }
`;

const SplashContainer = styled.div`
    display: flex;
    position: absolute;
    width: 300px;
    transform: translateX(-50%) translateY(-50%);
    top: 43%;
    left: 50%;
    flex-direction: column;
    justify-content: center;
    text-align: center;
`;

const StyledLoading = styled(Loading)`
    display: block;
    position: relative;
    transform: none !important;
    top: auto !important;
    left: auto !important;
`;

const RedirectSplash = ({ theme }) => {
    const params = new URL(decodeURIComponent(window.location.href)).searchParams;

    const msg = params.get('msg');
    const path = params.get('path');

    setTimeout(function () {
        history.push(path ? path : '/');
    }, 5000);

    return (
        <SplashContainer>
            <Logo src={theme.logo} alt={theme.name} />
            <MessageContainer>
                <h1>{msg ? msg : 'Action Successful'}</h1>
                <LineContainer>
                    <Line />
                    <h3>Redirecting</h3>
                    <Line />
                </LineContainer>
            </MessageContainer>
            <StyledLoading />
        </SplashContainer>
    );
};

const mapStateToProps = (state) => ({
    theme: reducerUtil.getSlice(tenantData, tenantData.theme, state),
});

export default withRouter(connect(mapStateToProps)(RedirectSplash));
