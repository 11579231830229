import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import CarouselPagination from './CarouselPagination';
import useCarouselReducer from './hooks/useCarouselReducer';

/*  
    Michael:
        I've added mobile swiping only to carousel instead of Featured Products for now.
        Featured Products is not standardized to match the UI interaction yet of Carousel.

        TODO: Needs more time in testing to check for swipe sensitivity and slide count
*/

const transitionSpeed = 1; // seconds
const swipeSensitivity = 20;

const Carousel = ({ data, style }) => {
    const containerRef = useRef();
    const touchX = useRef();
    const [carouselState, dispatchCarousel] = useCarouselReducer({
        transitionSpeed,
        swipeSensitivity,
    });
    const [mobile, setMobile] = useState(window.innerWidth < 768 ? true : false);
    const [responsiveHeight, setResponsiveHeight] = useState(window.innerWidth > 1440 ? '28.25vw' : '56.25vw');

    const mobileCheck = () => {
        setMobile(window.innerWidth < 768 ? true : false);
        setResponsiveHeight(window.innerWidth > 1440 ? '28.25vw' : '56.25vw');
    };

    const mobileSwipeStart = (e) => {
        touchX.current = e.touches[0].clientX;
    };

    const mobileSwipeMove = (e) => {
        if (typeof touchX.current == 'number') {
            const xDiff = touchX.current - e.touches[0].clientX;

            if (!carouselState.isSwiping) {
                if (xDiff > swipeSensitivity) {
                    dispatchCarousel({ type: 'next' });
                    slideDelay();
                } else if (xDiff < -swipeSensitivity) {
                    dispatchCarousel({ type: 'prev' });
                    slideDelay();
                }
            }
        }
    };

    const slideDelay = () => {
        setTimeout(function () {
            dispatchCarousel({ type: 'swiping', value: false });
        }, transitionSpeed * 1000);
    };

    useEffect(() => {
        if (data) {
            dispatchCarousel({ type: 'count', value: data.length });
        }
    }, [data]);

    useEffect(() => {
        if (data && data.length > 1) {
            window.addEventListener('resize', mobileCheck);
            containerRef.current.addEventListener('touchstart', mobileSwipeStart);
            containerRef.current.addEventListener('touchmove', mobileSwipeMove);
        }

        return () => {
            if (data && data.length > 1) {
                window.removeEventListener('resize', mobileCheck);
                containerRef.current.removeEventListener('touchstart', mobileSwipeStart);
                containerRef.current.removeEventListener('touchmove', mobileSwipeMove);
            }
        };
    }, [mobile]);

    return (
        <>
            {data && (
                <>
                    {data.length > 1 ? (
                        <CarouselContainer ref={containerRef} style={{ ...style, height: responsiveHeight }}>
                            {data.map((slide, idx) => (
                                <CarouselSlide
                                    style={{
                                        backgroundImage: `url('${slide}')`,
                                        transform: `translateX(${idx * 100 - carouselState.index * 100}%)`,
                                    }}
                                    index={idx}
                                    key={idx}
                                />
                            ))}
                            {mobile ? (
                                <CarouselPagination
                                    pageCount={data.length}
                                    currentPage={carouselState.index}
                                    setCurrentPage={(action) => {
                                        dispatchCarousel(action);
                                    }}
                                    color="dark"
                                    mobile={mobile}
                                />
                            ) : (
                                <CarouselNavigation
                                    pageCount={data.length}
                                    currentPage={carouselState.index}
                                    setCurrentPage={(action) => {
                                        dispatchCarousel(action);
                                    }}
                                    color="dark"
                                    mobile={mobile}
                                />
                            )}
                        </CarouselContainer>
                    ) : (
                        <CarouselContainer>
                            <CarouselSlide
                                style={{
                                    backgroundImage: `url('${data[0]}')`,
                                }}
                            />
                        </CarouselContainer>
                    )}
                </>
            )}
        </>
    );
};

const CarouselContainer = styled.div`
    position: relative;
    width: 100vw;
    height: 56.25vw;
    max-width: 820px;
    max-height: 462px;
    overflow: hidden;
    margin: auto;
`;

const CarouselSlide = styled.div`
    height: 100%;
    width: 100%;
    background-color: transparent;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 0px;
    left: 0px;
    transition: ${transitionSpeed}s all;
`;

const CarouselNavigation = styled(CarouselPagination)`
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
`;

export default Carousel;
