import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

export const LabelContainer = styled.label`
    color: ${(props) => props.theme.grey3};
    font-weight: 600;
    font-size: 1.4rem;
    display: block;
    margin-bottom: 0.7rem;
    ${({ required }) =>
        required &&
        css`
            &:after {
                content: '*';
                margin-left: 0.5rem;
                color: ${(props) => props.theme.red};
            }
        `};
`;

const FormLabel = ({ required, labelTitle }) => <LabelContainer {...{ required }}>{labelTitle}</LabelContainer>;

FormLabel.propTypes = {
    labelTitle: PropTypes.string.isRequired,
    required: PropTypes.bool,
};

export default FormLabel;
