import React, { useState } from 'react';
import styled from 'styled-components';
import { sortBy } from 'lodash';

import AttributeDisplay from './AttributeDisplay';
import SideNav from 'shared/SideNav';
import RatingSystems from './RatingSystems';
import Certificates from './Certificates';
import { SUSTAINABILITY_GROUPS } from '../utils';
import { FormDropdown, FormDropdownOption } from 'shared';

const Container = styled.div``;
const DropdownWrapper = styled.div`
    margin-top: 2rem;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
`;

const Title = styled.div`
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    margin-right: 2rem;
`;

const Dropdown = styled.div`
    min-width: 150px;
    width: fit-content;
    float: left;
    select {
        color: ${(props) => props.theme.grey5};
    }
`;

const Sustainability = ({ data, attachments = [], analyticMeta }) => {
    const defaultState = data && data.length > 0 ? data[0].id : undefined;
    const [optionId, setOptionId] = useState(defaultState);
    if (!data || (data && data.length < 1)) return null;

    const {
        documents = [],
        environmentCharacteristics = [],
        ratingSystems = [],
    } = data.find(({ id }) => id === optionId) || {};

    const getFileType = (url) => url.substring(url.length - 3, url.length);

    const navOptions = [];

    const formattedCerts = sortBy(
        documents.map((item) => {
            const { document_id: id, document: cert, url: link, asset_id } = item;
            const { location: url, size } = attachments.find(({ id }) => id === asset_id) || {};
            return {
                id,
                label: cert.name,
                name: cert.name,
                url,
                link,
                size,
                meta: { ...analyticMeta }, // analytic information
                type: null,
            };
        }),
        'name'
    );

    const handleValue = (value, type, internal_storage) => {
        if (type === 'number') {
            if (internal_storage === 'percentage') return value ? `${value}%` : value;
        }
        return value;
    };

    const formattedChars = sortBy(
        environmentCharacteristics.map((item) => {
            const { environment_characteristic_id: id, environmentCharacteristic: charInfor, value } = item;
            const { name: label, default_value: defaultValue, attribute_type_id: type, internal_storage } = charInfor;
            return {
                id,
                label,
                type,
                value: handleValue(value, type, internal_storage) || defaultValue,
            };
        }),
        'label'
    );

    const formattedRatings = sortBy(
        ratingSystems.map(({ ratingSystem }) => {
            const { id, name: label } = ratingSystem;
            return {
                id,
                label,
                value: true,
            };
        }),
        'label'
    );

    if (formattedCerts && formattedCerts.length > 0) {
        navOptions.push({
            label: SUSTAINABILITY_GROUPS[0],
            element: Certificates,
            data: formattedCerts,
        });
    }

    if (formattedChars && formattedChars.length > 0) {
        navOptions.push({
            label: SUSTAINABILITY_GROUPS[1],
            element: AttributeDisplay,
            data: formattedChars,
        });
    }

    if (formattedRatings && formattedRatings.length > 0) {
        navOptions.push({
            label: SUSTAINABILITY_GROUPS[2],
            element: RatingSystems,
            data: formattedRatings,
        });
    }
    if (navOptions.length === 0) return null;
    return (
        <Container>
            <DropdownWrapper>
                <Title>Product Options</Title>
                <Dropdown>
                    <FormDropdown value={optionId} onChange={(evt) => setOptionId(evt.target.value)}>
                        {data.map(({ id, name }) => (
                            <FormDropdownOption value={id} optionLabel={name} key={id} />
                        ))}
                    </FormDropdown>
                </Dropdown>
            </DropdownWrapper>
            <SideNav options={navOptions} />
        </Container>
    );
};

export default Sustainability;
