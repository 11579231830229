import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { history } from 'base-client';
import { Button, CloseIcon } from 'shared';

const Bold = styled.span`
    font-weight: bold;
    font-size: 16px;
    color: ${(props) => props.theme.grey5};
`;

const StyledNotification = styled.div`
    padding: 15px 34px 15px 20px;
`;

const ProjectProductsAddedCard = ({ card = {}, onRemove }) => {
    const {
        id,
        payloads: { products, project },
    } = card;
    const productsLength = (products && products.length) || 0;

    const goToProject = () => {
        history.push(`/projects/${project.id}`);
        onRemove(card.id);
    };

    return productsLength > 0 ? (
        <div className="pad-15">
            <div className="notification-card-title">
                <Bold>Product Added Successfully</Bold>
                <CloseIcon onClick={() => onRemove(id)} />
            </div>
            <div className="notification-card-content">
                {productsLength} Products were added to your project <Bold>{project.name}</Bold>
            </div>
            <div className="notification-card-footer">
                <Button btnLink onClick={goToProject}>
                    Go to Project
                </Button>
            </div>
        </div>
    ) : (
        <StyledNotification>
            <div className="notification-card-title">
                <Bold>Project Created Successfully</Bold>
                <CloseIcon height={16} width={16} top={1.5} right={1.4} onClick={() => onRemove(id)} />
            </div>
            <div className="notification-card-content">
                Your project, <Bold>{project.name}</Bold>, is now available!
            </div>
            <div className="notification-card-footer">
                <Button noUpperCase inverse onClick={goToProject}>
                    Go to Project
                </Button>
            </div>
        </StyledNotification>
    );
};

ProjectProductsAddedCard.propTypes = {
    card: PropTypes.shape({
        id: PropTypes.string,
        project: PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
        }),
        products: PropTypes.array,
        isSubmittalWorkflow: PropTypes.bool,
    }),
};

export default ProjectProductsAddedCard;
