import { css } from 'styled-components';

const sizes = {
    large: 992,
    medium: 768,
    small: 376,
};

// Iterate through the sizes and create a media template
const media = Object.keys(sizes).reduce((acc, label) => {
    acc[label] = (...args) => css`
        @media (min-width: ${sizes[label] / 16}em) {
            ${css(...args)};
        }
    `;

    return acc;
}, {});
export default media;
