import React from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';

import media from 'utils/media';
import { DisplayLink } from 'shared';
import { TrackClick } from 'analytics';

const shake = keyframes`
  from,
  to {
    transform: translateX(0) rotate(45deg);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-2px) rotate(45deg);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translateX(1px) rotate(45deg);
  }
`;

const Container = styled(DisplayLink)`
    display: flex;
    flex-direction: column;
    margin: 0 1% 2rem;
    background-color: #fff;
    padding: 1.5rem 2rem 1.5rem 2.3rem;
    border-radius: 3px;
    box-shadow: 0 3px 5px rgba(52, 62, 71, 0.12);
    position: relative;
    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 4px;
        background-color: ${(props) => (props.theme.accentColor ? props.theme.accentColor : props.theme.blue)};
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
    }
    &:after {
        content: '';
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        opacity: 0;
        border-radius: 3px;
        box-shadow: 0 3px 15px rgba(52, 62, 71, 0.12), 0 3px 25px rgba(52, 62, 71, 0.08);
        transition: opacity 0.3s ease-in-out;
    }
    &:hover:after {
        opacity: 1;
    }
    ${media.small`
    flex: 0 0 23%;
    min-width: 22rem;
  `};
`;

export const Title = styled.h4`
    margin: 0;
    font-size: 1.8rem;
`;

const Description = styled.p`
    font-size: 1.4rem;
`;

const Footer = styled.div`
    text-align: right;
    margin-top: auto;
    .navcard-link {
        color: ${(props) => (props.theme.primaryColor ? props.theme.primaryColor : props.theme.grey1)};
        font-size: 1.4rem;
        font-weight: 600;
    }
`;

const ArrowIcon = styled.i`
    position: relative;
    display: inline-block;
    font-size: 12px;
    width: 0.8rem;
    right: 0.8rem;
    height: 0.8rem;
    border-top: 2px solid ${(props) => (props.theme.primaryColor ? props.theme.primaryColor : props.theme.blue)};
    border-right: 2px solid ${(props) => (props.theme.primaryColor ? props.theme.primaryColor : props.theme.blue)};
    transform: rotate(45deg);
    &:after {
        content: '';
        position: absolute;
        border-top: 2px solid ${(props) => (props.theme.primaryColor ? props.theme.primaryColor : props.theme.blue)};
        top: 2px;
        right: -7px;
        width: 14px;
        height: 14px;
        height: 14px;
        transform: rotate(-45deg);
    }
    ${Container}:hover & {
        animation: ${shake} 1.2s;
    }
`;

const NavigationCard = ({ header, description, urlDisplayValue, url }) => (
    <Container {...{ url }}>
        <Title>{header}</Title>
        <Description>{description}</Description>
        <Footer>
            <TrackClick {...{ url }} meta={{ display: header }}>
                <span className="navcard-link">
                    <ArrowIcon />
                    {urlDisplayValue}
                </span>
            </TrackClick>
        </Footer>
    </Container>
);

NavigationCard.propTypes = {
    header: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    urlDisplayValue: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
};

NavigationCard.defaultProps = {
    header: '',
    description: '',
    urlDisplayValue: '',
    url: '',
};

export default NavigationCard;
