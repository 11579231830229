import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { reducerUtil } from 'base-client';

import { DisplayLink } from 'shared';
import { TrackClick } from 'analytics';
import MoreTools from './MoreTools';
import { reducerData as tenantData } from 'tenant';
import HeaderLinkMenu from './HeaderLinkMenu';

const Container = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    a {
        padding: 0 1.5rem 0.75rem 0;
        margin-right: 1rem;
        transition: color 0.1s ease;
        cursor: pointer;
        position: relative;
        font-size: 1.4rem;
        font-weight: 500;
        color: ${(props) => props.theme.textColor || props.theme.grey3};
        &:hover {
            color: ${(props) => props.theme.textHoverColor || 'inherit'};
        }
    }
`;

const MenuList = styled.ul`
    display: flex;
    list-style: none;
    position: relative;
    li:last-child {
        a {
            margin-right: 0;
        }
    }
    .active {
        color: ${(props) => props.theme.textHoverColor || 'inherit'};
        font-weight: 700;
    }
`;

const HeaderLinksContainer = ({ disabled, headerLinks }) => {
    if (disabled) return null;

    const list = [
        {
            displayValue: headerLinks[0].displayValue || 'Product Information',
            url: headerLinks[0].url || '/products',
            menu: headerLinks[0].menu || null,
        },
    ];
    const moreList = [];

    if (headerLinks.length > 1) {
        if (headerLinks.length < 5) list.push(...headerLinks.slice(1, headerLinks.length));
        else {
            list.push(...headerLinks.slice(1, 4));
            moreList.push(...headerLinks.slice(4, headerLinks.length));
        }
    }

    const links = list.map(({ displayValue, url, menu }, index) => {
        if (menu) return <HeaderLinkMenu {...{ displayValue, menu }} key={index} />;
        return (
            <li key={index}>
                <TrackClick {...{ url }} meta={{ display: displayValue }}>
                    <DisplayLink {...{ url }}>{displayValue}</DisplayLink>
                </TrackClick>
            </li>
        );
    });

    return (
        <Container>
            <MenuList>
                {links}
                {moreList.length > 0 && (
                    <li>
                        <MoreTools list={moreList} />
                    </li>
                )}
            </MenuList>
        </Container>
    );
};

HeaderLinksContainer.propTypes = {
    disabled: PropTypes.bool,
    headerLinks: PropTypes.arrayOf(
        PropTypes.shape({
            displayValue: PropTypes.string,
            url: PropTypes.string,
        })
    ),
};

HeaderLinksContainer.defaultProps = {
    headerLinks: [{}],
};

const mapStateToProps = (state) => {
    const header = reducerUtil.getSlice(tenantData, tenantData.header, state);
    const { custom } = reducerUtil.getSlice(tenantData, tenantData.disabled, state) || {};
    return { headerLinks: header, disabled: custom };
};

export default connect(mapStateToProps)(HeaderLinksContainer);
