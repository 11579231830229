import React, { useState, useEffect } from 'react';
import { List, Button } from 'shared';
import CreateProjectForm from './CreateProjectForm';
import styled from 'styled-components';

const TemplateContainer = styled.div`
    margin-bottom: 3px;
`;

const Container = styled.div`
    margin-bottom: 0.5em;
`;

const Label = styled.label`
    cursor: pointer;
    font-weight: 500;
    margin-bottom: 8px;
    input {
        margin-right: 0.5rem;
    }
    &.required {
        position: relative;
        &:after {
            position: absolute;
            content: '*';
            top: 0;
            right: -1rem;
            color: ${(props) => props.theme.red};
        }
    }
`;

const ProjectTemplateList = (props) => {
    const { projectTemplates, handleChange, handleCreateProject, setState, state } = props;
    const [isHidden, setIsHidden] = useState([]);
    const [canSubmit, setCanSubmit] = useState(true);
    const [selectedTemplate, setSelectedTemplate] = useState();
    const [, forceRender] = useState();

    useEffect(() => {
        state &&
            state.name !== '' &&
            state.zipCode !== '' &&
            state.template !== '' &&
            state.duplicateMessage === '' &&
            setCanSubmit(false);
    }, [state]);

    const handleClick = (e) => {
        let id = e.target.id;
        setIsHidden((prev) => {
            prev[id] = !prev[id];
            forceRender({});
            return prev;
        });
    };

    const handleCreation = async () => {
        let result = await handleCreateProject(state);
        if (!result) {
            setState({
                ...state,
                duplicateMessage: 'Please enter a unique project name',
            });
        }
    };

    const handleTemplateChange = (e) => {
        handleChange(e);
        setSelectedTemplate(e.target.value);
    };

    const TriangleDown = () => {
        return <>&#9660; &nbsp;</>;
    };

    const TriangleRight = () => {
        return <>&#9658; &nbsp;</>;
    };

    const templateList = () => {
        let list =
            projectTemplates &&
            projectTemplates.map((elem, i) => {
                return (
                    <li key={i}>
                        <Container>
                            <Label onClick={handleClick} key={i} id={i}>
                                {(isHidden && isHidden[i] && <TriangleDown />) || <TriangleRight />}
                                {elem.name}
                            </Label>

                            {isHidden && isHidden[i] && (
                                <List
                                    list={elem.templates.map((t, idx) => {
                                        return (
                                            <TemplateContainer key={idx}>
                                                <Button
                                                    inverse={selectedTemplate !== `${elem.name}:${t.name}`}
                                                    noUpperCase
                                                    value={`${elem.name}:${t.name}`}
                                                    name="template"
                                                    onClick={handleTemplateChange}
                                                >
                                                    {t.name}
                                                </Button>
                                            </TemplateContainer>
                                        );
                                    })}
                                ></List>
                            )}
                        </Container>
                    </li>
                );
            });
        return list;
    };

    return (
        <Container>
            {canSubmit}
            <strong>Template Selected:</strong>{' '}
            {(selectedTemplate && ` ${selectedTemplate.split(':')[0]}: ${selectedTemplate.split(':')[1]}`) || 'None'}
            <List list={templateList()} />
            <div>
                <Button disabled={canSubmit} small noUpperCase rightArrow onClick={handleCreation}>
                    Select Products
                </Button>
            </div>
        </Container>
    );
};

export default ProjectTemplateList;
