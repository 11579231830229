export default {
    auth: {
        name: 'auth',
        domain: {
            name: 'domain',
            key: 'auth0.domain',
            type: 'string',
        },
        clientId: {
            name: 'clientId',
            key: 'library.auth0.client.id',
            type: 'string',
        },
        audience: {
            name: 'audience',
            key: 'auth0.library_api_audience',
            type: 'string',
        },
    },
    api: {
        name: 'api',
        host: {
            name: 'host',
            key: 'api.endpoint.library',
            type: 'string',
        },
        gateway: {
            name: 'gateway',
            key: 'api.endpoint.gateway',
            type: 'string',
        },
    },
    agreement: {
        name: 'agreement',
        terms: {
            name: 'terms',
            key: 'client.agreement.terms',
            type: 'string',
        },
        privacy: {
            name: 'privacy',
            key: 'client.agreement.privacy',
            type: 'string',
        },
        website: {
            name: 'website',
            key: 'client.agreement.website',
            type: 'string',
        },
        cookieConsent: {
            name: 'cookieConsent',
            key: 'cookie.consent.page',
            type: 'string',
        },
        sitename: {
            name: 'sitename',
            key: 'library.agreement.sitename',
            type: 'string',
        },
        deleteAccountUrl: {
            name: 'deleteAccountUrl',
            key: 'library.agreement.deleteAccountUrl',
            type: 'string',
        },
    },
    analytics: {
        name: 'analytics',
        projectId: {
            name: 'projectId',
            key: 'keen.projectId',
            type: 'string',
        },
        writeKey: {
            name: 'writeKey',
            key: 'keen.writeKey',
            type: 'string',
        },
        segment: {
            name: 'segment',
            key: 'segment.writeKey',
            type: 'string',
        },
    },
    disabled: {
        name: 'disabled',
        project: {
            name: 'project',
            key: 'library.disabled.project',
            type: 'bool',
        },
        activeLink: {
            name: 'activeLink',
            key: 'library.disabled.activeLink',
            type: 'bool',
        },
        assetLinks: {
            name: 'assetLinks',
            key: 'library.disabled.assetLinks',
            type: 'bool',
        },
        submittalWorkflow: {
            name: 'submittalWorkflow',
            key: 'library.disabled.submittalWorkflow',
            type: 'bool',
        },
        banner: {
            name: 'banner',
            key: 'library.disabled.banner',
            type: 'bool',
        },
        sustainability: {
            name: 'sustainability',
            key: 'library.disabled.sustainability',
            type: 'bool',
        },
        filterGroup: {
            name: 'filterGroup',
            key: 'library.disabled.filterGroup',
            type: 'bool',
        },
        deleteAccount: {
            name: 'deleteAccount',
            key: 'library.disabled.deleteAccount',
            type: 'bool',
        },
    },
};
