import { actions as analyticsActions } from 'analytics';
import { actions as errorsActions } from 'errors';
import { actions as notificationActions, projectNotification } from 'notification';
import { api, history, reducerUtil } from 'base-client';
import { getDocumentTypesByMime } from 'proposal/constants';
import { reducerData as guestData, constants as initialState } from 'guest';
import { v4 as uuid } from 'uuid';

// Actions before removing queue direct download code
// https://github.com/SmartBIM/library-client/blob/fe33bf1ee40cf8846351fd33bb8e24a03199f2e2/src/guest/actions.js

const setOccupation = (occupation) => async (dispatch, getState) => {
    const spoofedUser = await dispatch(api.actions.get('guest/user', true));
    const state = getState();
    const { id } = spoofedUser.guest;
    const address = { ...initialState.generalDataObj.address, id };
    const user = { ...initialState.user, occupation };
    let generalData = {
        ...initialState.generalDataObj,
        id,
        user_id: id,
        tenant_id: state.tenant.tenant,
        address_id: id,
        createdAt: Date.now(),
        address,
        user,
    };

    dispatch(reducerUtil.setSlice(guestData, guestData.products, []));
    dispatch(reducerUtil.setSlice(guestData, guestData.generalData, generalData));
    await dispatch(reducerUtil.setSlice(guestData, guestData.guestOccupation, occupation));
    dispatch(updateLocalStorage());
};

const updateProject = () => async (dispatch, getState) => {
    const state = getState();
    if (!state.form || !state.form.projectDetails || !state.form.projectDetails.values) {
        return;
    }

    const form = state.form.projectDetails.values;
    const newGeneralData = {
        ...state.guest.generalData,
    };

    Object.entries(form).forEach(([key, val]) => {
        newGeneralData[key] = val;
    });

    await dispatch(reducerUtil.setSlice(guestData, guestData.generalData, newGeneralData));
    dispatch(updateLocalStorage());
};

const uploadCompanyLogo = (file) => async (dispatch) => {
    // currently unused for submittals
    const base64Logo = new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
    const logo = await base64Logo;

    if (logo instanceof Error) {
        dispatch(
            notificationActions.sendNotifications([
                {
                    id: `guest-submittal-logo-error-${uuid()}`,
                    type: projectNotification.GENERAL,
                    title: 'Error',
                    body: 'Your logo could not be uploaded',
                },
            ])
        );
        return;
    }

    await dispatch(reducerUtil.setSlice(guestData, guestData.generalData.company_logo, logo));
    dispatch(updateLocalStorage());
};

const addSelectedProductsToProject = (products) => async (dispatch, getState) => {
    if (!products && products.length < 1) {
        return;
    }
    if (products && !Array.isArray(products)) products = [products];

    const { guest, tenant, workflowProductSearch } = getState();
    const { id, name } = guest.generalData;

    const productsWithAssets = async () => {
        const productIds = products.map((product) => {
            return product.id;
        });
        const { assets } = await dispatch(
            api.actions.post('guest/assets', { products: productIds, tenant_id: tenant.tenant }, true)
        );

        return products.map((product, idx) => {
            // const assetArr = Object.values(assets);
            const match = workflowProductSearch.list.find((workflowProduct) => {
                if (workflowProduct.id === product.id) {
                    return true;
                }
            });
            const productWithThumbnail = {
                ...product,
                thumbnail: match.thumbnail || null,
            };
            return {
                ...productWithThumbnail,
                ...getDocumentTypesByMime(assets[product.id]),
            };
        });
    };

    const fullProducts =
        guest && guest.products ? [...guest.products, ...(await productsWithAssets())] : await productsWithAssets();

    await dispatch(reducerUtil.setSlice(guestData, guestData.products, fullProducts));
    dispatch(analyticsActions.track('projectaddproduct', { project_id: id, name, products }));
    dispatch(showNotification(products));
    dispatch(updateLocalStorage());
};

const removeProductFromProject = (product_id) => async (dispatch, getState) => {
    if (!product_id) {
        return;
    }
    const state = getState();
    const { products } = state.guest;

    const filteredProducts = products.filter((product) => {
        if (product.id !== product_id) {
            return true;
        }
    });

    await dispatch(reducerUtil.setSlice(guestData, guestData.products, filteredProducts));

    const { id, name } = state.guest.generalData;
    const notification = [
        {
            id: `update-project-${uuid()}`,
            type: projectNotification.PRODUCT_REMOVED,
            payloads: {
                project: {
                    project_id: id,
                    name,
                },
                products: [product_id],
            },
        },
    ];

    dispatch(notificationActions.sendNotifications(notification));
    dispatch(
        analyticsActions.track('projectremoveproduct', {
            project_id: id,
            name,
            products: [product_id],
        })
    );
    dispatch(updateLocalStorage());
};

const selectAllDocuments = () => async (dispatch, getState) => {
    const state = getState();
    const updatedProducts = JSON.parse(JSON.stringify(state.guest.products));

    updatedProducts.forEach((product) => {
        product.selected = [];
        product.documents.forEach((document) => {
            document.files.forEach((file) => {
                product.selected.push(file.name);
            });
        });
    });

    await dispatch(reducerUtil.setSlice(guestData, guestData.products, updatedProducts));
    dispatch(updateLocalStorage());
};

const deselectAllDocuments = () => async (dispatch, getState) => {
    const state = getState();
    const updatedProducts = JSON.parse(JSON.stringify(state.guest.products));

    updatedProducts.forEach((product) => {
        product.selected = [];
    });

    await dispatch(reducerUtil.setSlice(guestData, guestData.products, updatedProducts));
    dispatch(updateLocalStorage());
};

const showNotification = (products) => (dispatch, getState) => {
    const productsList = (products && products.map(({ product_id: id, name }) => ({ id, name }))) || [];
    const state = getState();
    const { id, name } = state.guest.generalData;
    const notification = [
        {
            id: `create-project-${uuid()}`,
            type: projectNotification.PRODUCT_ADDED,
            payloads: {
                project: {
                    id,
                    name,
                },
                products: productsList,
            },
        },
    ];

    dispatch(notificationActions.sendNotifications(notification));
};

const goToSubmittal = (defaultStep) => {
    history.push('/projects/guest/submittal', { defaultStep });
};

const handleCollapse = (productId) => (dispatch, getState) => {
    const state = getState();
    const newData = state.guest.products.map((product) => {
        if (product.id === productId) {
            const { collapse: preVal } = product;
            return { ...product, collapse: !preVal };
        } else {
            return { ...product };
        }
    });

    dispatch(reducerUtil.setSlice(guestData, guestData.products, newData));
};

const handleUpdateSelection = (productId, selectedItems) => async (dispatch, getState) => {
    if (!Array.isArray(selectedItems)) selectedItems = [selectedItems];

    const state = getState();
    const newProductData = state.guest.products.map((product) => {
        return product.id === productId ? { ...product, selected: selectedItems } : product;
    });

    await dispatch(reducerUtil.setSlice(guestData, guestData.products, newProductData));
    dispatch(updateLocalStorage());
};

const createSubmittal = () => async (dispatch, getState) => {
    const state = getState();
    const project = state.guest.generalData;
    const submittalOptions = state.guest.submittalOptions;
    const { products } = state.guest;
    const { id, name } = project;

    if (submittalOptions && !submittalOptions.targetEmail.length) {
        await dispatch(
            notificationActions.sendNotifications([
                {
                    id: 'error-submittal',
                    type: projectNotification.GENERAL,
                    title: 'Error',
                    body: 'Please enter generation method email before continuing',
                },
            ])
        );
        setTimeout(function () {
            dispatch(notificationActions.removeNotification('error-submittal'));
        }, 5000);
        return;
    }

    // reformat product data for submittal post
    const reformatProducts = (products) => {
        const formattedProducts = {
            productNameMapping: {},
            productAssetMapping: {},
            submittalOptions,
        };
        products.forEach((product) => {
            formattedProducts.productNameMapping[product.id] = product.name;
            formattedProducts.productAssetMapping[product.id] = product.selected;
        });

        return formattedProducts;
    };

    const reformattedProducts = products ? reformatProducts(products) : products;

    try {
        const notification = [
            {
                id: 'email-submittal',
                type: projectNotification.EMAIL_PROPOSAL,
                payloads: {
                    project: {
                        id,
                        name,
                    },
                },
            },
        ];
        dispatch(notificationActions.sendNotifications(notification, true));

        const result = await dispatch(
            api.actions.post(
                'guest/submittal',
                { generalData: state.guest.generalData, products: reformattedProducts, submittalOptions },
                true
            )
        );

        if (result) {
            const { submittal_id } = result;
            // send analytics
            dispatch(analyticsActions.track('submittal', { project_id: project.id, name, submittal_id }));
            dispatch(notificationActions.removeNotification('email-submittal'));
            dispatch(deleteGuestSession()); // deletes to get new guest uuid from api on next session
            history.push(`/redirect/${encodeURIComponent('?path=/&msg=Submittal Successful')}`);
        }
    } catch (error) {
        dispatch(errorsActions.error(error));
    }
};

const setSubmittalOptions = (options) => (dispatch) => {
    dispatch(reducerUtil.setSlice(guestData, guestData.submittalOptions, options));
};

const updateLocalStorage = () => async (dispatch, getState) => {
    const data = getState().guest;
    window.localStorage.setItem('guest', JSON.stringify(data));
};

const getStoredGuestSession = () => async (dispatch, getState) => {
    const storedGuestState = window.localStorage.getItem('guest');
    if (storedGuestState) {
        const parsedState = JSON.parse(storedGuestState);
        Object.entries(parsedState).forEach(([key, val]) => {
            dispatch(reducerUtil.setSlice(guestData, guestData[key], val));
        });
        return true;
    } else {
        return false;
    }
};

const clearGuestSubmittalData = () => async (dispatch, getState) => {
    const occupation = getState().guest.guestOccupation;
    await dispatch(setOccupation(occupation)); // resets and saves local storage
};

const deleteGuestSession = () => async (dispatch, getState) => {
    const guestEnabled = getState().guest.guestEnabled;
    const emptyGuestState = {
        generalData: {},
        guestEnabled: guestEnabled,
        guestOccupation: '',
        products: [],
        submittalOptions: {},
    };
    for (const [key, val] of Object.entries(emptyGuestState)) {
        dispatch(reducerUtil.setSlice(guestData, guestData[key], val));
    }
    window.localStorage.removeItem('guest');
};

export default {
    addSelectedProductsToProject,
    clearGuestSubmittalData,
    createSubmittal,
    deleteGuestSession,
    deselectAllDocuments,
    getStoredGuestSession,
    goToSubmittal,
    handleCollapse,
    handleUpdateSelection,
    removeProductFromProject,
    selectAllDocuments,
    setOccupation,
    setSubmittalOptions,
    showNotification,
    updateProject,
    uploadCompanyLogo,
};
