import React from 'react';
import PropTypes from 'prop-types';

import { sbBranding } from 'global-styles';
import { Button, ConfirmModal } from 'shared';
import { reducerData as tenantData } from 'tenant';
import { RightBlock } from 'projects';
import { reducerUtil } from 'base-client';
import { connect } from 'react-redux';

const TEXTS = {
    title: 'Project Tools',
    description: 'Create submittals, edit your project data or delete your project entirely.',
    brochureButton: 'Create A Brochure',
    submittalButton: 'Create A Submittal',
    editProjectButton: 'Edit Project',
    deleteProjectButton: 'Delete Project',
    deleteMessage: 'Deleting a project cannot be undone',
};

const ProjectTools = ({ id, name, editProject, createSubmittal, createBrochure, deleteProject, theme }) => {
    const btnCustomStyles = {
        fullWidth: true,
        upperCase: true,
        medium: true,
        fontWeight: 600,
        color: sbBranding.grey3,
    };
    const { brochureEnabled } = (theme && theme) || false;
    return (
        <RightBlock title={TEXTS.title} description={TEXTS.description}>
            {brochureEnabled && (
                <Button medium fullWidth onClick={createBrochure}>
                    {TEXTS.brochureButton}
                </Button>
            )}

            <Button medium fullWidth onClick={createSubmittal}>
                {TEXTS.submittalButton}
            </Button>
            <Button medium fullWidth bgColor={sbBranding.grey7} color={sbBranding.grey5} onClick={editProject}>
                {TEXTS.editProjectButton}
            </Button>
            <ConfirmModal
                title={`Delete ${name}`}
                messages={TEXTS.deleteMessage}
                onConfirm={deleteProject}
                confirmBtnText={TEXTS.deleteProjectButton}
                btnCustomStyles={btnCustomStyles}
            />
        </RightBlock>
    );
};

ProjectTools.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    editProject: PropTypes.func.isRequired,
    deleteProject: PropTypes.func.isRequired,
    createSubmittal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    theme: reducerUtil.getSlice(tenantData, tenantData.theme, state),
});
export default connect(mapStateToProps)(ProjectTools);
