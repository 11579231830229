import { reducerUtil, createStore } from 'base-client';
import { reducer as form } from 'redux-form';

import productDetails from 'productDetails/reducerData';
import search from 'productSearch/reducerData';
import workflowProductSearch from 'workflowProductSearch/reducerData';
import collectionProductSearch from 'customPages/components/ProductSearch/reducerData';
import errors from 'errors/reducerData';
import userAccount from 'userAccount/reducerData';
import tenant from 'tenant/reducerData';
import notification from 'notification/reducerData';
import projects from 'projects/reducerData';
import { reducerData as productComparer } from 'productComparer';
import projectDetails from 'projectDetails/reducerData';
import proposal from 'proposal/reducerData';
import guest from 'guest/reducerData';

const store = createStore({
    form,
    [guest.name]: reducerUtil.create(guest),
    [productDetails.name]: reducerUtil.create(productDetails),
    [search.name]: reducerUtil.create(search),
    [errors.name]: reducerUtil.create(errors),
    [userAccount.name]: reducerUtil.create(userAccount),
    [productComparer.name]: reducerUtil.create(productComparer),
    [tenant.name]: reducerUtil.create(tenant),
    [notification.name]: reducerUtil.create(notification),
    [projects.name]: reducerUtil.create(projects),
    [projectDetails.name]: reducerUtil.create(projectDetails),
    [proposal.name]: reducerUtil.create(proposal),
    [collectionProductSearch.name]: reducerUtil.create(collectionProductSearch),
    [workflowProductSearch.name]: reducerUtil.create(workflowProductSearch),
});

export default store;
