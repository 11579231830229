import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { config, login, reducerUtil } from 'base-client';

import { configMap } from 'configurations';
import { reducerData as tenantData } from 'tenant';

import { Button, Tooltip } from 'shared';
import { reducerData as projectData } from 'projects';

const Wrapper = styled.div`
    &.hasTooltip {
        position: relative;
        .tooltip {
            left: -2rem;
            right: -2rem;
        }
        &:hover {
            .tooltip {
                opacity: 1;
            }
        }
    }
`;

const AddToProjectButton = ({
    products,
    token,
    projData,
    modalOpen,
    projectSubmittalSettings,
    isSubmittalWorkflow,
    dispatch,
    children,
    ...btnProps
}) => {
    const { disabled: hideAddToProject } = projectSubmittalSettings;

    const disabledProject = dispatch(config.actions.getData(configMap.disabled.name, configMap.disabled.project.name));

    const disableBtn = isSubmittalWorkflow && (!products || (products && products.length < 1));

    return disabledProject || hideAddToProject ? null : (
        <Wrapper className={!token ? 'addToProjectbtn hasTooltip' : 'addToProjectbtn'}>
            <Button className="project" disabled={disableBtn} {...btnProps}>
                {children || 'Add to a Project'}
            </Button>
            {!token ? (
                <Tooltip>{isSubmittalWorkflow ? 'Login to add to a Submittal' : 'Login to add to a Project'}</Tooltip>
            ) : null}
        </Wrapper>
    );
};

const mapStateToProps = (state) => ({
    projectSubmittalSettings: reducerUtil.getSlice(tenantData, tenantData.projectSubmittalSettings, state),
    token: reducerUtil.getSlice(login.reducerData, login.reducerData.token, state),
    modalOpen: reducerUtil.getSlice(projectData, projectData.modalOpen, state),
});

export default connect(mapStateToProps)(AddToProjectButton);
