const protocolFix = (href) => {
    if (!href) {
        return href;
    }
    const hrefArr = href.includes('www.') ? href.split('www.') : href.split('//');
    const link = hrefArr[hrefArr.length - 1];
    return '//' + link;
};

export default protocolFix;
