import React from 'react';
import ErrorDisplay from './ErrorDisplay';

const ErrorDisplayServer = () => (
    <ErrorDisplay
        header="We're having some trouble connecting with the server."
        content="Please refresh the page or try again later. Our engineers are aware of this problem and are working to fix it."
        imageUrl="/contents/500.svg"
    />
);

export default ErrorDisplayServer;
