import React from 'react';
import { connect } from 'react-redux';
import { reducerUtil } from 'base-client';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { actions as detailsActions, reducerData as detailsData } from 'productDetails';
import { actions as projectActions } from 'projectDetails';
import { PageHeader, Row, Column } from 'shared';
import { reducerData as tenantData } from 'tenant';

import DetailsHeader from './DetailsHeader';
import Loading from '../../shared/Loading.js';
import ProductTabsContainer from './ProductTabsContainer';
import RightContent from './RightContent';
import TransitionButton from 'proposal/components/TransitionButton';

import media from 'utils/media';

const StyledRow = styled(Row)`
    .productSummary {
        ${media.medium`
    flex: 0 0 75%;
  `} ${media.large`
    flex: 0 0 75%;
    padding: 0 1.5rem 0 3rem;
  `};
    }
    .productImage {
        ${media.medium`
    width: 30%;
  `} ${media.large`
    width: 25%;
  `};
    }
    .side-nav {
        .tab-list {
            flex: 0 0 20%;
            border-bottom: 0;
            li {
                padding-left: 2.2rem;
            }
        }
        .tab-content {
            flex: 0 0 80%;
        }
    }
`;

class ProductDetailsContainer extends React.Component {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        product: PropTypes.object,
        assets: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                name: PropTypes.string,
                color: PropTypes.string,
                url: PropTypes.string,
            })
        ),
        match: PropTypes.shape({
            params: PropTypes.shape({
                id: PropTypes.string,
            }),
        }),
    };

    componentDidMount() {
        const { match, dispatch } = this.props;
        const id = match && match.params && match.params.id;
        if (id) dispatch(detailsActions.load(id));
    }

    componentDidUpdate(prevProps) {
        const { match, location, dispatch } = this.props;
        const { match: prevMatch } = prevProps;
        const id = match && match.params && match.params.id;
        const prevId = prevMatch && prevMatch.params && prevMatch.params.id;
        if (id && id !== prevId) dispatch(detailsActions.load(id));
        const search = location && location.search; // could be '?foo=bar'
        const params = search && new URLSearchParams(search);
        const project = params && params.get('project'); // bar
        if (project) {
            dispatch(projectActions.getProject(project, true));
            dispatch(projectActions.loadSavedProducts({ projectId: project, restart: true, noQuery: true }));
        }
    }

    render() {
        const { location, product, assets, projectSubmittalSettings, hideRAQ, dispatch } = this.props;
        const search = location && location.search; // could be '?foo=bar'
        const params = search && new URLSearchParams(search);
        const project = params && params.get('project'); // bar
        const hideActions = projectSubmittalSettings.disabled && hideRAQ;
        const hideAssets = !assets || (assets && assets.length < 1);

        return (
            <React.Fragment>
                {!product ? (
                    <Loading />
                ) : (
                    <>
                        {project && (
                            <TransitionButton
                                project={project}
                                leftArrow
                                textOnly
                                step={1}
                                text="Back to Product Selection"
                            />
                        )}
                        <PageHeader title={product.name} />
                        {hideActions && hideAssets ? (
                            <React.Fragment>
                                <DetailsHeader
                                    {...{ description: product.description, thumbnail: product.thumbnail }}
                                />
                                <ProductTabsContainer />
                            </React.Fragment>
                        ) : (
                            <StyledRow>
                                <Column mdUp={9} className="leftContent">
                                    <DetailsHeader
                                        {...{ description: product.description, thumbnail: product.thumbnail }}
                                    />{' '}
                                    <ProductTabsContainer />
                                </Column>
                                <RightContent
                                    {...{ assets, hideActions, hideAssets }}
                                    product={{ id: product.id, name: product.name }}
                                />
                            </StyledRow>
                        )}
                    </>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    assets: reducerUtil.getSlice(detailsData, detailsData.assets, state),
    hideRAQ: !reducerUtil.getSlice(tenantData, tenantData.showRequest, state),
    product: reducerUtil.getSlice(detailsData, detailsData.general, state),
    projectSubmittalSettings: reducerUtil.getSlice(tenantData, tenantData.projectSubmittalSettings, state) || {},
});

export default withRouter(connect(mapStateToProps)(ProductDetailsContainer));
