import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { ClampLines, HeaderAssets, Card, Checkbox } from 'shared';
import NoProductImg from 'shared/static/NoProductImage.jpg';
import Callout from './Callout';

const CardWrapper = styled(Card)`
    width: 100%;
    height: 100%;
    padding: 2rem;
    .select {
        position: absolute;
        left: 1rem;
        top: 1rem;
    }
`;

const Container = styled.div`
  padding: 0 0 2rem;
  height: 20rem;
  .header-assets {
    flex: 0 0 100%;
    margin-top: 0.5rem;
    a {
      position: relative;
      &:hover{
        > div{
          opacity: 1;
        }
      }
      span {
        font-size: 1.3rem;
        text-transform: none;
      }
    }
  .callout:nth-child(4) {
    margin-bottom: 0;
  }
`;

const ImageLink = styled(Link)`
    width: 20%;
    height: calc(100% - 2rem);
    margin-top: 2rem;
    float: left;
`;

const ImageNoLink = styled.div`
    width: 20%;
    height: calc(100% - 2rem);
    margin-top: 2rem;
    float: left;
`;

const ImageContainer = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
`;

const Image = styled.img`
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin: 0 auto;
`;

const Info = styled.div`
    padding: 0 2rem 0 4rem;
    width: 40%;
    float: left;
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const Name = styled.h2`
    font-size: 1.8rem;
    font-family: ${(props) => props.theme.fontAlt};
    margin: 0.3rem 0 0;
    font-weight: 800;
`;

const Description = styled.div`
  font-size: 1.4rem;
  line-height: 1.45;
  margin: 1.5rem 0;
  height: 100%;
  &.red-text {
    color:${(props) => props.theme.red};
  }
`;

const Specs = styled.div`
    width: 40%;
    float: left;
    display: flex;
    flex-wrap: wrap;
    padding-left: 2rem;
`;

const AssetsTitle = styled.h3`
    letter-spacing: 0.3px;
    color: ${(props) => props.theme.grey6};
    position: relative;
    margin-top: 0;
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
`;
/**
 * Function for creating a component representing a single item in the hybrid view.
 * @return {React.Element} Representation of a single product in hybrid view.
 */
class HybridCard extends Component {
    render() {
        const { style, product, enableCheckbox, toggleSelectProduct, showViewAllAssetsLink } = this.props;

        const {
            id,
            name,
            callouts = [],
            description,
            thumbnail,
            assets = [],
            checked,
            isDeleted,
            isDescriptionHidden,
        } = product;

        return isDeleted ? (
            <Container {...{ style }}>
                <CardWrapper>
                    {enableCheckbox && (
                        <Checkbox
                            label="Select"
                            id={id}
                            value={id}
                            checked={checked}
                            onChange={() => toggleSelectProduct(product)}
                        />
                    )}
                    <ImageNoLink>
                        <ImageContainer>
                            <Image src={thumbnail ? thumbnail : NoProductImg} />
                        </ImageContainer>
                    </ImageNoLink>
                    <Info>
                        <Name>
                            <ClampLines text={name || ''} lines={5} />
                        </Name>
                        <Description className="red-text">{<ClampLines text={description || ''} />}</Description>
                    </Info>
                </CardWrapper>
            </Container>
        ) : (
            <Container {...{ style }}>
                <CardWrapper>
                    {enableCheckbox && (
                        <Checkbox
                            label="Select"
                            id={id}
                            value={id}
                            checked={checked}
                            onChange={() => toggleSelectProduct(product)}
                        />
                    )}
                    <ImageLink to={id ? `/products/${id}` : '#'}>
                        <ImageContainer>
                            <Image src={thumbnail ? thumbnail : NoProductImg} />
                        </ImageContainer>
                    </ImageLink>
                    <Info>
                        <Link to={id ? `/products/${id}` : '#'}>
                            <Name>
                                <ClampLines text={name || ''} />
                            </Name>
                        </Link>
                        {isDescriptionHidden ? (
                            ''
                        ) : (
                            <Description>{<ClampLines text={description || ''} />}</Description>
                        )}
                    </Info>
                    <Specs>
                        {callouts.map(({ id, ...props }, index) => (
                            <Callout key={id || index} {...props} />
                        ))}
                        <HeaderAssets productId={id} {...{ assets, showViewAllAssetsLink }}>
                            <AssetsTitle>Links</AssetsTitle>
                        </HeaderAssets>
                    </Specs>
                </CardWrapper>
            </Container>
        );
    }
}
HybridCard.propTypes = {
    product: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        callouts: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                name: PropTypes.string,
                value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            })
        ),
        thumbnail: PropTypes.string,
        description: PropTypes.string,
        assets: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                url: PropTypes.string,
                color: PropTypes.string,
            })
        ),
    }),
    style: PropTypes.object.isRequired,
    enableCheckbox: PropTypes.bool,
    toggleSelectProduct: PropTypes.func,
    showDownloadAllFilesLink: PropTypes.bool,
};
HybridCard.defaultProps = { product: { callouts: [] } };
export default HybridCard;
