import React from 'react';
import styled from 'styled-components';

import { CloseIcon } from 'shared';

const Bold = styled.span`
    font-weight: 600;
    color: ${(props) => props.theme.grey5};
`;

const GeneralCard = ({ card, onRemove }) => (
    <div className="pad-15">
        <div className="notification-card-title">
            <Bold>{card.title}</Bold>
            <CloseIcon onClick={() => onRemove(card.id)} />
        </div>
        <div className="notification-card-content">{card.body}</div>
    </div>
);

export default GeneralCard;
