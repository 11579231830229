import { api, history, login, reducerUtil } from 'base-client';

import { accountUrl } from 'userAccount/utils';
import { actions as errorsActions } from 'errors';
import { actions as notificationActions, projectNotification } from 'notification';
import { sectors } from 'utils/staticData';
import accountData from 'userAccount/reducerData';
import tenantData from 'tenant/reducerData';
import { v4 as uuid } from 'uuid';

const toAccount = () => () => history.push(accountUrl);

const getUserAccount = (refresh) => async (dispatch, getState) => {
    const token = reducerUtil.getSlice(login.reducerData, login.reducerData.token, getState());

    if (!token) return;
    if (!refresh) dispatch(reducerUtil.setSlice(accountData, accountData.data, undefined));

    try {
        const result = await dispatch(api.actions.get('users/my/profile'));
        const { firstName, lastName, occupation, industry, sector, profile } = result || {};
        const { companyName, phoneNumber, email } = profile || {};
        const account = {
            firstName,
            lastName,
            occupation,
            industry,
            companyName,
            phoneNumber,
            email,
            sector: sector || sectors[sectors.length - 1],
        };
        dispatch(reducerUtil.setSlice(accountData, accountData.data, account));
    } catch (error) {
        dispatch(errorsActions.error(error));
    }
};

const setUserAccount = (values) => async (dispatch) => {
    try {
        const notification = [
            {
                id: `account-update-${uuid()}`,
                type: projectNotification.GENERAL,
                title: 'Account Updated',
                body: 'Your account data has been updated successfully.',
            },
        ];
        await dispatch(api.actions.patch('users/my/profile', JSON.stringify(values)));
        dispatch(notificationActions.sendNotifications(notification));
        dispatch(getUserAccount(true));
    } catch (error) {
        const notification = [
            {
                id: `account-update-${uuid()}`,
                type: projectNotification.GENERAL,
                title: 'Account Not Updated',
                body: 'Your account data has failed to update.',
            },
        ];
        dispatch(notificationActions.sendNotifications(notification));
        dispatch(errorsActions.error(error));
    }
};

const fillAccount = () => async (dispatch, getState) => {
    const token = reducerUtil.getSlice(login.reducerData, login.reducerData.token, getState());
    if (!token) return;

    await dispatch(getUserAccount());

    const accountInfo = reducerUtil.getSlice(accountData, accountData.data, getState()) || {};
    if (accountInfo.companyName && accountInfo.industry && accountInfo.occupation) return;

    dispatch(toAccount());
};

const clearAccount = () => (dispatch) => dispatch(reducerUtil.setSlice(accountData, accountData.data, undefined));

const deleteAccount = (values) => async (dispatch, getState) => {
    try {
        const tenant_id = reducerUtil.getSlice(tenantData, tenantData.tenant, getState());
        const result = await dispatch(api.actions.post('users/user-gdpr-delete', { ...values, tenant_id }));
        if (!result || result !== 'Email has been sent') {
            const notification = [
                {
                    id: `gdpr-deletion-req-${uuid()}`,
                    type: projectNotification.GENERAL,
                    title: 'Error',
                    body: 'Your data could not be deleted, please contact the company manually.',
                },
            ];
            dispatch(notificationActions.sendNotifications(notification));
        } else {
            clearAccount();
            dispatch(login.actions.logOut());
        }
        return result;
    } catch (error) {
        dispatch(errorsActions.error(error));
        return false;
    }
};

export default {
    toAccount,
    getUserAccount,
    setUserAccount,
    fillAccount,
    clearAccount,
    deleteAccount,
};
