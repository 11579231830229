import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reducerUtil } from 'base-client';
import { reducerData as tenantData } from 'tenant';
import Header from './components/Header';
import CardBanner from './components/CardBanner';
import FeaturedProducts from './components/FeaturedProducts';
import ProductSearch from './components/ProductSearch';
import ChildGrid from './components/ChildGrid';
import MarketingCopy from './components/MarketingCopy';
import { history } from 'base-client';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const findCustomPage = (customPages, location) =>
    customPages.reduce((a, page) => {
        if (a) return a;
        if (page.url === location) return page;
        if (page.children && location.startsWith(page.url)) {
            const foundPage = findCustomPage(page.children, location);
            if (foundPage) return foundPage;
            return page;
        }
    }, null);

const CustomPage = ({ dispatch, location, customPages }) => {
    const [page, setPage] = useState();
    useEffect(() => {
        if (customPages && location) {
            let targetPage = findCustomPage(customPages, location.pathname);
            if (targetPage) {
                setPage(targetPage);
            } else {
                history.push('');
            }
        }
    }, [location, customPages]);

    if (!page) return null;

    return (
        <Container>
            {page.components.map((component, idx) => {
                switch (component.type) {
                    case 'header':
                        return <Header {...{ ...component }} key={idx} />;
                    case 'children-grid':
                        return <ChildGrid {...{ ...component, ...page }} key={idx} />;
                    case 'card-banner':
                        return <CardBanner {...{ ...component }} key={idx} />;
                    case 'marketing-copy':
                        return <MarketingCopy {...{ ...component }} key={idx} />;
                    case 'featured-products':
                        return <FeaturedProducts {...{ ...component }} key={idx} />;
                    case 'product-search':
                        return <ProductSearch {...{ ...component }} id="product-anchor" key={idx} />;
                    default:
                        return <Header {...{ ...component }} key={idx} />;
                }
            })}
        </Container>
    );
};

const mapStateToProps = (state) => ({
    customPages: reducerUtil.getSlice(tenantData, tenantData.customPages, state) || {},
});

export default withRouter(connect(mapStateToProps)(CustomPage));
