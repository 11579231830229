import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ListName = styled.div`
    display: flex;
    align-items: center;
`;

const ListImageContainer = styled.div`
    flex: 0 0 10%;
    margin-right: 5%;
    margin-left: 2%;
`;

const ListImage = styled.img`
    max-height: 3rem;
    max-width: 100%;
    display: block;
    margin: 0 auto;
`;

const ListText = styled.div`
    flex: 0 0 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const namePropTypes = {
    rowData: PropTypes.shape({
        name: PropTypes.string,
        thumbnail: PropTypes.string,
    }).isRequired,
};

const Name = ({ rowData: { name, thumbnail } }) => (
    <ListName>
        <ListImageContainer>
            <ListImage src={thumbnail} />
        </ListImageContainer>
        <ListText>{name}</ListText>
    </ListName>
);

Name.propTypes = namePropTypes;

export default { Name };
export { ListName, ListImageContainer, ListImage, ListText };
