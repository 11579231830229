import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import SearchFieldContainer from './SearchFieldContainer';
import UserDropdownContainer from './UserDropdownContainer';
import HeaderLinksContainer from './HeaderLinksContainer';
import ContactSales from 'message/components/ContactSales';
import { ProjectButton } from 'projects';

const Wrapper = styled.div`
    padding: 1rem 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 128rem;
    margin: 0 auto;
`;

const HeaderLeft = styled.div`
    flex: 0 0 60%;
    display: flex;
    flex-direction: column;
`;

const HeaderRight = styled.div`
    flex: 0 0 40%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 1.4rem;
    & > * {
        margin-left: 2rem;
    }
`;

const NavOne = styled.div`
    display: flex;
    margin: 0.5rem 0;
`;

const NavTwo = styled.div`
    margin-top: 1rem;
`;

const LogoLink = styled(Link)`
    display: flex;
    align-items: center;
`;

const LogoImg = styled.img`
    max-height: ${(props) => props.theme.headerLogoSize || '2.5rem'};
`;

const isInternalRoute = (url) => {
    if (!url) {
        return true;
    }
    let splitUrl = url.split('/');

    if (splitUrl[0] === 'http:' || splitUrl[0] === 'https:') {
        return false;
    }
    return true;
};

const DesktopHeader = ({ logo, headerLogoLink, name, activeIndex }) => (
    <Wrapper>
        <HeaderLeft>
            <NavOne>
                {(isInternalRoute(headerLogoLink) && (
                    <LogoLink to={headerLogoLink || '/'}>
                        <LogoImg src={logo} alt={name} />
                    </LogoLink>
                )) || (
                    <a href={headerLogoLink}>
                        <LogoImg src={logo} alt={name} />
                    </a>
                )}

                <SearchFieldContainer />
            </NavOne>
            <NavTwo className="toggleMenu">
                <HeaderLinksContainer {...{ activeIndex }} />
            </NavTwo>
        </HeaderLeft>
        <HeaderRight>
            <ContactSales />
            <ProjectButton {...{ activeIndex }} />
            <UserDropdownContainer {...{ activeIndex }} />
        </HeaderRight>
    </Wrapper>
);

DesktopHeader.propTypes = {
    logo: PropTypes.string,
    headerLogoSize: PropTypes.string,
    headerLogoLink: PropTypes.string,
    name: PropTypes.string,
    activeIndex: PropTypes.string,
};

DesktopHeader.defaultProps = {
    logo: undefined,
    name: undefined,
};

export default DesktopHeader;
