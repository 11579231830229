import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.p`
    padding: 1rem 2rem;
    background: ${(props) => props.theme.grey0};
    border-radius: 4px;
    font-size: 1.3rem;
    color: ${(props) => props.theme.grey3};
    margin: 0;
`;

const Bold = styled.span`
    font-weight: 800;
    color: ${(props) => props.theme.grey5};
`;

const NoFacetsFound = ({ value }) => (
    <Container>
        Oops! No matches found for <Bold>"{value}"</Bold>. Please try another term.
    </Container>
);

NoFacetsFound.propTypes = {
    /** Search term */
    value: PropTypes.string.isRequired,
};

NoFacetsFound.defaultProps = {
    value: '',
};

export default NoFacetsFound;
