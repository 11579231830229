export default [
    'Education',
    'Healthcare',
    'Hospitality',
    'Property Management',
    'Property Development',
    'Retail',
    'Office / Corporate Workplace',
    'Senior Living',
    'Government',
    'Other',
];
