import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';
import { reducerUtil, config, login } from 'base-client';

import { configMap } from 'configurations';
import media from 'utils/media';
import { DisplayLink } from 'shared';
import { TrackClick } from 'analytics';
import { reducerData as tenantData } from 'tenant';
import { PoweredConcora } from 'shared';

const defaultColumnNames = ['Design Tools', 'Company Information', 'Legal Information', 'Contact Us'];

const FooterWrapper = styled.div`
    font-size: 1.4rem;
    position: relative;
    font-size: 1.4rem;
    padding: 3rem 0;
    margin-top: 3rem;
    background-color: ${(props) => props.theme.backgroundColor || '#ffffff'};
    clip-path: polygon(0 10%, 100% 7%, 100% 100%, 0 100%);
    background-size: cover;
    color: ${(props) => props.theme.textColor || props.theme.grey3};
    ${media.medium`
    clip-path: polygon(0 10%, 100% 4%, 100% 100%, 0 100%);
  `} ${media.large`
    clip-path: polygon(0 10%, 100% 0, 100% 100%, 0 100%);
  `};
`;

const Container = styled.div`
    padding: 3rem 1.5rem 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    max-width: 128rem;
    margin: 0 auto;
`;

const Colums = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 5rem;
    ${media.small`
    margin-top: 3rem;
  `} ${media.medium`
    width: 75%;
    margin-top: 0;
  `};
`;

const Column = styled.div`
    flex: 0 0 100%;
    margin-bottom: 2rem;
    ${media.small`
    flex: 0 0 48%;
    margin: 0 1% 2rem;
  `} ${media.large`
    flex: 0 0 23.5%;
    margin: 0 1.5% 0 0;
  `};
`;

const ColumnName = styled.h4`
    font-weight: 600;
    margin-bottom: 5px;
    margin-top: 0;
`;

const Link = styled(DisplayLink)`
    padding: 5px 0;
    display: block;
    transition: color 0.5s ease;
    cursor: pointer;
    &:hover {
        color: ${(props) => props.theme.textHoverColor || 'inherit'};
    }
    * {
        color: ${(props) => props.theme.grey4};
    }
`;

const BrandInfo = styled.div`
    ${media.medium`
    width: 25%;
  `};
`;

const Image = styled.img`
    max-width: 100px;
`;

const Copyright = styled.p`
    margin-top: 0;
    font-size: 1.2rem;
`;

const CCPA = styled.div`
    width: 27rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 1rem;
    right: 10rem;
`;

const FooterContainer = ({ disabled, footerLinks, name, logo, copyrightName, token, dispatch }) => {
    if (disabled) return null;

    const disabledDeleteAccount = dispatch(
        config.actions.getData(configMap.disabled.name, configMap.disabled.deleteAccount.name)
    );

    const deleteAccountUrl = '/gdpr-ccpa';
    // Leaving for potential future use
    // const deleteAccountUrl = dispatch(
    //   config.actions.getData(configMap.agreement.name, configMap.agreement.deleteAccountUrl.name)
    // );

    const columns = footerLinks.map(({ columnTitle, links }, columnIndex) => {
        const rows = links.map(({ displayValue, url }, rowIndex) => {
            if (columnIndex === 0 && rowIndex === 0) {
                return (
                    <Link className="e2e-footerTenantLink" url={url || '/products'} key={rowIndex}>
                        {displayValue || 'Product Information'}
                    </Link>
                );
            }
            return (
                <TrackClick {...{ url }} meta={{ display: displayValue }} key={rowIndex}>
                    <Link className="e2e-footerTenantLink" {...{ url }}>
                        {displayValue}
                    </Link>
                </TrackClick>
            );
        });

        return (
            <Column className="e2e-footerTenantColumn" key={columnIndex}>
                <ColumnName>{columnTitle || defaultColumnNames[columnIndex]}</ColumnName>
                {rows}
            </Column>
        );
    });

    return (
        <footer>
            <FooterWrapper>
                <Container>
                    <Colums>{columns}</Colums>
                    <BrandInfo>
                        <Image src={logo} alt={copyrightName || name} />
                        <Copyright>&copy;{` ${moment().year()} ${copyrightName || name}`}</Copyright>
                    </BrandInfo>
                </Container>
                <CCPA>
                    {!disabledDeleteAccount && token && <Link url={deleteAccountUrl}>CCPA</Link>}
                    <PoweredConcora />
                </CCPA>
            </FooterWrapper>
        </footer>
    );
};

FooterContainer.propTypes = {
    disabled: PropTypes.bool,
    footerLinks: PropTypes.arrayOf(
        PropTypes.shape({
            displayValue: PropTypes.string,
            url: PropTypes.string,
        })
    ),
    name: PropTypes.string,
    logo: PropTypes.string,
    copyrightName: PropTypes.string,
};

FooterContainer.defaultProps = {
    footerLinks: [{ links: [{}] }],
};

const mapStateToProps = (state) => {
    const { name, logo, copyrightName } = reducerUtil.getSlice(tenantData, tenantData.theme, state) || {};
    const footer = reducerUtil.getSlice(tenantData, tenantData.footer, state);
    const { custom } = reducerUtil.getSlice(tenantData, tenantData.disabled, state) || {};
    const token = reducerUtil.getSlice(login.reducerData, login.reducerData.token, state);
    return { footerLinks: footer, disabled: custom, name, logo, copyrightName, token };
};

export default connect(mapStateToProps)(FooterContainer);
