import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'react-virtualized';

const HybridView = ({ registerChild, ...props }) => <List {...props} ref={registerChild} />;

HybridView.propTypes = {
    registerChild: PropTypes.func,
};

export default HybridView;
