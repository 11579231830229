import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { actions as authActions } from 'login';

import googleImgUrl from '../assets/google.png';
import linkedinImgUrl from '../assets/linkedin.png';

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    img {
        cursor: pointer;
    }
`;

const SocialLogo = styled.img`
    margin: 0 1rem;
    max-height: 4rem;
`;

const Separator = styled.p`
    font-size: 1.2rem;
    color: ${(props) => props.theme.grey3};
    text-align: center;
    margin-top: 0;
    position: relative;
    margin-top: 1rem;
    flex: 0 0 100%;
    &:before,
    &:after {
        content: '';
        position: absolute;
        width: 5rem;
        height: 0.1rem;
        top: 0.9rem;
        background: ${(props) => props.theme.grey2};
    }
    &:before {
        left: 6rem;
    }
    &:after {
        right: 6rem;
    }
`;

const SocialContainer = ({ dispatch }) => (
    <Container>
        <SocialLogo
            src={googleImgUrl}
            alt="Authenticate with Google"
            onClick={() => dispatch(authActions.googleLogin())}
        />
        <SocialLogo
            src={linkedinImgUrl}
            alt="Authenticate with LinkedIn"
            onClick={() => dispatch(authActions.linkedInLogin())}
        />
        <Separator>or</Separator>
    </Container>
);

SocialContainer.propTypes = {
    dispatch: PropTypes.func.isRequired,
};

export default connect()(SocialContainer);
