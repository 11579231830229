import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { ExternalLink as BaseLink, Dropdown } from 'shared';
import protocolFix from 'utils/protocolFix';

const InternalLink = styled(Link)`
    display: block;
`;

const ExternalLink = styled(BaseLink)`
    display: block;
`;

const DropdownLink = styled(BaseLink)`
    padding: 0 0 0 0 !important;
    top: 2px;
`;

const MoreTools = ({ list }) => {
    const links = list.map(({ displayValue, url }, index) =>
        url.startsWith('/') ? (
            <InternalLink key={index} to={url}>
                {displayValue}
            </InternalLink>
        ) : (
            <ExternalLink key={index} href={protocolFix(url)}>
                {displayValue}
            </ExternalLink>
        )
    );
    return <Dropdown text={<DropdownLink>More Tools</DropdownLink>}>{links}</Dropdown>;
};

MoreTools.propTypes = {
    list: PropTypes.arrayOf(
        PropTypes.shape({
            displayValue: PropTypes.string,
            url: PropTypes.string,
        })
    ).isRequired,
};

export default MoreTools;
