import React from 'react';
import styled from 'styled-components';
import { find } from 'lodash';

import { FormDropdown, FormDropdownOption, Card } from 'shared';
import CellDisplay from './CellDisplay';
import { emptySymbol } from '../utils';

const Col = styled.col`
    width: ${(props) => 100 / props.cols + '%'};
`;

const Header = styled.h3`
    font-size: 16px;
    text-transform: uppercase;
    color: ${(props) => props.theme.grey3};
    font-weight: 600;
`;

const Table = styled.table`
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;

    tr:last-child td {
        border-bottom: none;
    }

    td {
        border-bottom: 1px solid ${(props) => props.theme.grey2};
        padding: 1rem 1rem 1rem 0;
        word-break: break-word;

        a {
            color: ${(props) => (props.theme.linkColor ? props.theme.linkColor : props.theme.blue)};
        }
    }

    tr {
        td:first-child {
            padding-left: 2rem;
            font-weight: 500;
        }
    }
`;

class BimData extends React.Component {
    state = {};

    componentDidMount() {
        const { products } = this.props;
        let configurations = products.map((p) => {
            return {
                familyType: p.configurations && p.configurations[0] && p.configurations[0].id,
                options: p.configurations && p.configurations.map((c) => ({ id: c.id, name: c.name })),
            };
        });

        let bimData = {};
        for (let i = 0; i < products.length; i++) {
            let p = products[i];
            let atts = p.configurations && p.configurations[0] && p.configurations[0].bimData;
            (atts || []).forEach((a) => {
                if (!bimData[a.attributeName] && !!a.attributeValue) {
                    bimData[a.attributeName] = {
                        values: [],
                        type: a.attributeType,
                    };
                }
            });
        }

        for (let key in bimData) {
            for (let i = 0; i < products.length; i++) {
                let p = products[i];
                let atts = p.configurations && p.configurations[0] && p.configurations[0].bimData;
                if (!atts) {
                    bimData[key].values.push(emptySymbol);
                    continue;
                } else {
                    // eslint-disable-next-line no-loop-func
                    let a = find(atts, (a) => a.attributeName === key);
                    if (a) {
                        bimData[key].values.push(a.attributeValue);
                    } else {
                        bimData[key].values.push(emptySymbol);
                    }
                }
            }
        }

        this.setState({ configurations, bimData });
    }

    handleChange = (event) => {
        const { configurations, bimData } = this.state;
        const { products } = this.props;

        for (let i = 0; i < configurations.length; i++) {
            let c = configurations[i];
            for (let j = 0; j < c.options.length; j++) {
                let o = c.options[j];
                if (o.id === event.target.value) {
                    c.familyType = event.target.value;
                }
            }

            for (let key in bimData) {
                for (let i = 0; i < products.length; i++) {
                    let p = products[i];
                    let c = find(p.configurations, (c) => c.id === event.target.value);
                    if (!c) {
                        continue;
                    }
                    let atts = c.bimData;
                    if (!atts) {
                        continue;
                    } else {
                        // eslint-disable-next-line no-loop-func
                        let a = find(atts, (a) => a.attributeName === key);
                        if (a) {
                            bimData[key].values[i] = a.attributeValue;
                        }
                    }
                }
            }
        }
        this.setState({ configurations, bimData });
    };

    render() {
        const { configurations, bimData } = this.state;

        if (!configurations || !Object.keys(bimData).length) return null;

        return (
            <div {...this.props}>
                <Header>BIM DATA</Header>
                <Card>
                    <Table>
                        <colgroup>
                            {[...[{}], ...configurations].map((b) => (
                                <Col cols={configurations.length + 1} />
                            ))}
                        </colgroup>
                        <tr>
                            <td>Family Type</td>
                            {(configurations || []).map((b) => (
                                <td>
                                    {!b.familyType ? null : (
                                        <FormDropdown noBorder value={b.familyType} onChange={this.handleChange}>
                                            {b.options &&
                                                b.options.map((o) => (
                                                    <FormDropdownOption value={o.id} optionLabel={o.name} key={o.id} />
                                                ))}
                                        </FormDropdown>
                                    )}
                                </td>
                            ))}
                        </tr>
                        {Object.keys(bimData || {})
                            .sort()
                            .map((key) => (
                                <tr>
                                    <td>{key}</td>
                                    {bimData[key].values.map((a) => (
                                        <td>
                                            <CellDisplay value={a} type={bimData[key].type} />
                                        </td>
                                    ))}
                                </tr>
                            ))}
                    </Table>
                </Card>
            </div>
        );
    }
}

BimData.propTypes = {};

BimData.defaultProps = {};

export default BimData;
