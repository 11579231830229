import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { reducerUtil, history } from 'base-client';

import { actions, reducerData } from 'productComparer';
import Product from './Product';
import ProductInformation from './ProductInformation';
import BimData from './BimData';
import Loading from '../../shared/Loading.js';
import { reducerData as tenantData } from 'tenant';

const StyledProduct = styled(Product)`
    display: inline-block;
    vertical-align: top;
    width: ${(props) => 100 / props.cols + '%'};
`;

class ProductComparer extends React.Component {
    constructor(props) {
        super(props);

        const {
            location: { search: queryJson },
        } = history;

        if (!queryJson) {
            history.push('/products');
        }

        let indexOfParams = queryJson.indexOf('=') + 1;
        const ids = queryJson.slice(indexOfParams).split('&');

        if (ids.length < 2) {
            history.push('/products');
        }

        props.dispatch(actions.getProducts(ids));
    }

    state = {};

    componentWillUnmount() {
        this.props.dispatch(reducerUtil.setSlice(reducerData, reducerData.products, undefined));
    }

    handleChange = (event) => {
        this.setState({ familyType: event.target.value });
    };

    render() {
        const { products, hasPrice } = this.props;

        return (
            <React.Fragment>
                {products && products.length > 0 ? (
                    <>
                        <h3>Compare Products</h3>
                        {[...[{}], ...products].map((p, idx) => (
                            <StyledProduct {...p} idx={idx} cols={products.length + 1} className="product-header" hasPrice={hasPrice} />
                        ))}
                        <ProductInformation
                            className="products-information"
                            headerText="Product Information"
                            {...{ products }}
                        />
                        {products && products.length && <BimData className="products-bimdata" {...this.props} />}
                    </>
                ) : (
                    <Loading />
                )}
            </React.Fragment>
        );
    }
}

ProductComparer.propTypes = {};

ProductComparer.defaultProps = {};

const mapStateToProps = (state) => ({
    products: reducerUtil.getSlice(reducerData, reducerData.products, state),
    hasPrice: reducerUtil.getSlice(tenantData, tenantData.hasPrice, state)
});

export default connect(mapStateToProps)(ProductComparer);
