import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { login, reducerUtil } from 'base-client';
import { reducerData as guestData } from 'guest';

const LoginRequired = (props) => {
    const { token, loginPage, children, location, guestOccupation } = props;
    let lsToken = localStorage.getItem('token');
    let prevPage = location.pathname;
    let guestPage = prevPage.search(/guest/gi) !== -1 ? true : false;
    if (lsToken || token || guestOccupation || guestPage) return <React.Fragment>{children}</React.Fragment>;
    return <Redirect to={loginPage} />;
};

LoginRequired.propTypes = {
    token: PropTypes.string,
    guestOccupation: PropTypes.string,
    loginPage: PropTypes.string,
    children: PropTypes.any,
};

const mapStateToProps = (state) => ({
    guestOccupation: reducerUtil.getSlice(guestData, guestData.guestOccupation, state),
    token: reducerUtil.getSlice(login.reducerData, login.reducerData.token, state),
    loginPage: reducerUtil.getSlice(login.reducerData, login.reducerData.loginPage, state),
});

export default withRouter(connect(mapStateToProps)(LoginRequired));
