import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reducerUtil, config } from 'base-client';
import { InfiniteList } from 'shared-features-client';

import { configMap } from 'configurations';
import { reducerData as tenantData } from 'tenant';
import ProductSearch from './ProductSearch';
import ListViewContainer from './ListViewContainer';
import GridViewContainer from './GridViewContainer';
import HybridViewContainer from './HybridViewContainer';
import { reducerData as productDetails } from 'productDetails';
import { actions as searchActions, reducerData as searchData } from 'productSearch';
import { LIST_VIEW, GRID_VIEW, HYBRID_VIEW, VIEW_KEY } from '../constants';
import Loading from '../../shared/Loading.js';

import stickyWrapper from './StickyWrapper';

class ProductSearchContainer extends React.Component {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        list: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                name: PropTypes.string,
                category: PropTypes.string,
                subcategory: PropTypes.string,
                thumbnail: PropTypes.string,
            })
        ),
        meta: PropTypes.shape({
            pagination: PropTypes.shape({
                page: PropTypes.number,
                pageCount: PropTypes.number,
            }),
        }),
        fetchId: PropTypes.string,
        tenantCompareProduct: PropTypes.bool,
        hasPrice: PropTypes.bool,
        selectedProducts: PropTypes.array,
        projectSubmittalSettings: PropTypes.object,
        projectsWithProduct: PropTypes.array,
    };

    static defaultProps = {
        list: [],
        selectedProducts: [],
        projectsWithProduct: [],
    };

    state = { [VIEW_KEY]: localStorage.getItem(VIEW_KEY) || HYBRID_VIEW };

    componentDidMount() {
        const {
            history: {
                location: { search },
            },
            selectedProducts,
            projectsWithProduct,
            dispatch,
        } = this.props;

        if (!search && selectedProducts && selectedProducts.length > 0) {
            dispatch(searchActions.clearSelectedProducts());
        }

        if (projectsWithProduct && projectsWithProduct.length > 0) {
            dispatch(reducerUtil.setSlice(productDetails, productDetails.projectsWithProduct, []));
        }

        this.handleSearch();
    }

    componentDidUpdate() {
        this.handleSearch();
    }

    handleSearch = () => {
        const { lastSearch } = this.state;
        const {
            history: {
                location: { search },
            },
        } = this.props;

        if (search !== lastSearch) {
            const { dispatch } = this.props;
            this.setState({ lastSearch: search });
            dispatch(searchActions.search(true));
        }
    };

    /** This sets the view and updates the storage */
    setView = (value) => {
        this.setState({ [VIEW_KEY]: value });
        localStorage.setItem(VIEW_KEY, value);
    };

    /** This gets the type of view and passes it to the Product Search component */
    getView = (viewProps) => {
        switch (this.state[VIEW_KEY]) {
            case LIST_VIEW:
                return <ListViewContainer {...viewProps} />;
            case GRID_VIEW:
                return <GridViewContainer {...viewProps} />;
            default:
                return <HybridViewContainer {...viewProps} />;
        }
    };

    updateFilter = ({ checked, attribute, facets, type }) => {
        const { dispatch } = this.props;
        if (type === 'radio') return dispatch(searchActions.updateFilter({ attribute, facets }));

        if (checked) dispatch(searchActions.addFilter({ attribute, facets }));
        else dispatch(searchActions.removeFilter({ attribute, facets }));
    };

    toggleSelectProduct = (product) => {
        const { dispatch } = this.props;
        const { checked, id, name } = product;
        if (!checked) {
            return dispatch(searchActions.selectProduct({ id, name }));
        } else {
            return dispatch(searchActions.deselectProduct({ id, name }));
        }
    };

    render() {
        const {
            defaultFilter,
            dispatch,
            fetchId,
            list,
            location,
            meta,
            projectSubmittalSettings,
            query,
            searching,
            selectedProducts,
            showViewAllAssetsLink,
            tenantCompareProduct,
            hasPrice
        } = this.props;

        const isSubmittalWorkflow = location && location.pathname && location.pathname === '/submittal';

        const length = list && list.length;
        const enableCheckbox = tenantCompareProduct || !projectSubmittalSettings.disabled;

        //get filters props
        const { filters: baseFilters = [] } = meta || {};
        const { filters: selected = [] } = query || {};
        const disabledFilterGroup = dispatch(
            config.actions.getData(configMap.disabled.name, configMap.disabled.filterGroup.name)
        );
        const filterProps = {
            filters: baseFilters
                .sort((a, b) => (parseInt(a.priority) > parseInt(b.priority) ? 1 : -1))
                .map((filter, i) => ({
                    ...filter,
                    group: !disabledFilterGroup && filter.group ? filter.group : '',
                })),
            selected,
            defaultFilter,
            updateFilter: this.updateFilter,
        };

        /* Determine if there is more to load based on the metadata */
        let hasNextPage;
        if (meta) {
            const {
                pagination: { page, pageCount },
            } = meta;
            hasNextPage = page < pageCount;
        }

        /* Create react-virtualized view props */
        const rowCount = hasNextPage ? length + 1 : length + 0 || 0;

        /* Organize props */
        const headerProps = {
            view: this.state[VIEW_KEY],
            setView: this.setView,
        };

        const infiniteListProps = {
            list,
            hasNextPage,
            fetching: !!fetchId,
            getNextPage: () => dispatch(searchActions.nextProductPage()),
        };

        const getProducts = () => {
            if (enableCheckbox) {
                return list.map((product) => {
                    return {
                        ...product,
                        checked: !selectedProducts ? false : !!selectedProducts.find(({ id }) => id === product.id),
                    };
                });
            }
            return list;
        };

        const viewProps = {
            enableCheckbox,
            rowCount,
            showViewAllAssetsLink,
            products: getProducts(),
            toggleSelectProduct: this.toggleSelectProduct,
            hasPrice
        };

        const productSearchProps = {
            ...headerProps,
            filterProps,
            selectedProducts,
            isSubmittalWorkflow,
            query,
            clearSelectedProducts: () => dispatch(searchActions.clearSelectedProducts()),
        };

        return (
            <>
                {searching ? (
                    <Loading />
                ) : (
                    <ProductSearch {...productSearchProps}>
                        <InfiniteList {...infiniteListProps}>{this.getView(viewProps)}</InfiniteList>
                    </ProductSearch>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    defaultFilter: reducerUtil.getSlice(tenantData, tenantData.defaultFilter, state),
    fetchId: reducerUtil.getSlice(searchData, searchData.fetchId, state),
    list: reducerUtil.getSlice(searchData, searchData.list, state),
    meta: reducerUtil.getSlice(searchData, searchData.meta, state),
    projectsWithProduct: reducerUtil.getSlice(productDetails, productDetails.projectsWithProduct, state),
    projectSubmittalSettings: reducerUtil.getSlice(tenantData, tenantData.projectSubmittalSettings, state) || {},
    query: reducerUtil.getSlice(searchData, searchData.query, state),
    searching: reducerUtil.getSlice(searchData, searchData.searching, state),
    selectedProducts: reducerUtil.getSlice(searchData, searchData.selectedProducts, state) || [],
    showViewAllAssetsLink: reducerUtil.getSlice(tenantData, tenantData.showViewAllAssetsLink, state),
    tenantCompareProduct: reducerUtil.getSlice(tenantData, tenantData.compareProduct, state),
    hasPrice: reducerUtil.getSlice(tenantData, tenantData.hasPrice, state)
});

export default withRouter(stickyWrapper(connect(mapStateToProps)(ProductSearchContainer)));
