import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import FileContainer from './FileContainer';
import Tooltip from './Tooltip';

export const FileType = styled.span`
    font-size: 1.3rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.3px;
    display: inline-block;
    margin-top: 0.3rem;
    border-bottom: ${(props) => (props.hasImage ? 'none' : `3px solid ${props.color || '#FFF'}`)};
`;

const CustomTooltip = ({ name, canDownload }) => (
    <Tooltip>{canDownload ? `${name === 'Download All' ? name : `Download ${name}`}` : 'Login to download'}</Tooltip>
);

CustomTooltip.propTypes = {
    canDownload: PropTypes.bool,
    name: PropTypes.string,
};

const CustomAsset = ({ viewAll, name, color, ...props }) => {
    return (
        <FileContainer {...props} title={name}>
            {!viewAll && <CustomTooltip {...{ name }} />}
            <FileType {...{ color }}>{name && name.toUpperCase()}</FileType>
        </FileContainer>
    );
};

CustomAsset.propTypes = {
    name: PropTypes.string,
    color: PropTypes.string,
};

export default CustomAsset;
