import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { login, reducerUtil } from 'base-client';

import { LinkButton, Dropdown, ButtonText } from 'shared';
import { reducerData as tenantData } from 'tenant';
import { actions as loginActions } from 'login';

const UserMenu = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    padding: 0.2rem 0 0.2rem 0;
    font-weight: 500;
    .dropdown {
        padding-right: 1rem;
        .visible {
            top: 4rem;
            right: -0.8rem;
        }
    }
    .dropdown-item {
        display: block;
        padding: 0 1.5rem 0.5rem;
        color: ${(props) => props.theme.textColor || props.theme.grey3};
        font-weight: 500;
        transition: color 0.2s ease;
        cursor: pointer;
        min-width: 11rem;
        &.active {
            color: ${(props) => props.theme.textHoverColor || 'inherit'};
            font-weight: 700;
        }
        &:last-child {
            padding-bottom: 0;
        }
    }
`;

const UserImage = styled.img`
    border-radius: 100%;
    margin: 0 1rem 0 0;
`;

const UserDropdownContainer = ({ token, hideLogin, dispatch, profile, activeIndex }) => {
    if (hideLogin) return null;

    if (!profile)
        return (
            <LinkButton small onClick={() => dispatch(login.actions.toLogin())}>
                Log In
            </LinkButton>
        );

    const { name, picture } = profile;

    return (
        <UserMenu>
            <UserImage src={picture} width="30" height="30" alt={name} />
            <Dropdown text="My Account">
                <Link
                    to="/account"
                    className={`dropdown-item ${activeIndex && activeIndex.startsWith('/account') ? 'active' : ''}`}
                >
                    Edit Account
                </Link>
                <ButtonText className="dropdown-item" onClick={() => dispatch(loginActions.logOut())}>
                    Log Out
                </ButtonText>
            </Dropdown>
        </UserMenu>
    );
};

UserDropdownContainer.propTypes = {
    hideLogin: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    profile: PropTypes.shape({
        nickname: PropTypes.string,
        name: PropTypes.string,
        picture: PropTypes.string,
    }),
    token: PropTypes.string,
};

const mapStateToProps = (state) => {
    const profile = reducerUtil.getSlice(login.reducerData, login.reducerData.profile, state);
    const hideLogin = reducerUtil.getSlice(tenantData, tenantData.hideLogin, state);
    const token = reducerUtil.getSlice(login.reducerData, login.reducerData.token, state);
    return { profile, hideLogin, token };
};

export default connect(mapStateToProps)(UserDropdownContainer);
export { UserMenu };
