import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { login, config, history, reducerUtil } from 'base-client';
import { Modal, LinkButton, ButtonText } from 'shared';
import { actions as projectActions, reducerData as projectData } from 'projects';
import { actions as detailsActions, reducerData as detailsData } from 'projectDetails';
import SelectProject from './SelectProject';
import CreateNewProject from './CreateNewProject';
import CreateProjectFromTemplate from './CreateProjectFromTemplate';

const ModalContent = styled.div`
    min-width: 50rem;
    font-size: 1.4rem;
`;

const FormGroup = styled.div`
    width: 100%;
    padding: 1.5rem 0 2rem;
    display: flex;
    flex-direction: column;
    &:first-child {
        padding-top: 0;
    }
    &.options {
        label {
            &:first-child {
                margin-right: 2rem;
            }
        }
    }
    &.projectInfor {
        padding-bottom: 0;
        display: flex;
    }
    .projectList {
        display: inline-block;
        width: 80%;
    }
`;

const Label = styled.label`
    cursor: pointer;
    font-weight: 500;
    margin-bottom: 8px;
    input {
        margin-right: 0.5rem;
    }
    &.required {
        position: relative;
        &:after {
            position: absolute;
            content: '*';
            top: 0;
            right: -1rem;
            color: ${(props) => props.theme.red};
        }
    }
`;

const GettingStartedModal = ({ token, lastSelectedProject, dispatch }) => {
    const [type, setType] = useState(2);
    const [projects, setProjects] = useState();

    useEffect(() => {
        const getProjects = async () => {
            const result = await dispatch(projectActions.getAllProjects());
            if (result && result.length > 0) {
                const list = result
                    .map(({ id, name }) => ({ id, name }))
                    .sort(({ name: a }, { name: b }) => a.toLowerCase().localeCompare(b.toLowerCase()));

                setProjects(list);
                setType(0);
            } else {
                setType(1);
            }
        };
        getProjects();
    }, []);

    const toggleProjectType = (evt) => {
        const { value } = evt.target;
        setType(parseInt(value));
    };

    const modalProps = {
        title: 'Getting Started',
        open: true,
        onClose: () => {
            return lastSelectedProject ? handleSelectProject(lastSelectedProject.id) : history.push('/');
        },
    };

    const handleCreateProject = async ({ name, zipCode }) => {
        const result = await dispatch(projectActions.createProject({ name, zipCode }));
        if (result) {
            await dispatch(detailsActions.getProject(result.id));
            await dispatch(detailsActions.loadSavedProducts({ projectId: result.id, restart: true }));
            history.push(`/projects/${result.id}/submittal`);
        }
        return result;
    };

    const handleCreateTemplateProject = async ({ name, zipCode, template }) => {
        const result = await dispatch(projectActions.createProject({ name, zipCode, template }));
        if (result) {
            dispatch(projectActions.closeModal());
            history.push({
                pathname: `/projects/${result.id}/submittal`,
                state: { modalState: true },
            });
        }
        return result;
    };

    const handleSelectProject = async (id) => {
        await dispatch(detailsActions.getProject(id));
        await dispatch(detailsActions.loadSavedProducts({ projectId: id, restart: true }));
        history.push(`/projects/${id}/submittal`);
        return;
    };

    const CardContainer = styled.div`
        display: flex;
        flex-direction: column;
    `;

    const SubTitle = styled.div`
        font-weight: bold;
        font-size: 14px;
        line-height: 23px;
        color: ${(props) => props.theme.primaryColor || props.theme.grey3};
    `;

    const Body = styled.div`
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 23px;
        margin-bottom: 13px;
    `;

    const LoginButton = styled(LinkButton)`
        width: 80px;
    `;

    const SignUp = styled.p`
        font-size: 1.2rem;
        button {
            color: ${(props) => props.theme.blue};
        }
    `;

    if (!token) {
        return (
            <Modal {...modalProps}>
                <ModalContent>
                    <CardContainer>
                        <SubTitle>Login/Sign Up</SubTitle>
                        <Body>Please login to create submittals and save them for later use.</Body>
                        <LoginButton small onClick={() => dispatch(login.actions.toLogin())}>
                            Log In
                        </LoginButton>
                        <SignUp>
                            Don't have an account?{' '}
                            <ButtonText onClick={() => history.push('/login', { signUp: true })}>
                                Create an account here
                            </ButtonText>
                        </SignUp>
                    </CardContainer>
                </ModalContent>
            </Modal>
        );
    }

    return type === 3 ? null : (
        <Modal {...modalProps}>
            <ModalContent>
                <CardContainer>
                    {projects && projects.length > 0 && (
                        <FormGroup className="options">
                            <Label htmlFor="useExistProject">
                                <input
                                    type="radio"
                                    id="useExistProject"
                                    name="Use Existing Project"
                                    value={0}
                                    checked={type === 0}
                                    onClick={(e) => toggleProjectType(e)}
                                    readOnly
                                />
                                Use Existing Project
                            </Label>
                            <Label htmlFor="startNewProject">
                                <input
                                    type="radio"
                                    id="startNewProject"
                                    name="Start New Project"
                                    value={1}
                                    checked={type === 1}
                                    readOnly
                                    onClick={(e) => toggleProjectType(e)}
                                />
                                Start New Project From Scratch
                            </Label>
                            <Label htmlFor="startProjectFromTemplate">
                                <input
                                    type="radio"
                                    id="startProjectFromTemplate"
                                    name="Start from a Project Template"
                                    value={2}
                                    checked={type === 2}
                                    readOnly
                                    onClick={(e) => toggleProjectType(e)}
                                />
                                Start from a Project Template
                            </Label>
                        </FormGroup>
                    )}
                    {type === 0 && <SelectProject {...{ projects, handleSelectProject }} />}
                    {type === 1 && <CreateNewProject {...{ handleCreateProject }} />}
                    {type === 2 && (
                        <CreateProjectFromTemplate {...{ handleCreateTemplateProject }}></CreateProjectFromTemplate>
                    )}
                </CardContainer>
            </ModalContent>
        </Modal>
    );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(GettingStartedModal);
