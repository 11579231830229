export default [
    {
        name: 'Most Recent',
        sortBy: 'created_at',
        sortDir: 'desc',
    },
    {
        name: 'Oldest',
        sortBy: 'created_at',
        sortDir: 'asc',
    },
    {
        name: 'A-Z',
        sortBy: 'name',
        sortDir: 'asc',
    },
    {
        name: 'Z-A',
        sortBy: 'name',
        sortDir: 'desc',
    },
];
