import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import media from 'utils/media';
import { FileContainer } from 'shared';
import FileIcon from '../../shared/static/FileIcon';

const Resource = ({ id, url, link, name, type, displayType, showArchivedItems, linkColor, archived, meta }) => {
    const cardClickTarget = url && link ? { url } : url ? { url } : { link };

    return (
        <>
            {!showArchivedItems && archived ? (
                <></>
            ) : displayType === 'grid' ? (
                <FileContainer
                    {...{ ...cardClickTarget, id, meta }}
                    name={`${name}${type ? `.${type}` : ''}`}
                    style={{ margin: 'auto 0.5em', alignItems: 'center', target: 'blank', cursor: 'pointer' }}
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Container>
                        <Image src={url} />
                        <Text>{name}</Text>
                    </Container>
                </FileContainer>
            ) : (
                <FileContainer
                    {...{ ...cardClickTarget, id, meta }}
                    name={`${name}${type ? `.${type}` : ''}`}
                    style={{
                        color: `${linkColor}`,
                        display: 'flex',
                        marginBottom: '0.5em',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        cursor: 'pointer',
                    }}
                >
                    <FileIcon />
                    <ListViewName>{name}</ListViewName>
                </FileContainer>
            )}
        </>
    );
};

Resource.propTypes = {
    id: PropTypes.string.isRequired,
    /** This is the link */
    url: PropTypes.string.isRequired,
    /** Resource name */
    name: PropTypes.string.isRequired,
    /** Resource file type */
    type: PropTypes.string.isRequired,
    size: PropTypes.number,
    meta: PropTypes.object,
};

Resource.defaultProps = {
    size: 0,
};

export default Resource;

const Image = styled.img`
    top: 5px;
    left: 5px;
    position: relative;
`;

const Container = styled.div`
    height: 150px;
    width: 150px;
    display: inline-block;
    max-width: 150px;
    padding: 10px;
    word-break: break-all;
`;

const Text = styled.p`
    font-weight: 700;
`;

const ListViewName = styled.p`
    max-width: 75%;
    line-height: 1;
`;
