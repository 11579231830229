import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FormLabel, Input } from 'shared';

export const Container = styled.div`
    margin-bottom: 2rem;
    text-align: left;
    margin-top: ${(props) => (props.labelTitle ? null : '-1rem')};
`;

const Error = styled.div`
    color: ${(props) => props.theme.red};
    font-size: 1.2rem;
    margin: 0.2rem 0 0;
`;

const ErrorText = styled.p`
    margin: 0;
`;

const FormInput = ({
    type,
    field,
    value,
    name,
    placeholder,
    required,
    labelTitle,
    hasError,
    errorMessage,
    disabled,
    onChange,
    onBlur,
}) => (
    <Container {...{ labelTitle }}>
        {labelTitle && <FormLabel {...{ required, labelTitle }} />}
        <Input
            {...{ type, placeholder, hasError, disabled, field, required, value, name }}
            onChange={(e) => !!onChange && onChange(e, field)}
            onBlur={(e) => !!onBlur && onBlur(e, field)}
        />
        {hasError && (
            <Error>
                {errorMessage.split('\n').map((i, key) => {
                    return (
                        <ErrorText style={key > 0 ? { 'margin-left': '20px' } : null} key={key}>
                            {i}
                        </ErrorText>
                    );
                })}
            </Error>
        )}
    </Container>
);

FormInput.propTypes = {
    /** This is the type attribute */
    type: PropTypes.string.isRequired,
    /** This is the placeholder attribute */
    placeholder: PropTypes.string,
    /** This is label content */
    labelTitle: PropTypes.string,
    /** This is the required attribute */
    required: PropTypes.bool,
    /** If validation fails, this should be true */
    hasError: PropTypes.bool,
    /** If validation fails, this should have a value */
    errorMessage: PropTypes.string,
    /** This is the disabled attribute */
    disabled: PropTypes.bool,
    field: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
};

FormInput.defaultProps = {
    type: 'text',
};

export default FormInput;
