import styled from 'styled-components';

const FlexColumnLayout = styled.div`
    display: flex;
    flex-flow: row wrap;
`;

const FlexColumn = styled.div`
    display: flex;
    flex: ${(props) => props.grow || 1};
`;

const FlexRowLayout = styled.div`
    display: flex;
    flex-direction: column wrap;
`;

export { FlexColumnLayout, FlexColumn, FlexRowLayout };
