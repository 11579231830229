import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import buttonStyles from './styles/buttonStyles';

export const Container = styled(Link)`
    ${buttonStyles}
`;

const RouterLinkButton = ({ small, secondary, children, to }) => (
    <Container {...{ small, secondary, to }}>{children}</Container>
);
export default RouterLinkButton;
