import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import ViewIcons from './ViewIcons';
import { ActionsBarContainer } from 'filters';
import { PageHeader, Card } from 'shared';
import { LIST_VIEW } from '../constants';
import ActionBanner from './ActionBanner';
import SubmittalWorkflowBanner from './SubmittalWorkflowBanner';
import ProductFilters from './Filters';
import RegionSelectModal from '../../projects/components/Modal/RegionSelectModal';

const FiltersResults = styled.div`
    display: flex;
    position: relative;
`;

const Results = styled.div`
    flex: 0 0 80%;
    position: relative;
`;

const BannerWrapper = styled.div`
    transition: all 0.1s ease-in;
    position: relative;
    .hasTooltip {
        position: relative;
        &:hover {
            .tooltip {
                opacity: 1;
            }
        }
        &.comparison {
            .tooltip {
                top: -5.5rem;
            }
        }
    }
    .tooltip {
        border: 1px solid ${(props) => props.theme.grey3};
        font-weight: 500;
        border-radius: 0.4rem;
        &:before {
            border-right: 1px solid ${(props) => props.theme.grey3};
            border-bottom: 1px solid ${(props) => props.theme.grey3};
            bottom: -0.5rem;
        }
    }
    &.banner-sticky {
        position: sticky;
        left: 0;
        top: 60px;
        right: 0;
        z-index: 100;
        .tooltipTop {
            .tooltip {
                bottom: -4rem;
                top: 3.6rem !important;
                &:before {
                    border-bottom: none;
                    border-right: none;
                    border-left: 1px solid ${(props) => props.theme.grey3};
                    border-top: 1px solid ${(props) => props.theme.grey3};
                    bottom: 0;
                    top: -0.5rem;
                }
            }
            .hasTooltip.comparison .tooltip {
                bottom: -5.5rem;
            }
        }
    }
`;

const noProductsMessage = (string) => (
    <b style={{ marginLeft: '1.2rem' }}>{`Sorry, there are no results matching "${string}"`} </b>
);

const ProductSearch = ({
    children,
    filterProps,
    isSubmittalWorkflow,
    query,
    selectedProducts,
    setView,
    view,
    ...bannerProps
}) => {
    return (
        <React.Fragment>
            <PageHeader title="All Products">
                <ViewIcons {...{ view, setView }} />
            </PageHeader>
            <ActionsBarContainer />
            <FiltersResults>
                <ProductFilters {...filterProps} />
                <Results>
                    <BannerWrapper className="banner">
                        {isSubmittalWorkflow ? (
                            <SubmittalWorkflowBanner {...{ selectedProducts, ...bannerProps }} />
                        ) : selectedProducts && selectedProducts.length > 0 ? (
                            <ActionBanner {...{ selectedProducts, ...bannerProps }} />
                        ) : null}
                    </BannerWrapper>
                    {(query &&
                        children.props.children.props.products.length < 1 &&
                        noProductsMessage(query.queryString)) ||
                        (view !== LIST_VIEW ? children : <Card>{children}</Card>)}
                </Results>
            </FiltersResults>
        </React.Fragment>
    );
};

ProductSearch.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
    view: PropTypes.string,
    setView: PropTypes.func.isRequired,
    filterProps: PropTypes.shape({
        filters: PropTypes.array,
        selected: PropTypes.array,
        updateFilter: PropTypes.func,
    }),
};
ProductSearch.defaultProps = {
    children: undefined,
    view: undefined,
    filterProps: {},
};

export default ProductSearch;
