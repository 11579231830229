import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { config } from 'base-client';

import { ExternalLink } from 'shared';
import { configMap } from 'configurations';

const Agreement = ({ dispatch }) => {
    const terms = dispatch(config.actions.getData(configMap.agreement.name, configMap.agreement.terms.name));
    const privacy = dispatch(config.actions.getData(configMap.agreement.name, configMap.agreement.privacy.name));

    const siteName = dispatch(config.actions.getData(configMap.agreement.name, configMap.agreement.sitename.name));

    return (
        <React.Fragment>
            By creating an account with {siteName}, you are agreeing to accept the{' '}
            <ExternalLink href={terms}>{siteName} terms of service</ExternalLink> and our{' '}
            <ExternalLink href={privacy}>Privacy Policy</ExternalLink>.
        </React.Fragment>
    );
};

Agreement.propTypes = {
    dispatch: PropTypes.func.isRequired,
};

export default connect()(Agreement);
