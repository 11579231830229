import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Field } from 'redux-form';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { required } from 'utils/reduxFormValidator';
import { PageHeader, ReduxFormInput } from 'shared';

const StyledProjectHeader = styled.div`
    display: flex;
    align-items: center;
    padding-bottom: 1.3rem;
    margin: 1rem 0;
    .project-name {
        margin: 0;
        width: 50%;
    }
`;

const StyledCreatedDate = styled.div`
    font-size: 1.2rem;
    color: ${(props) => props.theme.grey3};
    margin-left: auto;
`;

const StyledLink = styled(Link)`
    font-size: 1.2rem;
    position: relative;
    top: 2px;
    color: ${(props) => props.theme.grey3};
    transition: 0.2s color ease-in-out;
    margin-left: ${(props) => (props.marginleft ? props.marginleft : null)};
    &:hover,
    &:focus {
        cursor: pointer;
        text-decoration: underline;
        color: ${(props) => props.theme.grey5};
    }
`;

const ProjectHeader = ({ name, createdDate, isEdit, id }) => {
    return isEdit && id ? (
        <StyledProjectHeader>
            <Field
                type="text"
                name="name"
                className="project-name"
                component={ReduxFormInput}
                placeholder="Project Name"
                validate={[required]}
            />
            <StyledLink to={`/projects/${id}`} marginleft="2rem">
                Back to Project
            </StyledLink>
            <StyledCreatedDate>Created: {moment(createdDate).format('MMM DD, YYYY')}</StyledCreatedDate>
        </StyledProjectHeader>
    ) : (
        <PageHeader title={name}>
            <StyledLink to="/projects">Back to My Projects</StyledLink>
            <StyledCreatedDate>Created: {moment(createdDate).format('MMM DD, YYYY')}</StyledCreatedDate>
        </PageHeader>
    );
};

ProjectHeader.propTypes = {
    name: PropTypes.string.isRequired,
    createdDate: PropTypes.string,
    isEdit: PropTypes.bool,
    id: PropTypes.string,
};

ProjectHeader.defaultProps = {
    isEdit: false,
};

export default ProjectHeader;
