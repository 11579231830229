import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.h2`
  position: relative;
  padding: 1rem 0 0;
  margin: 0;
  text-align: center;
  font-family: ${(props) => props.theme.fontAlt};
  }
`;

const Header = ({ headerText }) => <Container>{headerText}</Container>;

Header.propTypes = {
    headerText: PropTypes.string.isRequired,
};

Header.defaultProps = {
    headerText: 'Title Here',
    hiddenBackLink: true,
};

export default Header;
