import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { login } from 'base-client';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import validator from 'validator';

import { actions as loginActions } from 'login';
import { actions as notificationActions, projectNotification } from 'notification';
import { ButtonText } from 'shared';
import Body from './Body';
import EmailPassword from './EmailPassword';
import FlashMessage from './FlashMessage';
import Header from './Header';
import Login from './Login';
import Logo from './Logo';
import PasswordReset from './PasswordReset';

const SignUp = styled.p`
    font-size: 1.2rem;
    text-align: center;
    button {
        color: ${(props) => props.theme.blue};
    }
`;

const MainContainer = styled.div`
    &.hidden {
        display: none;
    }
    .privacyPolicy {
        a {
            color: ${(props) => props.theme.blue};
        }
    }
`;

const BackButton = styled.button`
  position: absolute;
  top: 1.5rem;
  left: 1.5rem
  padding: 1rem;
  background: white;
  border: none;
  transition: background 0.2s ease;
  cursor: pointer;
  &:before,
  &:after {
    content: "";
    position: absolute;
    left: 0.6rem;
    width: 0.7rem;
    height: 0.2rem;
    background: ${(props) => props.theme.grey3};
  }
  &:before {
    top: 1.1rem;
    transform: rotate(45deg);
  }
  &:after {
    top: 0.8rem;
    transform: rotate(135deg);
  }
  &:hover {
    background: ${(props) => props.theme.grey2};
  }
  &:hover, &:focus, &:visited, &:active {
    outline: none;
  }
  &.hidden{
    display: none;
  }
`;

// These are used to facilitate unit testing
export const LOGIN_TEXTS = {
    email: {
        placeholder: 'Enter your email address',
        required: 'Please enter an email',
        invalid: 'Please enter a valid email address',
    },
    password: {
        placeholder: 'Enter your password',
        required: 'Please enter a password',
    },
    title: 'Log In',
};

class LoginContainer extends Component {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired,
        toSignUp: PropTypes.func.isRequired,
        tenantId: PropTypes.string
    };

    state = {
        email: '',
        password: '',
        emailError: '',
        passwordError: '',
        errorMessage: '',
        successMessage: '',
        viewStage: -1,
    };

    componentWillMount() {
        if (window.location.href.includes('?')) {
            this.setState({ viewStage: 1 });
        }
    }

    componentDidMount() {
        window.addEventListener('keyup', this.enterListener);
    }

    componentWillUnmount() {
        window.removeEventListener('keyup', this.enterListener);
    }

    enterListener = (e) => {
        if (e.key === 'Enter' || e.code === 'Enter') {
            this.handleLogin();
        }
    };

    validateEmail = (val) => {
        if (!val) {
            return this.setState({
                emailError: LOGIN_TEXTS.email.required,
            });
        } else if (!validator.isEmail(val)) {
            return this.setState({
                emailError: LOGIN_TEXTS.email.invalid,
            });
        } else if (this.state.emailError) {
            return this.setState({
                emailError: '',
            });
        }
        return;
    };

    validatePassword = (val) => {
        if (!val) {
            return this.setState({
                passwordError: LOGIN_TEXTS.password.required,
            });
        } else if (val && this.state.passwordError) {
            return this.setState({
                passwordError: '',
            });
        }
        return;
    };

    handleInputChange = (evt, field) => {
        const {
            target: { value },
        } = evt;
        this.setState({
            [field]: value,
        });
        return field === 'email' ? this.validateEmail(value) : this.validatePassword(value);
    };

    handleLogin = async () => {
        const { email, password } = this.state;
        !email && this.validateEmail(email);
        !password && this.validatePassword(password);

        const { emailError, passwordError } = this.state;
        if (email && password && !emailError && !passwordError) {
            const { dispatch, history } = this.props;
            const error = await dispatch(loginActions.userLogin(email, password, history));
            if (error) {
                this.setState({ errorMessage: error.description, successMessage: '' });
            }
        }
    };

    handleResetPasswordLink = async ({ tenantId }) => {
        const { email } = this.state;
        let { hostname, origin } = window.location;
        hostname = hostname.includes('localhost') ? 'http://localhost:8080' : hostname;
        !email && this.validateEmail(email);

        const { emailError } = this.state;
        if (email && !emailError) {
            const { dispatch } = this.props;
            const res = await dispatch(login.actions.changePassword({ hostname, origin, tenantId, username: email })); // base-client

            // notification dispatches are here instead of in the action
            // because we can't assume they would be standardized in base client at the time of this implementation
            if (res.message === 'OK') {
                this.setState({ email: '', emailError: '' });
                dispatch(
                    notificationActions.sendNotifications([
                        {
                            id: `password-reset-${uuid()}`,
                            type: projectNotification.GENERAL,
                            title: 'Password Reset Sent',
                            body: `Your reset link has been emailed to ${email}`,
                        },
                    ])
                );
            } else {
                const errorRes = await res.json();
                dispatch(
                    notificationActions.sendNotifications([
                        {
                            id: `password-reset-${uuid()}`,
                            type: projectNotification.GENERAL,
                            title: 'Password Reset Failed',
                            body: errorRes && errorRes.message ? errorRes.message : 'Your reset link failed to send',
                        },
                    ])
                );
            }
        }
    };

    handleChangePassword = async ({ auth0Id, password, username }) => {
        const { dispatch } = this.props;
        await dispatch(
            loginActions.changePassword({
                auth0Id,
                password,
                username,
            })
        ); // not base-client
    };

    toggleTypeChange = () => {
        const { viewStage } = this.state;
        return this.resetState({ viewStage: viewStage === -1 ? 0 : -1 });
    };

    resetState = ({ viewStage = -1, successMessage = '', errorMessage = '' }) => {
        return this.setState({
            email: '',
            emailError: '',
            errorMessage,
            viewStage,
            password: '',
            passwordError: '',
            successMessage,
        });
    };

    render() {
        const { email, emailError, errorMessage, password, passwordError, successMessage, viewStage } = this.state;

        const { toSignUp, logoText, logoImageUrl, tenantId } = this.props;
        const { handleInputChange, handleLogin, handleResetPasswordLink, handleChangePassword, toggleTypeChange } =
            this;

        const content = (
            <Fragment>
                <Logo {...{ logoText, logoImageUrl }} />
                <Header headerText={viewStage === -1 ? LOGIN_TEXTS.title : 'Reset Password'} />
                {viewStage === -1 && (
                    <SignUp tenantId={tenantId}>
                        Don't have an account? <ButtonText onClick={toSignUp}>Create an account</ButtonText>
                    </SignUp>
                )}
                {viewStage === 0 && <BackButton type="button" onClick={this.toggleTypeChange} />}
                <FlashMessage
                    messageText={successMessage ? successMessage : errorMessage ? errorMessage : ''}
                    hasError={!!errorMessage}
                    isDisplay={!!successMessage || !!errorMessage}
                />
                <Body>
                    <MainContainer className={viewStage === -1 ? 'login' : 'hidden'}>
                        <Login
                            {...{
                                email,
                                emailError,
                                errorMessage,
                                handleInputChange,
                                handleLogin,
                                password,
                                passwordError,
                                toggleTypeChange,
                            }}
                        />
                    </MainContainer>
                    <MainContainer className={viewStage === 0 ? 'reset-password' : 'hidden'}>
                        <EmailPassword
                            {...{
                                email,
                                emailError,
                                handleInputChange,
                                handleResetPasswordLink,
                            }}
                        />
                    </MainContainer>
                    <MainContainer className={viewStage < 1 && 'hidden'}>
                        <PasswordReset {...{ handleChangePassword }} />
                    </MainContainer>
                </Body>
            </Fragment>
        );
        return content;
    }
}

export default withRouter(connect()(LoginContainer));
