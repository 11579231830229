import React, { useState, useEffect } from 'react';
import { Field } from 'redux-form';

import states from 'utils/staticData/states';
import countries from 'utils/staticData/countries';
import { required } from 'utils/reduxFormValidator';
import { connect } from 'react-redux';

import { Row, Column } from 'shared';

import {
    ReduxFormInput,
    ReduxFormDropdown,
    FormDropdownOption,
    ReduxFormTextArea,
    UploadButton,
    FormLabel,
} from 'shared';

import styled from 'styled-components';

const CompanyLogoSection = styled.div`
    margin-bottom: 1.5rem;
    text-align: left;
    display: flex;
`;

const CompanyLogoButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const CompanyLogo = styled.img`
    max-height: 86px;
    max-width: 200px;
    margin: auto 0px auto 18px;
`;

export const CompanyLogoFileName = styled.div`
    font-weight: 500;
    font-size: 1.4rem;
    height: 100%;
    margin: auto 18px auto 0px;
`;

const TabProjectEdit = ({
    form,
    pristine,
    valid,
    submitting,
    onClick,
    address,
    estimated_ship_date,
    id,
    company_logo,
    setSelectedCompanyLogo,
    notes,
    dispatch,
    ...props
}) => {
    const [companyLogoUrl, setCompanyLogoUrl] = useState(company_logo || null);
    const [_country, setCountry] = useState(0);

    useEffect(() => {
        setCompanyLogoUrl(company_logo);
    }, [company_logo]);

    const handleImportCompanyLogo = (file) => {
        if (file[0]) {
            setSelectedCompanyLogo(file[0]);
            setCompanyLogoUrl(URL.createObjectURL(file[0]));
        }
    };

    return (
        <React.Fragment>
            <Row>
                <Column>
                    <Field
                        name="address.address_line_1"
                        type="text"
                        component={ReduxFormInput}
                        label="address_line_1"
                        labelTitle="Address Line 1"
                    />
                </Column>
            </Row>
            <Row>
                <Column>
                    <Field
                        name="address.address_line_2"
                        type="text"
                        component={ReduxFormInput}
                        label="address_line_2"
                        labelTitle="Address Line 2"
                    />
                </Column>
            </Row>
            <Row>
                <Column>
                    <Field
                        name="project_number"
                        type="text"
                        component={ReduxFormInput}
                        label="project_number"
                        labelTitle="Project Number"
                    />
                </Column>
            </Row>
            <Row>
                <Column>
                    <Field name="address.city" type="text" component={ReduxFormInput} label="city" labelTitle="City" />
                </Column>
                <Column>
                    <Field
                        name={'address.country'}
                        type="text"
                        component={ReduxFormDropdown}
                        labelTitle="Country"
                        onChange={(event) => setCountry(event.target.value)}
                    >
                        <FormDropdownOption value={''} optionLabel={'Select a Country'} disabled />
                        {countries.map((item) => (
                            <FormDropdownOption key={item.name} value={item.name} optionLabel={item.name} />
                        ))}
                    </Field>
                </Column>
            </Row>
            <Row>
                <Column>
                    <Field
                        name="address.zipcode"
                        type="text"
                        component={ReduxFormInput}
                        label="zipcode"
                        labelTitle="Zip Code/Area Code"
                        validate={[required]}
                        required
                    />
                </Column>
                <Column>
                    {_country === 'United States' ? (
                        <Field name={'address.state'} type="text" component={ReduxFormDropdown} labelTitle="State">
                            <FormDropdownOption value={''} optionLabel={'Select a State'} disabled />
                            {states.map((item) => (
                                <FormDropdownOption
                                    key={item.abbreviation}
                                    value={item.abbreviation}
                                    optionLabel={item.abbreviation}
                                />
                            ))}
                        </Field>
                    ) : (
                        <Field
                            name={'address.state'}
                            type="text"
                            component={ReduxFormInput}
                            labelTitle="State/Province/Regions"
                        />
                    )}
                </Column>
            </Row>
            <Row>
                <Column mdUp={6}>
                    <Field
                        name="estimated_ship_date"
                        type="date"
                        component={ReduxFormInput}
                        label="estimated_ship_date"
                        labelTitle="Estimated Ship Date"
                    />
                </Column>
                <Column mdUp={6}>
                    {window.location.href.includes('brochure') && (
                        <CompanyLogoSection>
                            <CompanyLogoButtonContainer>
                                <FormLabel {...{ labelTitle: 'Company Logo' }} />
                                <UploadButton buttonText="Upload logo" onUpload={handleImportCompanyLogo} />
                            </CompanyLogoButtonContainer>
                            {companyLogoUrl && <CompanyLogo src={companyLogoUrl} alt="Company Logo" />}
                        </CompanyLogoSection>
                    )}
                </Column>
            </Row>
            <Row>
                <Column>
                    <Field
                        name="notes"
                        type="text"
                        component={ReduxFormTextArea}
                        label="notes"
                        labelTitle="Project Notes"
                        rows={4}
                        resize="vertical"
                    />
                </Column>
            </Row>
        </React.Fragment>
    );
};

export default connect()(TabProjectEdit);
