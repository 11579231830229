import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const Container = styled.div`
    background: ${(props) => (props.hasError ? props.theme.red : props.theme.green)};
    padding: 1.5rem 4rem;
    color: white;
    text-align: center;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.3;
    margin: 2rem -2rem;
    &.hidden {
        display: none;
    }
`;

const FlashMessage = ({ messageText, hasError, isDisplay }) => (
    <Container {...{ hasError }} className={isDisplay ? '' : 'hidden'}>
        {messageText}
    </Container>
);

FlashMessage.propTypes = {
    messageText: PropTypes.string.isRequired,
    hasError: PropTypes.bool,
    isDisplay: PropTypes.bool,
};

FlashMessage.defaultProps = {
    messageText: 'Error',
    hasError: false,
    isDisplay: true,
};

export default FlashMessage;
