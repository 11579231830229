import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { login, history, reducerUtil } from 'base-client';
import { Modal, LinkButton, ButtonText } from 'shared';
import { reducerData as guestData } from 'guest';
import { actions as projectActions } from 'projects';
import occupations from '../../../utils/staticData/occupations';

const ModalContent = styled.div`
    min-width: 50rem;
    font-size: 1.4rem;
`;

const CardContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const CardSection = styled.div`
    display: flex;
    flex-direction: column;
`;

const Seperator = styled.div`
    padding: 5px 0px 5px 30px;
    margin-bottom: 15px;
    margin-right: 7px;
    border-right: 2px solid ${(props) => props.theme.grey1};
`;

const Select = styled.select`
    margin-bottom: 16px;
    padding: 5px;
    background-color: transparent;
    border-radius: 3px;
    border-color: ${(props) => props.theme.grey1};
    cursor: pointer;
`;

const SubTitle = styled.div`
    font-weight: bold;
    font-size: 14px;
    line-height: 23px;
    color: ${(props) => props.theme.primaryColor};
`;

const Body = styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    margin-bottom: 13px;
`;

const LoginButton = styled(LinkButton)`
    min-width: 80px;
    background-color: ${(props) => props.theme.primaryColor};
`;

const SignUp = styled.p`
    font-size: 1.2rem;
    color: ${(props) => props.theme.blueDarkest};
    button {
        color: ${(props) => props.theme.blueDarkest};
    }
`;

const GuestLoginModal = ({ title, open, onClose, dispatch, loginCb }) => {
    const [modalOpen, setModalOpen] = useState(open);
    const [occupation, setOccupation] = useState('');

    // useEffect(() => {
    //   const storedGuestState = window.localStorage.getItem('guest');
    //   if (storedGuestState) {
    //     const parsedState = JSON.parse(storedGuestState);
    //     Object.entries(parsedState).forEach(([key, val]) => {
    //       dispatch(reducerUtil.setSlice(guestData, guestData[key], val));
    //     });
    //   }
    // }, [])

    let modalProps = {
        title: title,
        open: modalOpen,
        onClose: () => defaultClose(),
    };

    if (onClose) {
        modalProps.onClose = onClose;
    }

    const defaultClose = async () => {
        await dispatch(projectActions.modalClose());
        setModalOpen(false);
        history.push('/');
    };

    return (
        <Modal {...modalProps}>
            <ModalContent>
                <CardContainer>
                    <CardSection>
                        <SubTitle>Login/Sign Up</SubTitle>
                        <Body>{'Login to save projects for later use.'}</Body>
                        <LoginButton small onClick={() => dispatch(login.actions.toLogin())}>
                            Login &gt;
                        </LoginButton>
                        <SignUp>
                            Don't have an account?{' '}
                            <ButtonText onClick={() => history.push('/login', { signUp: true })}>
                                Create an account here
                            </ButtonText>
                        </SignUp>
                    </CardSection>
                    <Seperator />
                    <CardSection>
                        <SubTitle>Guest Login</SubTitle>
                        <Select
                            value={occupation}
                            onChange={(e) => {
                                setOccupation(e.target.value);
                            }}
                        >
                            <option value="" disabled defaultValue>
                                Select Occupation
                            </option>
                            {occupations.map((job, idx) => (
                                <option value={job} key={idx}>
                                    {job}
                                </option>
                            ))}
                        </Select>
                        <LoginButton
                            style={occupation === '' ? { cursor: 'not-allowed', opacity: '0.5' } : {}}
                            small
                            onClick={() => {
                                loginCb(occupation);
                            }}
                        >
                            Continue as a Guest &gt;
                        </LoginButton>
                    </CardSection>
                </CardContainer>
            </ModalContent>
        </Modal>
    );
};

const mapStateToProps = (state) => ({
    theme: null, //reducerUtil.getSlice(tenantData, tenantData.theme, state),
    state: state,
});

export default connect(mapStateToProps)(GuestLoginModal);
