/* eslint-disable indent */
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { reducerUtil } from 'base-client';

import { Row, Column } from 'shared';

import SideDetails from './SideDetails';
import { ProjectDetailsTabCard } from 'projectDetails';
import SubmittalDocuments from './SubmittalDocuments';
import SubmittalOptions from './SubmittalOptions';
import { actions as proposalActions, reducerData as proposalData, getDocumentTypesByMime } from 'proposal';
import { actions as guestActions, reducerData as guestData } from 'guest';

const StyledColumn = styled(Column)`
    overflow: hidden !important;
`;

const StyledRow = styled(Row)`
    &.documents-review {
        margin-top: 2rem;
    }
    .project-detail-card {
        width: 100%;
    }
`;

const ProposalReview = ({ dispatch, generalData, guestOccupation, guestProducts = [], products = [], setStep }) => {
    if (!generalData) return null;

    const infiniteListProps = {
        list: [...(guestOccupation ? guestProducts : products)],
        hasNextPage: false,
        fetching: false,
        getNextPage: () => null,
        loadMoreRows: () => null,
        isRowLoaded: ({ index }) => true,
    };

    const filteredGuestProducts = [];
    if (guestOccupation) {
        JSON.parse(JSON.stringify(guestProducts)).forEach((product) => {
            if (product.selected === undefined || product.selected.length === 0) {
                return;
            }

            const filteredAssets = [];
            product.documents.forEach((document) => {
                document.files.forEach((file) => {
                    if (product.selected.includes(file.id)) {
                        filteredAssets.push(file);
                    }
                });
            });

            if (filteredAssets.length > 0) {
                filteredGuestProducts.push({
                    ...product,
                    ...getDocumentTypesByMime(filteredAssets),
                });
            }
        });
    }

    const documentCardProps = {
        products: guestOccupation ? filteredGuestProducts : products,
        projectId: generalData.id,
        canSelect: false,
        handleCollapse: (id) =>
            guestOccupation
                ? dispatch(guestActions.handleCollapse(id))
                : dispatch(proposalActions.collapseSelectedProducts(id)),
    };

    return (
        <React.Fragment>
            <StyledRow>
                <StyledColumn mdUp={4} className="left-content">
                    <SideDetails
                        title="Project Review"
                        description="Review the project details and files before creating the Submittal package. Package will include a zip of the Submittal pdf along with all of the selected files organized into product folders."
                        buttons={[
                            {
                                buttonText: 'Edit Project Details',
                                onClick: () => setStep('project-edit'),
                            },
                        ]}
                    >
                        <SubmittalOptions {...{ guestOccupation }} />
                    </SideDetails>
                </StyledColumn>
                <Column mdUp={8} className="right-content">
                    <ProjectDetailsTabCard {...generalData} />
                </Column>
            </StyledRow>
            <br />
            <StyledRow className="documents-review">
                <Column mdUp={4} className="left-content">
                    <SideDetails
                        title="Document and File Review"
                        description="Review the documents and files before creating the Submittal package. Package will include a zip of the Submittal pdf along with all of the selected files organized into product folders."
                        buttons={[
                            {
                                buttonText: 'Select Documents',
                                onClick: () => {
                                    window.scrollTo({
                                        top: 0,
                                    });
                                    setStep('select-proposal-documents');
                                },
                            },
                        ]}
                    />
                    <SideDetails
                        style={{ margin: '40px 3rem auto 0px' }}
                        description="Are you still missing a few products?"
                        buttons={[
                            {
                                buttonText: 'Add More Products',
                                onClick: () => {
                                    window.scrollTo({
                                        top: 0,
                                    });
                                    setStep('product-search');
                                },
                            },
                        ]}
                    ></SideDetails>
                </Column>
                <Column mdUp={8} className="right-content">
                    <SubmittalDocuments {...{ infiniteListProps }} {...documentCardProps} />
                </Column>
            </StyledRow>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    products: reducerUtil.getSlice(proposalData, proposalData.selectedProducts, state),
    guestProducts: reducerUtil.getSlice(guestData, guestData.products, state),
});

export default connect(mapStateToProps)(ProposalReview);
