import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { reducerUtil } from 'base-client';
import { reducerData as guestData } from 'guest';

const Bold = styled.span`
    font-weight: bold;
    font-size: 16px;
    color: ${(props) => props.theme.grey5};
`;

const StyledNotification = styled.div`
    padding: 15px 34px 15px 20px;
`;

const EmailProposalCard = ({ guestSubmittalOptions, guestOccupation, userSubmittalOptions }) => (
    <StyledNotification>
        <div className="notification-card-title">
            <Bold>We are preparing your files...</Bold>
        </div>
        <div className="notification-card-content">{`Your download will be emailed to ${
            guestOccupation !== undefined
                ? guestSubmittalOptions.targetEmail
                : userSubmittalOptions && userSubmittalOptions.targetEmail
        } shortly. You will be redirected shortly.`}</div>
    </StyledNotification>
);

const mapStateToProps = (state) => ({
    guestOccupation: reducerUtil.getSlice(guestData, guestData.guestOccupation, state),
    guestSubmittalOptions: reducerUtil.getSlice(guestData, guestData.submittalOptions, state),
    userSubmittalOptions: state.projectDetails.submittalOptions, // reducerUtil throws error if key doesn't exist yet
});

export default connect(mapStateToProps)(EmailProposalCard);
