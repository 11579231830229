import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { ButtonText } from 'shared';
import Filter from './Filter';

const StyledWrapper = styled.div`
    transition: all 0.3s ease;
    min-height: 4rem;
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
    border-bottom: 1px solid ${(props) => props.theme.grey4};
    &._expand {
        .btnToggle {
            &:after {
                transform: rotate(135deg);
            }
        }
        .filterList {
            display: block;
        }
    }
    .filterList {
        display: none;
        padding-left: 1rem;
        transition: display 0.3s ease;
    }
`;

const Header = styled.h6`
    letter-spacing: 0.3px;
    margin: 0;
    font-size: 1.4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 4rem;
    color: ${(props) => props.theme.grey4};
`;
const ToggleIcon = styled(ButtonText)`
    padding: 0 1.5rem 0.75rem;
    margin-right: 1rem;
    transition: color 0.5s ease;
    cursor: pointer;
    position: relative;
    font-size: 1.4rem;
    outline: none;
    &:after {
        content: '';
        display: inline-block;
        width: 6px;
        height: 6px;
        position: relative;
        top: -2px;
        right: -5px;
        border: none;
        border-right: 2px solid ${(props) => props.theme.grey4};
        border-top: 2px solid ${(props) => props.theme.grey4};
        transform: rotate(45deg);
    }
`;
const FilterList = styled.div`
    width: 100%;
    .filter:first-child {
        margin-top: 0;
    }
`;

const FilterGroup = ({ groupName, filters = [], updateFilter }) => {
    const [expand, setExpand] = useState(false);
    return (
        <StyledWrapper className={`group ${expand ? '_expand' : '_collapse'}`}>
            <Header>
                {groupName}
                <ToggleIcon className="btnToggle" onClick={() => setExpand(!expand)}></ToggleIcon>
            </Header>
            <FilterList className="filterList">
                {filters.map((data, indx) => (
                    <Filter key={indx} {...data} {...{ updateFilter }} />
                ))}
            </FilterList>
        </StyledWrapper>
    );
};

export default FilterGroup;
