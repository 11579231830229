import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import buttonStyles from './styles/buttonStyles';

export const Container = styled.button`
    ${buttonStyles} {
        background: ${(props) => (props.theme.primaryColor ? props.theme.primaryColor : props.theme.blue)};
    }
`;

const LinkButton = ({ children, onClick, disabled, className, ...props }) => {
    const submitClick = (event) => {
        event.preventDefault();
        onClick(event);
    };

    return (
        <Container
            {...{ disabled }}
            {...props}
            className={disabled ? className + ' not-active' : className}
            onClick={submitClick}
        >
            {children}
        </Container>
    );
};

LinkButton.propTypes = {
    /** This is the button text content */
    children: PropTypes.any,
    /** This makes the button smaller */
    small: PropTypes.bool,
    /** This makes the button less prominent visually */
    secondary: PropTypes.bool,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    href: PropTypes.string,
    marginBottom: PropTypes.string,
    marginTop: PropTypes.string,
};

export default LinkButton;
