import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'shared';
import { actions as projectActions } from 'projects';
import { history } from 'base-client';

const ModalContent = styled.div`
    min-width: 50rem;
    font-size: 1.4rem;
    padding-bottom: 0.5em;
`;

const SectionTitle = styled.div`
    min-width: 50rem;
    font-size: 1.4rem;
    padding-bottom: 0.5em;
`;

const Thumbnail = styled.img`
    width: 50px;
    height: 40px;
    padding: 5px;
`;

const ProductName = styled.div`
    padding: 5px;
`;

const ProductListItem = styled.div`
    display: flex;
    padding: 5px;
`;

const LinkContainer = styled.div`
    padding: 5px 5px 5px 10px;
`;

const ModalFooter = styled.div`
    display: flex;
`;

const RemoveButton = styled.button`
    color: white;
    background-color: #d95a5a;
    border-radius: 50% 50%;
    border: 0;
    width: 20px;
    height: 20px;
    margin-left: auto;
    cursor: pointer;
`;

const UndoContainer = styled.div`
    display: flex;
    flex-direction: row-reverse;
    padding-bottom: 20px;
`;

export const AddProductsModal = ({
    modalProps,
    handleSubmit,
    handleAddProducts,
    addTemplateProducts,
    goToSubmittal,
    goToBrochure,
    isBrochure,
    resetProducts,
    removeProduct,
    tempProducts,
    undo,
    dispatch,
}) => {
    const currentPath = window.location.pathname;

    const openProjectModal = async () => {
        await dispatch(projectActions.modalClose());
        await dispatch(projectActions.openModal(true));
    };

    const goToProducts = async () => {
        await dispatch(projectActions.modalClose());
        await addTemplateProducts();
        let success = await handleAddProducts();
        if (isBrochure) {
            success && (await goToBrochure('product-search'));
        } else {
            success && (await goToSubmittal('product-search'));
        }
    };

    return (
        <Modal {...modalProps}>
            <ModalContent>
                <SectionTitle>
                    Now that you have selected a template, you can either
                    <br /> <b>{`Configure ${isBrochure ? 'Brochure' : 'Submittal'}`}</b> with the current products
                    listed below or
                    <br />
                    <b>Add More Products</b> to match your specific needs.
                    <hr />
                </SectionTitle>
                <ModalContent>
                    {(tempProducts && (
                        <SectionTitle>
                            <b>{tempProducts.length} Product(s) in your Project</b>
                            <br />
                            {undo && (
                                <UndoContainer>
                                    <Button small noUpperCase type="submit" onClick={resetProducts}>
                                        Undo
                                    </Button>
                                </UndoContainer>
                            )}
                            {tempProducts.map((product, i) => {
                                return (
                                    <ProductListItem key={i}>
                                        <Thumbnail src={product.thumbnail}></Thumbnail>
                                        <ProductName>{product.name}</ProductName>
                                        <RemoveButton
                                            id={i}
                                            value={i}
                                            onClick={() => {
                                                removeProduct(i);
                                            }}
                                        >
                                            <b>x</b>
                                        </RemoveButton>
                                    </ProductListItem>
                                );
                            })}
                        </SectionTitle>
                    )) || <SectionTitle>No products to display</SectionTitle>}
                    <ModalFooter>
                        <Button small noUpperCase rightArrow type="submit" onClick={() => handleSubmit()}>
                            {`Configure ${isBrochure ? 'Brochure' : 'Submittal'}`}
                        </Button>
                        <LinkContainer>
                            <Link
                                to={{
                                    pathname: currentPath || '/',
                                    state: { modalState: false },
                                }}
                                onClick={() => goToProducts()}
                            >
                                Add More Products
                            </Link>
                        </LinkContainer>
                        {/* <LinkContainer>
              <Link to="/" onClick={() => openProjectModal()}>
                Go Back
              </Link>
            </LinkContainer> */}
                    </ModalFooter>
                </ModalContent>
            </ModalContent>
        </Modal>
    );
};
