import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FormLabel } from 'shared';

const Container = styled.div`
    text-align: left;
`;

const DropdownWrapper = styled.div`
    position: relative;
    &:after {
        content: '';
        width: 6px;
        height: 6px;
        position: absolute;
        top: 0.9rem;
        right: 1rem;
        border: none;
        border-right: 2px solid ${(props) => props.theme.grey3};
        border-top: 2px solid ${(props) => props.theme.grey3};
        transform: rotate(135deg);
        pointer-events: none;
    }
`;

const Error = styled.p`
    color: ${(props) => props.theme.red};
    font-size: 1.2rem;
    margin: 0.2rem 0 0;
`;

export const Dropdown = styled.select`
    font-family: ${(props) => props.theme.fontBody};
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 1.2;
    color: ${(props) => props.theme.grey3};
    appearance: none;
    width: 100%;
    border-radius: 2px;
    background: white;
    border: 1px solid ${(props) => (props.noBorder ? 'transparent' : props.theme.grey2)};
    height: 3rem;
    padding: 0.5rem 3rem 0.5rem 0.8rem;
    &:focus {
        outline: none;
        border-color: ${(props) => props.theme.accentColor};
        box-shadow: 0 0 3px rgba(10, 123, 228, 0.5);
    }
    &:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 #000;
    }
`;

const FormDropdown = ({
    children,
    required,
    labelTitle,
    onChange,
    onBlur,
    field,
    hasError,
    errorMessage,
    value,
    ...props
}) => (
    <Container>
        {labelTitle && <FormLabel {...{ required, labelTitle }} />}
        <DropdownWrapper>
            <Dropdown
                {...props}
                value={value}
                onChange={(e) => !!onChange && onChange(e, field)}
                onBlur={(e) => !!onBlur && onBlur(e, field)}
            >
                {children}
            </Dropdown>
        </DropdownWrapper>
        {hasError && <Error>{errorMessage}</Error>}
    </Container>
);

FormDropdown.propTypes = {
    /** This is the options in the select */
    children: PropTypes.any,
    /** This is the required attribute */
    required: PropTypes.bool,
    /** This is the label */
    labelTitle: PropTypes.string,
    /** Callback function for whenever a change */
    onChange: PropTypes.func,
    /** Set to true for a borderless dropdown */
    noBorder: PropTypes.bool,
    /** If validation fails, this should be true */
    hasError: PropTypes.bool,
    /** If validation fails, this should have a value */
    errorMessage: PropTypes.string,
    onBlur: PropTypes.func,
    field: PropTypes.string,
    value: PropTypes.string,
};

FormDropdown.defaultProps = {
    required: false,
    noBorder: false,
};

export default FormDropdown;
