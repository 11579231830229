import React from 'react';
import styled from 'styled-components';

import { DisplayLink } from 'shared';
import { emptySymbol } from '../utils';
import { truncateNumber, ATTRIBUTE_LINKS } from 'productDetails';

const Cell = styled.div`
    width: 100%;
    flex-grow: 1;
    overflow: hidden;
    word-break: break-word;
`;

const CellLink = styled(DisplayLink)`
    color: ${(props) => props.theme.linkColor || props.theme.blue};
`;

const CellDisplay = ({ value, index, type }) => {
    const getCellElm = () => {
        if (value === emptySymbol) return value;
        if (type === 'assetselection') {
            return <CellLink url={value}>Click Here To Download</CellLink>;
        }

        if (ATTRIBUTE_LINKS.includes(type) && value) return <CellLink url={value}>{value}</CellLink>;

        if (type === 'checkbox' && typeof value === 'boolean') {
            return value ? 'Yes' : 'No';
        }

        if (type === 'number') {
            return truncateNumber(value);
        }

        return value;
    };

    return (
        <Cell className="table-cell" key={`${value}-${index}`}>
            {getCellElm()}
        </Cell>
    );
};

export default CellDisplay;
