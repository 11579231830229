import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import Header from 'header';
import FooterContainer from './FooterContainer';
import ErrorHandling from 'errors';

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`;

const DashboardBody = styled.div`
    padding: 1.5rem;
    width: 100%;
    max-width: 128rem;
    margin: 9.5rem auto 0;
    flex: 1;
`;

class Dashboard extends Component {
    state = {
        hiddenMenu: false,
    };

    componentDidMount() {
        window.addEventListener('scroll', this.handleScrolling);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScrolling);
    }

    handleScrolling = () => {
        const scrollOffsetY = window.scrollY;
        if (scrollOffsetY <= 50) {
            return this.setState({ hiddenMenu: false });
        }
        return this.setState({
            hiddenMenu: true,
        });
    };

    render() {
        const { children } = this.props;
        const { hiddenMenu } = this.state;
        return (
            <Container>
                <Header className={hiddenMenu ? 'hiddenMenu' : ''} />
                <DashboardBody className="app-body">
                    <ErrorHandling>{children}</ErrorHandling>
                </DashboardBody>
                <FooterContainer />
            </Container>
        );
    }
}

Dashboard.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
};

export default withRouter(Dashboard);
