import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: ${(props) => (props.justifyContent ? props.justifyContent : null)};
    align-items: ${(props) => (props.alignItems ? props.alignItems : null)};
    margin: 0 -10px;
`;

const Row = ({ className, children, justifyContent, alignItems, tight }) => (
    <Wrapper {...{ className, justifyContent, alignItems, tight }}>{children}</Wrapper>
);

export default Row;
