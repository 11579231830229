import React, { Component } from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';

import DetailsLink from './DetailsLink';
import { truncateNumber, ATTRIBUTE_LINKS } from '../utils';

const StyledAttributeDisplay = styled.div`
    height: 100%;
    tr {
        &:last-of-type > td {
            border-bottom: 0;
        }
    }
    td {
        padding: 0.9rem 5rem 1.1rem 0;
        border-bottom: 1px solid ${(props) => props.theme.grey1};
        &:last-of-type {
            padding-right: 2rem;
        }
    }
    a {
        color: ${(props) => (props.theme.linkColor ? props.theme.linkColor : props.theme.blue)};
    }
`;

const Property = styled.td`
    font-weight: 600;
    width: fit-content;
    min-width: 200px;
    margin-right: 20px;
    color: ${(props) => props.theme.grey5};
`;

const Value = styled.td`
    height: 100%;
`;

class AttributeDisplay extends Component {
    static propTypes = {
        data: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            })
        ).isRequired,
    };

    getCellElm = (data) => {
        const { type, value } = data;
        if (ATTRIBUTE_LINKS.includes(type)) return <DetailsLink data={data} />;
        if (type === 'number') return truncateNumber(value);
        if (type === 'checkbox' && typeof value === 'boolean') {
            return value ? 'Yes' : 'No';
        }
        return value;
    };

    render() {
        const { data, labelOnly } = this.props;
        return (
            <StyledAttributeDisplay>
                <table cellSpacing={0}>
                    <tbody>
                        {data.map((o, idx) => (
                            <tr key={idx}>
                                <Property key={idx}>{o.label}</Property>
                                {!labelOnly && <Value className="dont-break-out">{this.getCellElm(o)}</Value>}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </StyledAttributeDisplay>
        );
    }
}

export default AttributeDisplay;
