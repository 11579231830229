import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import AttributeDisplay from './AttributeDisplay';

const StyledWrapper = styled.div`
    table {
        width: 100%;
        td {
            font-weight: normal;
        }
    }
`;

const Title = styled.h6`
    margin: 0;
    font-size: 1.4rem;
    padding-bottom: 0.5rem;
`;

const Desc = styled.p`
    margin: 0 0 1rem 0;
`;

const RatingSystems = ({ data = [] }) => (
    <StyledWrapper>
        <Title>Rating Systems</Title>
        <Desc>This product may qualify for the following rating systems:</Desc>
        <AttributeDisplay {...{ data }} labelOnly />
    </StyledWrapper>
);

RatingSystems.propTypes = {
    data: PropTypes.arrayOf({
        id: PropTypes.string,
        label: PropTypes.string,
        value: PropTypes.string,
        type: PropTypes.string,
    }),
};

export default RatingSystems;
