export default [
    'Education',
    'Healthcare',
    'Workplace/Office',
    'Retail',
    'Hospitality',
    'Multi-family',
    'Government',
    'Other',
    'Non-Profit',
];
