import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { reducerUtil } from 'base-client';

import { FormDropdown, FormDropdownOption } from 'shared';
import { reducerData as tenantData } from 'tenant';
import { actions as searchActions, reducerData as searchData } from 'productSearch';
import { querySort } from 'productSearch/actions/search';

const Container = styled.div`
    display: flex;
    align-items: baseline;
`;

const Label = styled.div`
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.2rem;
    letter-spacing: 0.3px;
    color: #77899e;
    padding-right: 1rem;
`;

class SortContainer extends React.Component {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        queryParams: PropTypes.shape({
            sortBy: PropTypes.string,
            sortDir: PropTypes.string,
        }),
        sortList: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                key: PropTypes.string,
                sortBy: PropTypes.string,
                sortDir: PropTypes.string,
            })
        ),
    };

    static defaultProps = {
        queryParams: {},
        sortList: [],
    };

    handleChange = (event) => {
        const { dispatch } = this.props;
        const {
            target: { value },
        } = event;
        dispatch(searchActions.setSort(value));
    };

    render() {
        const { queryParams, sortList } = this.props;
        const { sortBy, sortDir } = queryParams;
        const sortType = sortList.find((item) => item.sortBy === sortBy && item.sortDir === sortDir) || sortList[0];
        return (
            <Container>
                <Label>Sort By</Label>
                <FormDropdown value={sortType.name} onChange={this.handleChange}>
                    {sortList.map(({ name }) => (
                        <FormDropdownOption key={name} value={name} optionLabel={name} />
                    ))}
                </FormDropdown>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    const newList = reducerUtil.getSlice(tenantData, tenantData.sortList, state) || [];
    const sortList = newList.concat(querySort);
    return {
        queryParams: reducerUtil.getSlice(searchData, searchData.query, state),
        sortList,
    };
};

export default connect(mapStateToProps)(SortContainer);
