export default {
    name: 'tenant',
    searchIndex: 'searchIndex',
    tenant: 'tenant',
    theme: 'theme',
    header: 'header',
    footer: 'footer',
    callouts: 'callouts',
    assets: 'assets',
    features: 'features',
    favicon: 'favicon',
    titleTag: 'titleTag',
    sortList: 'sortList',
    requireLogin: 'requireLogin',
    hideLogin: 'hideLogin',
    landingPage: 'landingPage',
    scripts: 'scripts',
    showContact: 'showContact',
    showRequest: 'showRequest',
    defaultFilter: 'defaultFilter',
    productSections: 'productSections',
    projectTemplates: 'projectTemplates',
    compareProduct: 'compareProduct',
    projectSubmittalSettings: 'projectSubmittalSettings',
    font: 'font',
    contactTitle: 'contactTitle',
    requestTitle: 'requestTitle',
    showCookieBanner: 'showCookieBanner',
    showViewAllAssetsLink: 'showViewAllAssetsLink',
    showDownloadAllFilesLink: 'showDownloadAllFilesLink',
    showSustainability: 'showSustainability',
    customPages: 'customPages',
    assetGroups: 'assetGroups',
    hasPrice: 'hasPrice'
};
