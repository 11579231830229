import styled from 'styled-components';

const CloseIcon = styled.div`
    position: absolute;
    width: ${(props) => (props.width ? props.width : 20)}px;
    height: ${(props) => (props.height ? props.height : 20)}px;
    right: ${(props) => (props.right ? props.right : 2)}rem;
    top: ${(props) => (props.top ? props.top : 2)}rem;
    cursor: pointer;
    &:before,
    &:after {
        content: '';
        width: 0.2rem;
        height: ${(props) => (props.height ? props.height : 20)}px;
        right: 1rem;
        background: ${(props) => props.theme.grey2};
        position: absolute;
        transition: background 0.2s ease-in-out;
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
    &:hover {
        &:before,
        &:after {
            background: ${(props) => props.theme.grey4};
        }
    }
`;

export default CloseIcon;
