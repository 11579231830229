const getAttributeValue = ({ id, name, list, system }) => {
    const attribute =
        (id && list.find(({ attributeId }) => attributeId === id)) ||
        (name && list.find(({ attributeName }) => attributeName === name)) ||
        {};

    return attribute.attributeValue || system[name];
};

export default getAttributeValue;
