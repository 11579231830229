import React from 'react';
import PropTypes from 'prop-types';

import GridView from './GridView';
import GridCard from './GridCard';
import { GRID_COLUMN_WIDTH, GRID_ROW_HEIGHT } from '../constants';

class GridViewContainer extends React.Component {
    static propTypes = {
        onRowsRendered: PropTypes.func,
        width: PropTypes.number,
        products: PropTypes.arrayOf(
            PropTypes.shape({
                productKey: PropTypes.string,
                name: PropTypes.string,
                manufacturer: PropTypes.string,
                category: PropTypes.string,
                subcategory: PropTypes.string,
                thumbnail: PropTypes.string,
            })
        ),
        enableCheckbox: PropTypes.bool,
        toggleSelectProduct: PropTypes.func,
    };

    static defaultProps = {
        width: 0,
        products: [],
    };

    state = {};

    /** Convert the array into a matrix of rows and columns for react-virtualized */
    static getDerivedStateFromProps(props) {
        const { width, products } = props;
        const length = products ? products.length : 0;
        const columnCount = Math.max(Math.floor(width / GRID_COLUMN_WIDTH), 1);
        const columnWidth = Math.floor(width / columnCount);
        const rowCount = Math.ceil(length / columnCount);
        const rowHeight = Math.round((GRID_ROW_HEIGHT * columnWidth) / GRID_COLUMN_WIDTH);

        return { columnCount, columnWidth, rowCount, rowHeight };
    }

    /** Rendering function for react-virtualized */
    onSectionRendered = ({ columnStartIndex, columnStopIndex, rowStartIndex, rowStopIndex }) => {
        const { onRowsRendered } = this.props;
        const { columnCount } = this.state;
        const startIndex = rowStartIndex * columnCount + columnStartIndex;
        const stopIndex = rowStopIndex * columnCount + columnStopIndex;

        onRowsRendered({ startIndex, stopIndex });
    };

    /** The cell renderer must be inside the container due to its dependency on props
     * that cannot be passed via react-virtualized (products, isRowLoaded, columnCount)
     */
    cellRenderer = ({ style, columnIndex, rowIndex }) => {
        const { products, ...props } = this.props;
        const { columnCount } = this.state;
        const index = rowIndex * columnCount + columnIndex;
        const product = products[index];

        if (product) {
            return <GridCard {...{ style, product, key: product.id }} {...props} />;
        }

        return null;
    };

    render() {
        const gridProps = {
            cellRenderer: this.cellRenderer,
            onSectionRendered: this.onSectionRendered,
        };

        return <GridView {...this.props} {...this.state} {...gridProps} />;
    }
}

export default GridViewContainer;
