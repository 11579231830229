import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { CellMeasurerCache, createMasonryCellPositioner } from 'react-virtualized';

import MasonryView from './MasonryView';

const DEFAULT_SPACER = 20;
const DEFAULT_CELL_WIDTH = 300;
const DEFAULT_CELL_HEIGHT = 250;
const DEFAULT_COLUMN_COUNT = 2;

const MasonryWrapper = styled.div`
    width: 100%;
`;

class SubmittalDocuments extends React.Component {
    constructor(props) {
        super(props);

        this.cache = new CellMeasurerCache({
            defaultHeight: DEFAULT_CELL_HEIGHT,
            defaultWidth: DEFAULT_CELL_WIDTH,
            fixedWidth: true,
        });

        this.cellPositioner = createMasonryCellPositioner({
            cellMeasurerCache: this.cache,
            columnCount: DEFAULT_COLUMN_COUNT,
            columnWidth: DEFAULT_CELL_WIDTH,
            spacer: DEFAULT_SPACER,
        });

        this.state = {
            columnWidth: DEFAULT_CELL_WIDTH,
        };
    }

    resetCells = () => {
        this.cellPositioner.reset({
            cellMeasurerCache: this.cache,
            columnCount: this.currentColumnCount,
            columnWidth: this.state.columnWidth,
            spacer: DEFAULT_SPACER,
        });

        this.masonry.recomputeCellPositions();
    };

    onRemoveCell = () => {
        this.cache.clearAll();

        this.cellPositioner.reset({
            cellMeasurerCache: this.cache,
            columnCount: this.currentColumnCount,
            columnWidth: this.state.columnWidth,
            spacer: DEFAULT_SPACER,
        });

        this.masonry.clearCellPositions();
    };

    onResize = ({ width }) => {
        if (width) {
            const { newColumnCount, newColumnWidth } = this.getColumnWidth(width);

            this.cellPositioner.reset({
                columnCount: newColumnCount,
                columnWidth: newColumnWidth,
                spacer: DEFAULT_SPACER,
            });

            this.masonry.recomputeCellPositions();

            this.currentColumnCount = newColumnCount;
            this.setState({
                columnWidth: newColumnWidth,
            });
        }
    };

    getColumnWidth = (width) => {
        const newColumnCount = Math.max(Math.floor(width / (DEFAULT_CELL_WIDTH + DEFAULT_SPACER)), 1);
        const totalSpacer = (newColumnCount - 1) * DEFAULT_SPACER;
        const newColumnWidth = Math.floor((width - totalSpacer) / newColumnCount);

        return {
            newColumnCount,
            newColumnWidth,
        };
    };

    setMasonryRef = (ref) => {
        this.masonry = ref;
    };

    componentWillUnmount() {
        this.cache.clearAll();
    }

    render() {
        return (
            <MasonryWrapper>
                <MasonryView
                    cellWidth={this.state.columnWidth}
                    onResize={this.onResize}
                    cache={this.cache}
                    setMasonryRef={this.setMasonryRef}
                    cellPositioner={this.cellPositioner}
                    resetCells={this.resetCells}
                    onRemoveCell={this.onRemoveCell}
                    {...this.props}
                />
            </MasonryWrapper>
        );
    }
}

export default SubmittalDocuments;
