import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

import { login, history, reducerUtil } from 'base-client';
import { actions as guestActions, reducerData as guestData } from 'guest';
import { actions as detailsActions, reducerData as projectDetailsData } from 'projectDetails';
import { connect } from 'react-redux';
import GuestLoginModal from '../../projects/components/Modal/GuestLoginModal';
import ProjectCreationModal from '../../projects/components/Modal/ProjectCreationModal';

const Landing = ({
    projectData,
    projectProducts,
    token,
    guestEnabled,
    guestOccupation,
    dispatch,
    location,
    ...bannerProps
}) => {
    const [gettingStartedModalOpen, setGettingStartedModalOpen] = useState(false);
    const [guestLoginModalOpen, setGuestLoginModalOpen] = useState(false);

    useEffect(() => {
        if (guestEnabled && guestOccupation) {
            history.push('/projects/guest/submittal');
        } else if (guestEnabled && !token && !guestOccupation && location.pathname !== '/brochure') {
            setGuestLoginModalOpen(true);
        } else if (
            (!token || !projectData || !Object.keys(projectData).length || !projectProducts) &&
            !guestOccupation
        ) {
            setGettingStartedModalOpen(true);
        } else if (location.pathname === '/submittal') {
            history.push(`/projects/${projectData.id}/submittal`);
        } else if (location.pathname === '/brochure') {
            history.push(`/projects/${projectData.id}/brochure`);
        }
    });

    const onClose = () => {
        setGettingStartedModalOpen(false);
        setGuestLoginModalOpen(false);
        history.push('/');
    };

    if (!guestLoginModalOpen && !gettingStartedModalOpen) return null;
    return (
        <>
            <ProjectCreationModal title="Getting Started" open={gettingStartedModalOpen} onClose={onClose} />
            {guestEnabled && (
                <GuestLoginModal
                    title="Getting Started"
                    open={guestLoginModalOpen}
                    onClose={null}
                    loginCb={(guestOccupation) => {
                        dispatch(guestActions.setOccupation(guestOccupation));
                        setGuestLoginModalOpen(false);
                        setGettingStartedModalOpen(true);
                    }}
                />
            )}
        </>
    );
};

const mapStateToProps = (state) => ({
    token: reducerUtil.getSlice(login.reducerData, login.reducerData.token, state),
    guestOccupation: reducerUtil.getSlice(guestData, guestData.guestOccupation, state),
    guestEnabled: reducerUtil.getSlice(guestData, guestData.guestEnabled, state),
    projectData: reducerUtil.getSlice(projectDetailsData, projectDetailsData.generalData, state),
    projectProducts: reducerUtil.getSlice(projectDetailsData, projectDetailsData.products, state),
    loginPage: reducerUtil.getSlice(login.reducerData, login.reducerData.loginPage, state),
});

export default withRouter(connect(mapStateToProps)(Landing));
