import React from 'react';
import styled from 'styled-components';

import { Row, Column } from 'shared';

import ContactEdit from './ContactEdit';

const VendorBlockEdit = styled(Row)`
    && {
        font-size: 1.4rem;
        margin-bottom: 3rem;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
`;

const TabContactInformationEdit = ({ architect, contractor, general_contractor, distributor, ...props }) => {
    return (
        <div {...props}>
            <VendorBlockEdit>
                <Column>
                    <strong>Contractor Information</strong>
                    <ContactEdit {...(contractor || {})} entity="contractor" />
                </Column>
                <Column>
                    <strong>Distributor Information</strong>
                    <ContactEdit {...(distributor || {})} entity="distributor" />
                </Column>
            </VendorBlockEdit>
            <VendorBlockEdit>
                <Column>
                    <strong>Architect Information</strong>
                    <ContactEdit {...(architect || {})} entity="architect" />
                </Column>
                <Column>
                    <strong>GC Information</strong>
                    <ContactEdit {...(general_contractor || {})} entity="general_contractor" />
                </Column>
            </VendorBlockEdit>
        </div>
    );
};

export default TabContactInformationEdit;
