import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { SearchIcon } from 'shared';

export const HeaderSearch = styled.div`
    display: flex;
    height: 3rem;
    position: relative;
`;

export const SearchForm = styled.div`
    width: 45rem;
`;

export const SearchInput = styled.input`
    height: 3rem;
    width: 100%;
    appearance: none;
    border: 1px solid ${(props) => props.theme.grey2};
    border-radius: 3px;
    padding: 0.5rem 3rem 0.7rem 1rem;
    font: inherit;
    font-size: 1.4rem;
    caret-color: ${(props) => props.theme.accentColor};
    &:focus {
        outline: 0;
        border-color: ${(props) => props.theme.accentColor};
    }
`;

export const SearchSubmit = styled.a`
    position: absolute;
    right: 1rem;
    top: 0.7rem;
    cursor: pointer;
`;

const searchFieldPropTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
};
const searchFieldDefaultProps = {};

const SearchField = ({ onChange, value }) => (
    <HeaderSearch>
        <SearchForm>
            <SearchInput placeholder="What are you looking for?" {...{ onChange, value: value || '' }} />
            <SearchSubmit>
                <SearchIcon small />
            </SearchSubmit>
        </SearchForm>
    </HeaderSearch>
);

SearchField.propTypes = searchFieldPropTypes;
SearchField.defaultProps = searchFieldDefaultProps;

export default SearchField;
