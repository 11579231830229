import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { change, reduxForm } from 'redux-form';

import { reducerData as tenantData } from 'tenant';
import { history, reducerUtil, login } from 'base-client';

import { StyledTabs, Card, Row, Column } from 'shared';

import { actions as detailsActions, reducerData as detailsData } from 'projectDetails';
import { actions as guestActions, reducerData as guestData } from 'guest';
import { actions as editActions } from 'projectEdit';
import { ErrorProjectLogin } from 'errors';
import { ProjectHeader } from 'projectDetails';
import TabProjectEdit from './TabProjectEdit';
import TabAdditionalInfomationEdit from './TabAdditionalInfomationEdit';
import TabContactInformationEdit from './TabContactInformationEdit';
import SideDetails from '../../proposal/components/SideDetails';
import ProjectCompletionStatus from './ProjectCompletionStatus';

const TabContent = styled.div`
    display: inline-block;
    vertical-align: top;
    padding: 2rem;
    width: 100%;
`;

const ProjectEditContainer = ({
    projectSubmittalSettings,
    token,
    generalData,
    match,
    dispatch,
    saveChanges,
    handleSubmit,
    reset,
    pristine,
    submitting,
    hideHeader,
    invalid,
    valid,
    guestOccupation,
    guestGeneralData,
}) => {
    const [companyLogo, setCompanyLogo] = useState();
    const id = match && match.params && match.params.id;
    useEffect(() => {
        if (!guestOccupation) {
            dispatch(detailsActions.getProject(id));
        } else if (window.localStorage.getItem('guest')) {
            // local storage values already loaded into state by now
            Object.entries(guestGeneralData).forEach(([key, value]) => {
                dispatch(change('projectDetails', key, value));
            });
        }
    }, [id]);

    if (projectSubmittalSettings.disabled) {
        history.push('/products');
        return null;
    }

    if (!id) {
        history.push('/projects');
        return null;
    }

    if (projectSubmittalSettings.requiredLogin && !token && !guestOccupation) {
        return (
            <ErrorProjectLogin>
                Please <Link to="/login">log in</Link> to view this project
            </ErrorProjectLogin>
        );
    }

    if (!(guestOccupation ? guestGeneralData : generalData)) return null;

    const onSubmit = async () => {
        guestOccupation ? await dispatch(guestActions.updateProject()) : await dispatch(editActions.updateProject());
    };

    const setSelectedCompanyLogo = async (file) => {
        setCompanyLogo(file);
        if (guestOccupation) {
            await dispatch(guestActions.uploadCompanyLogo(file));
        } else {
            await dispatch(editActions.uploadCompanyLogo(id, file));
        }
    };

    const { name, created_date: createdDate } = guestOccupation ? guestGeneralData : generalData || {};

    return (
        <form onSubmit={handleSubmit}>
            {!hideHeader && <ProjectHeader {...{ name, createdDate, isEdit: true, id }} />}
            <Row>
                <Column mdUp={4}>
                    <SideDetails
                        title="Edit Project"
                        description="Project details appear on the final Submittal Prep and are mostly optional.
            We recommend filling them all out to provide a comprehensive submittal package."
                    >
                        <ProjectCompletionStatus
                            invalid={invalid || !valid}
                            {...{
                                isGuest: guestOccupation,
                                generalData: guestOccupation ? guestGeneralData : generalData,
                                saveChanges,
                                companyLogo,
                                pristine,
                                submitting,
                                reset,
                                onClick: onSubmit,
                            }}
                        />
                    </SideDetails>
                </Column>
                <Column mdUp={8}>
                    <Card>
                        <StyledTabs>
                            <Tabs forceRenderTabPanel={true}>
                                <TabList>
                                    <Tab key={1}>Project Details</Tab>
                                    <Tab key={2}>Additional Information</Tab>
                                    <Tab key={3}>Contact Information</Tab>
                                </TabList>
                                <TabContent className="tab-content">
                                    <TabPanel key={1}>
                                        <TabProjectEdit
                                            {...{
                                                ...(guestOccupation ? guestGeneralData : generalData),
                                                setSelectedCompanyLogo,
                                                guestOccupation,
                                            }}
                                        />
                                    </TabPanel>
                                    <TabPanel key={2}>
                                        <TabAdditionalInfomationEdit
                                            {...{
                                                ...(guestOccupation ? guestGeneralData : generalData),
                                                guestOccupation,
                                            }}
                                        />
                                    </TabPanel>
                                    <TabPanel key={3}>
                                        <TabContactInformationEdit
                                            {...{
                                                ...(guestOccupation ? guestGeneralData : generalData),
                                                guestOccupation,
                                            }}
                                        />
                                    </TabPanel>
                                </TabContent>
                            </Tabs>
                        </StyledTabs>
                    </Card>
                </Column>
            </Row>
        </form>
    );
};

ProjectEditContainer.propTypes = {
    projectSubmittalSettings: PropTypes.object,
    generalData: PropTypes.object,
    products: PropTypes.arrayOf(PropTypes.shape({})),
    token: PropTypes.string,
    hideHeader: PropTypes.bool,
    guestOccupation: PropTypes.string,
    guestGeneralData: PropTypes.object,
};

const mapStateToProps = (state) => ({
    projectSubmittalSettings: reducerUtil.getSlice(tenantData, tenantData.projectSubmittalSettings, state),
    generalData: reducerUtil.getSlice(detailsData, detailsData.generalData, state),
    products: reducerUtil.getSlice(detailsData, detailsData.products, state),
    token: reducerUtil.getSlice(login.reducerData, login.reducerData.token, state),
    initialValues: reducerUtil.getSlice(detailsData, detailsData.generalData, state),
    guestOccupation: reducerUtil.getSlice(guestData, guestData.guestOccupation, state),
    guestGeneralData: reducerUtil.getSlice(guestData, guestData.generalData, state),
});

const asyncValidate = async (values, dispatch) => {
    const { exists } = await dispatch(editActions.isUnique(values['name']));
    if (exists) {
        throw { name: 'Name already exists' };
    }
};

export default withRouter(
    connect(mapStateToProps)(
        reduxForm({
            form: detailsData.name,
            asyncValidate,
            asyncChangeFields: ['name'],
            enableReinitialize: true,
            keepDirtyOnReinitialize: false,
        })(ProjectEditContainer)
    )
);
