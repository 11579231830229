import { api, config, reducerUtil } from 'base-client';

import { v4 as uuid } from 'uuid';

import { actions as analyticsActions } from 'analytics';
import { actions as notificationActions, projectNotification } from 'notification';
import { reducerData as detailsData } from 'productDetails';
import { reducerData as tenantData } from 'tenant';
import { formNames } from './utils';

const displayNotification =
    (res = false, keenBody = false) =>
    (dispatch) => {
        if (res && res === 'OK' && keenBody) {
            dispatch(
                notificationActions.sendNotifications([
                    {
                        id: `contact-form-${uuid()}`,
                        type: projectNotification.GENERAL,
                        title: 'Success',
                        body: 'Your contact form has been sent',
                    },
                ])
            );
            return dispatch(analyticsActions.track('message', { ...keenBody, job_id: null }));
        }
        dispatch(
            notificationActions.sendNotifications([
                {
                    id: `contact-form-${uuid()}`,
                    type: projectNotification.GENERAL,
                    title: 'Error',
                    body: res.error && res.error.message ? res.error.message : 'Your contact form failed to send',
                },
            ])
        );
    };

const formatValues = (formName) => async (dispatch, getState) => {
    const state = getState();
    const visitorId = dispatch(analyticsActions.getVisitorId());
    const userId = dispatch(analyticsActions.getUserId());
    const tenant = reducerUtil.getSlice(tenantData, tenantData.tenant, state);
    const { id: productId, name: productName } = reducerUtil.getSlice(detailsData, detailsData.general, state) || {};
    const { [formName]: form } = state.form || {};
    const { values } = form || {};

    // this is sent with the form values to the backend lambda and also to keene
    const userInfo = {
        user_id: userId,
        visitor_id: visitorId,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        company: values.companyName,
        occupation: values.occupation,
        industry: values.industry,
    };

    // this is sent to keen
    const keenBody = {
        emailType: formName,
        form: { ...values, productName: formName === formNames.request ? productName : undefined },
        product_id: formName === formNames.request ? productId : undefined,
        tenant_id: tenant,
        userInfo,
    };

    return { keenBody, productId, productName, tenant, values, userInfo };
};

const contact = (formName) => async (dispatch) => {
    const { keenBody, productId, tenant, values, userInfo } = await dispatch(formatValues(formName));

    // this is sent to the backend lambda
    const body = {
        ...userInfo,
        emailType: formName,
        messageDetails: values.message ? values.message : '',
        product_id: formName === formNames.request ? productId : undefined,
        tenant_id: tenant,
        tenantName: process.env.REACT_APP_TENANT_NAME,
        zipcode: values.zipcode,
    };

    try {
        const res = await dispatch(api.actions.post('lambdas/contact', body, true));
        dispatch(displayNotification(res, keenBody));
    } catch (err) {
        const res = await err.json();
        dispatch(displayNotification(res));
    }
};

const quote = (formName) => async (dispatch) => {
    const { keenBody, productId, tenant, values, userInfo } = await dispatch(formatValues(formName));

    // this is sent to the backend lambda
    const body = {
        ...userInfo,
        emailType: formName,
        messageDetails: values.message ? values.message : '',
        product_id: formName === formNames.request ? productId : '',
        productCountNeeded: values.productCount ? values.productCount : '',
        projectLocation: values.projectLocation ? values.projectLocation : '',
        projectName: values.projectName ? values.projectName : '',
        projectSegment: values.segment ? values.segment : '',
        projectStage: values.stage ? values.stage : '',
        projectValue: values.value ? values.value : '',
        tenant_id: tenant,
        tenantName: process.env.REACT_APP_TENANT_NAME,
        zipcode: values.zipcode,
    };

    try {
        const res = await dispatch(api.actions.post('lambdas/request-quote', body, true));
        dispatch(displayNotification(res, keenBody));
    } catch (err) {
        const res = await err.json();
        dispatch(displayNotification(res));
    }
};

export default { contact, quote };
