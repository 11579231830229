import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { reducerUtil } from 'base-client';

import ErrorDisplayNotFound from './ErrorDisplayNotFound';
import ErrorDisplayNoConnection from './ErrorDisplayNoConnection';
import ErrorDisplayServer from './ErrorDisplayServer';
import { actions as errorActions, reducerData as errorsData } from 'errors';

const notFoundError = (message) => message >= 400 && message < 500;
const serverError = (message) => message >= 500 && message < 600;
const disconnectError = (message) => message === 'Failed to fetch';

class ErrorContainer extends React.Component {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired,
        error: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
    };

    state = {};

    componentDidMount() {
        const { history, dispatch } = this.props;
        const endListen = history.listen(() => dispatch(errorActions.endError()));
        this.setState({ endListen });
    }

    componentWillUnmount() {
        const { endListen } = this.state;
        if (endListen) endListen();
    }

    render() {
        const { error, children } = this.props;
        if (error) {
            if (notFoundError(error)) return <ErrorDisplayNotFound />;
            if (serverError(error)) return <ErrorDisplayServer />;
            if (disconnectError(error)) return <ErrorDisplayNoConnection />;
        }
        return children;
    }
}

const mapStateToProps = (state) => ({
    error: reducerUtil.getSlice(errorsData, errorsData.error, state),
});

export default withRouter(connect(mapStateToProps)(ErrorContainer));
