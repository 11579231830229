import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { reducerUtil } from 'base-client';

import HeroArticle from './HeroArticle';
import Navigations from './Navigations';
import ProductSearch from 'productSearch';
import { reducerData as tenantData } from 'tenant';
import RegionSelectModal from '../../projects/components/Modal/RegionSelectModal';

const Container = styled.div``;

const LandingContainer = ({ dispatch, landingPage, hasPrice }) => {
    if (!landingPage) return <ProductSearch />;

    const {
        heroTitle: title,
        heroSubtitle: subTitle,
        heroDescription: description,
        heroLink: link,
        heroImage: image,
        navigationTiles,
    } = landingPage;
    const [regionSelectorModalOpen, setRegionSelectorModalOpen] = useState(false);

    useEffect(() => {
        const region = JSON.parse(localStorage.getItem('region'));
        region ? setRegionSelectorModalOpen(false) : setRegionSelectorModalOpen(true)
    }, []);


    return (
        <Container>
            {hasPrice && regionSelectorModalOpen &&
                <RegionSelectModal
                    title="Select a Region"
                    open={regionSelectorModalOpen}
                />
            }
            <HeroArticle {...{ title, subTitle, description, link, image }} />
            <Navigations {...{ navigationTiles }} />
        </Container>
    );
};

LandingContainer.propTypes = {
    dispatch: PropTypes.func.isRequired,
    landingPage: PropTypes.shape({
        heroTitle: PropTypes.string,
        heroSubtitle: PropTypes.string,
        heroDescription: PropTypes.string,
        heroLink: PropTypes.shape({
            displayValue: PropTypes.string,
            url: PropTypes.string,
        }),
        heroImage: PropTypes.string,
        navigationTiles: PropTypes.arrayOf(
            PropTypes.shape({
                header: PropTypes.string,
                description: PropTypes.string,
                urlDisplayValue: PropTypes.string,
                url: PropTypes.string,
            })
        ),
    }),
    hasPrice: PropTypes.bool
};

const mapStateToProps = (state) => ({
    landingPage: reducerUtil.getSlice(tenantData, tenantData.landingPage, state),
    hasPrice: reducerUtil.getSlice(tenantData, tenantData.hasPrice, state)
});

export default connect(mapStateToProps)(LandingContainer);
