import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { reducerUtil } from 'base-client';

import { Row, Column } from 'shared';

import SideDetails from './SideDetails';
import styled from 'styled-components';

import { actions as proposalActions, reducerData as proposalData } from 'proposal';
import { actions as detailsActions, reducerData as detailsData } from 'projectDetails';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const ProductCard = styled.div`
    padding: 20px;
    display: flex;
    margin: auto auto 6px auto;
    background-color: white;
    box-shadow: 0 3px 5px rgba(52, 62, 71, 0.12);
`;

const DragIcon = styled.div`
    width: 14px;
    height: 13px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: auto 0px auto 16px;
`;
const DragLine = styled.div`
    width: 14px;
    height: 3px;
    background-color: #5a6678;
`;

const ProductIndex = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;

    display: flex;
    align-items: center;
    text-align: center;

    color: #5a6678;
`;

const ProductImage = styled.img`
    width: 100%;
    max-width: 30px;
    height: 23px;
    margin: auto 0px auto 16px;
`;

const ProductName = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin: auto auto auto 16px;
`;

const ProductActionContainer = styled.div`
    display: flex;
    width: 185px;
    justify-content: space-between;
    margin: auto 0px auto auto;
`;

const ProductActionButton = styled.div`
    font-size: 12px;
    line-height: 12px;
    color: #5f7386;
    cursor: pointer;
`;

const OrderProducts = ({
    projectId,
    fetchId,
    setStep,
    projectProducts = [],
    pagination = {},
    orderedProducts,
    dispatch,
    state,
}) => {
    const [products, setProducts] = useState(projectProducts);
    const [localProductOrder, setLocalProductOrder] = useState(orderedProducts);

    const reset = async () => {
        const projectProductsIds = projectProducts.map((product) => product.id);
        setProducts(projectProducts);
        setLocalProductOrder(projectProductsIds);
        await dispatch(proposalActions.setProductOrder(projectProductsIds));
        await dispatch(detailsActions.loadSavedProducts({ projectId, forSubmittal: true, restart: true }));
    };

    useEffect(() => {
        const loadData = async () => {
            await dispatch(detailsActions.loadSavedProducts({ projectId, forSubmittal: true, restart: true }));
        };
        loadData();
    }, [projectId]);

    useEffect(() => {
        const run = async () => {
            if (!orderedProducts) {
                alert(JSON.stringify(orderedProducts));
                setLocalProductOrder(projectProducts.map((product) => product.id));
                await dispatch(proposalActions.setProductOrder(projectProducts.map((product) => product.id)));
                products.length != projectProducts.length && reset();
            }
        };
        run();
    }, [projectProducts]);

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragEnd = (result) => {
        if (!result.destination) return;
        const newOrder = reorder(localProductOrder, result.source.index, result.destination.index);
        dispatch(proposalActions.setProductOrder(newOrder));
        setLocalProductOrder(newOrder);
    };

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: 'none',
        //  styles we need to apply on draggables
        ...draggableStyle,
    });

    const moveUp = (index, old_index, new_index) => {
        const newOrder = [...localProductOrder];
        if (index - 1 >= newOrder.length) {
            var k = index - 1 - newOrder.length + 1;
            while (k--) {
                newOrder.push(undefined);
            }
        }
        newOrder.splice(index - 1, 0, newOrder.splice(index, 1)[0]);
        dispatch(proposalActions.setProductOrder(newOrder));
        setLocalProductOrder(newOrder);
    };

    const moveDown = (index, old_index, new_index) => {
        const newOrder = [...localProductOrder];
        if (index + 1 >= newOrder.length) {
            var k = index + 1 - newOrder.length + 1;
            while (k--) {
                newOrder.push(undefined);
            }
        }
        newOrder.splice(index + 1, 0, newOrder.splice(index, 1)[0]);
        dispatch(proposalActions.setProductOrder(newOrder));
        setLocalProductOrder(newOrder);
    };

    const removeProduct = (id) => {
        setProducts(products.filter((product) => product.id != id));
        dispatch(proposalActions.handleRemoveProduct(id));
    };

    return (
        <Row>
            <Column mdUp={4} className="left-content">
                <SideDetails
                    title="Set Product Order"
                    description="Define the order of your product within your Owner Brochure document. Drag &amp; Drop the products in this view to set the order."
                    buttons={[
                        {
                            buttonText: 'Add More Products',
                            onClick: () => {
                                window.scrollTo({
                                    top: 0,
                                });
                                setStep('product-search');
                            },
                        },
                        {
                            buttonText: 'Reset Product List',
                            onClick: () => {
                                reset();
                            },
                        },
                    ]}
                />
            </Column>
            <Column mdUp={8} className="right-content">
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div {...provided.droppableProps} ref={provided.innerRef} style={{ width: '100%' }}>
                                {localProductOrder.map((product_id, index) => {
                                    const product = products.find(({ id }) => id === product_id);
                                    if (!product) return null;
                                    return (
                                        <Draggable key={product.id} draggableId={product.id} index={index}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                >
                                                    <ProductCard>
                                                        <ProductIndex>{index + 1}</ProductIndex>
                                                        <DragIcon>
                                                            <DragLine />
                                                            <DragLine />
                                                            <DragLine />
                                                        </DragIcon>
                                                        <ProductImage src={product.thumbnail}></ProductImage>
                                                        <ProductName>{product.name}</ProductName>
                                                        <ProductActionContainer>
                                                            <ProductActionButton onClick={() => moveUp(index)}>
                                                                Move Up
                                                            </ProductActionButton>
                                                            <ProductActionButton onClick={() => moveDown(index)}>
                                                                {' '}
                                                                • Move Down •{' '}
                                                            </ProductActionButton>
                                                            <ProductActionButton
                                                                style={{ color: '#D95A5A' }}
                                                                onClick={() => removeProduct(product.id)}
                                                            >
                                                                Remove
                                                            </ProductActionButton>
                                                        </ProductActionContainer>
                                                    </ProductCard>
                                                </div>
                                            )}
                                        </Draggable>
                                    );
                                })}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </Column>
        </Row>
    );
};

const mapStateToProps = (state) => ({
    fetchId: reducerUtil.getSlice(detailsData, detailsData.fetchId, state),
    projectProducts: reducerUtil.getSlice(detailsData, detailsData.products, state),
    pagination: reducerUtil.getSlice(detailsData, detailsData.pagination, state),
    orderedProducts: reducerUtil.getSlice(proposalData, proposalData.orderedProducts, state) || [],
    state: state,
});

export default connect(mapStateToProps)(OrderProducts);
