import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Table, Column } from 'react-virtualized';

import ListRow from './ListRow';

const TableHeader = styled.div`
    .ReactVirtualized__Table__headerRow {
        text-transform: none;
        border-bottom: 1px solid ${(props) => props.theme.grey2};
        & > .ReactVirtualized__Table__headerColumn:first-of-type {
            padding-left: 1rem;
        }
    }
`;

/**
 * Function that creates a component representing the list view.
 * @param {object} props - The props object containing rowCount, loadMoreRows,
 *   isRowLoaded, rowRenderer.
 * @return {React.Element} List view component.
 */
const ListView = ({ registerChild, ...props }) => (
    <TableHeader>
        <Table {...props} ref={registerChild}>
            <Column
                dataKey="name"
                cellRenderer={ListRow.Name}
                disableSort
                flexGrow={1}
                label="Product Name"
                width={550}
            />
            <Column dataKey="category" disableSort flexGrow={1} label="Category" width={150} />
        </Table>
    </TableHeader>
);

ListView.propTypes = {
    registerChild: PropTypes.func,
};

export default ListView;
