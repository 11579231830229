import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Card, Checkbox } from 'shared';
import NoProductImg from 'shared/static/GridNoProductImage.jpg';
import media from 'utils/media';

export const CardContainer = styled(Card)`
    width: 93%;
    margin-bottom: 1rem;
    // border: 1px solid ${(props) => props.theme.grey2};
    border-radius: 3px;
    display: flex;
    overflow: hidden;
    position: relative;
    height: 95%;
    &:before {
        content: '';
        display: block;
        padding-top: 100%;
    }
    ${media.small`
    width: 95%;
  `};
    ${media.medium`
    // position: relative;
    // &:before {
    //   content: "";
    //   display: block;
    //   padding-top: 100%;
    // }
  `};
`;

export const CardImage = styled.img`
    // flex: 0 0 35%;
    // width: 35%;
    // height: auto;
    // ${media.small`
  //   flex: 0 0 25%;
  //   width: 25%;
  // `} ${media.medium`
  //   position: absolute;
  //   width: 120%;
  //   left: -10%;
  // `};
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
    margin: 0 auto;
`;

export const CardInfo = styled.div`
    padding: 1rem 1rem 1.5rem;
    background: white;
    position: absolute;
    width: 100%;
    bottom: 0;
    border-top: 1px solid ${(props) => props.theme.grey1};
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    transition: background 0.2s ease;
    &:hover {
        background: rgba(255, 255, 255, 0.9);
    }
    // ${media.small`
    // flex: 0 0 75%;
  // `} ${media.medium`
    // background: white;
    // position: absolute;
    // width: 100%;
    // bottom: 0;
    // border-top: 1px solid ${(props) => props.theme.grey2};
    // border-bottom-left-radius: 3px;
    // border-bottom-right-radius: 3px;
    // transition: background 0.2s ease;
    // &:hover {
    //   background: rgba(255,255,255,0.9);
    // }
  // `};
`;

export const CardText = styled.div`
    display: block;
    cursor: pointer;
    width: 80%;
    // ${media.medium`
  //   width: 80%;
  // `};
`;

export const ProductName = styled.h3`
    font-size: 1.4rem;
    margin: 0.3rem 0 0;
    font-weight: 600;
    ${media.medium`
    font-size: 1.6rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    ${CardText}:hover & {
      white-space: normal;
    }
  `};
`;

const ItemWrapper = styled.div`
    .select {
        z-index: 1000;
        position: absolute;
        left: 1rem;
        top: 1rem;
        label {
            text-shadow: 1px 1px 1px white;
        }
    }
`;

const GridCard = ({ style, product, enableCheckbox, toggleSelectProduct }) => {
    const { id, name, thumbnail, checked } = product;
    if (enableCheckbox) {
        return (
            <ItemWrapper {...{ style }}>
                <Checkbox
                    label="Select"
                    id={id}
                    value={id}
                    checked={checked}
                    onChange={() => toggleSelectProduct(product)}
                />
                <Link to={id ? `/products/${id}` : '#'}>
                    <CardContainer>
                        <CardImage src={thumbnail ? thumbnail : NoProductImg} />
                        <CardInfo>
                            <CardText>
                                <ProductName>{name}</ProductName>
                            </CardText>
                        </CardInfo>
                    </CardContainer>
                </Link>
            </ItemWrapper>
        );
    } else {
        return (
            <Link {...{ style }} to={id ? `/products/${id}` : '#'}>
                <CardContainer>
                    <CardImage src={thumbnail ? thumbnail : NoProductImg} />
                    <CardInfo>
                        <CardText>
                            <ProductName>{name}</ProductName>
                        </CardText>
                    </CardInfo>
                </CardContainer>
            </Link>
        );
    }
};

GridCard.propTypes = {
    product: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        category: PropTypes.string,
        thumbnail: PropTypes.string,
    }),
    style: PropTypes.object.isRequired,
    enableCheckbox: PropTypes.bool,
    toggleSelectProduct: PropTypes.func,
};

GridCard.defaultProps = {
    product: [],
};

export default GridCard;
