import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Modal } from 'shared';
import { UNITED_STATES, CANADA, UnitedStatesFlag, CanadianFlag } from '../../../utils/pricingInformation'

const ModalContent = styled.div`
    font-size: 1.4rem;
`;

const CardContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const CardSection = styled.div`
    display: flex;
    flex-direction: column;
`;

const Seperator = styled.div`
    margin-bottom: 15px;
    margin-right: 7px;
    margin-left: 7px;
    border-right: 2px solid ${(props) => props.theme.grey1};
`;

const SubTitle = styled.div`
    font-weight: bold;
    font-size: 14px;
    line-height: 23px;
    color: ${(props) => props.theme.grey2};
    text-align: center;
`;

const RegionSelectButton = styled.button`
    border: none; 
    cursor: pointer; 
    width: 200px;
    background: none;
`

const RegionSelectModal = ({ title, open, onClose }) => {
    const [modalOpen, setModalOpen] = useState(open);
    const [region, setRegion] = useState('');

    useEffect(() => {
        localStorage.setItem('region', JSON.stringify(region));
    }, [region]);

    let modalProps = {
        title: title,
        open: modalOpen,
        onClose: () => defaultClose(),
    };

    if (onClose) {
        modalProps.onClose = onClose;
    }

    const defaultClose = async () => {
        setModalOpen(false);
        setRegion(UNITED_STATES)
    };

    return (
        <Modal {...modalProps}>
            <ModalContent>
                <CardContainer>
                    <CardSection>
                        <RegionSelectButton onClick={() => { setRegion(UNITED_STATES); setModalOpen(false) }}>
                            <UnitedStatesFlag />
                        </RegionSelectButton>
                        <SubTitle>{UNITED_STATES}</SubTitle>
                    </CardSection>
                    <Seperator />
                    <CardSection>
                        <RegionSelectButton onClick={() => { setRegion(CANADA); setModalOpen(false) }}>
                            <CanadianFlag />
                        </RegionSelectButton>
                        <SubTitle>{CANADA}</SubTitle>
                    </CardSection>
                </CardContainer>
            </ModalContent>
        </Modal>
    );
};

const mapStateToProps = (state) => ({
    theme: null, //reducerUtil.getSlice(tenantData, tenantData.theme, state),
    state: state,
});

export default connect(mapStateToProps)(RegionSelectModal);
