import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledFacet = styled.div`
    display: flex;
    margin-bottom: 0.5rem;
`;

const Label = styled.label`
    margin-left: 0.35rem;
`;

const Facet = ({ attribute, type = 'checkbox', facetData, updateFilter }) => {
    const { name, count, checked } = facetData;
    return (
        <StyledFacet key={name} className="facets">
            <input
                id={name}
                {...{ type, name, checked }}
                onClick={() => updateFilter({ checked: !checked, attribute, facets: [name], type })}
                readOnly
            />
            <Label htmlFor={name}>{count ? `${name} (${count})` : name}</Label>
        </StyledFacet>
    );
};

export default Facet;
