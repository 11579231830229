import React from 'react';
import ErrorDisplay from './ErrorDisplay';

const ErrorDisplayNotFound = () => (
    <ErrorDisplay
        header="Hmm, there doesn't seem to be anything here"
        content="Please check the URL and try again."
        imageUrl="/contents/404.svg"
    />
);

export default ErrorDisplayNotFound;
