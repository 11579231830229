import React from 'react';
import styled from 'styled-components';

import { Screen } from '../global-styles';

const ColWidth = (span) => {
    const ColCount = 12;
    const ColumnWidth = (span / ColCount) * 100;
    return ColumnWidth;
};

const Wrapper = styled.div`
    position: relative;
    width: 100%;
    padding: 0 ${(props) => (props.paddingY ? props.paddingY : '10px')};
    // equal width columns by default, regardless of count
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    // only works if same for all breakpoints
    align-items: ${(props) => (props.alignItems ? props.alignItems : null)};
    // only works if same for all breakpoints
    justify-content: ${(props) => (props.justifyContent ? props.justifyContent : null)};
    // set to 100% width unless otherwise specified for small viewports
    @media ${Screen.sm} {
        flex: 0 0 ${(props) => (props.sm ? ColWidth(props.sm) : '100')}%;
    }
    // set to breakpoint width only if specified for medium viewports
    @media ${Screen.md} {
        flex: ${(props) => (props.md ? `0 0 ${ColWidth(props.md)}%` : null)};
    }
    // set to breakpoint width only if specified for medium and larger viewports
    @media ${Screen.mdUp} {
        flex: ${(props) => (props.mdUp ? `0 0 ${ColWidth(props.mdUp)}%` : null)};
    }
    // set to breakpoint width only if specified for large viewports
    @media ${Screen.lg} {
        flex: ${(props) => (props.lg ? `0 0 ${ColWidth(props.lg)}%` : null)};
    }
    &:first-of-type {
        padding-left: 10px;
    }
    &:last-of-type {
        padding-right: 10px;
    }
`;

const Column = ({ className, children, span, alignItems, justifyContent, sm, md, mdUp, lg, paddingY }) => (
    <Wrapper {...{ className, span, alignItems, justifyContent, sm, md, mdUp, lg, paddingY }}>{children}</Wrapper>
);

export default Column;
