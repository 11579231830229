import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { reducerData as tenantData } from 'tenant';
import { reducerData as guestData } from 'guest';
import { history, reducerUtil, login } from 'base-client';
import { Row, Column } from 'shared';

import { actions as projectsActions } from 'projects';
import { actions as detailsActions, reducerData as detailsData } from 'projectDetails';

import { ErrorProjectLogin } from 'errors';
import ProjectTools from './ProjectTools';
import PastDocuments from './PastDocuments';
import SavedProducts from './SavedProducts';
import ProjectHeader from './ProjectHeader';
import ProjectDetailsTabCard from './ProjectTabComponent';

const ProjectDetails = ({
    projectSubmittalSettings = {},
    token,
    generalData,
    guestGeneralData,
    guestOccupation,
    pastSubmittals,
    pastBrochures,
    match,
    dispatch,
}) => {
    const id = match && match.params && match.params.id;

    useEffect(() => {
        dispatch(detailsActions.getProject(id));
        return () => {
            dispatch(detailsActions.clearProjectsDetailsData());
        };
    }, [id]);

    if (projectSubmittalSettings.disabled) {
        history.push('/products');
        return null;
    }

    if (!id) {
        history.push('/projects');
        return null;
    }

    if (projectSubmittalSettings.requiredLogin && !token) {
        return (
            <ErrorProjectLogin>
                Please <Link to="/login">log in</Link> to view this project
            </ErrorProjectLogin>
        );
    }

    if (guestOccupation ? !guestGeneralData : !generalData || generalData.id !== id) return null;

    const { name, created_at: createdDate } = generalData || {};

    const projectToolsProps = {
        id,
        name,
        editProject: () => dispatch(detailsActions.goToProjectEdit(id)),
        deleteProject: () => dispatch(projectsActions.deleteProject(id)),
        createSubmittal: () => dispatch(detailsActions.goToSubmittal(id)),
        createBrochure: () => dispatch(detailsActions.goToBrochure(id)),
    };

    return (
        <React.Fragment>
            <ProjectHeader
                {...(guestOccupation ? { name: 'Guest Project', createdDate: new Date() } : { name, createdDate })}
            />
            <Row>
                <Column mdUp={9} className="leftContent">
                    <ProjectDetailsTabCard {...(guestOccupation ? guestGeneralData : generalData)} />
                    <SavedProducts projectId={id} projectName={name} />
                </Column>
                <Column mdUp={3} className="rightContent">
                    <ProjectTools {...projectToolsProps} />
                    <PastDocuments documents={[...pastSubmittals, ...pastBrochures]} />
                </Column>
            </Row>
        </React.Fragment>
    );
};

ProjectDetails.propTypes = {
    projectSubmittalSettings: PropTypes.object,
    generalData: PropTypes.object,
    token: PropTypes.string,
    pastSubmittals: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
        })
    ),
    pastBrochures: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
        })
    ),
    dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    projectSubmittalSettings: reducerUtil.getSlice(tenantData, tenantData.projectSubmittalSettings, state),
    generalData: reducerUtil.getSlice(detailsData, detailsData.generalData, state),
    guestGeneralData: reducerUtil.getSlice(guestData, guestData.generalData, state),
    guestOccupation: reducerUtil.getSlice(guestData, guestData.guestOccupation, state),
    pastSubmittals: reducerUtil.getSlice(detailsData, detailsData.pastSubmittals, state) || [],
    pastBrochures: reducerUtil.getSlice(detailsData, detailsData.pastBrochures, state) || [],
    token: reducerUtil.getSlice(login.reducerData, login.reducerData.token, state),
});

export default withRouter(connect(mapStateToProps)(ProjectDetails));
