import React from 'react';
import styled from 'styled-components';

import { LinkButton } from 'shared';

const Container = styled(LinkButton)`
    background-color: ${(props) => props.theme.accentColor};
`;

const SubmitButton = ({ children, ...props }) => (
    <Container type="submit" marginBottom="2rem" {...props}>
        {children}
    </Container>
);

export default SubmitButton;
