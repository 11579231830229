import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import media from 'utils/media';

export const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 0 2rem 2rem;
    justify-content: space-between;
    ${media.medium`
    justify-content: unset;
  `};
`;

const Resources = ({ children }) => <Container>{children}</Container>;

Resources.propTypes = {
    children: PropTypes.any.isRequired,
};

export default Resources;
