import React from 'react';
import PropTypes from 'prop-types';

import ProjectTabGroup from './ProjectTabGroup';

const Contact = ({ company_name, contact_name, email, phone, fax, address, ...props }) => {
    const { address_line_1, address_line_2, city, state, zipcode, country } = address || {};
    return (
        <React.Fragment>
            <ProjectTabGroup>
                <strong>Company Name</strong>
                <span>{company_name || 'N/A'}</span>
            </ProjectTabGroup>
            <ProjectTabGroup>
                <strong>Contact Name</strong>
                <span>{contact_name || 'N/A'}</span>
            </ProjectTabGroup>
            <ProjectTabGroup>
                <strong>Email</strong>
                <span>{email || 'N/A'}</span>
            </ProjectTabGroup>
            <ProjectTabGroup>
                <strong>Phone</strong>
                <span>{phone || 'N/A'}</span>
            </ProjectTabGroup>
            <ProjectTabGroup>
                <strong>Fax</strong>
                <span>{fax || 'N/A'}</span>
            </ProjectTabGroup>
            <ProjectTabGroup>
                <strong>Address Line 1</strong>
                <span>{address_line_1 || 'N/A'}</span>
            </ProjectTabGroup>
            <ProjectTabGroup>
                <strong>Address Line 2</strong>
                <span>{address_line_2 || 'N/A'}</span>
            </ProjectTabGroup>
            <ProjectTabGroup>
                <div className="display-inline-block pad-right-15">
                    <strong>City</strong>
                    <span>{city || 'N/A'}</span>
                </div>
                <div className="display-inline-block">
                    <strong>State</strong>
                    <span>{state || 'N/A'}</span>
                </div>
            </ProjectTabGroup>
            <ProjectTabGroup>
                <div className="display-inline-block pad-right-15">
                    <strong>Zip Code/Area Code</strong>
                    <span>{zipcode || 'N/A'}</span>
                </div>
                <div className="display-inline-block">
                    <strong>Country</strong>
                    <span>{country || 'N/A'}</span>
                </div>
            </ProjectTabGroup>
        </React.Fragment>
    );
};

Contact.propTypes = {
    submittals: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            url: PropTypes.string,
        })
    ),
};

export default Contact;
