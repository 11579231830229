import React from 'react';
import { Field } from 'redux-form';

import projectType from 'utils/staticData/projectType';
import ownerType from 'utils/staticData/ownerType';
import projectPhase from 'utils/staticData/projectPhase';

import { Row, Column } from 'shared';

import { FormGroup, ReduxFormDropdown, FormDropdownOption, ReduxFormCheckbox } from 'shared';
import NumberCommaInput from './NumberCommaInput';

const TabProjectEdit = ({
    owner_type,
    owner_occupied,
    purchase_order,
    project_budget,
    project_size,
    project_type,
    project_phase,
    ...props
}) => {
    return (
        <React.Fragment>
            <Row>
                <Column mdUp={6}>
                    <FormGroup>
                        <Field
                            name="project_budget"
                            type="text"
                            component={NumberCommaInput}
                            label="project_budget"
                            labelTitle="Project Budget"
                            prefix="$"
                        />
                    </FormGroup>
                </Column>
            </Row>
            <Row>
                <Column mdUp={6}>
                    <FormGroup>
                        <Field
                            name="project_size"
                            type="text"
                            component={NumberCommaInput}
                            label="project_size"
                            labelTitle="Project Size"
                            suffix="sq. ft"
                        />
                    </FormGroup>
                </Column>
            </Row>
            <Row>
                <Column mdUp={6}>
                    <FormGroup>
                        <Field
                            name="project_type"
                            type="text"
                            component={ReduxFormDropdown}
                            label="project_type"
                            labelTitle="Project Type"
                        >
                            <FormDropdownOption value={''} optionLabel={'Select a Project Type'} disabled />
                            {projectType.map((item) => (
                                <FormDropdownOption key={item} value={item} optionLabel={item} />
                            ))}
                        </Field>
                    </FormGroup>
                </Column>
            </Row>
            <Row>
                <Column mdUp={6}>
                    <FormGroup>
                        <Field
                            name="owner_type"
                            type="text"
                            component={ReduxFormDropdown}
                            label="owner_type"
                            labelTitle="Owner Type"
                        >
                            <FormDropdownOption value={''} optionLabel={'Select a Owner Type'} disabled />
                            {ownerType.map((item) => (
                                <FormDropdownOption key={item} value={item} optionLabel={item} />
                            ))}
                        </Field>
                    </FormGroup>
                </Column>
            </Row>
            <Row>
                <Column mdUp={6}>
                    <FormGroup>
                        <Field
                            name="project_phase"
                            type="text"
                            component={ReduxFormDropdown}
                            label="project_phase"
                            labelTitle="Project Phase"
                        >
                            <FormDropdownOption value={''} optionLabel={'Select a Project Phase'} disabled />
                            {projectPhase.map((item) => (
                                <FormDropdownOption key={item} value={item} optionLabel={item} />
                            ))}
                        </Field>
                    </FormGroup>
                </Column>
            </Row>
            <Row>
                <Column mdUp={6}>
                    <FormGroup>
                        <Field
                            name="owner_occupied"
                            type="checkbox"
                            component={ReduxFormCheckbox}
                            label="owner_occupied"
                            labelTitle="Owner Occupied"
                        />
                    </FormGroup>
                </Column>
            </Row>
        </React.Fragment>
    );
};

export default TabProjectEdit;
