import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { actions as analyticsActions } from 'analytics';

const TrackClick = ({ url, meta, children, dispatch, ...props }) => {
    const onClick = () => dispatch(analyticsActions.trackClick(url, meta));
    return <div {...{ onClick }}>{children}</div>;
};

TrackClick.propTypes = {
    dispatch: PropTypes.func.isRequired,
    meta: PropTypes.shape({}),
    url: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
};

export default connect()(TrackClick);
