import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { SelectedTag } from 'filters';
import SortContainer from './SortContainer';
import { Button } from 'shared';
import { addComma } from 'shared-features-client';

const Container = styled.div`
    margin: 2rem 0;
    z-index: 1;
`;

const Wrapper = styled.div`
    display: flex;
    width: 100%;
    max-width: 125rem;
    margin: auto;
`;

const AppliedFilters = styled.div`
    font-size: 1.4rem;
    font-family: ${(props) => props.theme.fontAlt};
    margin-right: 1rem;
    display: flex;
    flex-wrap: wrap;
    flex: 1;
`;

const RightActions = styled.div`
    margin-left: auto;
    margin-top: -0.3rem;
    display: flex;
    button {
        text-transform: none;
        margin-left: 1rem;
    }
`;

const FilteredCount = styled.div`
    font-weight: 800;
    width: 25rem;
    margin-top: 0.3rem;
    span {
        font-style: italic;
        font-weight: 500;
    }
`;

const SelectedFilters = styled.div`
    flex: 1;
`;

const ClearAll = styled(Button)`
    color: ${(props) => props.theme.accentColor};
    white-space: nowrap;
    &:hover,
    &:focus {
        color: ${(props) => props.theme.blueDarkest};
    }
`;

const ActionsBar = ({ filters, totalHits, removeAllFilters }) => (
    <Container className="action-bar">
        <Wrapper>
            <AppliedFilters>
                <FilteredCount>Showing {addComma(totalHits)} products </FilteredCount>
                <SelectedFilters>
                    {filters.map(({ key, ...filter }) => (
                        <SelectedTag key={key} {...filter} />
                    ))}
                    {filters.length > 1 ? (
                        <ClearAll btnLink onClick={removeAllFilters}>
                            Clear All
                        </ClearAll>
                    ) : (
                        ''
                    )}
                </SelectedFilters>
            </AppliedFilters>

            <RightActions>
                <SortContainer />
            </RightActions>
        </Wrapper>
    </Container>
);

ActionsBar.propTypes = {
    filters: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
        }).isRequired
    ),
    removeAllFilters: PropTypes.func.isRequired,
    totalHits: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

ActionsBar.defaultProps = {
    totalHits: 0,
};

export default ActionsBar;
