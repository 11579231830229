const truncateString = (string, maxLength) => {
    if (string) {
        const words = string
            .trim()
            .replace(/[ ]{2,}/gi, ' ')
            .replace(/\n /, '\n')
            .split(' ');
        return words.length > maxLength ? `${words.slice(0, maxLength).join(' ')}...` : string;
    }

    return;
};

export default truncateString;
