import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { HeaderAssets } from 'shared';
import NoProductImg from 'shared/static/GridNoProductImage.jpg';
import { getPricingInfo, UNITED_STATES, CANADA } from '../../utils/pricingInformation';

const Image = styled.img`
    max-width: 90%;
    width: auto;
    height: auto;
    display: block;
    max-height: 14rem;
`;

const ProductLink = styled(Link)`
    transition: color 0.1s ease;
    word-break: break-word;
    &:hover {
        color: ${(props) => props.theme.linkColor || props.theme.blue};
    }
`;


const Product = props => {
    const { id, thumbnail, name, assets, idx, productInfo, hasPrice } = props
    const information = productInfo && productInfo.information ? productInfo.information : null;

    const prices = getPricingInfo(information);
    // TO DO: wire this to read as prop from state

    const [region, setRegion] = useState('');

    useEffect(() => {
        const region = JSON.parse(localStorage.getItem('region'));
        region ? setRegion(region) : setRegion('United States')
    }, [region, setRegion]);

    const usd = prices && prices['usd'] ? prices['usd'] : null;
    const cad = prices && prices['cad'] ? prices['cad'] : null;

    return (
        <div className="product-compare-product" {...props}>
            {idx !== 0 && (
                <>
                    <Image src={thumbnail ? thumbnail : NoProductImg} alt={name} />
                    <h4>
                        <ProductLink to={`/products/${id}`}>{name}</ProductLink>
                    </h4>
                    {hasPrice && usd && region === UNITED_STATES && <h5>{usd} USD</h5>}
                    {hasPrice && cad && region === CANADA && <h5>{cad} CAD</h5>}
                    <HeaderAssets {...{ assets }} />
                </>
            )}
        </div>
    );
}

export default Product;
