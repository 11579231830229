import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { Helmet } from 'react-helmet';
import { reducerUtil, history } from 'base-client';
import { withRouter } from 'react-router-dom';
import Favicon from 'react-favicon';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';

import { actions as guestActions } from 'guest';
import { reducerData as tenantData } from 'tenant';
import { sbBranding, getGlobals } from 'global-styles';
import CookieBanner from './CookieBanner';

const Tenant = ({
    children,
    customPages,
    favicon,
    font,
    generalProductDetails,
    generalProjectDetails,
    loadGuestSession,
    theme,
    titleTag,
    token,
}) => {
    let newTheme;
    const { family: fontFamily } = font || {};

    if (!fontFamily) {
        newTheme = { ...sbBranding, ...theme };
    } else {
        newTheme = { ...sbBranding, ...theme, fontBody: fontFamily };
    }

    useEffect(() => {
        if (history) {
            const { pathname, search } = history.location;
            // products and projects
            if (
                pathname.includes('/products/') &&
                search.includes('project') &&
                generalProductDetails !== undefined &&
                generalProjectDetails !== undefined
            ) {
                document.title = titleTag + ' | ' + generalProjectDetails.name + ' | ' + generalProductDetails.name;
            }
            // products
            else if (pathname === '/products') {
                document.title = titleTag + ' | Product Search';
            } else if (
                pathname.includes('/products/') &&
                !pathname.includes('submittal') &&
                !pathname.includes('brochure') &&
                generalProductDetails !== undefined
            ) {
                document.title = titleTag + ' | ' + generalProductDetails.name;
            } else if (
                pathname.includes('/products/') &&
                pathname.includes('submittal') &&
                generalProductDetails !== undefined
            ) {
                document.title = titleTag + ' | ' + generalProductDetails.name + ' Submittal';
            }
            // projects
            else if (pathname === '/projects') {
                document.title = titleTag + ' | Projects';
            } else if (
                pathname.includes('/projects/') &&
                !pathname.includes('/brochure') &&
                generalProjectDetails !== undefined
            ) {
                document.title = titleTag + ' | ' + generalProjectDetails.name;
            } else if (
                pathname.includes('/projects/') &&
                pathname.includes('/brochure') &&
                generalProjectDetails !== undefined
            ) {
                document.title = titleTag + ' | ' + generalProjectDetails.name + ' Brochure';
            }
            // etc
            else if (pathname === '/account') {
                document.title = titleTag + ' | Account';
            } else if (pathname.includes('/compare')) {
                document.title = titleTag + ' | Compare Products';
            } else if (pathname === '/submittal') {
                document.title = titleTag + ' | Submittal';
            }
            // custom pages
            else if (customPages && customPages.length > 0) {
                customPages.forEach((page) => {
                    if (pathname.includes(page.url) && page.titleTag !== undefined) {
                        document.title = titleTag + ' | ' + page.titleTag;
                    }
                });
            }
        }
    }, [history.location.pathname, customPages, generalProductDetails, generalProjectDetails]);

    const GlobalStyles = createGlobalStyle`${getGlobals(font)}`;

    useEffect(() => {
        if (theme.gaTrackingID) {
            ReactGA.initialize(theme.gaTrackingID, {
                debug: true,
                titleCase: false,
            });
        }
    }, [theme.gaTrackingID]);

    useEffect(() => {
        if (token) {
            window.localStorage.removeItem('guest');
        }
    }, [token]);

    useEffect(() => {
        loadGuestSession();
    }, []);

    return (
        <React.Fragment>
            <Favicon url={favicon} />
            <Helmet>
                <title>{titleTag}</title>
                <link
                    href={font ? font.url : 'https://fonts.googleapis.com/css?family=IBM+Plex+Sans:400,500,700'}
                    rel="stylesheet"
                    type="text/css"
                />
            </Helmet>
            <GlobalStyles />
            <ThemeProvider theme={newTheme}>
                {children}
                <CookieBanner />
            </ThemeProvider>
        </React.Fragment>
    );
};

Tenant.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
    theme: PropTypes.object,
    favicon: PropTypes.string,
    titleTag: PropTypes.string,
    font: PropTypes.object,
};

Tenant.defaultProps = {
    theme: {},
};

const mapStateToProps = (state) => ({
    theme: reducerUtil.getSlice(tenantData, tenantData.theme, state),
    favicon: reducerUtil.getSlice(tenantData, tenantData.favicon, state),
    titleTag: reducerUtil.getSlice(tenantData, tenantData.titleTag, state),
    font: reducerUtil.getSlice(tenantData, tenantData.font, state),
    customPages: reducerUtil.getSlice(tenantData, tenantData.customPages, state),
    generalProductDetails: reducerUtil.getSlice({ name: 'productDetails' }, 'general', state),
    generalProjectDetails: reducerUtil.getSlice({ name: 'projectDetails' }, 'generalData', state),
    token: reducerUtil.getSlice({ name: 'login' }, 'token', state),
});

const mapDispatchToProps = (dispatch) => ({
    loadGuestSession: () => dispatch(guestActions.getStoredGuestSession()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Tenant));
