import { api, history, login, reducerUtil, config } from 'base-client';
import { v4 as uuid } from 'uuid';

import projectsData from '../reducerData';
import projectActions from './actions';

import { actions as analyticsActions } from 'analytics';
import { reducerData as tenantData } from 'tenant';
import { actions as notificationActions, projectNotification } from 'notification';

/**
 * This opens the modal to add a product or array of products to a project
 * @param {[{id: string, name: string}]} products The name and id of the product(s)
 */
const openModal = (products, isSubmittalWorkflow) => (dispatch, getState) => {
    // add in login check
    const state = getState();
    const token = reducerUtil.getSlice(login.reducerData, login.reducerData.token, state);
    if (!token) {
        const loginPage = reducerUtil.getSlice(login.reducerData, login.reducerData.loginPage, state);
        history.push(loginPage);
        return;
    }
    if (!products) {
        dispatch(reducerUtil.setSlice(projectsData, projectsData.products, undefined));
    } else {
        if (products && !Array.isArray(products)) products = [products];
        if (products.length < 1) return;

        dispatch(reducerUtil.setSlice(projectsData, projectsData.products, products));
    }
    dispatch(reducerUtil.setSlice(projectsData, projectsData.isSubmittalWorkflow, isSubmittalWorkflow));
    dispatch(reducerUtil.setSlice(projectsData, projectsData.modalOpen, true));
};

const closeModal = () => (dispatch) => {
    dispatch(reducerUtil.setSlice(projectsData, projectsData.products, undefined));
    dispatch(reducerUtil.setSlice(projectsData, projectsData.modalOpen, undefined));
    dispatch(reducerUtil.setSlice(projectsData, projectsData.isSubmittalWorkflow, undefined));
};

const modalClose = () => (dispatch) => {
    dispatch(reducerUtil.setSlice(projectsData, projectsData.modalOpen, undefined));
};

/**
 * @desc get projects that belongs to user.
 * @return a list of project
 */
const getAllProjects = (limit) => async (dispatch, getState) => {
    const tenantId = reducerUtil.getSlice(tenantData, tenantData.tenant, getState());
    try {
        const { projects } = await dispatch(
            api.actions.get(`projects?tenant_id=${tenantId}&limit=${limit || 100}`, true)
        );
        return projects;
    } catch (error) {
        dispatch(config.actions.error(error));
    }
};

/**
 * @desc check project name is unique (or not) bases on project list that gets from server
 * @params string (name) : project's name that wants to check
 * @return boolean : true/false
 */
const checkProjectName =
    ({ name }) =>
    async (dispatch, getState) => {
        if (!name) return;
        const tenantId = reducerUtil.getSlice(tenantData, tenantData.tenant, getState());

        try {
            const { projects } = await dispatch(api.actions.get(`projects?tenant_id=${tenantId}`, true));
            return !projects.find(({ name: pName }) => pName === name);
        } catch (error) {
            dispatch(config.actions.error(error));
        }
    };

const createProject = (projectInfo) => async (dispatch, getState) => {
    const canCreate = await dispatch(checkProjectName(projectInfo));
    if (!canCreate) return;

    const tenantId = reducerUtil.getSlice(tenantData, tenantData.tenant, getState());
    const templates = reducerUtil.getSlice(tenantData, tenantData.projectTemplates, getState());

    const { name, zipCode, template } = projectInfo;

    const apiParams = {
        name,
        address: {
            zipcode: zipCode,
        },
    };
    try {
        // create the project
        const result = await dispatch(api.actions.post('projects', { tenant_id: tenantId, ...apiParams }, true));

        if (result) {
            const { id, name } = result;

            dispatch(analyticsActions.track('projectcreate', { project_id: id, name, template: template }));

            template && (await getProductsFromTemplate(dispatch, template, templates));

            return { id, name };
        }
    } catch (error) {
        dispatch(config.actions.error(error));
    }
};

const getProductsFromTemplate = async (dispatch, selectedTemplate, templates) => {
    let category = selectedTemplate.split(':')[0];
    let name = selectedTemplate.split(':')[1];
    let templateCategory = templates.filter((template) => {
        return template.name === category;
    });

    let templateSelected =
        templateCategory &&
        templateCategory[0].templates.filter((t) => {
            return t.name === name;
        });

    const selectedProducts = templateSelected[0].products;
    const fullProducts = await dispatch(
        api.actions.post('search/products/ids', {
            product_ids: selectedProducts,
        }),
        true
    );
    await dispatch(reducerUtil.setSlice(projectsData, projectsData.products, fullProducts));
    return fullProducts;
};

/**
 * @desc add an array of products to a project - products is get from redux store
 * @param Object({id: string, name: string}) The name and id of the project
 */

const addProducts = (project) => async (dispatch, getState) => {
    const state = getState();
    const userId = dispatch(analyticsActions.getUserId());
    const tenantId = reducerUtil.getSlice(tenantData, tenantData.tenant, state);
    const rawProducts = project.products || reducerUtil.getSlice(projectsData, projectsData.products, state);
    const isSubmittalWorkflow = reducerUtil.getSlice(projectsData, projectsData.isSubmittalWorkflow, state);

    if (!rawProducts || (rawProducts && rawProducts.length < 1)) {
        dispatch(showNotification(project));

        const {
            location: { pathname },
        } = history;

        if (pathname === '/projects') {
            dispatch(projectActions.getProjects(true));
        }

        dispatch(closeModal());
    } else {
        const products = rawProducts.map(({ name, id }) => ({ name, id }));

        try {
            const result = await dispatch(
                api.actions.patch(
                    `projects/${project.id}/products`,
                    {
                        user_id: userId,
                        tenant_id: tenantId,
                        products: products.map(({ name, id }) => ({ product_id: id, name })),
                    },
                    true
                )
            );

            if (result) {
                dispatch(showNotification(project, products, isSubmittalWorkflow));

                // send analytics
                const { id, name } = project;
                dispatch(analyticsActions.track('projectaddproduct', { project_id: id, name, products }));
                dispatch(closeModal());
            }
        } catch (error) {
            const errorObject = await error
                .json()
                .then((obj) => obj.error)
                .catch((e) => null);
            if (errorObject && errorObject.message && errorObject.message.startsWith('Record already exists')) {
                dispatch(showNotification(project, products, isSubmittalWorkflow));

                // send analytics
                const { id, name } = project;
                dispatch(analyticsActions.track('projectaddproduct', { project_id: id, name, products }));
                dispatch(closeModal());
            } else {
                dispatch(config.actions.error(error));
            }
        }
    }
};

/**
 * @desc show a product_added notification
 * @param object ({id: string, name: string}), products: array of {id, name})
 */
const showNotification = (project, products, isSubmittalWorkflow) => (dispatch) => {
    const productsList = (products && products.map(({ product_id: id, name }) => ({ id, name }))) || [];
    const { id, name } = project;

    const notification = [
        {
            id: `create-project-${uuid()}`,
            type: projectNotification.PRODUCT_ADDED,
            payloads: {
                isSubmittalWorkflow,
                project: {
                    id,
                    name,
                },
                products: productsList,
            },
        },
    ];
    dispatch(notificationActions.sendNotifications(notification));
};

export default {
    openModal,
    closeModal,
    modalClose,
    checkProjectName,
    createProject,
    addProducts,
    showNotification,
    getProductsFromTemplate,
    getAllProjects,
};
