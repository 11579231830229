/* eslint max-len: 0 */
import React from 'react';
import styled, { css } from 'styled-components';

import PropTypes from 'prop-types';

const DWG_YELLOW = '#F5C507';
const DWG_BLUE = '#0A7BE4';
const RVT_BLUE = '#1858A8';
const SKP_RED = '#E72B2D';
const PDF_RED = '#FF0201';
const TDS_TEAL = '#32BCAD';
const CAD_RED = '#DD2222';
const GREY_STROKE = '#C3CDD9';
const DOC_BLUE = '#2B579A';

const svgIcon = (stroke, fill) =>
    '%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 27"%3E%3Cpath fill="%23F0F3F6" stroke="%23' +
    stroke.replace(/#/, '') +
    '" stroke-width="2" d="M17.3,1H5.5C4.1,1,3,2.1,3,3.5v20C3,24.9,4.1,26,5.5,26h15c1.4,0,2.5-1.1,2.5-2.5V6.7L17.3,1z" /%3E%3Cpath fill="%23' +
    fill.replace(/#/, '') +
    '" d="M1,6h7c0.6,0,1,0.4,1,1v3c0,0.6-0.4,1-1,1H1c-0.6,0-1-0.4-1-1V7C0,6.4,0.4,6,1,6z" /%3E%3C/svg%3E';

const hoverSVG =
    '%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 27"%3E%3Cpath opacity="0.75" fill="%23343E47" d="M17.7,0H5.5C3.6,0,2,1.6,2,3.5V6H1C0.4,6,0,6.4,0,7v3c0,0.6,0.4,1,1,1h1v12.5C2,25.4,3.6,27,5.5,27h15c1.9,0,3.5-1.6,3.5-3.5V6.3L17.7,0z"/%3E%3Cpath fill="%23FFFFFF" d="M15,10.6l-1.4,1.4V8h-1.2v4L11,10.6l-0.8,0.8l2.8,2.8l2.8-2.8L15,10.6z"/%3E%3Cpath fill="%23FFFFFF" d="M17.8,15.2H8.2c-0.7,0-1.2,0.5-1.2,1.2v2.4C7,19.5,7.5,20,8.2,20h9.6c0.7,0,1.2-0.5,1.2-1.2v-2.4C19,15.7,18.5,15.2,17.8,15.2z M14.8,18.2c-0.3,0-0.6-0.3-0.6-0.6s0.3-0.6,0.6-0.6s0.6,0.3,0.6,0.6S15.1,18.2,14.8,18.2z M16.6,18.2c-0.3,0-0.6-0.3-0.6-0.6s0.3-0.6,0.6-0.6c0.3,0,0.6,0.3,0.6,0.6S16.9,18.2,16.6,18.2z"/%3E%3C/svg%3E';

const File = styled.div`
    text-align: center;
    cursor: pointer;
    position: relative;
    width: fit-content;
    margin: ${(props) => (props.small ? '0 0.7rem 0.7rem 0' : '0 1rem 1rem 0')};
`;

// 'File' below is directly referencing the File component above.
//   That's how styled components do it.
const Tooltip = styled.div`
    position: absolute;
    background: ${(props) => props.theme.grey6};
    color: white;
    top: -4rem;
    left: -5rem;
    right: -5rem;
    border-radius: 3px;
    padding: 6px 6px 8px;
    font-size: 1.2rem;
    font-weight: 600;
    opacity: 0;
    transition: opacity 0.3s ease;
    pointer-events: none;
    &:before {
        content: '';
        width: 8px;
        height: 8px;
        background: ${(props) => props.theme.grey6};
        position: absolute;
        left: calc(50% - 4px);
        bottom: -3px;
        border-radius: 2px;
        transform: rotate(45deg);
    }
    ${File}:hover & {
        opacity: 1;
    }
`;

/* prettier-ignore */
const FileIcon = styled.div`
  width: ${props => (props.small ? '24px' : '33px')};
  height: ${props => (props.small ? '27px' : '37px')};
  ${({ fileType }) => {
    switch (fileType) {
      case 'rvt':
        return css`background: url('data:image/svg+xml,${svgIcon(RVT_BLUE, RVT_BLUE)}')`;
      case 'rfa':
        return css`background: url('data:image/svg+xml,${svgIcon(RVT_BLUE, RVT_BLUE)}')`;
      case 'dwg':
        return css`background: url('data:image/svg+xml,${svgIcon(DWG_YELLOW, DWG_BLUE)}')`;
      case 'skp':
        return css`background: url('data:image/svg+xml,${svgIcon(SKP_RED, SKP_RED)}')`;
      case '3ds':
        return css`background: url('data:image/svg+xml,${svgIcon(TDS_TEAL, TDS_TEAL)}')`;
      case 'obj':
        return css`background: url('data:image/svg+xml,${svgIcon(TDS_TEAL, TDS_TEAL)}')`;
      case 'dxf':
        return css`background: url('data:image/svg+xml,${svgIcon(CAD_RED, CAD_RED)}')`;
      case 'dwf':
        return css`background: url('data:image/svg+xml,${svgIcon(RVT_BLUE, RVT_BLUE)}')`;
      case 'pdf':
        return css`background: url('data:image/svg+xml,${svgIcon(GREY_STROKE, PDF_RED)}')`;
      case 'doc':
        return css`background: url('data:image/svg+xml,${svgIcon(GREY_STROKE, DOC_BLUE)}')`;
      case 'docx':
        return css`background: url('data:image/svg+xml,${svgIcon(GREY_STROKE, DOC_BLUE)}')`;
      default /* cad */:
        return css`background: url('data:image/svg+xml,${svgIcon(CAD_RED, CAD_RED)}')`;
    }
  }};
`;

FileIcon.propTypes = {
    fileType: PropTypes.string,
};

export const FileIconHover = styled.div`
    width: ${(props) => (props.small ? '24px' : '33px')};
    height: ${(props) => (props.small ? '27px' : '37px')};
    background: url('data:image/svg+xml,${hoverSVG}');
    position: absolute;
    top: 0;
    opacity: 0;
    transition: opacity 0.2s ease;
    ${File}:hover & {
        opacity: 1;
    }
`;

const FileType = styled.span`
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.3px;
    display: inline-block;
    margin-top: 0.3rem;
`;

const FileImage = ({ fileType, small, noToolTip }) => (
    <File {...{ small }}>
        <FileIcon {...{ fileType, small }} />
        {!noToolTip && (
            <Tooltip>
                Download <FileType>{fileType}</FileType> file
            </Tooltip>
        )}
        <FileIconHover {...{ small }} />
        <FileType>{fileType}</FileType>
    </File>
);

FileImage.propTypes = {
    /** File Type extension */
    fileType: PropTypes.string,
    /** Small version */
    small: PropTypes.bool,
    /** No tooltip, icon only  */
    noToolTip: PropTypes.bool,
};

FileImage.defaultProps = {
    fileType: undefined,
    small: undefined,
    noToolTip: undefined,
};

export default FileImage;
