import React, { Component } from 'react';
import PropTypes from 'prop-types';

import HybridView from './HybridView';
import HybridCard from './HybridCard';

import { HYBRID_ROW_HEIGHT } from '../constants';

/** Class representing a container for the hybrid view. */
class HybridViewContainer extends Component {
    static propTypes = {
        products: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                name: PropTypes.string,
                manufacturer: PropTypes.string,
                category: PropTypes.string,
                subcategory: PropTypes.string,
                thumbnail: PropTypes.string,
                description: PropTypes.string,
            })
        ),
        enableCheckbox: PropTypes.bool,
        toggleSelectProduct: PropTypes.func,
    };

    static defaultProps = {
        products: [],
    };

    rowRenderer = ({ index, key, style }) => {
        const { products, toggleSelectProduct, enableCheckbox, showViewAllAssetsLink, showDownloadAllFilesLink } =
            this.props;
        if (!products) return;

        const product = products[index];
        if (!product) return;

        return (
            <HybridCard
                {...{
                    key,
                    style,
                    product,
                    enableCheckbox,
                    toggleSelectProduct,
                    showViewAllAssetsLink,
                }}
            />
        );
    };

    render() {
        const { products, enableCheckbox, toggleSelectProduct, registerChild } = this.props;

        const additionalProps = {
            rowRenderer: this.rowRenderer,
            rowCount: products ? products.length : 0,
            rowHeight: HYBRID_ROW_HEIGHT,
            enableCheckbox,
            toggleSelectProduct,
        };

        return <HybridView {...this.props} {...additionalProps} />;
    }
}

export default HybridViewContainer;
