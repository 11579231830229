import React from 'react';
import styled from 'styled-components';

import media from 'utils/media';
import { CloseIcon, LinkButton } from 'shared';
// import { Filter, Facet } from 'shared/Filters';
// import { ExpandCollapse } from 'shared';

export const Container = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fafcff;
    overflow-y: auto;
    transform: ${(props) => (props.isOpen ? 'none' : 'translateX(100%)')};
    transition: transform 0.3s cubic-bezier(0.48, 0.14, 0.23, 0.97);
    display: none;
    // ${media.medium`
  // `};
`;

const MobileFiltersHeader = styled.div`
    height: 5rem;
    padding: 2rem;
    background: white;
    display: flex;
    align-items: center;
    position: fixed;
    border-bottom: 1px solid ${(props) => props.theme.grey2};
    width: 100%;
    z-index: 100;
`;

export const MobileFiltersBody = styled.div`
    margin-top: 5rem;
    margin-bottom: ${(props) => (props.isFacetChecked ? '7.5rem' : '0')};
    padding: 2rem 2rem 0;
`;

const SelectedFilters = styled.div`
    margin-bottom: 1rem;
`;

const SelectedFiltersTitle = styled.h3`
    text-transform: uppercase;
    margin: 0 0 1rem;
    font-weight: 400;
    font-size: 1.2rem;
    color: ${(props) => props.theme.grey3};
`;

const BackLink = styled.a`
    position: absolute;
    font-size: 1.4rem;
    font-weight: 600;
    color: ${(props) => props.theme.accentColor};
`;

const Title = styled.div`
    flex: 1;
    font-family: ${(props) => props.theme.fontAlt};
    font-weight: 600;
    font-size: 1.8rem;
    text-align: center;
`;

const FilterWrapper = styled.div`
    padding: 1.8rem 2rem 2rem;
    background: white;
    border-radius: 3px;
    border: 1px solid ${(props) => props.theme.grey2};
    margin-bottom: 1rem;
    position: relative;
    &:after {
        content: '';
        width: 6px;
        height: 6px;
        position: absolute;
        top: 2.5rem;
        right: 2rem;
        border: none;
        border-right: 2px solid ${(props) => props.theme.grey3};
        border-top: 2px solid ${(props) => props.theme.grey3};
        transform: rotate(45deg);
    }
`;

const Facets = styled.div`
    padding: 1.8rem 2rem 2rem;
    background: white;
    border-radius: 3px;
    border: 1px solid ${(props) => props.theme.grey2};
    margin-bottom: 1rem;
`;

export const ApplyFilters = styled.div`
    background: white;
    border-top: 1px solid ${(props) => props.theme.grey1};
    padding: 1.5rem 2rem;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    transform: ${(props) => (props.isFacetChecked ? 'none' : 'translateY(100%)')};
    transition: transform 0.3s cubic-bezier(0.48, 0.14, 0.23, 0.97);
`;

const MobileFilters = ({ isOpen, isFacetChecked }) => (
    <Container {...{ isOpen }}>
        <MobileFiltersHeader>
            <BackLink>Back</BackLink>
            <Title>Refine By</Title>
            <CloseIcon />
        </MobileFiltersHeader>
        {/* isFacetChecked should be true if >=1 facet is checked */}
        <MobileFiltersBody {...{ isFacetChecked }}>
            {isFacetChecked && (
                <SelectedFilters>
                    <SelectedFiltersTitle>Selected Filters</SelectedFiltersTitle>
                    {/*<SelectedTag name="foo" onRemoveClick={() => {}} />
          <SelectedTag name="foo" onRemoveClick={() => {}} />
          <SelectedTag name="foo" onRemoveClick={() => {}} />
          <SelectedTag name="foo" onRemoveClick={() => {}} />*/}
                </SelectedFilters>
            )}
            <FilterWrapper>Manufacturer</FilterWrapper>
            <Facets>
                {/* <Filter title="Manufacturers">
          <Facet onClick={() => {}} label="Manufacturer A has a long name" total={121} />
          <Facet onClick={() => {}} label="Hubbell TayMac" total={46} />
          <Facet onClick={() => {}} label="Hubbell Wiring Device - Kellems" total={28} />
          <Facet onClick={() => {}} label="Intermatic" total={15} />
          <Facet onClick={() => {}} label="Hubbell TayMac" total={46} />
          <Facet onClick={() => {}} label="Hubbell Wiring Device - Kellems" total={28} />
          <Facet onClick={() => {}} label="Intermatic" total={15} />
          <Facet onClick={() => {}} label="Hubbell TayMac" total={46} />
          <Facet onClick={() => {}} label="Hubbell Wiring Device - Kellems" total={28} />
          <Facet onClick={() => {}} label="Intermatic" total={15} />
          <ExpandCollapse showLess onClick={() => {}}>
            children
          </ExpandCollapse>
        </Filter> */}
            </Facets>
            {/* When no facets match the search term */}
            <Facets>{/* <Filter title="Category" defaultValue="" /> */}</Facets>
        </MobileFiltersBody>
        <ApplyFilters {...{ isFacetChecked }}>
            <LinkButton small>Apply Filters</LinkButton>
        </ApplyFilters>
    </Container>
);

export default MobileFilters;
