const DocumentTypes = [
    { type: 'Documents', exts: ['doc', 'docx', 'pdf'] },
    { type: '3D Files', exts: ['rfa', 'rvt', 'dwf', 'dwg', 'dwx', 'skp', 'obj'] },
    { type: 'Images', exts: ['png', 'jpg', 'jpeg'] },
    { type: 'Bundles', exts: ['zip', 'gzip', 'gz'] },
];

const getDocumentTypesByMime = (assetArr) => {
    if (assetArr) {
        const docTypes = {};
        const schema = (type) => {
            return { files: [], type: type, exts: [], selected: [] };
        };

        // creates document array with files array combined by document type
        assetArr.map((asset) => {
            // mimetype keys dont' exist on external attributes
            if (!Object.hasOwn(asset, 'mimetype')) {
                const locLen = asset.location.length;
                const extension = asset.location.substr(locLen - 4, locLen).replace('.', ''); // account for 4 digit ext
                let obj;
                DocumentTypes.every((dtObj) => {
                    return obj
                        ? false // false break every loop
                        : dtObj.exts.forEach((ext) => {
                              if (ext === extension) {
                                  obj = dtObj;
                              }
                          });
                });

                if (!docTypes[obj.type]) {
                    docTypes[obj.type] = schema(obj.type);
                }

                docTypes[obj.type].exts = obj.exts;
                docTypes[obj.type].files.push(asset);
            } else {
                // mimetype keys exist on assets
                if (asset.mimetype.includes('octet-stream')) {
                    if (!docTypes['3D Files']) {
                        docTypes['3D Files'] = schema('3D Files');
                    }

                    docTypes['3D Files'].exts = DocumentTypes[1].exts;
                    docTypes['3D Files'].files.push(asset);
                } else {
                    DocumentTypes.forEach((obj) => {
                        const match = obj.exts.find((ext) => asset.mimetype.includes(ext));
                        if (match) {
                            if (!docTypes[obj.type]) {
                                docTypes[obj.type] = schema(obj.type);
                            }

                            docTypes[obj.type].exts = obj.exts;
                            docTypes[obj.type].files.push(asset);
                        }
                    });
                }
            }
        });

        return { documents: Object.values(docTypes) };
    } else {
        console.warn(`assetArray is ${assetArr}. Returning { documents: [] }`);
        return { documents: [] };
    }
};

export { DocumentTypes, getDocumentTypesByMime };
