export default [
    'Developer',
    'Federal Government',
    'For Profit',
    'Individual',
    'Local Government',
    'Mixed Occupancy',
    'Non Profit',
    'Other',
    'State/Provincial Government',
];
