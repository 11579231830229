import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Facet from './Facet';

const defaultShowAll = false;

const Container = styled.div`
    margin: 1.5rem 0 1.3rem;
`;

const Caret = styled.span`
    float: right;
    font-size: 1.6rem !important;
    font-weight: thin !important;
`;

const Header = styled.div`
    display: flex;
    width: 100%;
    padding: 0;
    justify-content: space-between;
`;

const Title = styled.h4`
    font-size: 1.2rem;
    text-transform: uppercase;
    letter-spacing: 0.3px;
    margin: 0 0 0.5rem;
    color: ${(props) => (props.expanded ? props.theme.grey6 : props.theme.grey3)};
    width: 100%;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
`;

const FilterSearchForm = styled.form`
    position: relative;
`;

const ToggleShowMore = styled.div`
    cursor: pointer;
    color: rgb(9, 113, 211);
`;

const Filter = ({ type, attribute, name, facets = [], numberFacetShow = 4, updateFilter, ...props }) => {
    const [showAll, setShowAll] = useState(defaultShowAll);
    const [expanded, setExpanded] = useState(false);

    if (facets.length < 1) return null;

    const displayFacets = !showAll && facets.length > numberFacetShow ? facets.slice(0, numberFacetShow) : facets;

    return (
        <Container key={attribute} className="filter">
            <Header>
                <Title
                    expanded={expanded}
                    onClick={() => {
                        setExpanded(!expanded);
                    }}
                >
                    {name}&nbsp;({facets.length})
                    <Caret style={expanded ? { transform: 'rotate(90deg)' } : {}}>&gt;</Caret>
                </Title>
            </Header>
            <FilterSearchForm />
            {expanded && (
                <>
                    {displayFacets.map((facetData, index) => (
                        <Facet key={index} {...{ attribute, type, facetData, updateFilter }} />
                    ))}
                    {!defaultShowAll && facets.length > numberFacetShow && (
                        <ToggleShowMore className="toggleBtn" onClick={() => setShowAll(!showAll)}>
                            {showAll ? 'Show less...' : 'Show more...'}
                        </ToggleShowMore>
                    )}
                </>
            )}
        </Container>
    );
};

Filter.propTypes = {
    type: PropTypes.string,
    attribute: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    facets: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            checked: PropTypes.bool,
            count: PropTypes.number,
        })
    ),
    updateFilter: PropTypes.func.isRequired,
};

export default Filter;
