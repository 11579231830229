import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Document from './Document';
import { Card, Button } from 'shared';

const StyledCard = styled(Card)`
    color: ${(props) => props.theme.grey4};
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    padding: 2rem;
`;

const Image = styled.img`
    min-width: 3rem;
    max-height: 3rem;
`;

const Name = styled.h3`
    font-size: 1.4rem;
    line-height: 1.8rem;
    margin: 0 3rem 0 1rem;
    word-break: break-all;
    color: ${(props) => props.theme.grey4};
`;

const ToggleButton = styled.button`
    position: relative;
    border: none;
    flex-grow: 1;
    cursor: pointer;
    padding: 0;
    &:after {
        content: '';
        display: inline-block;
        width: 0.8rem;
        height: 0.8rem;
        position: absolute;
        top: -1rem;
        right: 0;
        transform: rotate(-45deg);
        border-right: 2px solid ${(props) => props.theme.grey3};
        border-top: 2px solid ${(props) => props.theme.grey3};
    }
    &.down:after {
        transform: rotate(135deg);
    }
`;

const Body = styled.div`
    padding: 0 2rem 2rem;
    font-size: 1.2rem;
    transition: opacity 0.3s 0.3s, height 0.3s 0;
    &.hide {
        transition: opacity 0.3s 0, height 0.3s 0.1s;
        height: 0;
        opacity: 0;
        padding-bottom: 0;
        ~ div {
            margin-top: 0;
        }
    }
`;

const NoAssets = styled.p`
    margin: 0;
    text-align: center;
`;

const Footer = styled.div`
    height: 4.3rem;
    font-size: 1.2rem;
    padding: 2rem 1rem 2rem;
    display: flex;
    justify-content: flex-end;
    background-color: ${(props) => props.theme.grey7};
`;

const ButtonGroup = styled.ul`
    display: inline-flex;
    align-items: center;
`;

const ButtonItem = styled.li`
    list-style-position: outside;
    margin: 0 1rem;
    &:first-child {
        list-style: none;
    }
    &:last-child {
        button {
            color: ${(props) => props.theme.red};
        }
    }
    button {
        cursor: pointer;
        font-size: 1.2rem;
        color: ${(props) => props.theme.grey4};
    }
`;

const SubmittalProductSelectCard = ({
    canSelect,
    handleCollapse,
    handleUpdateSelection,
    infiniteListGuestProps = false,
    onRemove,
    product,
    recalculatePosition,
}) => {
    const { thumbnail, id, name, documents = [], collapse, selected = [], guestAssets } = product || {};

    useEffect(() => {
        recalculatePosition();
    }, [collapse, guestAssets, documents]);

    const handleSingleSelect = (evt) => {
        if (collapse === true) return;

        const { checked, name, value } = evt.currentTarget;
        let newSelected;

        // Guest Login requires asset mapping to use names instead of id's
        // (sboauth request has unusual response - ids dont match)
        if (infiniteListGuestProps) {
            newSelected = checked ? [...selected, name] : selected.filter((item) => item !== name);
        } else {
            newSelected = checked ? [...selected, value] : selected.filter((item) => item !== value);
        }

        handleUpdateSelection(id, [...newSelected]);
    };

    const handleUpdateAll = (value) => {
        if (collapse === true) return;

        let result = [];

        if (value) {
            documents.forEach((document) => {
                const { files } = document;
                const newSelected = infiniteListGuestProps ? files.map(({ name }) => name) : files.map(({ id }) => id);
                result = [...result, ...newSelected];
            });
        }
        handleUpdateSelection(id, [...result]);
    };

    return (
        <StyledCard key={id}>
            <Header>
                <Image src={thumbnail} />
                <Name>{name}</Name>
                <ToggleButton
                    className={`toggle-button ${collapse ? 'up' : 'down'}`}
                    onClick={() => handleCollapse(id)}
                />
            </Header>
            <Body className={!collapse ? 'show' : 'hide'}>
                {documents.length > 0 ? (
                    documents.map((document, idx) => {
                        const { type, files = [] } = document;
                        return (
                            <Document
                                {...{
                                    canSelect,
                                    files,
                                    handleSingleSelect,
                                    idx,
                                    isGuest: infiniteListGuestProps ? true : false,
                                    selected,
                                    type,
                                }}
                                key={idx}
                            />
                        );
                    })
                ) : (
                    <NoAssets>This product has no assets</NoAssets>
                )}
            </Body>
            {canSelect && (
                <Footer>
                    <ButtonGroup>
                        {documents.length > 0 && (
                            <React.Fragment>
                                <ButtonItem>
                                    <Button
                                        btnLink
                                        onClick={() => handleUpdateAll(true)}
                                        className={selected.length === documents.length ? 'active' : ''}
                                    >
                                        Select All
                                    </Button>
                                </ButtonItem>
                                <ButtonItem>
                                    <Button
                                        btnLink
                                        onClick={() => handleUpdateAll(false)}
                                        className={!selected || selected.length < 1 ? 'active' : ''}
                                    >
                                        Deselect All
                                    </Button>
                                </ButtonItem>
                            </React.Fragment>
                        )}
                        <ButtonItem>
                            <Button btnLink onClick={() => onRemove(id)}>
                                Remove
                            </Button>
                        </ButtonItem>
                    </ButtonGroup>
                </Footer>
            )}
        </StyledCard>
    );
};

SubmittalProductSelectCard.propTypes = {
    canSelect: PropTypes.bool,
    handleCollapse: PropTypes.func,
    handleUpdateSelection: PropTypes.func,
    infiniteListGuestProps: PropTypes.object,
    onRemove: PropTypes.func,
    product: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        documents: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                name: PropTypes.string,
                location: PropTypes.string,
            })
        ),
    }),
    recalculatePosition: PropTypes.func,
};

export default SubmittalProductSelectCard;
