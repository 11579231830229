import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'react-virtualized';

const gridViewPropTypes = {
    registerChild: PropTypes.func,
};

const GridView = ({ registerChild, ...props }) => (
    <div>
        <Grid {...props} ref={registerChild} />
    </div>
);

GridView.propTypes = gridViewPropTypes;

export default GridView;
