import React from 'react';
import PropTypes from 'prop-types';

export default class OutsideClick extends React.Component {
    static propTypes = {
        onOutside: PropTypes.func,
        children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
    };

    state = {};

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRef = (node) => this.setState({ wrapperRef: node });

    handleClickOutside = (event) => {
        const { wrapperRef } = this.state;
        if (wrapperRef && !wrapperRef.contains(event.target)) {
            const { onOutside } = this.props;
            if (onOutside) onOutside();
        }
    };

    render() {
        const { children } = this.props;
        return <div ref={this.setWrapperRef}>{children}</div>;
    }
}
