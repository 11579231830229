import { useReducer } from 'react';

/*
  Michael:
    Because Featured Products is not standardized with Carousel, swiping logic is done in component

    TODO: Refactor Featured Products - then add in swiping logic to this custom hook
*/

function useCarouselReducer({ transitionSpeed, swipeSensitivity }) {
    const initialState = {
        index: 0,
        isSwiping: false,
        slideCount: undefined,
        transitionSpeed: typeof transitionSpeed === 'number' ? transitionSpeed : 1,
        swipeSensitivity: typeof swipeSensitivity === 'number' ? swipeSensitivity : 20,
    };

    const reducer = (state, action) => {
        let { index, slideCount, isSwiping, transitionSpeed, swipeSensitivity } = state;
        switch (action.type) {
            case 'count':
                return {
                    ...state,
                    slideCount: action.value,
                };
            case 'swiping':
                return {
                    ...state,
                    isSwiping: action.value,
                };
            case 'next':
                return {
                    ...state,
                    index: index + 1 < slideCount ? index + 1 : index,
                    isSwiping: true,
                };
            case 'prev':
                return {
                    ...state,
                    index: index - 1 > -1 ? index - 1 : index,
                    isSwiping: true,
                };
            default:
                console.error(`${action} - Action Type Not Supported`);
        }
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    return [state, dispatch];
}

export default useCarouselReducer;
