import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { ClampLines, HeaderAssets, Card, Checkbox, ButtonText, Button } from 'shared';
import NoProductImg from 'shared/static/NoProductImage.jpg';
import Callout from './Callout';
import AddProductsToProject from '../../projects/components/Modal/AddProductsToProject';

const CardWrapper = styled(Card)`
    width: 100%;
    height: 100%;
    padding: 2rem;
    .select {
        position: absolute;
        left: 1rem;
        top: 1rem;
    }
`;

const Container = styled.div`
  padding: 0 0 2rem;
  margin-left: 1.2rem;
  height: 20rem;
  .header-assets {
    flex: 0 0 100%;
    margin-top: 0.5rem;
    a {
      position: relative;
      &:hover{
        > div{
          opacity: 1;
        }
      }
      span {
        font-size: 1.3rem;
        text-transform: none;
      }
    }
  .callout:nth-child(4) {
    margin-bottom: 0;
  }
`;

const ImageLink = styled(Link)`
    width: 20%;
    height: calc(100% - 2rem);
    margin-top: 2rem;
    float: left;
`;

const ImageNoLink = styled.div`
    width: 20%;
    height: calc(100% - 2rem);
    margin-top: 2rem;
    float: left;
`;

const ImageContainer = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
`;

const Image = styled.img`
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin: 0 auto;
`;

const Info = styled.div`
    padding: 0 2rem 0 4rem;
    width: 40%;
    float: left;
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const Name = styled.h2`
    font-size: 1.8rem;
    font-family: ${(props) => props.theme.fontAlt};
    margin: 0.3rem 0 0;
    font-weight: 800;
`;

const Description = styled.div`
  font-size: 1.4rem;
  line-height: 1.45;
  margin: 1.5rem 0;
  height: 100%;
  &.red-text {
    color:${(props) => props.theme.red};
  }
`;

const Specs = styled.div`
    width: 40%;
    float: left;
    display: flex;
    flex-wrap: wrap;
    padding-left: 2rem;
`;

const RemoveFromProjectText = styled(ButtonText)`
    position: absolute;
    left: 1rem;
    top: 1rem;
    text-align: left;
    font-weight: 500;
    color: ${(props) => props.theme.primaryColor || props.theme.blue};
`;

const ProductProjectButton = styled(Button)`
    margin-top: 10px;
`;

const AssetsTitle = styled.h3`
    letter-spacing: 0.3px;
    color: ${(props) => props.theme.grey3};
    position: relative;
    margin-top: 0;
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
`;
/**
 * Function for creating a component representing a single item in the hybrid view.
 * @return {React.Element} Representation of a single product in hybrid view.
 */
class HybridCard extends Component {
    descRef = React.createRef();
    state = {
        descHeight: 0,
    };
    componentDidMount() {
        const { current } = this.descRef;
        current && this.setState({ descHeight: current.clientHeight });
    }
    render() {
        const {
            style,
            product,
            enableCheckbox,
            toggleSelectProduct,
            showViewAllAssetsLink,
            handleAddProduct,
            handleRemoveProduct,
            inGuestProject,
        } = this.props;

        const {
            id,
            name,
            callouts = [],
            description,
            thumbnail,
            assets = [],
            checked,
            inProject,
            projectId,
            isDeleted,
        } = product;

        const { descHeight } = this.state;

        return isDeleted ? (
            <Container {...{ style }}>
                <CardWrapper>
                    {enableCheckbox && (
                        <Checkbox
                            label="Select"
                            id={id}
                            value={id}
                            checked={checked}
                            onChange={() => toggleSelectProduct(product)}
                        />
                    )}
                    <ImageNoLink>
                        <ImageContainer>
                            <Image src={thumbnail ? thumbnail : NoProductImg} />
                        </ImageContainer>
                    </ImageNoLink>
                    <Info>
                        <Name>
                            <ClampLines text={name || ''} lineHeight={23} lines={5} />
                        </Name>
                        <Description ref={this.descRef} className="red-text">
                            {descHeight > 0 && <ClampLines text={description || ''} maxHeight={descHeight} />}
                        </Description>
                    </Info>
                </CardWrapper>
            </Container>
        ) : (
            <Container {...{ style }}>
                <CardWrapper>
                    {enableCheckbox && !inProject && !inGuestProject && (
                        <Checkbox
                            label="Select"
                            id={id}
                            value={id}
                            checked={checked}
                            onChange={() => toggleSelectProduct(product)}
                        />
                    )}
                    <ImageLink
                        to={id ? { pathname: `/products/${id}`, search: projectId && `?project=${projectId}` } : '#'}
                    >
                        <ImageContainer>
                            <Image src={thumbnail ? thumbnail : NoProductImg} />
                        </ImageContainer>
                    </ImageLink>
                    <Info>
                        <Link
                            to={
                                id ? { pathname: `/products/${id}`, search: projectId && `?project=${projectId}` } : '#'
                            }
                        >
                            <Name>
                                <ClampLines text={name || ''} lineHeight={23} lines={5} />
                            </Name>
                        </Link>
                        <Description ref={this.descRef}>
                            {descHeight > 0 && <ClampLines text={description || ''} maxHeight={descHeight} />}
                        </Description>
                    </Info>
                    <Specs>
                        {callouts.map(({ id, ...props }, index) => (
                            <Callout key={id || index} {...props} />
                        ))}
                        {
                            <ProductProjectButton
                                small
                                inverse={inGuestProject ? !!inGuestProject : !!inProject}
                                color={inProject || inGuestProject ? '#5a6678' : null}
                                onClick={() => {
                                    if (inProject || inGuestProject) {
                                        handleRemoveProduct(id);
                                    } else {
                                        handleAddProduct(id, name, thumbnail, checked, assets);
                                        if (checked) toggleSelectProduct(product);
                                    }
                                }}
                            >
                                {inProject || inGuestProject ? 'Remove from Project' : 'Add to Project'}
                            </ProductProjectButton>
                        }
                    </Specs>
                </CardWrapper>
            </Container>
        );
    }
}

HybridCard.propTypes = {
    product: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        callouts: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                name: PropTypes.string,
                value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            })
        ),
        thumbnail: PropTypes.string,
        description: PropTypes.string,
        assets: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                url: PropTypes.string,
                color: PropTypes.string,
            })
        ),
    }),
    style: PropTypes.object.isRequired,
    enableCheckbox: PropTypes.bool,
    toggleSelectProduct: PropTypes.func,
    showDownloadAllFilesLink: PropTypes.bool,
    inGuestProject: PropTypes.bool,
};
HybridCard.defaultProps = { product: { callouts: [] } };
export default HybridCard;
