import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SearchField from './SearchField';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import { reducerUtil, history } from 'base-client';

import { actions as searchActions, reducerData as searchData } from 'productSearch';
import { actions as workflowSearchActions, reducerData as workflowSearchData } from 'workflowProductSearch';

const QUERY_DELAY = 500;

class SearchFieldContainer extends Component {
    static propTypes = {
        updateQueryParams: PropTypes.func.isRequired,
        queryParams: PropTypes.shape({
            queryString: PropTypes.string,
        }),
    };
    static defaultProps = {
        queryParams: undefined,
    };

    state = {};

    static getDerivedStateFromProps(props, state) {
        const { lastText } = state;
        const { queryParams, workflowQueryParams } = props;

        if (!queryParams) return null;
        const { queryString } = history.location.pathname.startsWith('/projects') ? workflowQueryParams : queryParams;

        if (!!queryString === !!lastText) return null;
        return { text: queryString || '', lastText: queryString || '' };
    }

    postSearch = debounce(() => {
        const { updateQueryParams, workflowUpdateQueryParams } = this.props;
        const { text } = this.state;
        if (history.location.pathname.startsWith('/projects')) {
            workflowUpdateQueryParams(text);
        } else {
            updateQueryParams(text);
        }
    }, QUERY_DELAY);

    handleChange = (event) => {
        this.setState({ text: event.target.value });
        this.postSearch();
    };

    render() {
        const { text } = this.state;
        const searchProps = {
            onChange: (event) => this.handleChange(event),
            value: text,
        };
        return <SearchField {...searchProps} />;
    }
}

const mapStateToProps = (state) => ({
    queryParams: reducerUtil.getSlice(searchData, searchData.query, state),
    workflowQueryParams: reducerUtil.getSlice(workflowSearchData, workflowSearchData.query, state),
});

const mapDispatchToProps = (dispatch) => ({
    updateQueryParams: (text) => dispatch(searchActions.setText(text)),
    workflowUpdateQueryParams: (text) => dispatch(workflowSearchActions.setText(text)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchFieldContainer);
export { QUERY_DELAY, SearchFieldContainer, mapStateToProps, mapDispatchToProps };
