import { api, reducerUtil, history } from 'base-client';

import { actions as analyticsActions } from 'analytics';
import { actions as errorsActions } from 'errors';
import { reducerData as tenantData } from 'tenant';
import { actions as notificationActions, projectNotification } from 'notification';

import { reducerData as proposalData } from 'proposal';
import { actions as detailsActions, reducerData as detailsData } from 'projectDetails';

//collects the product ids and asset ids and sends them to the api to create submittal
const createSubmittal = () => async (dispatch, getState) => {
    const state = getState();
    const tenantId = reducerUtil.getSlice(tenantData, tenantData.tenant, state);
    const products = reducerUtil.getSlice(proposalData, proposalData.selectedProducts, state);
    const project = reducerUtil.getSlice(detailsData, detailsData.generalData, state);
    const submittalOptions = reducerUtil.getSlice(detailsData, detailsData.submittalOptions, state);

    const { id, name } = project;

    if (submittalOptions && !submittalOptions.targetEmail.length) {
        await dispatch(
            notificationActions.sendNotifications([
                {
                    id: 'error-submittal',
                    type: projectNotification.GENERAL,
                    title: 'Error',
                    body: 'Please enter generation method email before continuing',
                },
            ])
        );
        setTimeout(function () {
            dispatch(notificationActions.removeNotification('error-submittal'));
        }, 5000);
        return;
    }

    // reformat product data for submittal post
    const reformatProducts = (products) => {
        const formattedProducts = { product_asset_mapping: {} };
        products.map((product) => {
            const { id, selected } = product;
            formattedProducts.product_asset_mapping[id] = selected;
        });

        return formattedProducts;
    };

    const reformattedProducts = products ? reformatProducts(products) : products;
    let submittalId;

    const notification = [
        {
            id: submittalOptions && submittalOptions.isDirectDownload ? 'download-submittal' : 'email-submittal',
            type:
                submittalOptions && submittalOptions.isDirectDownload
                    ? projectNotification.DOWNLOAD_PROPOSAL
                    : projectNotification.EMAIL_PROPOSAL,
            payloads: {
                project: {
                    id,
                    name,
                },
            },
        },
    ];
    dispatch(notificationActions.sendNotifications(notification, true));

    try {
        const result = await dispatch(
            api.actions.post(
                `projects/${id}/submittals?tenant_id=${tenantId}`,
                JSON.stringify({ ...reformattedProducts, submittalOptions }),
                true
            )
        );

        if (result) {
            submittalId = result && result.submittal_id;

            dispatch(analyticsActions.track('submittal', { project_id: id, name, submittal_id: submittalId }));
            dispatch(
                notificationActions.removeNotification(
                    submittalOptions.isDirectDownload ? 'download-submittal' : 'email-submittal'
                )
            );
            const queryStr = encodeURIComponent('?path=/&msg=Submittal Successful');
            history.push(`/redirect/${queryStr}`);
        }
    } catch (error) {
        dispatch(errorsActions.error(error));
    }
    try {
        await dispatch(pollSubmittal(submittalId, project));
    } catch (error) {
        dispatch(errorsActions.error(error));
    }
};

const createBrochure = () => async (dispatch, getState) => {
    const state = getState();
    const tenantId = reducerUtil.getSlice(tenantData, tenantData.tenant, state);
    const products = reducerUtil.getSlice(proposalData, proposalData.selectedProducts, state);
    const orderedProducts = reducerUtil.getSlice(proposalData, proposalData.orderedProducts, state);
    const project = reducerUtil.getSlice(detailsData, detailsData.generalData, state);
    const submittalOptions = reducerUtil.getSlice(detailsData, detailsData.submittalOptions, state);

    const { id, name } = project;

    if (submittalOptions && !submittalOptions.targetEmail.length) {
        await dispatch(
            notificationActions.sendNotifications([
                {
                    id: 'error-submittal',
                    type: projectNotification.GENERAL,
                    title: 'Error',
                    body: 'Please enter generation method email before continuing',
                },
            ])
        );
        setTimeout(function () {
            dispatch(notificationActions.removeNotification('error-submittal'));
        }, 5000);
        return;
    }


    // reformat product data for submittal post
    const reformatProducts = (products) => {
        const formattedProducts = { product_asset_mapping: {}, product_order: orderedProducts };
        products.map((product) => {
            const { id } = product;
            const { selected } = product;

            formattedProducts.product_asset_mapping[id] = selected;
        });

        return formattedProducts;
    };

    const reformattedProducts = products ? reformatProducts(products) : products;
    let brochureId;

    const notification = [
        {
            id: submittalOptions && submittalOptions.isDirectDownload ? 'download-brochure' : 'email-brochure',
            type:
                submittalOptions && submittalOptions.isDirectDownload
                    ? projectNotification.DOWNLOAD_PROPOSAL
                    : projectNotification.EMAIL_PROPOSAL,
            payloads: {
                project: {
                    id,
                    name,
                },
            },
        },
    ];
    dispatch(notificationActions.sendNotifications(notification, true));

    try {
        const result = await dispatch(
            api.actions.post(
                `projects/${id}/brochures?tenant_id=${tenantId}`,
                JSON.stringify(reformattedProducts),
                true
            )
        );

        if (result) {
            brochureId = result && result.brochure_id;
            // send analytics
            dispatch(analyticsActions.track('brochure', { project_id: id, name, brochure_id: brochureId }));
            dispatch(
                notificationActions.removeNotification(
                    submittalOptions.isDirectDownload ? 'download-brochure' : 'email-brochure'
                )
            );
            const queryStr = encodeURIComponent('?path=/&msg=Brochure Successful');
            history.push(`/redirect/${queryStr}`);
        }
    } catch (error) {
        dispatch(errorsActions.error(error));
    }
    try {
        await dispatch(pollBrochure(brochureId, project));
    } catch (error) {
        dispatch(errorsActions.error(error));
    }
};

const wait = (ms) => (async) => {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
};

let count = 0;
const pollSubmittal = (submittalId, project) => async (dispatch, getState) => {
    const { id } = project;

    try {
        const response = await dispatch(api.actions.get(`projects/${id}/submittals/${submittalId}`, true));
        if (response) {
            setTimeout(() => {
                dispatch(notificationActions.removeNotification('download-submittal'));
            }, 5000);
        }
    } catch (error) {
        dispatch(errorsActions.error(error));
        return;
    }
};

const pollBrochure = (brochureId, project) => async (dispatch, getState) => {
    const { id } = project;
    // while (count < 60) {
        try {
            const response = await dispatch(api.actions.get(`projects/${id}/brochures/${brochureId}`, true));
            if (response) {

                setTimeout(() => {
                    dispatch(notificationActions.removeNotification('download-brochure'));
                }, 5000);


                // if (response.link) {
                //     const { link } = response;
                //     dispatch(reducerUtil.setSlice(proposalData, proposalData.downloadLink, link));
                //     window.location.href = link;
                //     dispatch(notificationActions.removeNotification('download-proposal'));
                //     return;
                // } else {
                //     count += 1;
                //     await dispatch(wait(2000));
                //     await dispatch(pollBrochure);
                // }
            }
        } catch (error) {
            dispatch(errorsActions.error(error));
            return;
        }
   // }
};

const removeProduct = (id) => (dispatch, getState) => {
    const state = getState();
    const { orderedProducts } = state.proposal;

    const newOrderedProducts = [...orderedProducts].filter((productId) => productId !== id);

    dispatch(reducerUtil.setSlice(proposalData, proposalData.orderedProducts, newOrderedProducts));
};

const addProduct = (id) => (dispatch, getState) => {
    const state = getState();
    const { orderedProducts } = state.proposal;

    const newOrderedProducts = !orderedProducts ? [id] : [...orderedProducts, id];

    dispatch(reducerUtil.setSlice(proposalData, proposalData.orderedProducts, newOrderedProducts));
};

const setProducts = (productIds) => (dispatch, getState) => {
    const state = getState();

    const newOrderedProducts = state.projectDetails.products.map((product) => {
        return product.id;
    });

    dispatch(reducerUtil.setSlice(proposalData, proposalData.orderedProducts, newOrderedProducts));
};

//clear proposal data
const clearProposalData = () => (dispatch, getState) => {
    dispatch(reducerUtil.setSlice(proposalData, proposalData.selectedProducts, undefined));
    dispatch(reducerUtil.setSlice(proposalData, proposalData.orderedProducts, undefined));
};

export default {
    addProduct,
    clearProposalData,
    createBrochure,
    createSubmittal,
    pollBrochure,
    pollSubmittal,
    removeProduct,
    setProducts,
};
