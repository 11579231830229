import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import states from 'utils/staticData/states';
import countries from 'utils/staticData/countries';

import { ReduxFormInput, ReduxFormDropdown, FormDropdownOption } from 'shared';
import { positiveNumber, isEmail } from 'utils/reduxFormValidator';

const ContactEdit = ({
    company_name,
    contact_name,
    email,
    phone,
    fax,
    address_line_1,
    address_line_2,
    city,
    state,
    zipcode,
    country,
    entity,
    ...props
}) => {
    const [_country, setCountry] = useState(0);

    return (
        <React.Fragment>
            <Field name={`${entity}.company_name`} type="text" component={ReduxFormInput} labelTitle="Company Name" />
            <Field name={`${entity}.contact_name`} type="text" component={ReduxFormInput} labelTitle="Contact Name" />
            <Field
                name={`${entity}.email`}
                type="text"
                component={ReduxFormInput}
                labelTitle="Email"
                validate={[isEmail]}
            />
            <Field name={`${entity}.phone`} type="text" component={ReduxFormInput} labelTitle="Phone" />
            <Field name={`${entity}.fax`} type="text" component={ReduxFormInput} labelTitle="Fax" />
            <Field
                name={`${entity}.address.address_line_1`}
                type="text"
                component={ReduxFormInput}
                labelTitle="Address Line 1"
            />
            <Field
                name={`${entity}.address.address_line_2`}
                type="text"
                component={ReduxFormInput}
                labelTitle="Address Line 2"
            />
            <Field name={`${entity}.address.city`} type="text" component={ReduxFormInput} labelTitle="City" />
            <Field
                name={`${entity}.address.country`}
                type="text"
                component={ReduxFormDropdown}
                labelTitle="Country"
                onChange={(event) => setCountry(event.target.value)}
            >
                <FormDropdownOption value={''} optionLabel={'Select a Country'} disabled />
                {countries.map((item) => (
                    <FormDropdownOption
                        key={item.name}
                        value={item.name}
                        optionLabel={item.name}
                        onClick={() => setCountry(item.name)}
                    />
                ))}
            </Field>
            {_country === 'United States' ? (
                <Field name={`${entity}.address.state`} type="text" component={ReduxFormDropdown} labelTitle="State">
                    <FormDropdownOption value={''} optionLabel={'Select a State'} disabled />
                    {states.map((item) => (
                        <FormDropdownOption
                            key={item.abbreviation}
                            value={item.abbreviation}
                            optionLabel={item.abbreviation}
                        />
                    ))}
                </Field>
            ) : (
                <Field
                    name={`${entity}.address.state`}
                    type="text"
                    component={ReduxFormInput}
                    labelTitle="State/Province/Regions"
                />
            )}
            <Field
                name={`${entity}.address.zipcode`}
                type="text"
                component={ReduxFormInput}
                labelTitle="Zip Code/Area Code"
                validate={[positiveNumber]}
            />
        </React.Fragment>
    );
};

ContactEdit.propTypes = {
    submittals: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            url: PropTypes.string,
        })
    ),
};

export default ContactEdit;
