import React from 'react';
import { connect } from 'react-redux';
import { history, login as baseLogin, reducerUtil } from 'base-client';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { reducerData as tenantData } from 'tenant';
import LoginContainer from './LoginContainer';
import SignUpContainer from './SignUpContainer';

const Container = styled.div`
    margin: 8.4rem auto;
    border: 1px solid ${(props) => props.theme.grey2};
    border-radius: 3px;
    background: white;
    position: relative;
    max-width: 35rem;
    width: 100%;
    padding: 2rem;
`;

export class LoginSignUpContainer extends React.Component {
    constructor(props) {
        super(props);
        const { location } = props;

        if (location && location.state) {
            this.state = { ...location.state };
        } else {
            this.state = {};
        }
    }
    static propTypes = {
        disabled: PropTypes.bool,
        logoText: PropTypes.string,
        logoImageUrl: PropTypes.string,
        tenantId: PropTypes.string
    };

    toLogin = () => this.setState({ signUp: undefined });
    toSignUp = () => this.setState({ signUp: true });

    render() {
        const { disabled, logoText, logoImageUrl, token, tenantId, ...props } = this.props;
        if (disabled) return null;
        token && history.push('/');

        const { signUp } = this.state;

        const toLogin = this.toLogin;
        const toSignUp = this.toSignUp;

        return (
            <React.Fragment>
                <Container>
                    {signUp ? (
                        <SignUpContainer {...{ logoText, logoImageUrl, toLogin, tenantId }} />
                    ) : (
                        <LoginContainer {...{ logoText, logoImageUrl, toSignUp, tenantId }} />
                    )}
                </Container>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const { name: logoText, logo: logoImageUrl } = reducerUtil.getSlice(tenantData, tenantData.theme, state) || {};
    const { login } = reducerUtil.getSlice(tenantData, tenantData.disabled, state) || {};
    const { token } = reducerUtil.getSlice(baseLogin, baseLogin.reducerData, state) || {};
    const tenantId = reducerUtil.getSlice(tenantData, tenantData.tenant, state);
    return { disabled: login, logoImageUrl, logoText, token, tenantId };
};

export default withRouter(connect(mapStateToProps)(LoginSignUpContainer));
