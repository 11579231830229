import { api, reducerUtil, config, history } from 'base-client';
import { v4 as uuid } from 'uuid';

import { reducerData } from 'projects';
import { reducerData as tenantData } from 'tenant';
import { actions as analyticsActions } from 'analytics';
import sortOptions from '../sortOptions';
import { actions as notificationActions, projectNotification } from 'notification';

const getProjects = (restart) => async (dispatch, getState) => {
    let state = getState();
    let { pagination, sort } = reducerUtil.getSlice(reducerData, reducerData.meta, state) || {};
    if (!sort) sort = sortOptions[0];
    const page = restart ? 1 : ((pagination && pagination.page) || 0) + 1;
    const limit = 100;

    const fetchId = uuid();

    dispatch(reducerUtil.setSlice(reducerData, reducerData.fetchId, fetchId));

    const tenantId = reducerUtil.getSlice(tenantData, tenantData.tenant, state);

    try {
        const result = await dispatch(
            api.actions.get(
                `projects?tenant_id=${tenantId}&page=${page}
        &limit=${limit}&order[${sort.sortBy}]=${sort.sortDir}`,
                true
            )
        );

        // check that this is the correct fetch
        state = getState();
        if (fetchId !== reducerUtil.getSlice(reducerData, reducerData.fetchId, state)) return;

        // allow another search
        dispatch(reducerUtil.setSlice(reducerData, reducerData.fetchId, undefined));

        // get loaded projects if not restarting
        const loadedProjects = (!restart && reducerUtil.getSlice(reducerData, reducerData.projects, state)) || [];

        const newList = result.projects ? [...loadedProjects, ...result.projects] : [...loadedProjects];
        // set the data
        dispatch(reducerUtil.setSlice(reducerData, reducerData.projects, newList));
        dispatch(reducerUtil.setSlice(reducerData, reducerData.meta, { pagination: result.pagination, sort }));
    } catch (error) {
        dispatch(config.actions.error(error));
    }
};

const addSelectedProductsToProject = (products, project) => async (dispatch, getState) => {
    if (!products) {
        return;
    }
    if (products && !Array.isArray(products)) products = [products];
    if (products.length < 1) return;

    const state = getState();

    const userId = dispatch(analyticsActions.getUserId());
    const tenantId = reducerUtil.getSlice(tenantData, tenantData.tenant, state);
    try {
        const result = await dispatch(
            api.actions.patch(
                `projects/${project.id}/products`,
                {
                    user_id: userId,
                    tenant_id: tenantId,
                    products: products.map(({ name, id }) => ({ product_id: id, name })),
                },
                true
            )
        );
        if (result) {
            await dispatch(showNotification(project, products));

            const { id, name } = project;
            dispatch(analyticsActions.track('projectaddproduct', { project_id: id, name, products }));
        }
        return;
    } catch (error) {
        const errorObject = await error
            .json()
            .then((obj) => obj.error)
            .catch((e) => null);
        if (errorObject && errorObject.message && errorObject.message.startsWith('Record already exists')) {
            await dispatch(showNotification(project, products));

            const { id, name } = project;
            dispatch(analyticsActions.track('projectaddproduct', { project_id: id, name, products }));
        } else {
            dispatch(config.actions.error(error));
        }
    }
};

const removeProductFromProject = (product_id, project) => async (dispatch, getState) => {
    const state = getState();

    const userId = dispatch(analyticsActions.getUserId());
    const tenantId = reducerUtil.getSlice(tenantData, tenantData.tenant, state);

    try {
        await dispatch(
            api.actions.delete(
                `projects/${project.id}/products`,
                { user_id: userId, tenant_id: tenantId, product_ids: [product_id] },
                true
            )
        );

        const notification = [
            {
                id: `update-project-${uuid()}`,
                type: projectNotification.PRODUCT_REMOVED,
                payloads: {
                    project: {
                        id: project.id,
                        name: project.name,
                    },
                    products: [product_id],
                },
            },
        ];
        dispatch(notificationActions.sendNotifications(notification));
        // send analytics
        dispatch(
            analyticsActions.track('projectremoveproduct', {
                project_id: project.id,
                name: project.name,
                products: [product_id],
            })
        );

        //refresh the data for project details page.
    } catch (error) {
        dispatch(config.actions.error(error));
    }
};

const showNotification = (project, products) => (dispatch) => {
    const productsList = (products && products.map(({ product_id: id, name }) => ({ id, name }))) || [];
    const { id, name } = project;

    const notification = [
        {
            id: `create-project-${uuid()}`,
            type: projectNotification.PRODUCT_ADDED,
            payloads: {
                project: {
                    id,
                    name,
                },
                products: productsList,
            },
        },
    ];
    dispatch(notificationActions.sendNotifications(notification));
};

const deleteProject = (id) => async (dispatch, getState) => {
    let state = getState();
    const tenantId = reducerUtil.getSlice(tenantData, tenantData.tenant, state);
    try {
        await dispatch(api.actions.delete(`projects/${id}`, { tenant_id: tenantId }, true));

        // send analytics
        const projectList = reducerUtil.getSlice(reducerData, reducerData.projects, state);
        const { name } = projectList.find(({ id: projectId }) => projectId === id) || {};
        dispatch(analyticsActions.track('projectdelete', { project_id: id, name }));

        const {
            location: { pathname },
        } = history;

        if (pathname !== '/projects') {
            history.push('/projects');
        } else {
            dispatch(getProjects(true));
        }
    } catch (error) {
        dispatch(config.actions.error(error));
    }
};

const nextProjectsPage = () => (dispatch) => dispatch(getProjects());

export default {
    getProjects,
    deleteProject,
    nextProjectsPage,
    addSelectedProductsToProject,
    removeProductFromProject,
};
