import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    font-size: 1.2rem;
    strong {
        margin: 0;
        display: block;
        font-weight: bold;
    }
`;

const ProjectTabContent = ({ children }) => <Container>{children}</Container>;

export default ProjectTabContent;
