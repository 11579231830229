import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import media from 'utils/media';
import { DisplayLink, buttonStyles } from 'shared';

const Container = styled.div`
    padding: 2rem;
    ${media.medium`
    display: flex;
  `};
`;

const LeftContent = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    ${media.medium`
    width: 38%;
    padding-right: 5rem;
    align-items: flex-start;
    text-align: left;
    justify-content: center;
  `};
`;

const RightContent = styled.div`
    border-radius: 6px;
    background-image: url(${(props) => props.background});
    padding-top: 90%;
    background-size: cover;
    background-position: 100%;
    ${media.small`
    padding-top: 50%;
  `};
    ${media.medium`
    width: 62%;
    padding-top: 40%;
  `};
    ${media.large`
    padding-top: 40%;
  `};
`;

const Title = styled.h1`
    font-size: 3.4rem;
    font-weight: 800;
    margin: 2rem 0 0.5rem;
    line-height: 1.2;
`;

const SubTitle = styled.p`
    margin: 0;
    color: ${(props) => props.theme.grey4};
    font-size: 1.8rem;
    line-height: 1.4;
`;

const Desc = styled.p`
    font-size: 1.4rem;
    margin-top: 2rem;
`;

const ReadMoreLink = styled(DisplayLink)`
    ${buttonStyles} {
        padding-right: 2.4rem;
        margin: 0.5rem 0 1.5rem;
        align-self: center;
        font-size: 1.2rem;
        line-height: 3rem;
        background: ${(props) => (props.theme.primaryColor ? props.theme.primaryColor : props.theme.blue)};
        :after {
            content: '';
            display: inline-block;
            width: 6px;
            height: 6px;
            position: absolute;
            top: 11px;
            right: 12px;
            border-right: 2px solid ${(props) => (props.secondary ? props.theme.grey3 : '#fff')};
            border-top: 2px solid ${(props) => (props.secondary ? props.theme.grey3 : '#fff')};
            transform: rotate(45deg);
        }
        ${media.medium`
      align-self: flex-start;
    `};
    }
`;

const HeroArticle = ({ title, subTitle, description, link, image }) => {
    const { displayValue, url } = link || {};
    return (
        <Container>
            <LeftContent>
                <Title>{title}</Title>
                {subTitle && <SubTitle>{subTitle}</SubTitle>}
                {description && <Desc>{description}</Desc>}
                {url && <ReadMoreLink {...{ url }}>{displayValue}</ReadMoreLink>}
            </LeftContent>
            <RightContent background={image} />
        </Container>
    );
};

HeroArticle.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    description: PropTypes.string,
    link: PropTypes.shape({
        displayValue: PropTypes.string,
        url: PropTypes.string,
    }),
    image: PropTypes.string,
};

HeroArticle.defaultProps = {
    title: '',
    subTitle: '',
    description: '',
    link: {},
    image: '',
};

export default HeroArticle;
