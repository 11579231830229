import React from 'react';
import styled from 'styled-components';
import { history } from 'base-client';

const Container = styled.div`
    display: flex;
    margin: -50px auto 22px auto;
    columns: 3;
    column-gap: 20px;
    padding: 0px 20px;
    @media (max-width: 768px) {
        margin-top: 2px;
        flex-wrap: wrap;
    }
`;

const CardContainer = styled.div`
    background: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 3px;
    cursor: pointer;
    margin: 0px 0px 20px 0px;
    border-left: 4px solid ${(props) => props.theme.primaryColor || '#33659C'};
    padding: 20px 20px 15px 20px;
    box-shadow: 0 3px 5px rgba(52, 62, 71, 0.12);
    @media (max-width: 768px) {
        width: 50%;
        margin: 5px auto;
    }
`;

const CardTitle = styled.div`
    font-size: 18px;
    font-weight: 600px;
    margin: 0px auto 8px 0px;
`;

const CardSubtitle = styled.div`
    font-size: 14px;
    line-height: 20px;
    margin: 0px auto 8px 0px;
`;

const CardDescription = styled.div`
    font-size: 14px;
    line-height: 20px;
    margin: 0px auto 8px 0px;
`;

const CardLinkButton = styled.div`
    border-radius: 100%;
    background-color: ${(props) => props.theme.primaryColor || '#33659C'};
    width: 24px;
    height: 24px;
    margin: auto auto 0px 0px;
`;

const ArrowIcon = styled.i`
    display: inline-block;
    margin: auto 0px auto 10px;
    font-size: 12px;
    width: 0.8rem;
    right: 0.8rem;
    height: 0.8rem;
    border-top: 2px solid white;
    border-right: 2px solid white;
    transform: rotate(45deg);
    &:after {
        content: '';
        position: absolute;
        border-top: 2px solid white;
        top: 2px;
        right: -7px;
        width: 14px;
        height: 14px;
        height: 14px;
        transform: rotate(-45deg);
    }
`;

const CardImage = styled.div`
    width: 400px;
    height: 300px;
    background: ${(props) => (props.image ? `url(${props.image})` : 'none')};
    background-repeat: no-repeat;
    background-size: cover;
`;

const Card = ({ title, subtitle, description, image, link }) => {
    return (
        <CardContainer
            onClick={() => link && (link.startsWith('/') ? history.replace(link) : (window.location.href = link))}
        >
            {image && <CardImage image={image} />}
            <CardTitle>{title}</CardTitle>
            {subtitle && <CardSubtitle>{subtitle}</CardSubtitle>}
            {description && <CardDescription>{description}</CardDescription>}
            <CardLinkButton>
                <ArrowIcon />
            </CardLinkButton>
        </CardContainer>
    );
};

const CardBanner = ({ top, columns, cards }) => {
    if (!cards || cards.length === 0) return null;
    return (
        <Container columns={columns} top={top}>
            {cards.map((card, idx) => (
                <Card {...{ ...card }} key={idx} />
            ))}
        </Container>
    );
};

export default CardBanner;
