import styled from 'styled-components';

const SearchIcon = styled.div`
    width: ${(props) => (props.small ? '1.2rem' : '1.5rem')};
    height: ${(props) => (props.small ? '1.2rem' : '1.5rem')};
    border-radius: 100%;
    border: 2px solid ${(props) => (props.light ? props.theme.grey2 : props.theme.grey4)};
    position: relative;
    top: ${(props) => (props.small ? '0' : '-0.2rem')};
    &:after {
        content: '';
        position: absolute;
        width: 0.2rem;
        height: ${(props) => (props.small ? '0.6rem' : '0.8rem')};
        background: ${(props) => (props.light ? props.theme.grey2 : props.theme.grey4)};
        transform: rotate(-45deg);
        right: ${(props) => (props.small ? '-0.3rem' : '-0.2rem')};
        top: ${(props) => (props.small ? '0.7rem' : '0.9rem')};
    }
`;

export default SearchIcon;
