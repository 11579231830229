import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { actions as configActions } from 'configurations';

class Config extends React.Component {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        history: PropTypes.shape({ listen: PropTypes.func.isRequired }).isRequired,
        children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
    };

    state = {};

    componentDidMount() {
        this.startApp();
        this.fixScroll();
    }

    startApp = async () => {
        const { dispatch } = this.props;
        await dispatch(configActions.start());

        this.setState({ started: true });
    };

    fixScroll = () => {
        const { history } = this.props;
        history.listen(({ pathname }) => {
            const { pathname: lastPath } = this.state;
            if (pathname === lastPath) return;

            window.scrollTo(0, 0);
            this.setState({ pathname });
        });
    };

    render() {
        const { started } = this.state;
        if (!started) {
            return null;
        }

        const { children } = this.props;
        return children;
    }
}

export default withRouter(connect()(Config));
