/* eslint max-len: 0 */
import React from 'react';
import PropTypes from 'prop-types';

import { FileContainer, FileImage } from 'shared';

const FileAttachment = ({ fileType, url, ...props }) => (
    <FileContainer {...{ url }} id={fileType} name={`${fileType}.zip`}>
        <FileImage {...{ fileType, ...props }} />
    </FileContainer>
);

FileAttachment.propTypes = {
    fileType: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
};

FileAttachment.defaultProps = {};

export default FileAttachment;
