import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { AddToProjectButton, actions as projectActions, reducerData as projectData } from 'projects';
import { CloseIcon, Button } from 'shared';
import { connect } from 'react-redux';
import { reducerUtil } from 'base-client';
import ComparisonButton from './ComparisonButton';
import ProjectCreationModal from '../../projects/components/Modal/ProjectCreationModal';

const Container = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    background-color: ${(props) => props.theme.grey5};
    color: ${(props) => props.theme.white};
    height: 50px;
    border-radius: 4px;
    box-shadow: 0 3px 5px rgba(52, 62, 71, 0.12);
    padding-left: 2rem;
    font-size: 1.4rem;
    font-weight: 500;
    margin-bottom: 1rem;
    text-align: center;
    button {
        text-transform: none;
        margin-right: 1rem;
        font-weight: 500;
        line-height: 2rem;
        height: 2.5rem;
        padding: 0 1rem;
        border: 1px solid ${(props) => props.theme.grey3};
        color: ${(props) => props.theme.white};
        background-color: ${(props) => props.theme.lightBlack};
        &:hover {
            color: ${(props) => props.theme.lightBlack};
            background-color: ${(props) => props.theme.white};
            border-color: ${(props) => props.theme.white};
        }
    }
`;

const CloseButton = styled(CloseIcon)`
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    margin-right: 10px;
    &:before,
    &:after {
        background-color: ${(props) => props.theme.white};
        width: 0.2rem;
    }
    &:hover {
        &:before,
        &:after {
            background-color: ${(props) => props.theme.white};
        }
    }
`;

const SelectedText = styled.span`
    font-size: 1.2rem;
    letter-spacing: 0.2px;
    line-height: 3rem;
    font-weight: 500;
    margin-right: 3rem;
`;

const initialState = {
    isShow: true,
};

const ActionBanner = ({ selectedProducts, clearSelectedProducts, removeProductsFromProject, isOpen, closeModal }) => {
    const [state, setstate] = useState(initialState);
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        const isShow = selectedProducts && selectedProducts.length > 0 ? true : false;
        if (state.isShow !== isShow) {
            return setstate({
                ...state,
                isShow,
            });
        }
    }, [selectedProducts]);

    useEffect(() => {}, [modalOpen]);

    if (!selectedProducts) return null;

    const handleModalClick = async () => {
        setModalOpen(!modalOpen);
    };

    const { isShow } = state;

    const onCloseBanner = () => {
        setstate({ ...state, isShow: false });
        clearSelectedProducts();
    };

    const onClose = () => {
        closeModal();
        setModalOpen(false);
    };

    return isShow ? (
        <Container className="action-banner tooltipTop">
            {modalOpen && (
                <ProjectCreationModal
                    title={'Add Products to Project'}
                    open={modalOpen}
                    onClose={onClose}
                    products={selectedProducts}
                    from={'ActionBanner'}
                />
            )}
            <CloseButton onClick={onCloseBanner} height={15} width={15} />
            <SelectedText>{selectedProducts.length} Selected</SelectedText>
            {!removeProductsFromProject ? (
                <AddToProjectButton transparent small products={selectedProducts} onClick={handleModalClick}>
                    Add to a Project
                </AddToProjectButton>
            ) : (
                <Button transparent small onClick={removeProductsFromProject}>
                    Remove from Project
                </Button>
            )}

            <ComparisonButton selectedProducts={selectedProducts}>
                Compare Products {selectedProducts.length}/4
            </ComparisonButton>
        </Container>
    ) : null;
};

ActionBanner.propTypes = {
    selectedProducts: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        })
    ),
    clearSelectedProducts: PropTypes.func,
    removeProductsFromProject: PropTypes.func,
};

ActionBanner.defaultProps = {
    selectedProducts: undefined,
    removeProductsFromProject: undefined,
};

const mapStateToProps = (state) => ({
    isOpen: reducerUtil.getSlice(projectData, projectData.modalOpen, state),
});

const mapDispatchToProps = (dispatch) => ({
    openModal: async () => await dispatch(projectActions.setModalOpen()),
    closeModal: async () => await dispatch(projectActions.closeModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActionBanner);
