import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { RightBlock } from 'projects';
import { Card } from 'shared';
import _ from 'lodash';

const StyledPastDocumentsCard = styled(Card)`
    margin-top: 1.2rem;
    ul {
        list-style: none;
    }

    li {
        margin-bottom: 1.2rem;
        display: flex;
        flex-direction: column;
        &:last-child {
            margin-bottom: 0;
        }
    }
`;

const DocumentLink = styled.a`
    transition: color 0.1s ease;
    display: block;
    color: ${(props) => (props.theme.primaryColor ? props.theme.primaryColor : props.theme.blue)};
`;

const DocumentType = styled.div`
    font-weight: 500;
    font-size: 12px;
    line-height: 144.3%;
    text-transform: uppercase;
    color: #343e47;
`;

const PastDocuments = ({ documents }) => {
    if (!documents || (documents && documents.length < 1)) return null;

    const getDocumentName = (fileLocation) => {
        const n = fileLocation.lastIndexOf('/');
        return fileLocation.substring(n + 1);
    };

    return (
        <StyledPastDocumentsCard>
            <RightBlock title="Past Submittals" description="Download documents you have made in the past.">
                <ul>
                    {_.orderBy(documents, ['created_at', 'desc']).map((document) => (
                        <li key={document.id}>
                            <DocumentType>{document.type}</DocumentType>
                            <DocumentLink href={`${document.link}`}>
                                {getDocumentName(document.file_location)}
                            </DocumentLink>
                        </li>
                    ))}
                </ul>
            </RightBlock>
        </StyledPastDocumentsCard>
    );
};

PastDocuments.propTypes = {
    documents: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
        })
    ),
};

export default PastDocuments;
