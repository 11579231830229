export default {
    name: 'projectDetails',
    fetchId: 'fetchId',
    generalData: 'generalData',
    order: 'order',
    pagination: 'pagination',
    pastBrochures: 'pastBrochures',
    pastSubmittals: 'pastSubmittals',
    products: 'products',
    selectedProducts: 'selectedProducts',
    submittalOptions: 'submittalOptions',
};
