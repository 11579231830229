import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FormDropdown, FormDropdownOption } from 'shared';
import { sortList } from 'projectDetails';

const Container = styled.div`
    display: flex;
    align-items: baseline;
`;

const Label = styled.div`
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.2rem;
    letter-spacing: 0.3px;
    color: ${(props) => props.theme.grey3};
    padding-right: 1rem;
`;

const StyledDropdown = styled(FormDropdown)`
    border-radius: 3px;
    border: 1px solid ${(props) => props.theme.grey2};
`;

const SortProducts = ({ currentSort = {}, onSort }) => {
    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        onSort(value);
    };

    const { sortBy, sortDir } = currentSort;
    const sortType = sortList.find((item) => item.sortBy === sortBy && item.sortDir === sortDir) || sortList[0];

    return (
        <Container>
            <Label>Sort By</Label>
            <StyledDropdown noBorder value={sortType.name} onChange={handleChange}>
                {sortList.map(({ name }) => (
                    <FormDropdownOption key={name} value={name} optionLabel={name} />
                ))}
            </StyledDropdown>
        </Container>
    );
};

export default SortProducts;
