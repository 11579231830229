import { v4 as uuid } from 'uuid';
import { api, history, reducerUtil } from 'base-client';

import { sbBranding } from 'global-styles';
import searchData from '../reducerData';
import getAttributeValue from 'utils/getAttributeValue';
import { actions as errorsActions } from 'errors';
import { actions as analyticsActions } from 'analytics';
import { reducerData as tenantData } from 'tenant';

const querySort = [
    { name: 'Relevance', sortBy: '_score', sortDir: 'desc' },
    {
        name: 'Alphabetical (A to Z)',
        sortBy: 'name',
        sortDir: 'asc',
    },
    {
        name: 'Alphabetical (Z to A)',
        sortBy: 'name',
        sortDir: 'desc',
    },
];

/** This sets the search query string.
 */
const setSearch = () => (dispatch, getState) => {
    const queryParams = reducerUtil.getSlice(searchData, searchData.query, getState()) || {};
    const {
        location: { pathname },
    } = history;

    history.push(`${pathname}?${encodeURIComponent(JSON.stringify(queryParams))}`);
};

/** This performs a product search.
 * @param {bool} [restart] Whether or not to restart the list.
 */
const search = (restart) => async (dispatch, getState) => {
    let state = getState();

    // get the querystring
    const {
        location: { search: queryJson },
    } = history;

    const query = queryJson ? JSON.parse(decodeURIComponent(trimQuery(queryJson))) : {};

    // get default sorting
    const customList = reducerUtil.getSlice(tenantData, tenantData.sortList, state) || [];
    const defaultSort = [...customList, ...querySort][0];
    const defaultSortQuery = { sortBy: defaultSort.sortBy, sortDir: defaultSort.sortDir };

    // get the page information
    const { pagination } = reducerUtil.getSlice(searchData, searchData.meta, state) || {};
    const page = restart ? 1 : ((pagination && pagination.page) || 0) + 1;
    const limit = 100;

    const fetchId = uuid();
    dispatch(reducerUtil.setSlice(searchData, searchData.fetchId, fetchId));

    const defaultFilter = reducerUtil.getSlice(tenantData, tenantData.defaultFilter, state);
    const { filters: queryFilter } = query;
    const apiFilter = getApiFilters(defaultFilter, queryFilter);
    const searchIndex = reducerUtil.getSlice(tenantData, tenantData.searchIndex, state);

    let apiData = {
        ...defaultSortQuery,
        ...query,
        index: searchIndex,
    };

    if (apiFilter) {
        apiData = {
            ...apiData,
            filters: apiFilter,
        };
    }
    try {
        const { searchResults: results, searchMetaData: meta } = await dispatch(
            api.actions.post(`search/products?page=${page}&limit=${limit}`, JSON.stringify(apiData))
        );

        // check that this is the correct fetch
        state = getState();
        if (fetchId !== reducerUtil.getSlice(searchData, searchData.fetchId, state)) return;

        // get current product info if not restarting
        const list = (!restart && reducerUtil.getSlice(searchData, searchData.list, state)) || [];

        // create the new product list
        const newList = list.concat(dispatch(formatProducts(results)));

        // set the data
        dispatch(reducerUtil.setSlice(searchData, searchData.list, newList));
        dispatch(reducerUtil.setSlice(searchData, searchData.meta, meta));

        // update the search parameters
        dispatch(reducerUtil.setSlice(searchData, searchData.query, query));

        // allow another search
        dispatch(reducerUtil.setSlice(searchData, searchData.fetchId, undefined));

        // send analytics
        const {
            queryString,
            pagination: { totalHits },
            sort: { sortBy, sortDir },
        } = meta;
        const seachId = dispatch(
            analyticsActions.track('search', {
                queryString,
                totalHits,
                page,
                limit,
                sortBy,
                sortDir,
            })
        );
        dispatch(reducerUtil.setSlice(searchData, searchData.searchId, seachId));
    } catch (error) {
        dispatch(errorsActions.error(error));
    }
};

/** This removes the ? from the beginning of a url query string.
 *  @param {string} [query] The string to check.
 * @returns {string} The trimmed query.
 */
const trimQuery = (query) => {
    if (typeof query !== 'string') return query;
    while (query.charAt(0) === '?') query = query.substr(1);
    return query;
};

const getApiFilters = (defaultFilter, queryFilter) => {
    if (!defaultFilter) return queryFilter;
    if (!queryFilter) return defaultFilter;
    const results = [...queryFilter];
    defaultFilter.forEach((item) => {
        const { attribute: defaultAttr } = item;
        const isExist = queryFilter.find(({ attribute }) => defaultAttr === attribute);
        if (!isExist) {
            results.push(item);
        }
    });

    return results;
};

const formatAssets = (product, assetList, assetData) => (dispatch, getState) => {
    const state = getState();

    const showDownloadAllFilesLink = reducerUtil.getSlice(tenantData, tenantData.showDownloadAllFilesLink, state);
    const { id: productId, name: productName, category: productCategory, allFiles } = product;

    const result = assetList
        .map(({ attributeId: id, attributeName: name, displayName, color }) => {
            const link = getAttributeValue({ id, name, list: assetData, system: product });

            return {
                id,
                color,
                link,
                name: displayName || name,
                meta: {
                    product_id: productId,
                    productName,
                    productCategory,
                    attribute_id: id,
                    attributeName: name,
                    assetUrl: link,
                },
            };
        })
        .filter(({ name, link }) => name && link);

    //append download all button
    if (showDownloadAllFilesLink && allFiles) {
        const downloadAllInfor = {
            id: 'Download All',
            color: sbBranding.blue,
            name: 'Download All',
            link: allFiles,
            meta: {
                product_id: productId,
                productName,
                productCategory,
                attributeName: 'Download All',
                assetUrl: allFiles,
            },
        };
        return [...result, downloadAllInfor];
    }
    return result;
};

const formatProducts = (rawProducts) => (dispatch, getState) => {
    const state = getState();

    const calloutList = reducerUtil.getSlice(tenantData, tenantData.callouts, state) || [];
    const calloutMap = calloutList.filter((item) => item.attributeName).slice(0, 4);

    const tenantAssets = reducerUtil.getSlice(tenantData, tenantData.assets, state) || {};
    const assetList = tenantAssets.searchPage || [];

    return (rawProducts || []).map(
        ({ callouts: callData, assets: assetData, manufacturer_id: manufacturerId, ...product }) => {
            const callouts = callData || [];
            const assets = assetData || [];

            const formattedAssets = dispatch(formatAssets(product, assetList, assets));

            return {
                ...product,
                assets: formattedAssets,
                callouts: calloutMap.map(({ attributeId: id, attributeName: name, displayName }) => ({
                    id,
                    name: displayName || name,
                    value: getAttributeValue({ id, name, list: callouts, system: product }),
                })),
            };
        }
    );
};

/** This searches for the next page, using the current search parameters.
 */
const nextProductPage = () => (dispatch) => dispatch(search());

/** This sets the search text and starts a timer before searching.
 * @param {Object} [queryParams] The new string to search.
 */
const setQuery = (query) => (dispatch) => {
    dispatch(reducerUtil.setSlice(searchData, searchData.query, query));
    return dispatch(setSearch(true));
};

/** This sets the search text part of the product query object.
 * @param {string} [queryString] The new query string.
 */
const setText = (queryString) => (dispatch, getState) => {
    const {
        location: { pathname },
    } = history;
    const query = reducerUtil.getSlice(searchData, searchData.query, getState());
    return dispatch(setQuery({ ...query, queryString }));
};

/** This sets the sort method part of the product query object.
 * @param {string} [sortKey] The new query string.
 */
const setSort = (name) => (dispatch, getState) => {
    const state = getState();
    const query = reducerUtil.getSlice(searchData, searchData.query, state);
    const sortList = reducerUtil.getSlice(tenantData, tenantData.sortList, state) || [];
    const sortInfo = sortList.find((item) => item.name === name) || querySort.find((item) => item.name === name) || {};
    const { sortBy, sortDir } = sortInfo;
    return dispatch(setQuery({ ...query, sortBy, sortDir }));
};

const addFilter =
    ({ attribute, facets }) =>
    (dispatch, getState) => {
        if (!Array.isArray(facets)) facets = [facets];
        const query = reducerUtil.getSlice(searchData, searchData.query, getState()) || {};
        const { filters } = query;

        let newFilters;
        if (!filters) {
            newFilters = [{ attribute, facets }];
        } else {
            const index = filters.findIndex(({ attribute: name }) => name === attribute);
            if (index < 0) {
                newFilters = [...filters, { attribute, facets }];
            } else {
                const { facets: prevFacets } = filters[index];
                newFilters = [...filters];
                newFilters[index] = { attribute, facets: [...prevFacets, ...facets] };
            }
        }

        return dispatch(setQuery({ ...query, filters: newFilters }));
    };

const removeFilter =
    ({ attribute, facets }) =>
    (dispatch, getState) => {
        if (!Array.isArray(facets)) facets = [facets];
        const query = reducerUtil.getSlice(searchData, searchData.query, getState()) || {};
        const { filters } = query;

        let newFilters;
        const index = filters.findIndex(({ attribute: name }) => name === attribute);
        const { facets: prevFacets } = filters[index];
        const newFacets = prevFacets.filter((name) => name !== facets[0]);
        if (newFacets.length > 0) {
            newFilters = [...filters];
            newFilters[index] = { attribute, facets: newFacets };
        } else if (filters.length > 1) {
            newFilters = filters.filter((item, filterIndex) => filterIndex !== index);
        }

        return dispatch(setQuery({ ...query, filters: newFilters }));
    };

const updateFilter =
    ({ attribute, facets }) =>
    (dispatch, getState) => {
        if (!Array.isArray(facets)) facets = [facets];
        const query = reducerUtil.getSlice(searchData, searchData.query, getState()) || {};
        const { filters = [] } = query;
        let newFilters = [...filters];
        const index = filters.findIndex(({ attribute: name }) => name === attribute);

        if (index < 0) {
            newFilters.push({ attribute, facets });
        } else {
            newFilters[index] = { attribute, facets };
        }
        return dispatch(setQuery({ ...query, filters: newFilters }));
    };

const clearAllFilters = () => (dispatch, getState) => {
    const state = getState();
    const query = reducerUtil.getSlice(searchData, searchData.query, getState()) || {};
    return dispatch(
        setQuery({
            ...query,
            filters: reducerUtil.getSlice(tenantData, tenantData.defaultFilter, state),
        })
    );
};

const selectProduct = (product) => (dispatch, getState) => {
    const state = getState();
    const selectedProducts = reducerUtil.getSlice(searchData, searchData.selectedProducts, state) || [];
    return dispatch(reducerUtil.setSlice(searchData, searchData.selectedProducts, [...selectedProducts, product]));
};

const deselectProduct = (product) => (dispatch, getState) => {
    const state = getState();
    const selectedProducts = reducerUtil.getSlice(searchData, searchData.selectedProducts, state) || [];
    const newList = selectedProducts.filter(({ id }) => id !== product.id);
    return dispatch(
        reducerUtil.setSlice(searchData, searchData.selectedProducts, newList.length > 0 ? newList : undefined)
    );
};

const clearSelectedProducts = () => (dispatch) => {
    return dispatch(reducerUtil.setSlice(searchData, searchData.selectedProducts, undefined));
};

export default {
    search,
    nextProductPage,
    setText,
    setSort,
    addFilter,
    removeFilter,
    updateFilter,
    clearAllFilters,
    selectProduct,
    deselectProduct,
    clearSelectedProducts,
    formatProducts,
    formatAssets,
};
export { querySort };
