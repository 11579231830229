// These two containers are siblings in the DOM
import ReactDOM from 'react-dom';
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { actions, reducerData, projectNotification } from 'notification';
import { reducerUtil } from 'base-client';
import DownloadProposalCard from './DownloadProposalCard';
import EmailProposalCard from './EmailProposalCard';
import ProjectProductsAddedCard from './ProjectProductsAddedCard';
import ProjectProductsRemovedCard from './ProjectProductsRemovedCard';
import GeneralCard from './GeneralCard';

const NotificationCard = ({ className, children }) => (
    <div className={className + ' notification-card'}>{children}</div>
);

const StyledNotificationCard = styled(NotificationCard)`
    display: block;
    position: relative;
    border-radius: 3px;
    background-color: ${(props) => props.theme.white};
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
    max-width: 500px;
    margin-top: 10px;
    margin-bottom: 20px;

    &:before {
        content: '';
        width: 5px;
        height: 100%;
        background-color: ${(props) => (props.theme.primaryColor ? props.theme.primaryColor : props.theme.blue)};
        display: block;
        position: absolute;
        border-radius: 3px 0 0 3px;
    }

    .notification-card-title,
    .notification-card-content,
    .notification-card-footer {
        margin: 5px;
    }
    .notification-card-content {
        margin-top: 15px;
        margin-bottom: 10px;
    }
`;

const Element = ({ type, ...props }) => {
    switch (type) {
        case projectNotification.PRODUCT_ADDED:
            return <ProjectProductsAddedCard {...props} />;
        case projectNotification.PRODUCT_REMOVED:
            return <ProjectProductsRemovedCard {...props} />;
        case projectNotification.DOWNLOAD_PROPOSAL:
            return <DownloadProposalCard {...props} />;
        case projectNotification.EMAIL_PROPOSAL:
            return <EmailProposalCard {...props} />;
        case projectNotification.GENERAL:
            return <GeneralCard {...props} />;
        default:
            return null;
    }
};

class Notification extends React.Component {
    onRemove = (id) => {
        this.props.dispatch(actions.removeNotification(id));
    };

    render() {
        const { className, notifications } = this.props;

        if (!notifications || (!!notifications && notifications.length === 0)) {
            return null;
        }

        return (
            <div className={className + ' notification'}>
                {notifications.map((notification, idx) => (
                    <StyledNotificationCard key={idx}>
                        <Element type={notification.type} card={notification} onRemove={this.onRemove} />
                    </StyledNotificationCard>
                ))}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    notifications: reducerUtil.getSlice(reducerData, reducerData.notifications, state),
});

const connectedNotification = connect(mapStateToProps)(Notification);

const StyledNotification = styled(connectedNotification)`
    position: fixed;
    top: 105px;
    right: 10px;
`;

class Portal extends React.Component {
    render() {
        return ReactDOM.createPortal(<StyledNotification {...this.props} />, document.body);
    }
}

export default Portal;
