export default {
    name: 'productDetails',
    general: 'general',
    projectsWithProduct: 'projectsWithProduct',
    callouts: 'callouts',
    features: 'features',
    assets: 'assets',
    information: 'information',
    bimData: 'bimData',
    resources: 'resources',
    sustainabilityData: 'sustainabilityData',
    attachments: 'attachments',
};
