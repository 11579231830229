import { api, config, reducerUtil } from 'base-client';
import { reducerData as tenantData } from 'tenant';
import { reducerData as guestData } from 'guest';
import { configMap } from 'configurations';

const defaultCallouts = [
    { attributeName: 'brand', displayName: 'Brand' },
    { attributeName: 'category', displayName: 'Category' },
];

const defaultAssets = [
    {
        displayName: 'Revit',
        attributeName: 'revit',
        color: '#0A7BE4',
    },
    {
        displayName: 'CAD',
        attributeName: 'autoCAD',
        color: '#0A7BE4',
    },
    {
        displayName: 'Sketchup',
        attributeName: 'sketchup',
        color: '#BF3838',
    },
    {
        displayName: 'Misc3D',
        attributeName: 'misc3D',
        color: '#5A6678',
    },
];

const defaultTitle = 'Design Studio';

/** This loads theme object based on subdomain and adds its information to the redux store.
 *  Also returns a token from the cookie (this is how login persists with cookie implementation)
 */
const start = (serverUrl) => async (dispatch) => {
    const brand = dispatch(checkTenant(serverUrl));

    try {
        const result = await dispatch(api.actions.get(`brandedSites/${brand}`));
        const { searchIndex, settings, tenant_id: tenant, filters } = result;
        const projectTemplates = await dispatch(api.actions.get(`tenants/templates?tenant_id=${tenant}`, true));

        const {
            customPages,
            header,
            footer,
            callouts,
            assets,
            assetGroups,
            loginFeatureLock,
            favicon,
            titleTag,
            landingPage,
            showLogin,
            customScripts,
            featureAttributeList: features,
            custom_sort_attribute_id: sortId,
            emailRecipients,
            productSections,
            compareProduct,
            projectSettings,
            contactSalesText: contactTitle,
            requestQuoteText: requestTitle,
            font,
            showCookieBanner,
            showDownloadAllFilesLink,
            showViewAllAssetsLink,
            showSustainability,
            guestLogin,
            hasPrice,
            ...theme
        } = settings;

        //Enable or disable brochure
        let brochureEnabled = checkForBrochure(theme);
        let tenantTheme = Object.assign(theme);
        tenantTheme.brochureEnabled = brochureEnabled;

        dispatch(reducerUtil.setSlice(tenantData, tenantData.projectTemplates, projectTemplates || undefined));

        // search index
        dispatch(reducerUtil.setSlice(tenantData, tenantData.tenant, tenant));
        dispatch(reducerUtil.setSlice(tenantData, tenantData.searchIndex, searchIndex));

        // basic theme
        dispatch(reducerUtil.setSlice(tenantData, tenantData.theme, theme));

        // custom functions
        dispatch(reducerUtil.setSlice(tenantData, tenantData.header, header || undefined));
        dispatch(reducerUtil.setSlice(tenantData, tenantData.footer, footer || undefined));
        dispatch(reducerUtil.setSlice(tenantData, tenantData.callouts, callouts || defaultCallouts));
        dispatch(reducerUtil.setSlice(tenantData, tenantData.assets, assets || defaultAssets));
        dispatch(reducerUtil.setSlice(tenantData, tenantData.features, features || undefined));
        dispatch(reducerUtil.setSlice(tenantData, tenantData.favicon, favicon || undefined));
        dispatch(reducerUtil.setSlice(tenantData, tenantData.titleTag, titleTag || defaultTitle));
        dispatch(
            reducerUtil.setSlice(tenantData, tenantData.showViewAllAssetsLink, showViewAllAssetsLink || undefined)
        );
        dispatch(
            reducerUtil.setSlice(tenantData, tenantData.showDownloadAllFilesLink, showDownloadAllFilesLink || undefined)
        );

        // handle cookie banner
        const disableBanner = dispatch(config.actions.getData(configMap.disabled.name, configMap.disabled.banner.name));
        if (!disableBanner) {
            const banner = !showCookieBanner && showCookieBanner !== false ? true : showCookieBanner;
            dispatch(reducerUtil.setSlice(tenantData, tenantData.showCookieBanner, banner));
        }

        // get assets base on configuration of tenant
        const { onSearch, onDetail } = assets || {};
        dispatch(
            reducerUtil.setSlice(tenantData, tenantData.assets, {
                searchPage: onSearch || defaultAssets,
                detailPage: onDetail || defaultAssets,
            })
        );

        // special sorting
        const sortList = sortId && [{ name: 'Featured', sortBy: 'custom', sortDir: 'asc' }];
        dispatch(reducerUtil.setSlice(tenantData, tenantData.sortList, sortList || []));

        //landing page
        dispatch(reducerUtil.setSlice(tenantData, tenantData.landingPage, landingPage || undefined));

        // asset groups
        dispatch(reducerUtil.setSlice(tenantData, tenantData.assetGroups, assetGroups || []));

        // features that require login:
        const requireLogin = {
            download: loginFeatureLock && loginFeatureLock.includes('download'),
        };
        const hideLogin = !(loginFeatureLock && loginFeatureLock.length > 0) && !showLogin;
        dispatch(reducerUtil.setSlice(tenantData, tenantData.requireLogin, requireLogin));
        dispatch(reducerUtil.setSlice(tenantData, tenantData.hideLogin, hideLogin));

        // add scripts
        dispatch(reducerUtil.setSlice(tenantData, tenantData.scripts, customScripts || undefined));

        // add messages
        const { reqForQuote: request, salesReq: contact } = emailRecipients || {};
        dispatch(reducerUtil.setSlice(tenantData, tenantData.showContact, contact && contact.length > 0));
        dispatch(reducerUtil.setSlice(tenantData, tenantData.showRequest, request && request.length > 0));

        // set productSections
        dispatch(reducerUtil.setSlice(tenantData, tenantData.productSections, productSections || undefined));

        dispatch(reducerUtil.setSlice(tenantData, tenantData.compareProduct, compareProduct || true));

        // set font
        dispatch(reducerUtil.setSlice(tenantData, tenantData.font, font || undefined));

        // set message title
        dispatch(reducerUtil.setSlice(tenantData, tenantData.contactTitle, contactTitle || undefined));
        dispatch(reducerUtil.setSlice(tenantData, tenantData.requestTitle, requestTitle || undefined));

        dispatch(reducerUtil.setSlice(tenantData, tenantData.customPages, customPages || undefined));

        // set default filters
        dispatch(getDefaultFilters(filters));

        // set showSustainability value
        dispatch(reducerUtil.setSlice(tenantData, tenantData.showSustainability, showSustainability));

        // set guest login
        dispatch(reducerUtil.setSlice(guestData, guestData.guestEnabled, guestLogin || false));

        // set hasPricing
        dispatch(reducerUtil.setSlice(tenantData, tenantData.hasPrice, hasPrice || false));

        const { showSubmittal } = projectSettings || {};
        const projectSubmittalSettings = {
            disabled: !(loginFeatureLock && loginFeatureLock.includes('project')) || hideLogin,
            requiredLogin: window.sessionStorage.getItem('concoraGuest') ? false : true,
            showSubmittal,
        };

        dispatch(reducerUtil.setSlice(tenantData, tenantData.projectSubmittalSettings, projectSubmittalSettings));
    } catch (error) {
        dispatch(config.actions.error(error));
    }
};

const checkTenant = (serverUrl) => () => {
    const url = serverUrl || window.location.host;
    if (/8080/.test(url)) {
        return process.env.REACT_APP_TENANT_NAME || 'smartbimsynthetics';
    }

    return url;
};

const checkForBrochure = (theme) => {
    if (
        theme.ownerBrochure &&
        theme.ownerBrochure.hasOwnProperty('tagAttributes') &&
        theme.ownerBrochure.hasOwnProperty('descriptionAttributes')
    ) {
        return true;
    }
    return false;
};

const getDefaultFilters = (filters) => (dispatch) => {
    if (!filters || (filters && filters.length < 1)) return;

    const singleSelects = filters.filter(({ single_select_facet }) => single_select_facet);

    if (!singleSelects || (singleSelects && singleSelects.length < 1)) {
        dispatch(reducerUtil.setSlice(tenantData, tenantData.defaultFilter, undefined));
    } else {
        const defaultFilters = singleSelects.map(({ attribute, default_facet }) => ({
            attribute,
            facets: [default_facet],
        }));

        dispatch(reducerUtil.setSlice(tenantData, tenantData.defaultFilter, defaultFilters));
    }
};

export default { start };
