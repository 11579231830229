import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { ActionsBarContainer } from './filterComponents';
import { AddProductsModal } from './AddProductsModal';
import ProjectWorkflowBanner from './ProjectWorkflowBanner';
import ProductFilters from './Filters';
import projectsData from '../../projects/reducerData';
import { login, history, reducerUtil } from 'base-client';
import { actions as detailsActions, reducerData as projectDetailsData, reducerData } from 'projectDetails';
import { reducerData as tenantData } from 'tenant';
import { actions as proposalActions } from 'proposal';
import { actions as projectActions } from 'projects';
import { actions as guestActions, reducerData as guestData } from 'guest';
import { reducerData as searchData } from 'productSearch';
import { actions as searchActions } from 'workflowProductSearch';
import { connect } from 'react-redux';
import Loading from '../../shared/Loading';

const FiltersResults = styled.div`
    display: flex;
    position: relative;
`;

const Results = styled.div`
    flex: 0 0 80%;
    position: relative;
`;

const BannerWrapper = styled.div`
    transition: all 0.1s ease-in;
    position: relative;
    .hasTooltip {
        position: relative;
        &:hover {
            .tooltip {
                opacity: 1;
            }
        }
        &.comparison {
            .tooltip {
                top: -5.5rem;
            }
        }
    }
    .tooltip {
        border: 1px solid ${(props) => props.theme.grey3};
        font-weight: 500;
        border-radius: 0.4rem;
        &:before {
            border-right: 1px solid ${(props) => props.theme.grey3};
            border-bottom: 1px solid ${(props) => props.theme.grey3};
            bottom: -0.5rem;
        }
    }
    &.banner-sticky {
        position: sticky;
        left: 0;
        top: 60px;
        right: 0;
        z-index: 100;
        .tooltipTop {
            .tooltip {
                bottom: -4rem;
                top: 3.6rem !important;
                &:before {
                    border-bottom: none;
                    border-right: none;
                    border-left: 1px solid ${(props) => props.theme.grey3};
                    border-top: 1px solid ${(props) => props.theme.grey3};
                    bottom: 0;
                    top: -0.5rem;
                }
            }
            .hasTooltip.comparison .tooltip {
                bottom: -5.5rem;
            }
        }
    }
`;

const ProductSearch = ({
    children,
    currentWorkflow,
    dispatch,
    filterProps,
    generalData,
    goToNextStep,
    guestGeneralData,
    guestOccupation,
    guestProducts,
    location,
    project,
    projectData,
    projectProducts,
    query,
    searching,
    selectedProducts,
    setView,
    templateProducts,
    theme,
    token,
    view,
    ...bannerProps
}) => {
    const [tempProducts, setTempProducts] = useState(templateProducts);
    const [undo, setUndo] = useState(false);
    let { modalState } = history.location.state || false;
    const { brochureEnabled } = (theme && theme) || false;
    const isBrochure = brochureEnabled && history.location.pathname.endsWith('/brochure');
    let modalProps = {
        title: `Configure ${isBrochure ? 'Brochure' : 'Submittal'}`,
        open: modalState,
        onClose: async () => {
            tempProducts &&
                (await dispatch(
                    projectActions.addSelectedProductsToProject([...tempProducts, ...selectedProducts], projectData)
                ));
            await dispatch(proposalActions.setProposalProducts());
            history.push({ pathname: history.location.pathname, state: { modalState: false } });
        },
    };

    const addTemplateProducts = async () => {
        if (guestOccupation) {
            await dispatch(guestActions.addSelectedProductsToProject([...tempProducts, ...selectedProducts]));
        } else {
            await dispatch(
                projectActions.addSelectedProductsToProject([...tempProducts, ...selectedProducts], projectData)
            );
            await dispatch(
                reducerUtil.setSlice(projectDetailsData, projectDetailsData.products, [
                    ...tempProducts,
                    ...selectedProducts,
                ])
            );
        }
        await dispatch(searchActions.clearSelectedProducts());
    };

    const goToSubmittal = async (step) => {
        await handleAddProducts();
        if (guestOccupation) {
            await dispatch(detailsActions.goToSubmittal(step));
        } else {
            await dispatch(proposalActions.setProposalProducts());
            await dispatch(detailsActions.goToSubmittal(generalData.id, step));
        }
    };

    const goToBrochure = async (step) => {
        await dispatch(detailsActions.goToBrochure(generalData.id, step));
    };

    const handleAddProducts = async () => {
        if (guestOccupation) {
            await dispatch(guestActions.addSelectedProductsToProject(selectedProducts));
        } else {
            await dispatch(projectActions.addSelectedProductsToProject(selectedProducts, projectData));
            await dispatch(detailsActions.loadSavedProducts({ projectId: projectData.id, restart: true }));
        }
        dispatch(searchActions.clearSelectedProducts());
        return true;
    };

    const removeProduct = (i) => {
        setUndo(true);
        setTempProducts(
            tempProducts.filter((p, idx) => {
                return idx !== i;
            })
        );
    };

    const resetProducts = () => {
        setTempProducts(templateProducts);
        setUndo(false);
    };

    const handleSubmit = async () => {
        await addTemplateProducts();
        if (isBrochure) {
            goToBrochure('set-proposal-products');
        } else {
            goToSubmittal('select-proposal-documents');
        }
        dispatch(projectActions.closeModal());
    };

    const noProductsMessage = () => (
        <b style={{ marginLeft: '1.2rem' }}>Sorry, there are no results matching your search</b>
    );

    return (
        <React.Fragment>
            <ActionsBarContainer />
            <FiltersResults>
                <ProductFilters {...filterProps} />
                <Results>
                    <BannerWrapper className="banner">
                        <ProjectWorkflowBanner
                            {...{
                                isBrochure,
                                handleAddProducts,
                                goToNextStep,
                                projectData,
                                projectProducts,
                                selectedProducts,
                                guestOccupation,
                                guestProducts,
                                guestGeneralData,
                                ...bannerProps,
                            }}
                        />
                    </BannerWrapper>
                    {searching ? (
                        <Loading />
                    ) : children && children.props && children.props.list && children.props.list.length > 0 ? (
                        children
                    ) : (
                        noProductsMessage()
                    )}
                </Results>
            </FiltersResults>
            {
                <AddProductsModal
                    {...{
                        modalProps,
                        handleAddProducts,
                        handleSubmit,
                        templateProducts,
                        addTemplateProducts,
                        isBrochure,
                        goToSubmittal,
                        goToBrochure,
                        resetProducts,
                        removeProduct,
                        setTempProducts,
                        tempProducts,
                        dispatch,
                        undo,
                    }}
                />
            }
        </React.Fragment>
    );
};

ProductSearch.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
    filterProps: PropTypes.shape({
        filters: PropTypes.array,
        selected: PropTypes.array,
        updateFilter: PropTypes.func,
    }),
};

const mapStateToProps = (state) => ({
    generalData: reducerUtil.getSlice(reducerData, reducerData.generalData, state),
    guestGeneralData: reducerUtil.getSlice(guestData, guestData.generalData, state),
    guestProducts: reducerUtil.getSlice(guestData, guestData.products, state),
    loginPage: reducerUtil.getSlice(login.reducerData, login.reducerData.loginPage, state),
    projectData: reducerUtil.getSlice(projectDetailsData, projectDetailsData.generalData, state),
    projectProducts: reducerUtil.getSlice(projectDetailsData, projectDetailsData.products, state),
    query: reducerUtil.getSlice(searchData, searchData.query, state),
    searching: reducerUtil.getSlice(searchData, searchData.searching, state),
    templateProducts: reducerUtil.getSlice(projectsData, projectsData.products, state),
    theme: reducerUtil.getSlice(tenantData, tenantData.theme, state),
    token: reducerUtil.getSlice(login.reducerData, login.reducerData.token, state),
});

export default withRouter(connect(mapStateToProps)(ProductSearch));
