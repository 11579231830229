import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Resource from './Resource';

const StyledContainer = styled.div`
    display: flex;
    .resource {
        flex: 0 0 20%;
        padding-top: 20%;
        margin-top: 0;
        margin-bottom: 3.5rem;
        .actions {
            bottom: -4.5rem;
        }
    }
    flex-wrap: wrap;
`;

const Certificates = ({ data = [] }) => (
    <StyledContainer>
        {data.map((item) => (
            <Resource key={item.id} {...item} className="resource" />
        ))}
    </StyledContainer>
);

Certificates.propTypes = {
    data: PropTypes.arrayOf({
        id: PropTypes.string,
        label: PropTypes.string,
        name: PropTypes.string,
        url: PropTypes.string,
        link: PropTypes.string,
        size: PropTypes.number,
        meta: PropTypes.object,
        type: PropTypes.string,
    }),
};

export default Certificates;
