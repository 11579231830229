import { v4 as uuid } from 'uuid';
import { api, history, reducerUtil } from 'base-client';

import { sbBranding } from 'global-styles';
import searchData from '../reducerData';
import getAttributeValue from 'utils/getAttributeValue';
import { actions as errorsActions } from 'errors';
import { actions as analyticsActions } from 'analytics';
import { reducerData as tenantData } from 'tenant';

const querySort = [
    { name: 'Relevance', sortBy: '_score', sortDir: 'desc' },
    {
        name: 'Alphabetical (A to Z)',
        sortBy: 'name',
        sortDir: 'asc',
    },
    {
        name: 'Alphabetical (Z to A)',
        sortBy: 'name',
        sortDir: 'desc',
    },
];

const getFeaturedProducts = (products) => async (dispatch, getState) => {
    let state = getState();
    const searchIndex = reducerUtil.getSlice(tenantData, tenantData.searchIndex, state);
    try {
        const { searchResults: results } = await dispatch(
            api.actions.post(
                `search/products?page=${0}&limit=${100}`,
                JSON.stringify({
                    index: searchIndex,
                    filters: [
                        {
                            attribute: 'id',
                            facets: products,
                        },
                    ],
                })
            )
        );
        const formattedProducts = dispatch(formatProducts(results));
        return products
            .map((product) => formattedProducts.find((formattedProduct) => formattedProduct.id === product))
            .filter((product) => !!product);
    } catch (er) {
        return [];
    }
};

/** This performs a curated product search.
 * @param {bool} [restart] Whether or not to restart the list.
 */
const search = (restart, products) => async (dispatch, getState) => {
    let state = getState();

    const pathArray = window.location.pathname.split('/');
    const pathUrl = `/${pathArray[1]}`;

    // set custom filter
    const collections = state.tenant.customPages.find((section, idx) => section.url === pathUrl);

    const paths = collections.paths;

    const pathname = window.location.pathname;

    const setCustomFilter = (pathname) =>
        collections.customFilters.find((filter) => filter.name === paths[pathname]).customDefaultFilter;
    const customFilter = setCustomFilter(pathname);

    const query = state.collectionProductSearch.query ? state.collectionProductSearch.query : {};
    // get default sorting
    const customList = reducerUtil.getSlice(tenantData, tenantData.sortList, state) || [];
    const defaultSort = [...customList, ...querySort][0];
    const defaultSortQuery = { sortBy: defaultSort.sortBy, sortDir: defaultSort.sortDir };

    // get the page information
    const { pagination } = reducerUtil.getSlice(searchData, searchData.meta, state) || {};
    const page = restart ? 1 : ((pagination && pagination.page) || 0) + 1;
    const limit = 100;

    const fetchId = uuid();
    dispatch(reducerUtil.setSlice(searchData, searchData.fetchId, fetchId));

    const defaultFilter = reducerUtil.getSlice(tenantData, tenantData.defaultFilter, state) || [];

    const { filters: queryFilter } = query;
    const apiFilter = getApiFilters([...defaultFilter], queryFilter);
    const searchIndex = reducerUtil.getSlice(tenantData, tenantData.searchIndex, state);
    let apiData = {
        ...defaultSortQuery,
        ...query,
        index: searchIndex,
    };

    try {
        let defaultProductsList = [];
        let data = {
            ...apiData,
            filters: [...customFilter, ...apiFilter],
        };
        const lim = 1000;

        const { searchResults: results, searchMetaData: meta } = await dispatch(
            api.actions.post(`search/products?page=0&limit=${lim}`, JSON.stringify(data))
        );

        defaultProductsList = results;

        if (defaultProductsList > 0) {
            apiData = {
                ...apiData,
                ...apiFilter,
                filters: customFilter,
            };
        }

        // set the filter selection list by removing any attributes that match customFilter attributes
        const metaFilterList = meta.filters;

        const metaFilters = (metaFilterList) => {
            let newMetaFilterList = [];
            for (var i = 0; i < metaFilterList.length; i++) {
                for (var j = 0; j < customFilter.length; j++)
                    if (metaFilterList[i].attribute === customFilter[j].attribute) {
                        newMetaFilterList = metaFilterList.splice(i, 1);
                    }
                metaFilterList = newMetaFilterList;
            }
        };

        metaFilters(metaFilterList);

        // check that this is the correct fetch
        state = getState();
        if (fetchId !== reducerUtil.getSlice(searchData, searchData.fetchId, state)) return;

        // get current product info if not restarting
        const list = (!restart && reducerUtil.getSlice(searchData, searchData.list, state)) || [];

        // create the new product list
        const newList = list.concat(dispatch(formatProducts(results)));

        // set the data
        dispatch(reducerUtil.setSlice(searchData, searchData.list, newList));
        dispatch(reducerUtil.setSlice(searchData, searchData.meta, meta));

        // update the search parameters
        dispatch(reducerUtil.setSlice(searchData, searchData.query, query));

        // allow another search
        dispatch(reducerUtil.setSlice(searchData, searchData.fetchId, undefined));

        // send analytics
        const {
            queryString,
            pagination: { totalHits },
            sort: { sortBy, sortDir },
        } = meta;
        const seachId = dispatch(
            analyticsActions.track('search', {
                queryString,
                totalHits,
                page,
                limit,
                sortBy,
                sortDir,
            })
        );

        dispatch(reducerUtil.setSlice(searchData, searchData.searchId, seachId));
    } catch (error) {
        dispatch(errorsActions.error(error));
    }
};

const getApiFilters = (defaultFilter, queryFilter) => {
    if (!defaultFilter) return queryFilter;
    if (!queryFilter) return defaultFilter;
    const results = [...queryFilter];
    defaultFilter.forEach((item) => {
        const { attribute: defaultAttr } = item;
        const isExist = queryFilter.find(({ attribute }) => defaultAttr === attribute);
        if (!isExist) {
            results.push(item);
        }
    });

    return results;
};

const formatAssets = (product, assetList, assetData) => (dispatch, getState) => {
    const state = getState();

    const showDownloadAllFilesLink = reducerUtil.getSlice(tenantData, tenantData.showDownloadAllFilesLink, state);
    const { id: productId, name: productName, category: productCategory, allFiles } = product;

    const result = assetList
        .map(({ attributeId: id, attributeName: name, displayName, color }) => {
            const link = getAttributeValue({ id, name, list: assetData, system: product });

            return {
                id,
                color,
                link,
                name: displayName || name,
                meta: {
                    product_id: productId,
                    productName,
                    productCategory,
                    attribute_id: id,
                    attributeName: name,
                    assetUrl: link,
                },
            };
        })
        .filter(({ name, link }) => name && link);

    //append download all button
    if (showDownloadAllFilesLink && allFiles) {
        const downloadAllInfor = {
            id: 'Download All',
            color: sbBranding.blue,
            name: 'Download All',
            link: allFiles,
            meta: {
                product_id: productId,
                productName,
                productCategory,
                attributeName: 'Download All',
                assetUrl: allFiles,
            },
        };
        return [...result, downloadAllInfor];
    }
    return result;
};

const formatProducts = (rawProducts) => (dispatch, getState) => {
    const state = getState();

    const calloutList = reducerUtil.getSlice(tenantData, tenantData.callouts, state) || [];
    const calloutMap = calloutList.filter((item) => item.attributeName).slice(0, 4);

    const tenantAssets = reducerUtil.getSlice(tenantData, tenantData.assets, state) || {};
    const assetList = tenantAssets.searchPage || [];

    return (rawProducts || []).map(
        ({ callouts: callData, assets: assetData, manufacturer_id: manufacturerId, ...product }) => {
            const callouts = callData || [];
            const assets = assetData || [];

            const formattedAssets = dispatch(formatAssets(product, assetList, assets));

            return {
                ...product,
                assets: formattedAssets,
                callouts: calloutMap.map(({ attributeId: id, attributeName: name, displayName }) => ({
                    id,
                    name: displayName || name,
                    value: getAttributeValue({ id, name, list: callouts, system: product }),
                })),
            };
        }
    );
};

/** This searches for the next page, using the current search parameters.
 */
const nextProductPage = (products) => (dispatch) => dispatch(search(false, products));

/** This sets the search text and starts a timer before searching.
 * @param {Object} [queryParams] The new string to search.
 */
const setQuery = (query) => (dispatch) => {
    dispatch(reducerUtil.setSlice(searchData, searchData.query, query));
};

/** This sets the search text part of the product query object.
 * @param {string} [queryString] The new query string.
 */
const setText = (queryString) => (dispatch, getState) => {
    const query = reducerUtil.getSlice(searchData, searchData.query, getState());
    return dispatch(setQuery({ ...query, queryString }));
};

/** This sets the sort method part of the product query object.
 * @param {string} [sortKey] The new query string.
 */
const setSort = (name) => (dispatch, getState) => {
    const state = getState();
    const query = reducerUtil.getSlice(searchData, searchData.query, state);
    const sortList = reducerUtil.getSlice(tenantData, tenantData.sortList, state) || [];
    const sortInfo = sortList.find((item) => item.name === name) || querySort.find((item) => item.name === name) || {};
    const { sortBy, sortDir } = sortInfo;
    return dispatch(setQuery({ ...query, sortBy, sortDir }));
};

const addFilter =
    ({ attribute, facets }) =>
    (dispatch, getState) => {
        if (!Array.isArray(facets)) facets = [facets];
        const query = reducerUtil.getSlice(searchData, searchData.query, getState()) || {};
        const { filters } = query;

        let newFilters;
        if (!filters) {
            newFilters = [{ attribute, facets }];
        } else {
            const index = filters.findIndex(({ attribute: name }) => name === attribute);
            if (index < 0) {
                newFilters = [...filters, { attribute, facets }];
            } else {
                const { facets: prevFacets } = filters[index];
                newFilters = [...filters];
                newFilters[index] = { attribute, facets: [...prevFacets, ...facets] };
            }
        }

        return dispatch(setQuery({ ...query, filters: newFilters }));
    };

const removeFilter =
    ({ attribute, facets }) =>
    (dispatch, getState) => {
        if (!Array.isArray(facets)) facets = [facets];
        const query = reducerUtil.getSlice(searchData, searchData.query, getState()) || {};
        const { filters } = query;

        let newFilters;
        const index = filters.findIndex(({ attribute: name }) => name === attribute);
        const { facets: prevFacets } = filters[index];
        const newFacets = prevFacets.filter((name) => name !== facets[0]);
        if (newFacets.length > 0) {
            newFilters = [...filters];
            newFilters[index] = { attribute, facets: newFacets };
        } else if (filters.length > 1) {
            newFilters = filters.filter((item, filterIndex) => filterIndex !== index);
        }

        return dispatch(setQuery({ ...query, filters: newFilters }));
    };

const updateFilter =
    ({ attribute, facets }) =>
    (dispatch, getState) => {
        if (!Array.isArray(facets)) facets = [facets];
        const query = reducerUtil.getSlice(searchData, searchData.query, getState()) || {};
        const { filters = [] } = query;
        let newFilters = [...filters];
        const index = filters.findIndex(({ attribute: name }) => name === attribute);

        if (index < 0) {
            newFilters.push({ attribute, facets });
        } else {
            newFilters[index] = { attribute, facets };
        }

        return dispatch(setQuery({ ...query, filters: newFilters }));
    };

const clearAllFilters = () => (dispatch, getState) => {
    const state = getState();
    const query = reducerUtil.getSlice(searchData, searchData.query, getState()) || {};
    // Write logic to set defaultFilter state back to the custom filter
    return dispatch(
        setQuery({
            ...query,
            filters: reducerUtil.getSlice(tenantData, tenantData.defaultFilter, state),
        })
    );
};

const selectProduct = (product) => (dispatch, getState) => {
    const state = getState();
    const selectedProducts = reducerUtil.getSlice(searchData, searchData.selectedProducts, state) || [];
    return dispatch(reducerUtil.setSlice(searchData, searchData.selectedProducts, [...selectedProducts, product]));
};

const deselectProduct = (product) => (dispatch, getState) => {
    const state = getState();
    const selectedProducts = reducerUtil.getSlice(searchData, searchData.selectedProducts, state) || [];
    const newList = selectedProducts.filter(({ id }) => id !== product.id);
    return dispatch(
        reducerUtil.setSlice(searchData, searchData.selectedProducts, newList.length > 0 ? newList : undefined)
    );
};

const clearSelectedProducts = () => (dispatch) => {
    return dispatch(reducerUtil.setSlice(searchData, searchData.selectedProducts, undefined));
};

export default {
    search,
    nextProductPage,
    setText,
    setSort,
    addFilter,
    removeFilter,
    updateFilter,
    clearAllFilters,
    selectProduct,
    deselectProduct,
    clearSelectedProducts,
    formatProducts,
    formatAssets,
    getFeaturedProducts,
};
export { querySort };
