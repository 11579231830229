import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { login, history, reducerUtil } from 'base-client';
import { Modal, LinkButton, ButtonText } from 'shared';
import { actions as projectActions, reducerData as projectData } from 'projects';
import { actions as detailsActions, reducerData as projectDetailsData } from 'projectDetails';
import { reducerData as searchData } from 'productSearch';
import { reducerData as tenantData } from 'tenant';
import { reducerData as customPagesData } from 'customPages';

import SelectProject from './SelectProject';
import CreateNewProject from './CreateNewProject';
import CreateProjectFromTemplate from './CreateProjectFromTemplate';

const ModalContent = styled.div`
    min-width: 50rem;
    font-size: 1.4rem;
`;

const FormGroup = styled.div`
    width: 100%;
    padding: 1.5rem 0 2rem;
    display: flex;
    flex-direction: column;
    &:first-child {
        padding-top: 0;
    }
    &.options {
        label {
            &:first-child {
                margin-right: 2rem;
            }
        }
    }
    &.projectInfor {
        padding-bottom: 0;
        display: flex;
    }
    .projectList {
        display: inline-block;
        width: 80%;
    }
`;

const Label = styled.label`
    cursor: pointer;
    font-weight: 500;
    margin-bottom: 8px;
    input {
        margin-right: 0.5rem;
    }
    &.required {
        position: relative;
        &:after {
            position: absolute;
            content: '*';
            top: 0;
            right: -1rem;
            color: ${(props) => props.theme.red};
        }
    }
`;

const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const SubTitle = styled.div`
    font-weight: bold;
    font-size: 14px;
    line-height: 23px;
    color: ${(props) => props.theme.primaryColor || props.theme.grey3};
`;

const Body = styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    margin-bottom: 13px;
`;

const LoginButton = styled(LinkButton)`
    width: 80px;
`;

const SignUp = styled.p`
    font-size: 1.2rem;
    button {
        color: ${(props) => props.theme.blue};
    }
`;

const ProjectCreationModal = ({
    hideOption = [],
    token,
    lastSelectedProject,
    collectionSelectedProducts,
    selectedProducts,
    productDetails,
    currentProject,
    defaultSelected,
    title,
    open,
    onClose,
    projectTemplates,
    dispatch,
    theme,
    from,
    fetchProjectsWithProduct = false,
}) => {
    const [type, setType] = useState();
    const [projects, setProjects] = useState();
    const [modalOpen, setModalOpen] = useState(open);

    const currentLocation = window.location.pathname;
    const { brochureEnabled } = (theme && theme) || false;
    const isBrochure = (brochureEnabled && currentLocation === '/brochure') || currentLocation.endsWith('/brochure');
    let modalProps = {
        title: title,
        open: modalOpen,
        onClose: () => defaultClose(),
    };

    if (onClose) {
        modalProps.onClose = onClose;
    }

    useEffect(() => {
        (async () => {
            const result = await dispatch(projectActions.getAllProjects());
            if (result && result.length > 0) {
                const list = result
                    .map(({ id, name }) => ({ id, name }))
                    .sort(({ name: a }, { name: b }) => a.toLowerCase().localeCompare(b.toLowerCase()));

                setProjects(list);
                setType(defaultSelected || 0);
            } else {
                setType(1);
            }
        })();
    }, []);

    const toggleProjectType = (evt) => {
        const { value } = evt.target;
        setType(parseInt(value));
    };

    const defaultClose = async () => {
        setModalOpen(false);
        dispatch(projectActions.modalClose());
    };

    const handleCreateProject = async ({ name, zipCode, template }) => {
        const result = await dispatch(projectActions.createProject({ name, zipCode }));
        let templateProducts =
            template && (await projectActions.getProductsFromTemplate(dispatch, template, projectTemplates));
        if (result) {
            dispatch(projectActions.showNotification(result));
            selectedProducts &&
                (await dispatch(
                    projectActions.addSelectedProductsToProject(selectedProducts, {
                        id: result.id,
                        name: result.name,
                    })
                ));
            collectionSelectedProducts &&
                (await dispatch(
                    projectActions.addSelectedProductsToProject(collectionSelectedProducts, {
                        id: result.id,
                        name: result.name,
                    })
                ));
            handleClose();

            if (productDetails) {
                await dispatch(detailsActions.getProject(result.id));
                await dispatch(
                    detailsActions.loadSavedProducts({
                        projectId: result.id,
                        restart: true,
                        noQuery: true,
                    })
                );
                await dispatch(
                    projectActions.addSelectedProductsToProject([productDetails], {
                        id: result.id,
                        name: result.name,
                    })
                );
                await dispatch(reducerUtil.setSlice(projectDetailsData, projectDetailsData.products, [productDetails]));
            }

            if (fetchProjectsWithProduct) {
                fetchProjectsWithProduct();
            }

            switch (from) {
                case 'ActionBanner':
                    if (currentLocation === '/products') {
                        template && addTemplateProducts(templateProducts, { id: result.id, name: result.name });
                        history.push({ pathname: '/products', state: { modalState: true } });
                        break;
                    } else {
                        template && addTemplateProducts(templateProducts, { id: result.id, name: result.name });
                        history.push({ pathname: currentLocation, state: { modalState: true } });
                        break;
                    }
                case 'Projects':
                    template && addTemplateProducts(templateProducts, { id: result.id, name: result.name });
                    history.push({ pathname: '/projects', state: { modalState: false } });
                    break;
                case 'ProjectsDropdown':
                    template && addTemplateProducts(templateProducts, { id: result.id, name: result.name });
                    history.push({ pathname: currentLocation, state: { modalState: false } });
                    break;
                case 'RightContent':
                    template && addTemplateProducts(templateProducts, { id: result.id, name: result.name });
                    history.push({ pathname: currentLocation, state: { modalState: false } });
                    break;
                default:
                    let productsModalState;
                    template ? (productsModalState = true) : (productsModalState = false);

                    if (isBrochure) {
                        history.push({
                            pathname: `/projects/${result.id}/brochure`,
                            state: { modalState: productsModalState },
                        });
                    } else {
                        history.push({
                            pathname: `/projects/${result.id}/submittal`,
                            state: { modalState: productsModalState },
                        });
                    }
                    break;
            }
        }

        await dispatch(reducerUtil.setSlice(searchData, searchData.selectedProducts, undefined));
        dispatch(projectActions.getProjects(true));
        return result;
    };

    const addTemplateProducts = async (templateProducts, project) => {
        await dispatch(projectActions.addSelectedProductsToProject(templateProducts, project));
    };

    const handleSelectProject = async (project) => {
        const { id, name } = project;
        selectedProducts &&
            (await dispatch(
                projectActions.addSelectedProductsToProject(selectedProducts, {
                    id,
                    name,
                })
            ));
        collectionSelectedProducts &&
            (await dispatch(
                projectActions.addSelectedProductsToProject(collectionSelectedProducts, {
                    id,
                    name,
                })
            ));

        await dispatch(detailsActions.getProject(id));

        if (productDetails) {
            await dispatch(detailsActions.loadSavedProducts({ projectId: id, restart: true, noQuery: true }));
            await dispatch(
                projectActions.addSelectedProductsToProject([productDetails], {
                    id: id,
                    name: name,
                })
            );
            if (fetchProjectsWithProduct) {
                fetchProjectsWithProduct();
            }
        } else if (currentProject && currentProject.id !== id) {
            if (isBrochure) {
                history.push(`/projects/${id}/brochure`);
            } else {
                history.push(`/projects/${id}/submittal`);
            }
        } else if (from == 'ActionBanner') {
            if (currentLocation === '/products') {
                history.push({ pathname: '/products', state: { modalState: true } });
            } else {
                history.push({ pathname: currentLocation, state: { modalState: false } });

                await dispatch(reducerUtil.setSlice(customPagesData, customPagesData.selectedProducts, undefined));
            }
        } else {
            //await dispatch(detailsActions.loadSavedProducts({ projectId: id, restart: true }));
            if (isBrochure) {
                history.push(`/projects/${id}/brochure`);
            } else {
                history.push(`/projects/${id}/submittal`);
            }
        }

        dispatch(reducerUtil.setSlice(searchData, searchData.selectedProducts, undefined));
        await handleClose();
    };

    const handleClose = async () => {
        await dispatch(projectActions.modalClose());
        setModalOpen(false);
    };

    if (!token) {
        return (
            <Modal {...modalProps}>
                <ModalContent>
                    <CardContainer>
                        <SubTitle>Login/Sign Up</SubTitle>
                        <Body>{'Please login to create submittals or brochures and save them for later use.'}</Body>
                        <LoginButton small onClick={() => dispatch(login.actions.toLogin())}>
                            Log In
                        </LoginButton>
                        <SignUp>
                            Don't have an account?{' '}
                            <ButtonText onClick={() => history.push('/login', { signUp: true })}>
                                Create an account here
                            </ButtonText>
                        </SignUp>
                    </CardContainer>
                </ModalContent>
            </Modal>
        );
    }
    return type === 3 ? null : (
        <Modal {...modalProps}>
            <ModalContent>
                <CardContainer>
                    <FormGroup className="options">
                        {projects && projects.length > 0 && !hideOption.includes(0) && (
                            <Label htmlFor="useExistProject">
                                <input
                                    type="radio"
                                    id="useExistProject"
                                    name="Use Existing Project"
                                    value={0}
                                    checked={type === 0}
                                    onClick={(e) => toggleProjectType(e)}
                                    readOnly
                                />
                                Use Existing Project
                            </Label>
                        )}

                        {!hideOption.includes(1) && (
                            <Label htmlFor="startNewProject">
                                <input
                                    type="radio"
                                    id="startNewProject"
                                    name="Start New Project"
                                    value={1}
                                    checked={type === 1}
                                    readOnly
                                    onClick={(e) => toggleProjectType(e)}
                                />
                                Start New Project From Scratch
                            </Label>
                        )}
                        {projectTemplates && projectTemplates.length > 0 && !hideOption.includes(2) && (
                            <Label htmlFor="startProjectFromTemplate">
                                <input
                                    type="radio"
                                    id="startProjectFromTemplate"
                                    name="Start from a Project Template"
                                    value={2}
                                    checked={type === 2}
                                    readOnly
                                    onClick={(e) => toggleProjectType(e)}
                                />
                                Start from a Project Template
                            </Label>
                        )}
                    </FormGroup>

                    {type === 0 && <SelectProject {...{ projects, handleSelectProject }} />}
                    {type === 1 && <CreateNewProject {...{ handleCreateProject }} />}
                    {type === 2 && <CreateProjectFromTemplate {...{ handleCreateProject }}></CreateProjectFromTemplate>}
                </CardContainer>
            </ModalContent>
        </Modal>
    );
};

const mapStateToProps = (state) => ({
    token: reducerUtil.getSlice(login.reducerData, login.reducerData.token, state),
    selectedProducts: reducerUtil.getSlice(searchData, searchData.selectedProducts, state),
    collectionSelectedProducts: reducerUtil.getSlice(customPagesData, customPagesData.selectedProducts, state),
    projectTemplates: reducerUtil.getSlice(tenantData, tenantData.projectTemplates, state),
    modalState: reducerUtil.getSlice(projectData, projectData.modalOpen, state),
    theme: reducerUtil.getSlice(tenantData, tenantData.theme, state),
    state: state,
});

export default connect(mapStateToProps)(ProjectCreationModal);
