import React from 'react';
import styled from 'styled-components';

const FileIcon = () => {
    return (
        <SvgContainer version="1.1" viewBox="0 0 24 24">
            <path
                d="M15.414 2H6C4.897 2 4 2.898 4 4V20C4 21.103 4.897 22 6 22H18C19.103 22 20 21.103 20 20V6.586L15.414 2ZM18.001 20H6V4H14V8H18L18.001 20Z"
                fill="#5A6678"
            />
            <rect x="3" y="8" width="6" height="3" rx="1" fill="#D95A5A" />
        </SvgContainer>
    );
};

export default FileIcon;

const SvgContainer = styled.svg`
  width: 24px;
  height: 24px;
  fill="none"
`;
