import { api, reducerUtil, config } from 'base-client';
import { configMap } from 'configurations';

import { reducerData as detailsData } from 'productDetails';
import { actions as errorsActions } from 'errors';
import { actions as analyticsActions } from 'analytics';
import { reducerData as searchData, actions as productSearchActions } from 'productSearch';
import { reducerData as tenantData } from 'tenant';
import getAttributeValue from 'utils/getAttributeValue';

/** This loads a product and adds its information to the redux store.
 * @param {string} [id] the product key
 */
const load = (id) => async (dispatch, getState) => {
    let state = getState();
    dispatch(reducerUtil.setSlice(detailsData, detailsData.general, undefined));

    try {
        let assetGroups = await reducerUtil.getSlice(tenantData, tenantData.assetGroups, state);

        let assetResult;
        if (assetGroups && assetGroups.length > 0) {
            let scopes = `?scopes=['attachments']`;
            assetResult = await dispatch(api.actions.get(`products/${id}${scopes}`));
        }
        let scopes = `?scopes=['product', 'attachments']`;
        const result = await dispatch(api.actions.get(`products/${id}${scopes}`));

        state = getState();

        const {
            manufacturer_id: manufacturerId,
            configurations: bimData,
            attachments,
            productInfo,
            sustainabilityData,
            ...general
        } = result;

        const resultAssets = assetResult && assetResult.assets;

        const { id: productId, name: productName, category: productCategory } = general;
        const information = (productInfo && productInfo.information) || [];

        // Callouts
        const calloutList = reducerUtil.getSlice(tenantData, tenantData.callouts, state) || [];
        const callouts = calloutList
            .map(({ attributeId: id, attributeName: name, displayName }) => ({
                id,
                name: displayName || name,
                value: getAttributeValue({ id, name, list: information, system: general }),
            }))
            .filter(({ name, value }) => name && value);

        // Assets
        const tenantAssets = reducerUtil.getSlice(tenantData, tenantData.assets, state) || {};
        const assetList = tenantAssets.detailPage || [];

        const assets = dispatch(productSearchActions.formatAssets(general, assetList, information));

        // Features
        const featureList = reducerUtil.getSlice(tenantData, tenantData.features, state) || [];
        const features = featureList
            .map(({ attributeId: id, attributeName: name, displayName }) => ({
                id,
                name: displayName || name,
                value: getAttributeValue({ id, name, list: information, system: general }),
            }))
            .filter(({ name, value }) => name && value);

        // Resources
        const resources = (attachments || []).map(({ id, name, downloadLocation: url, size, group, archived }) => {
            const meta = {
                product_id: productId,
                productName,
                productCategory,
                asset_id: id,
                assetName: name,
                assetUrl: url,
            };
            return { id, name, url, meta, size, group, archived };
        });

        // TODO: Remove duplicates from information?

        // Set product parts
        dispatch(reducerUtil.setSlice(detailsData, detailsData.callouts, callouts));
        dispatch(reducerUtil.setSlice(detailsData, detailsData.features, features));
        dispatch(reducerUtil.setSlice(detailsData, detailsData.assets, assets));
        dispatch(reducerUtil.setSlice(detailsData, detailsData.information, information));
        dispatch(reducerUtil.setSlice(detailsData, detailsData.bimData, bimData));
        dispatch(reducerUtil.setSlice(detailsData, detailsData.resources, resources));
        dispatch(reducerUtil.setSlice(detailsData, detailsData.attachments, attachments));
        dispatch(reducerUtil.setSlice(detailsData, detailsData.sustainabilityData, sustainabilityData));
        // send analytics
        const lastSearch = reducerUtil.getSlice(searchData, searchData.searchId, state);
        dispatch(
            analyticsActions.track('product', {
                lastSearch,
                product_id: productId,
                productName,
                productCategory,
            })
        );

        // set general product
        dispatch(reducerUtil.setSlice(detailsData, detailsData.general, general));
    } catch (error) {
        dispatch(errorsActions.error(error));
    }
};

export default {
    load,
};
