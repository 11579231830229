import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { InfiniteLoader, WindowScroller, AutoSizer, CellMeasurer, Masonry } from 'react-virtualized';

import Loading from '../../shared/Loading.js';
import SubmittalProductSelectCard from './SubmittalProductSelectionCard';

const overscanByPixels = 10;

const MasonryView = ({
    projectId,
    products = [],
    cellWidth,
    onResize,
    setMasonryRef,
    cellPositioner,
    cache,
    resetCells,
    onRemoveCell,
    handleRemoveProduct,
    infiniteListProps,
    ...submittalCardProps
}) => {
    // async fix for documents not loaded yet
    const [documentsLoaded, setDocumentsLoaded] = useState(false);

    useEffect(() => {
        const productsWithDocuments = products.filter((product) => {
            if (product.hasOwnProperty('documents')) {
                return true;
            }
        });

        productsWithDocuments.length === products.length ? setDocumentsLoaded(true) : setDocumentsLoaded(false);
    }, [products]);

    // update based on screen position
    const ref = useRef({});

    const [{ top }, setState] = useState({});

    const onScroll = () => {
        const { divRef, scrollRef } = ref.current || {};
        if (!divRef || !scrollRef) return;
        const rect = divRef.getBoundingClientRect();
        const newTop = rect.top + window.scrollY;
        if (top && Math.abs(top - newTop) < 1) return;
        scrollRef.updatePosition();
        setState({ top });
    };

    // all other logic
    if (!products || (products && products.length < 1)) return null;

    const cellRenderer = ({ index, key, parent, style }) => {
        return (
            <CellMeasurer cache={cache} index={index} key={key} parent={parent}>
                {({ measure }) => {
                    // callback function when colapse or expand product card.
                    const recalculatePosition = () => {
                        measure();
                        resetCells();
                    };
                    // add function to reset Masonry when remove any item.
                    const onRemove = (id) => {
                        handleRemoveProduct(id);
                        onRemoveCell();
                    };

                    return (
                        <div style={{ ...style, width: cellWidth }}>
                            <SubmittalProductSelectCard
                                product={products[index]}
                                canSelect={true}
                                index={index}
                                recalculatePosition={recalculatePosition}
                                onRemove={onRemove}
                                {...submittalCardProps}
                            />
                        </div>
                    );
                }}
            </CellMeasurer>
        );
    };

    return (
        <React.Fragment>
            {documentsLoaded ? (
                <>
                    <div ref={(el) => (ref.current.divRef = el)} />
                    <InfiniteLoader {...infiniteListProps}>
                        {({ onRowsRendered, registerChild }) => (
                            <WindowScroller
                                ref={(el) => (ref.current.scrollRef = el)}
                                {...{ overscanByPixels, onScroll }}
                            >
                                {({ height, scrollTop, isScrolling }) => {
                                    return (
                                        <AutoSizer
                                            disableHeight
                                            overscanByPixels={overscanByPixels}
                                            {...{ height, scrollTop, onResize }}
                                        >
                                            {({ width }) => (
                                                <Masonry
                                                    autoHeight={true}
                                                    cellCount={products.length}
                                                    cellMeasurerCache={cache}
                                                    ref={setMasonryRef}
                                                    {...{
                                                        cellPositioner,
                                                        cellRenderer,
                                                        height,
                                                        overscanByPixels,
                                                        isScrolling,
                                                        scrollTop,
                                                        width,
                                                    }}
                                                />
                                            )}
                                        </AutoSizer>
                                    );
                                }}
                            </WindowScroller>
                        )}
                    </InfiniteLoader>
                </>
            ) : (
                <Loading />
            )}
        </React.Fragment>
    );
};

MasonryView.propTypes = {
    cellWidth: PropTypes.number.isRequired,
    products: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        })
    ),
    onResize: PropTypes.func,
    setMasonryRef: PropTypes.func,
    cellPositioner: PropTypes.func.isRequired,
    cache: PropTypes.object.isRequired,
    resetCells: PropTypes.func.isRequired,
    onRemoveCell: PropTypes.func,
};

export default MasonryView;
