import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';

const DocumentGroup = styled.div`
    margin-bottom: 2rem;
    &:last-child {
        margin-bottom: 0;
    }
`;

const GroupName = styled.h4`
    font-size: 1.2rem;
    letter-spacing: 0.3px;
    margin: 0 0 0.5rem 0;
`;

const Asset = styled.div`
    margin-bottom: 1rem;
`;

const Label = styled.label`
    display: flex;
    align-items: center;
    cursor: pointer;
    word-break: break-all;
`;

const Input = styled.input`
    margin-right: 0.7rem;
`;

const Document = ({ type, files, idx, isGuest, selected, canSelect, handleSingleSelect }) => {
    return (
        <>
            {files.length > 0 && (
                <DocumentGroup key={idx} className="document-group">
                    <GroupName>{type}</GroupName>
                    {_.sortBy(files, 'name').map(({ id, name }, idx) => (
                        <Asset key={idx}>
                            <Label>
                                {canSelect && (
                                    <Input
                                        type="checkbox"
                                        id={id}
                                        name={name}
                                        value={id}
                                        checked={isGuest ? selected.includes(name) : selected.includes(id)} // guest has to use names for assets due to sboauth response
                                        onClick={handleSingleSelect}
                                        readOnly
                                    />
                                )}
                                {name}
                            </Label>
                        </Asset>
                    ))}
                </DocumentGroup>

            )}
        </>
    );
};

export default Document;
