import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import media from 'utils/media';

const ImageContainer = styled.div`
    margin-bottom: 2rem;
    height: 20rem;
    width: 100%;
    @media (min-width: 500px) {
        width: 35%;
    }
    ${media.medium`
    width: 25%;
  `} ${media.large`
    width: 20%;
    height: 23rem;
    margin-bottom: 0;
  `};
`;

/* prettier-ignore */
const Image = styled.img`
  max-height: 100%;
  max-width: 100%;
  display: block;
  margin: 0 auto;
`;

const ProductImage = ({ src }) => (
    <ImageContainer className="productImage">
        <Image src={src} />
    </ImageContainer>
);

ProductImage.propTypes = {
    src: PropTypes.string,
};

export default ProductImage;
