import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ButtonText, OutsideClick } from 'shared';

const DropdownContainer = styled.div``;

const MenuItem = styled(ButtonText)`
    padding: 0 1.5rem 0.75rem;
    margin-right: 1rem;
    transition: color 0.5s ease;
    cursor: pointer;
    position: relative;
    font-size: 1.4rem;
    outline: none;
    &:after {
        content: '';
        display: inline-block;
        width: 6px;
        height: 6px;
        position: relative;
        top: -2px;
        right: -10px;
        border: none;
        border-right: 2px solid ${(props) => props.theme.grey3};
        border-top: 2px solid ${(props) => props.theme.grey3};
        transform: rotate(135deg);
    }
`;

const DropdownContent = styled.div`
    display: none;
    position: absolute;
    right: -1rem;
    top: 3.5rem;
    padding: 1rem;
    background: #fff;
    border-radius: 3px;
    border: 1px solid ${(props) => props.theme.grey2};
    font-size: 1.4rem;
    &:before {
        content: '';
        position: absolute;
        right: 1rem;
        top: -0.4rem;
        width: 1rem;
        height: 1rem;
        background: #fff;
        border-radius: 3px;
        transform: rotate(45deg);
        box-shadow: -1px -1px 0px ${(props) => props.theme.grey2};
    }
    &.visible {
        display: block;
    }
`;

class Dropdown extends Component {
    static propTypes = {
        text: PropTypes.any,
        children: PropTypes.any,
    };

    state = {
        open: false,
    };

    toggleDropdown = () => {
        const { open } = this.state;
        this.setState({ open: !open });
    };

    closeDropdown = () => {
        this.setState({ open: false });
    };

    render() {
        const { open } = this.state;
        const { text, children, className } = this.props;

        return (
            <OutsideClick onOutside={this.closeDropdown}>
                <DropdownContainer className={`dropdown ${open ? 'active' : ''} ${className}`}>
                    <MenuItem onClick={this.toggleDropdown} className="dropdown-label">
                        {text}
                    </MenuItem>
                    <DropdownContent onClick={this.toggleDropdown} className={open ? 'visible' : ''}>
                        {children}
                    </DropdownContent>
                </DropdownContainer>
            </OutsideClick>
        );
    }
}

export default Dropdown;
