/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reducerUtil, login, config } from 'base-client';

import { accountUrl } from 'userAccount/utils';
import { CustomPage } from 'customPages';
import { Landing } from 'workflowProductSearch';
import Dashboard from 'dashboard';
import LandingPage from 'landingPage';
import LoginSignUp from 'login';
import ProductComparer from 'productComparer';
import ProductDetails from 'productDetails';
import ProductSearch from 'productSearch';
import ProjectDetails from 'projectDetails';
import ProjectEdit from 'projectEdit';
import Projects from 'projects';
import Proposal from 'proposal';
import RedirectSplash from 'redirectSplash';
import UserProfileContainer, { UserAccountDelete } from 'userAccount';

import { configMap } from 'configurations';
import { LoginRequired } from 'shared';
import { reducerData as tenantData } from 'tenant';

const Routes = (props) => {
    const { loginPage, dispatch, projectSubmittalSettings, customPages, theme } = props;
    const { brochureEnabled } = (theme && theme) || false;
    // const disabledDeleteAccount = dispatch(config.actions.getData(configMap.disabled.name, configMap.disabled.deleteAccount.name));
    const disabledSubmittalWorkflow = dispatch(
        config.actions.getData(configMap.disabled.name, configMap.disabled.submittalWorkflow.name)
    );

    const getCollectionGroupRoutes = () => {
        if (!customPages) return null;
        return (
            <Route path="">
                <Switch>
                    {customPages
                        .map(({ url }, idx) => {
                            if (!url) return null;
                            return <Route path={url} component={CustomPage} key={idx} />;
                        })
                        .filter((comp) => !!comp)}
                    <Route component={LandingPage} />
                </Switch>
            </Route>
        );
    };

    return (
        <Switch>
            <Route path={loginPage} component={LoginSignUp} />
            <Dashboard>
                <Switch>
                    <Route path="/redirect" component={RedirectSplash} />
                    <Route path="/compare" component={ProductComparer} />
                    <Route path="/products">
                        <Switch>
                            <Route path="/products/:id" component={ProductDetails} />
                            <Route component={ProductSearch} />
                        </Switch>
                    </Route>
                    <Route path="/projects">
                        <LoginRequired>
                            <Switch>
                                <Route path="/projects/:id/edit" component={ProjectEdit} />
                                <Route path="/projects/:id/submittal" component={Proposal} />
                                {brochureEnabled && <Route path="/projects/:id/brochure" component={Proposal} />}
                                <Route path="/projects/:id" component={ProjectDetails} />
                                <Route component={Projects} />
                            </Switch>
                        </LoginRequired>
                    </Route>
                    {!disabledSubmittalWorkflow && !projectSubmittalSettings.disabled && (
                        <Route path={'/submittal'} component={Landing} />
                    )}
                    {!disabledSubmittalWorkflow && !projectSubmittalSettings.disabled && brochureEnabled && (
                        <Route path={'/brochure'} component={Landing} />
                    )}
                    <Route path={accountUrl} component={UserProfileContainer} />
                    <Route path={'/gdpr-ccpa'} component={UserAccountDelete} />
                    {getCollectionGroupRoutes()}
                    <Route component={LandingPage} />
                </Switch>
            </Dashboard>
        </Switch>
    );
};

Routes.propTypes = {
    loginPage: PropTypes.string,
};

const mapStateToProps = (state) => ({
    loginPage: reducerUtil.getSlice(login.reducerData, login.reducerData.loginPage, state),
    projectSubmittalSettings: reducerUtil.getSlice(tenantData, tenantData.projectSubmittalSettings, state) || {},
    customPages: reducerUtil.getSlice(tenantData, tenantData.customPages, state),
    theme: reducerUtil.getSlice(tenantData, tenantData.theme, state),
});

export default withRouter(connect(mapStateToProps)(Routes));
