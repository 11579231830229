import styled from 'styled-components';

const Footer = styled.div`
    font-size: 1.2rem;
    text-align: center;
    & > p {
        & > button {
            color: ${(props) => props.theme.blue};
        }
    }
`;

export default Footer;
