import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    form {
        display: flex;
        flex-wrap: wrap;
    }
`;

const ContactForm = ({ children }) => <Container>{children}</Container>;

export default ContactForm;
