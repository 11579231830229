import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FormLabel, Input, ReduxFormError } from 'shared';

const Container = styled.div`
    margin-bottom: 1.5rem;
    text-align: left;
    margin-top: ${(props) => (props.labelTitle ? null : '-1rem')};
`;

const ReduxFormInput = ({ type, required, labelTitle, input, placeholder, meta, className }) => (
    <Container {...{ labelTitle, className }}>
        {labelTitle && <FormLabel {...{ required, labelTitle }} />}
        <Input {...input} placeholder={placeholder} type={type} />
        <ReduxFormError {...{ meta }} />
    </Container>
);

ReduxFormInput.propTypes = {
    /** This is the type attribute */
    type: PropTypes.string.isRequired,
    /** This is the placeholder attribute */
    placeholder: PropTypes.string,
    /** This is label content */
    labelTitle: PropTypes.string,
    /** This is the required attribute */
    required: PropTypes.bool,
    /** If validation fails, this should be true */
    /** This is the disabled attribute */
    disabled: PropTypes.bool,
    meta: PropTypes.any,
    input: PropTypes.any,
};

ReduxFormInput.defaultProps = {
    type: 'text',
};

export default ReduxFormInput;
