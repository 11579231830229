const PRODUCT_HIDDEN_SECTIONS = ['System', 'Custom', 'Hidden'];

const ATTRIBUTE_LINKS = ['url', 'assetselection', 'assetlink'];

const truncateNumber = (value) => {
    if (!value) return value;
    const arrString = value.toString().split(' ');
    const numberVal = parseFloat(arrString[0]);
    if (!numberVal || Math.floor(numberVal) === numberVal || arrString[0].split('.')[1].length <= 3) {
        return value;
    }
    return `${numberVal.toFixed(3).toString()} ${arrString[1] || ''}`;
};

const createElementId = () => {
    let dt = new Date().getTime();
    let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
};

const SUSTAINABILITY_GROUPS = ['Certificates & Documents', 'Environmental Characteristics', 'Rating Systems'];

export { PRODUCT_HIDDEN_SECTIONS, ATTRIBUTE_LINKS, truncateNumber, createElementId, SUSTAINABILITY_GROUPS };
