import React from 'react';
import PropTypes from 'prop-types';

const ExternalLink = ({ children, href, ...props }) => (
    <a target="_blank" rel="noopener noreferrer" {...{ href }} {...props}>
        {children}
    </a>
);

ExternalLink.propTypes = {
    children: PropTypes.any,
    href: PropTypes.string,
};

export default ExternalLink;
