import styled from 'styled-components';

const ButtonText = styled.button`
    background: none !important;
    color: inherit;
    border: none;
    padding: 0 !important;
    font: inherit;
    cursor: pointer;
`;

export default ButtonText;
