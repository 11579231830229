import React, { useState } from 'react';
import styled from 'styled-components';

import { FormInput, FormPasswordInput, LinkButton, ButtonText, PoweredConcora } from 'shared';
import Footer from './Footer';
import SocialContainer from './SocialContainer';

const LoginButton = styled(LinkButton)`
    width: 100%;
    margin: 0;
`;

const LoginForm = styled.form`
    text-align: center;
`;

const Login = ({
    email,
    emailError,
    errorMessage,
    handleInputChange,
    handleLogin,
    password,
    passwordError,
    toggleTypeChange,
}) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
        <>
            <SocialContainer />
            <LoginForm noValidate>
                <FormInput
                    onChange={handleInputChange}
                    value={email}
                    field="email"
                    labelTitle="Email Address"
                    placeholder="Enter your email address"
                    type="email"
                    hasError={!!emailError || !!errorMessage}
                    errorMessage={emailError}
                    required
                />
                <FormPasswordInput
                    onChange={(e) => handleInputChange(e, 'password')}
                    showPassword={showPassword}
                    setShowPassword={setShowPassword}
                    value={password}
                    field="password"
                    labelTitle="Password"
                    placeholder="Enter your password"
                    type="password"
                    hasError={!!passwordError || !!errorMessage}
                    errorMessage={passwordError}
                    required
                />
                <br />
                <LoginButton small marginTop="1rem" marginBottom="1rem" onClick={handleLogin}>
                    Log In
                </LoginButton>
            </LoginForm>
            <Footer>
                <p>
                    <ButtonText onClick={toggleTypeChange}>Forgot Password?</ButtonText>
                </p>
                <PoweredConcora margin="1rem 0 0" />
            </Footer>
        </>
    );
};

export default Login;
