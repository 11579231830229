import { v4 as uuid } from 'uuid';
import { reducerUtil } from 'base-client';

import { reducerData } from 'notification';

const sendNotifications = (notifications, noTimeout) => (dispatch, getState) => {
    if (!notifications) return;
    if (!Array.isArray(notifications)) notifications = [notifications];

    notifications = notifications.map(({ id: oldId, ...rest }) => {
        const id = oldId || uuid();
        let timeout = null;
        if (!noTimeout) {
            setTimeout(() => {
                dispatch(removeNotification(id));
                clearTimeout(timeout);
            }, 4000);
        }
        return { id, timeout, ...rest };
    });

    const oldNotifications = reducerUtil.getSlice(reducerData, reducerData.notifications, getState()) || [];
    dispatch(reducerUtil.setSlice(reducerData, reducerData.notifications, [...oldNotifications, ...notifications]));
};

const removeNotification = (id) => (dispatch, getState) => {
    const notifications = reducerUtil.getSlice(reducerData, reducerData.notifications, getState());
    const toRemove = notifications.find(({ id: itemId }) => itemId === id);
    if (toRemove) {
        clearTimeout(toRemove.timeout);
        const results = notifications.filter((n) => n.id !== id);
        dispatch(reducerUtil.setSlice(reducerData, reducerData.notifications, results));
    }
};

export default { sendNotifications, removeNotification };
