import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import ExternalLink from './ExternalLink';

const DisplayLink = ({ url, children, className, activeClassName, ...props }) => {
    return url.startsWith('/') ? (
        <NavLink to={url} {...props} {...{ activeClassName, className }}>
            {children}
        </NavLink>
    ) : (
        <ExternalLink href={url} className={className} {...props}>
            {children}
        </ExternalLink>
    );
};

DisplayLink.propTypes = {
    url: PropTypes.string.isRequired,
    children: PropTypes.any.isRequired,
};

export default DisplayLink;
