import { api, reducerUtil } from 'base-client';
import { v4 as uuid } from 'uuid';
import { reducerData as tenantData } from 'tenant';
import { actions as analyticsActions } from 'analytics';

import { actions as errorsActions } from 'errors';
import { actions as notificationActions, projectNotification } from 'notification';
import { reducerData as detailsData } from 'projectDetails';

const updateProject = (hideNotification) => async (dispatch, getState) => {
    try {
        const state = getState();
        const { [detailsData.name]: form } = state.form || {};
        const { values } = form || {};
        const { company_logo, ...trimmedFormValues } = values;

        const project = await dispatch(
            api.actions.patch(`projects/${trimmedFormValues.id}`, { ...trimmedFormValues }, true)
        );

        await Promise.all(
            ['contractor', 'general_contractor', 'architect', 'distributor'].map(async (o) => {
                try {
                    project[o] = await dispatch(
                        api.actions.put(`projects/${values.id}/participants/${o}`, { ...values[o] }, true)
                    );
                } catch (error) {
                    project[o] = {};
                }
            })
        );

        dispatch(reducerUtil.setSlice(detailsData, detailsData.generalData, project));
        if (!hideNotification) {
            dispatch(
                notificationActions.sendNotifications([
                    {
                        id: `saved-project-changes-${uuid()}`,
                        type: projectNotification.GENERAL,
                        title: 'Saved Project Changes',
                        body: 'Your project changes have been saved',
                    },
                ])
            );
        }

        // send analytics
        const { id, name } = project;
        dispatch(analyticsActions.track('projectupdate', { project_id: id, name }));
    } catch (error) {
        dispatch(errorsActions.error(error));
    }
};

const uploadCompanyLogo = (project_id, file) => async (dispatch) => {
    const body = new FormData();
    body.append('file', file);
    try {
        await dispatch(tryUpload(`projects/${project_id}/company_logo`, body));
        dispatch(
            notificationActions.sendNotifications([
                {
                    id: `saved-project-changes-${uuid()}`,
                    type: projectNotification.GENERAL,
                    title: 'Logo Added To Project',
                    body: 'Your company logo has successfully uploaded',
                },
            ])
        );
    } catch (error) {
        dispatch(errorsActions.error(error));
    }
};

const tryUpload =
    (url, body, attempts = 0) =>
    async (dispatch) => {
        try {
            return dispatch(api.actions.postFile(url, body, true));
        } catch (error) {
            if (attempts < 3) return dispatch(tryUpload(url, body, attempts + 1));
            else throw error;
        }
    };

const isUnique = (name) => async (dispatch, getState) => {
    const state = getState();
    const tenantId = reducerUtil.getSlice(tenantData, tenantData.tenant, state);
    const userId = dispatch(analyticsActions.getUserId());
    try {
        return await dispatch(
            api.actions.post(
                'projects/exists',
                {
                    name,
                    tenant_id: tenantId,
                },
                true
            )
        );
    } catch (error) {
        dispatch(errorsActions.error(error));
    }
};

export default {
    updateProject,
    uploadCompanyLogo,
    isUnique,
};
