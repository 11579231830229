import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import media from 'utils/media';
import { GRID_VIEW, LIST_VIEW, HYBRID_VIEW } from '../constants';

const GRID_ICON_BOX_SHADOW = '-6px -6px, 0 -6px, 6px -6px, -6px 0, 6px 0, -6px 6px, 0 6px, 6px 6px';
const LIST_ICON_BOX_SHADOW = '0 6px, 0 -6px';

const Container = styled.div`
    // display: none;
    // ${media.medium`
  //   display: block;
  // `};
`;

export const GridIcon = styled.i`
    cursor: pointer;
    margin-right: 5px;
    width: 20px;
    height: 18px;
    display: inline-block;
    &:before {
        content: '';
        height: 4px;
        width: 4px;
        display: inline-block;
        color: ${(props) => props.theme.grey2};
        background: ${(props) => props.theme.grey2};
        position: relative;
        top: -5px;
        left: 8px;
        box-shadow: ${GRID_ICON_BOX_SHADOW};
    }

    &.active {
        color: ${(props) => props.theme.grey4};
        &:before {
            background: ${(props) => props.theme.grey4};
            color: ${(props) => props.theme.grey4};
        }
    }
`;

export const HybridIcon = styled.i`
    cursor: pointer;
    position: relative;
    margin-right: 7px;
    height: 18px;
    display: inline-block;
    &:before {
        content: '';
        height: 7px;
        width: 20px;
        display: inline-block;
        color: ${(props) => props.theme.grey2};
        background: ${(props) => props.theme.grey2};
        position: relative;
        top: -8px;
        box-shadow: 0 9px;
    }

    &.active {
        &:before {
            background: ${(props) => props.theme.grey4};
            color: ${(props) => props.theme.grey4};
        }
    }
`;

export const ListIcon = styled.i`
    cursor: pointer;
    position: relative;
    height: 18px;
    display: inline-block;
    &:before {
        content: '';
        height: 4px;
        width: 20px;
        display: inline-block;
        color: ${(props) => props.theme.grey2};
        background: ${(props) => props.theme.grey2};
        position: relative;
        top: -5px;
        box-shadow: ${LIST_ICON_BOX_SHADOW};
    }

    &:after {
        content: '';
        display: inline-block;
        width: 2px;
        height: 18px;
        background: white;
        position: absolute;
        left: 4px;
        top: 0;
    }

    &.active {
        &:before {
            background: ${(props) => props.theme.grey4};
            color: ${(props) => props.theme.grey4};
        }
    }
`;

const viewIconPropTypes = { view: PropTypes.string, setView: PropTypes.func.isRequired };
const viewIconDefaultProps = { view: HYBRID_VIEW };

const ViewIcons = ({ view, setView }) => {
    const getClassName = (v) => (v === view ? 'active' : '');

    return (
        <Container>
            <GridIcon id="grid-icon" className={getClassName(GRID_VIEW)} onClick={() => setView(GRID_VIEW)} />
            <HybridIcon id="hybrid-icon" className={getClassName(HYBRID_VIEW)} onClick={() => setView(HYBRID_VIEW)} />
            <ListIcon id="list-icon" className={getClassName(LIST_VIEW)} onClick={() => setView(LIST_VIEW)} />
        </Container>
    );
};

ViewIcons.propTypes = viewIconPropTypes;
ViewIcons.defaultProps = viewIconDefaultProps;

export default ViewIcons;
