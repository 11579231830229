import React, { useState } from 'react';
import styled from 'styled-components';
import { FormInput, Button } from 'shared';

const FormGroup = styled.div`
    width: 100%;
    padding: 1.5rem 0 2rem;
    &:first-child {
        padding-top: 0;
    }
    &.projectInfor {
        padding-bottom: 0;
        display: flex;
    }
`;

const ProjectField = styled.div`
    width: 36%;
    &:first-child {
        margin-right: 4%;
        width: 60%;
    }
    > div {
        margin-bottom: 1rem;
    }
    input {
        margin: 0.5rem 0 0;
        border-radius: 0.4rem;
        border-color: ${(props) => props.theme.grey3};
        height: 3.2rem;
        &.error {
            border-color: ${(props) => props.theme.red};
        }
    }
`;

const ProjectFieldOne = styled(ProjectField)`
    width: 53% !important;
`;

const ProjectFieldTwo = styled(ProjectField)`
    width: 43% !important;
`;

const ResponseMessage = styled.span`
    color: ${(props) => props.theme.red};
    margin-top: 0.5rem;
    display: block;
    width: 100%;
    font-size: 1.3rem;
`;

const CreateProjectForm = (props) => {
    const { hideButton, handleCreateProject, handleChange, state, setState } = props;

    const onCreateProject = async () => {
        const { name, zipCode } = state || {};
        if (!name || !zipCode) {
            return setState({
                ...state,
                errorMessage: 'Field is required',
            });
        }
        const projectNameValid = await handleCreateProject({ name, zipCode });
        if (!projectNameValid) {
            setState({
                ...state,
                duplicateMessage: 'Please enter a unique project name',
            });
        }
    };

    const { name, zipCode, duplicateMessage, errorMessage } = state;
    let buttonText;

    buttonText = 'Create Project';

    return (
        <form noValidate>
            <FormGroup className="projectInfor">
                <ProjectFieldOne>
                    <FormInput
                        onChange={handleChange}
                        value={name}
                        field="name"
                        name="name"
                        className="name"
                        labelTitle="Project Name"
                        placeholder="Project Name"
                        type="text"
                        hasError={!name && errorMessage}
                        errorMessage={errorMessage}
                        required
                    />
                </ProjectFieldOne>
                <ProjectFieldTwo>
                    <FormInput
                        onChange={handleChange}
                        value={zipCode}
                        field="zipCode"
                        name="zipCode"
                        className="zipCode"
                        labelTitle="Location - Zip Code/Area Code"
                        placeholder="Zip Code/Area Code"
                        type="text"
                        hasError={!zipCode && errorMessage}
                        errorMessage={errorMessage}
                        required
                    />
                </ProjectFieldTwo>
            </FormGroup>
            {!duplicateMessage ? null : <ResponseMessage>{duplicateMessage}</ResponseMessage>}
            <FormGroup>
                {!hideButton && (
                    <Button small noUpperCase rightArrow type="submit" onClick={onCreateProject}>
                        {buttonText}
                    </Button>
                )}
            </FormGroup>
        </form>
    );
};

export default CreateProjectForm;
